import { OpenInNewOutlined, CheckCircleOutline, DeleteOutline } from '@mui/icons-material'
import { Chip, IconButton, Stack, Typography, Link } from '@mui/material'
import { ChipOwnProps } from '@mui/material/Chip/Chip'
import React, { useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { useCampaignOverviewPerformanceContext } from '@momentum/routes/campaign/e-commerce/performance/overviewPerformanceContext'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { EditProductDialog } from './EditProductDialog'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { calculateDatesToGoal } from '@momentum/routes/campaign/e-commerce/performance/timeToUtils'
import { DateTime } from 'luxon'
import { pluralWord } from '@momentum/utils/stringUtils'
import { ConfirmDeleteDialog } from '@momentum/routes/campaign/e-commerce/performance/components/ConfirmDeleteDialog'

type ProductCardProps = {
  index: number
  chipLabel: string
  chipColor: ChipOwnProps['color']
  canDelete?: boolean
}

export const ProductCard = ({ index, chipLabel, chipColor, canDelete }: ProductCardProps) => {
  const { isAdminView } = useUserSessionContext()
  const { deleteCampaignPerformanceProduct } = useCampaignOverviewPerformanceContext()
  const { watch, control } = useFormContext()
  const { remove } = useFieldArray({ control, name: 'products' })

  const [editProductOpen, setEditProductOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const comparisonMetric = watch('comparisonMetric')
  const comparisonMetricValue = watch('comparisonMetricValue')
  const product = watch(`products.${index}`) as CampaignPerformanceProduct
  const { productImage, productName, productUrl, bestSellerRankDataUploaded, reviewDataUploaded } = product
  const onDelete = async () => {
    setUploading(true)
    await deleteCampaignPerformanceProduct(product.id)
    remove(index)
    setUploading(false)
    setConfirmDeleteOpen(false)
  }

  return (
    <Stack px={1} pt={0.5} pb={0.5} height={'100%'}>
      <Stack direction={'row'} spacing={2} alignItems={'center'} flex={1}>
        <img
          src={getCdnImageUrl(productImage) || '/images/no-product.png'}
          alt={productName}
          style={{ width: 72, height: 72, objectFit: 'contain' }}
          onError={noProductImageAlt}
        />
        <Stack minWidth={0} width={'100%'} height={'100%'} alignSelf={'flex-start'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} mb={1} mt={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Chip size={'small'} label={chipLabel} color={chipColor || 'secondary'} />
            </Stack>
            {!!productUrl && (
              <Link href={productUrl} target={'_blank'} display={'inline-flex'} sx={{ verticalAlign: 'sub' }}>
                <OpenInNewOutlined color={'primary'} fontSize={'medium'} />
              </Link>
            )}
          </Stack>
          <Stack direction={'row'} spacing={0.5}>
            <Typography
              variant={'link2'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {productName}
            </Typography>
          </Stack>
          <Typography variant={'body1'}>
            {getTimeToText(calculateDatesToGoal(product, comparisonMetric, comparisonMetricValue))}
          </Typography>
          <Typography variant={'body1'}>
            time to {comparisonMetricValue?.toLocaleString()}{' '}
            {comparisonMetric === PerformanceComparisonMetric.REVIEWS ? 'reviews' : 'best seller rank'}
          </Typography>
        </Stack>
      </Stack>
      {isAdminView && (
        <Stack
          onClick={event => event.stopPropagation()}
          direction={'row'}
          mt={0.5}
          pt={0.5}
          justifyContent={'space-between'}
          sx={{ borderTop: theme => `1px solid ${theme.palette.grey.A200}` }}
        >
          {comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK && bestSellerRankDataUploaded && (
            <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
              <CheckCircleOutline color={'success'} fontSize={'mSmall'} />
              <Typography variant={'caption3'}>BSR uploaded</Typography>
            </Stack>
          )}
          {comparisonMetric === PerformanceComparisonMetric.REVIEWS && reviewDataUploaded && (
            <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
              <CheckCircleOutline color={'success'} fontSize={'mSmall'} />
              <Typography variant={'body1'}>Time to review uploaded</Typography>
            </Stack>
          )}
          <Stack direction={'row'} spacing={1} alignItems={'center'} ml={'auto'}>
            <Typography
              color={'primary'}
              onClick={e => {
                !uploading && setEditProductOpen(true)
              }}
              typography={'link2'}
              alignSelf={'center'}
              sx={{ cursor: 'pointer' }}
            >
              Edit
            </Typography>
            {canDelete && (
              <IconButton
                size={'small'}
                disabled={uploading}
                onClick={e => {
                  setConfirmDeleteOpen(true)
                }}
                sx={{ p: 0 }}
              >
                <DeleteOutline fontSize={'medium'} color={uploading ? 'disabled' : 'primary'} />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
      <EditProductDialog
        product={product}
        productIndex={index}
        open={editProductOpen}
        onClose={() => setEditProductOpen(false)}
      />
      <ConfirmDeleteDialog open={confirmDeleteOpen} onConfirm={onDelete} onClose={() => setConfirmDeleteOpen(false)} />
    </Stack>
  )
}

const getTimeToText = (input?: { startDate: string; endDate: string }) => {
  if (input) {
    const { startDate, endDate } = input
    const diff = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), ['months', 'days'])

    const components = []
    if (diff.months > 0) {
      components.push(pluralWord(`${diff.months} month`, diff.months > 1))
    }
    if (diff.days >= 1) {
      components.push(pluralWord(`${diff.days} day`, diff.days > 1))
    }
    return components.join(', ')
  }
  return '--'
}
