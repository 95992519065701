import { ModelTypes, ProposalGoal, CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { sumBy, groupBy, entries, sum, min, max } from 'lodash'
import { Campaign, AutoBuyReleaseSchedule } from './selectors'
import { Product, getCreatorType } from '@productwindtom/shared-momentum'

import { DateTime } from 'luxon'

export const mapCampaign = (
  campaign: Campaign
):
  | (Omit<ModelTypes['CampaignDetails'], 'creators' | 'product'> & {
      productIds: string[]
      flows: (Product & { creatorCount?: number; unitsPurchased?: number; creatorType?: CreatorType })[]
    })
  | undefined => {
  const skuId = campaign.skuId

  if (!skuId) {
    return
  }

  const productsWithType = (campaign.campaignProducts?.items || [])
    .filter(notEmpty)
    .map(p => ({ ...p, creatorType: getCreatorType(p) }))
    .filter(p => p.creatorType)
    .map(p => ({
      ...p,
      // Setting brand advocate flows to have slightly different values which better represent them to exclude lift creators
      creatorCount: p.creatorType === CreatorType.ADVOCATE ? p.brandAdvocateCount : p.creatorCount,
      unitsPurchased:
        p.creatorType === CreatorType.ADVOCATE ? min([p.brandAdvocateCount, p.unitsPurchased || 0]) : p.unitsPurchased
    }))

  const startDate = campaign.actualStartDate || campaign.anticipatedStartDate

  if (!startDate) {
    return
  }

  const numCreators = sumBy(productsWithType, 'creatorCount')
  const numCreatorsJoined = sumBy(productsWithType, 'unitsPurchased') || 0

  const startDateTime = DateTime.fromISO(startDate)
  const now = DateTime.now()

  return {
    id: campaign.id,
    createdAt: campaign.createdAt,
    brandId: campaign.brandId,
    title: campaign.title || 'Campaign',
    goal: campaign.goal ? ProposalGoal[campaign.goal] : ProposalGoal.PRODUCT_LAUNCH,
    anticipatedStartDate: campaign.anticipatedStartDate,
    // If no purchases and start date has passed, set it to tomorrow
    startDate:
      !campaign.endDate && startDateTime < now && !numCreatorsJoined
        ? now.plus({ days: 1 }).toJSDate().toISOString()
        : startDate,
    preLaunchDate: campaign.preLaunchDate,
    endDate: campaign.endDate,
    skuId: campaign.skuId!,
    cost: campaign.marketingFee || 0,
    productIds: productsWithType.map(cp => cp.id).filter(notEmpty),
    numCreators: numCreators || 0,
    numCreatorsJoined,
    numPremiumUgcCreators: getCreatorCountForType(CreatorType.PREMIUM_UGC, productsWithType),
    numUgcCreators: getCreatorCountForType(CreatorType.UGC, productsWithType),
    numSocialCreators: sum(socialCreatorTypes.map(t => getCreatorCountForType(t, productsWithType))),
    numBrandAdvocateCreators: getCreatorCountForType(CreatorType.ADVOCATE, productsWithType),
    releaseSchedule: calculateWeeklyReleaseSchedule(startDate, campaign),
    expectedSocialPostCount: campaign.socialCommittedContentCount,
    expectedPremiumUgcPostCount: campaign.premiumLifestyleCommittedContentCount,
    expectedUgcPostCount: campaign.lifestyleCommittedContentCount,
    expectedReviewsCount: campaign.committedReviewsCount,
    expectedClicksCounts: campaign.committedClicks,
    expectedPlacementWins: campaign.committedPlacementWinsCount,
    flows: productsWithType
  }
}

const socialCreatorTypes = [CreatorType.TT, CreatorType.IG, CreatorType.YOUTUBE]

const getCreatorCountForType = (
  creatorType: CreatorType,
  productsWithType: { id: string; creatorType?: CreatorType; creatorCount?: number }[]
) => {
  return sumBy(
    productsWithType.filter(p => p.creatorType === creatorType),
    b => b.creatorCount || 0
  )
}

type ReleaseSchedule = {
  brandAdvocatesWeeklySchedule: number[]
  ugcWeeklySchedule: number[]
  premiumUgcWeeklySchedule: number[]
  socialWeeklySchedule: number[]
}

/*
 Uses the autobuy schedules to determine weekly releases for the campaign which is used on the
 front-end for campaign timeline related actions. Potentially sometime in the future, it would be daily level
 and on a per flow bases
 */
const calculateWeeklyReleaseSchedule = (startDate: string, campaign: Campaign): ReleaseSchedule | undefined => {
  const schedules = (campaign.campaignProducts?.items || [])
    .map(p => {
      const creatorType = getCreatorType(p)
      if (creatorType === CreatorType.ADVOCATE) {
        //Need to adjust schedule to not include lift creators
        const today = DateTime.now().toISODate()
        let totalBrandAdvocates = p.brandAdvocateCount || 0
        p.autoBuyReleaseSchedule = {
          items: (p.autoBuyReleaseSchedule?.items || []).map(a => {
            const purchases = min([totalBrandAdvocates, a.actualPurchases || 0]) || 0
            const adjusted = min([totalBrandAdvocates, a.adjustedDesiredPurchases || 0]) || 0
            totalBrandAdvocates -= a.date < today ? purchases : adjusted

            return {
              ...a,
              actualPurchases: purchases,
              adjustedDesiredPurchases: a.date < today ? a.adjustedDesiredPurchases : adjusted
            }
          })
        }
      }
      if (p.autoBuyReleaseSchedule?.items.length && creatorType) {
        return (p.autoBuyReleaseSchedule?.items || []).map(a => ({ ...a, creatorType }))
      }
    })
    .filter(notEmpty)
    .flat()

  if (!schedules.length) {
    return undefined
  }
  const startDateTime = DateTime.fromISO(startDate.split('T')[0])
  const groupedByType = groupBy(schedules, 'creatorType')

  return {
    brandAdvocatesWeeklySchedule: calculateWeekReleaseSchedule(
      startDateTime,
      groupedByType[CreatorType.ADVOCATE] || []
    ),
    ugcWeeklySchedule: calculateWeekReleaseSchedule(startDateTime, groupedByType[CreatorType.UGC] || []),
    premiumUgcWeeklySchedule: calculateWeekReleaseSchedule(startDateTime, groupedByType[CreatorType.PREMIUM_UGC] || []),
    socialWeeklySchedule: calculateWeekReleaseSchedule(
      startDateTime,
      socialCreatorTypes.map(t => groupedByType[t] || []).flat()
    )
  }
}

const calculateWeekReleaseSchedule = (
  startDateTime: DateTime,
  dailyRecords: (AutoBuyReleaseSchedule & { creatorType: CreatorType })[]
) => {
  const groupedByDate = groupBy(dailyRecords, 'date')
  const today = DateTime.now().toISODate()

  const schedule = entries(groupedByDate)
    .map(([date, schedules]) => ({
      date,
      adjustedDesiredPurchases: sumBy(schedules, s =>
        s.date < today ? s.actualPurchases || 0 : s?.adjustedDesiredPurchases || 0
      ),
      week: Math.floor(DateTime.fromISO(date).diff(startDateTime, 'weeks').weeks)
    }))
    .filter(a => a.adjustedDesiredPurchases > 0)

  const maxWeek = max(schedule.map(s => s.week)) || -1

  const data = []

  for (let i = 0; i <= maxWeek; i++) {
    const weekData = schedule.filter(s => s.week === i)
    data.push(sumBy(weekData, s => s.adjustedDesiredPurchases))
  }
  return data
}
