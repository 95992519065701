import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { listBrandCampaigns, listProducts, listProposals, getProposal } from '@momentum/routes/brand/queries'
import { Campaign, ManualProduct, Product, ProductUrl, Proposal } from '@momentum/routes/brand/types'
import { Brand, Company } from '@momentum/routes/queries'
import { getStoreFromRegionRetailer } from '@momentum/utils/storeUtils'
import { Typography } from '@mui/material'
import { Retailer, STORE_TO_RETAILER } from '@productwindtom/shared-momentum'
import { sleep } from '@productwindtom/shared-node'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { captureException } from '@sentry/react'
import { isFunction, map, max, uniqBy } from 'lodash'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'
import AddProductDialog from '../components/add-product-dialog'
import { adjustProductRecommendationVisibility, createManualProduct, createProductFromUrl } from '../mutations'
import { BrandContextType } from './types'

const BrandContext = createContext<BrandContextType>({
  brand: {} as Brand,
  company: {} as Company,
  proposals: [],
  setProposals: () => [],
  refreshProposal: async () => undefined,
  products: [],
  recommendations: [],
  campaigns: [],
  refreshCampaigns: async () => undefined,
  handleAddProduct: async (_data: ManualProduct | ProductUrl) => undefined,
  isAddProductActiveOrCallback: false,
  setIsAddProductActiveOrCallback: () => false,
  adjustProductRecommendationVisibility: async () => console.log('Not implemented')
})

const BrandProvider = () => {
  const { companies, selectedBrand: brand, loadingResources, countsByBrand } = useUserSessionContext()
  const brandId = brand?.id

  const [products, setProducts] = useState<Product[] | undefined>()
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [proposals, setProposals] = useState<Proposal[] | undefined>()
  const [recommendations, setRecommendations] = useState<Product[] | undefined>()
  const [campaigns, setCampaigns] = useState<Campaign[] | undefined>()
  const [isAddProductActiveOrCallback, setIsAddProductActiveOrCallback] = useState<
    boolean | ((product: Product) => void)
  >(false)

  const company = companies.find(c => c.id === brand?.companyId)
  const productCount = brandId ? countsByBrand?.[brandId]?.totalProducts : undefined

  useEffect(() => {
    if (brandId) {
      setCampaigns(undefined)
      setProducts(undefined)
      setProposals(undefined)
      setRecommendations(undefined)

      listProducts(brandId, productCount).then(ps => {
        setProducts(ps)
        setRecommendations(ps.filter(({ recommendations }) => !!recommendations?.length))
        setLoadingProducts(false)
      })
      listProposals(brandId).then(setProposals)
      refreshCampaigns()
    }
  }, [brandId, productCount])

  const refreshCampaigns = async () => {
    if (brandId) {
      setCampaigns(await listBrandCampaigns(brandId))
    }
  }

  const refreshProposal = async (proposalId: string) => {
    const proposal = await getProposal(proposalId)
    if (proposal) {
      setProposals(prevState => (prevState || []).map(p => (p.id === proposalId ? proposal : p)))
    }
  }

  const amazonScrapedAt = useMemo(() => {
    return (
      brand?.lastScrapedAt ||
      max(
        map(
          recommendations?.filter(r => STORE_TO_RETAILER[r.store] === Retailer.AMAZON),
          product => map(product.recommendations, 'createdAt')
        ).flat()
      )
    )
  }, [recommendations, brand])

  const walmartScrapedAt = useMemo(() => {
    return (
      brand?.walmartLastScrapedAt ||
      max(
        map(
          recommendations?.filter(r => STORE_TO_RETAILER[r.store] === Retailer.WALMART),
          product => map(product.recommendations, 'createdAt')
        ).flat()
      )
    )
  }, [recommendations, brand])

  if (loadingResources || !brand || !company) {
    return <Loading />
  }

  const handleAdjustProductRecommendationVisibility = async (id: string, isVisible: boolean) => {
    const newProducts = await adjustProductRecommendationVisibility({ id, isVisible })
    setProducts(prevState => (prevState || []).map(product => (product.id === id ? newProducts : product)))
    setRecommendations(prevState => (prevState || []).map(product => (product.id === id ? newProducts : product)))
  }

  const handleAddProduct = async (submitValues: ProductUrl | ManualProduct, isRetry?: boolean) => {
    try {
      const brandId = brand.id
      const region = brand.region

      let product: Product | undefined
      if ((submitValues as ProductUrl).productUrl) {
        const { productUrl } = submitValues as ProductUrl

        product = await createProductFromUrl({
          brandId,
          productUrl
        })
      } else {
        const {
          productId,
          productTitle: title,
          productImage,
          retailer,
          price,
          listingLink,
          estimatedMonthlyUnitsSold
        } = submitValues as ManualProduct

        product = await createManualProduct({
          id: productId,
          brandId,
          store: getStoreFromRegionRetailer(region, retailer),
          name: title,
          price: toCurrencyStringCents(price, brand.region),
          listingLink,
          estimatedMonthlyUnitsSold: estimatedMonthlyUnitsSold && +estimatedMonthlyUnitsSold,
          image: productImage
        })
      }

      if (product) {
        if (isFunction(isAddProductActiveOrCallback)) {
          isAddProductActiveOrCallback(product)
        }

        setProducts(currentProducts => uniqBy([product!, ...(currentProducts || [])], 'id'))
        setIsAddProductActiveOrCallback(false)
        toast(<Typography variant={'subtitle2'}>Successfully added product!</Typography>, { type: 'success' })
      } else {
        toast(<Typography variant={'subtitle2'}>We were not able to add this product</Typography>, { type: 'error' })
      }

      return product
    } catch (err: any) {
      captureException(err)
      console.log('err', err)
      const error = err?.errors?.[0]

      if (error.errorType === 'ERROR') {
        toast(<Typography variant={'subtitle2'}>{error.message}</Typography>, { type: 'error' })
      } else if (error?.message?.includes('Execution timed out')) {
        if (isRetry !== true) {
          toast(
            <Typography variant={'subtitle2'}>
              Finding the product is taking longer than expected, this operation may take an additional 30 seconds.
            </Typography>,
            { type: 'warning' }
          )

          await sleep(15)

          await handleAddProduct(submitValues, true)
        } else {
          toast(
            <Typography variant={'subtitle2'}>
              We were unable to create your product, we are attempting to do this behind the scenes! Please check your
              products list in a few minutes and then try again if the product does not exist.
            </Typography>,
            { type: 'error' }
          )
        }
      }
    }
  }

  return (
    <BrandContext.Provider
      value={{
        brand,
        company,
        products,
        loadingProducts,
        proposals,
        campaigns,
        refreshCampaigns,
        setProposals,
        refreshProposal,
        recommendations,
        handleAddProduct,
        adjustProductRecommendationVisibility: handleAdjustProductRecommendationVisibility,
        isAddProductActiveOrCallback,
        setIsAddProductActiveOrCallback,
        amazonScrapedAt,
        walmartScrapedAt
      }}
    >
      <Outlet />
      <AddProductDialog />
    </BrandContext.Provider>
  )
}

BrandContext.displayName = 'BrandContext'

export default BrandProvider

export const useBrandContext = () => useContext(BrandContext)
