import {
  styled,
  TableCell as MuiTableCell,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableBody
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { max, sum } from 'lodash'
import { CREATOR_TYPE_PRICING_CONFIG } from '@momentum/utils/brandPricing'

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`
export const CreatorScheduleInput = () => {
  const { getValues } = useFormContext<ProposalCreateForm>()

  const creatorPricing = getValues('creatorPricing').filter(r => r.numCreators > 0)
  const weeks = max(creatorPricing.map(creator => creator.schedule.length)) || 0
  const weeksArray = Array.from({ length: weeks }, (_, i) => i + 1)

  const totals = creatorPricing
    .map(r => r.schedule)
    .reduce((acc, schedule) => {
      for (let i = 0; i < schedule.length; i++) {
        acc[i] = (acc[i] || 0) + schedule[i]
      }
      return acc
    }, [])

  return (
    <Stack spacing={2}>
      <TableContainer
        sx={{
          borderRadius: '4px',
          borderTop: '1px solid #eaeaea',
          borderLeft: '1px solid #eaeaea',
          borderRight: '1px solid #eaeaea'
        }}
      >
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell padding={'none'} width={60} />
              {creatorPricing.map((creator, index) => (
                <TableCell key={index}>
                  <Stack alignItems={'center'}>
                    <Typography variant={'label2'}>{CREATOR_TYPE_PRICING_CONFIG[creator.type]?.title}</Typography>
                    <Typography variant={'label2'}>{sum(creator.schedule)}</Typography>
                  </Stack>
                </TableCell>
              ))}
              <TableCell>
                <Stack alignItems={'center'}>
                  <Typography variant={'label2'}>Total creators</Typography>
                  <Typography variant={'label2'}>{sum(totals)}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weeksArray.map((week, weekIndex) => (
              <TableRow key={week}>
                <TableCell sx={{ py: 2 }}>
                  <Typography variant={'label2'}>Week {week}</Typography>
                </TableCell>
                {creatorPricing.map((creator, index) => (
                  <TableCell key={index} align={'center'} data-cy={`${creator.type}.week.${weekIndex}`}>
                    <Typography variant={'label3'}>{(creator.schedule[weekIndex] || 0).toLocaleString()}</Typography>
                  </TableCell>
                ))}
                <TableCell align={'center'} data-cy={`total.week.${weekIndex}`}>
                  <Typography variant={'label2'}>{totals[weekIndex]?.toLocaleString()}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
