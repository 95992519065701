import SocialPortraitIcon from '@momentum/components/icons/social-portrait'
import { BenchmarkProductsInput } from '@momentum/components/proposal-common/BenchmarkProductsInput'
import { MultiTextListInput } from '@momentum/components/proposal-common/MultiTextListInput'
import { Question } from '@momentum/components/proposal-common/Question'
import { Product, Proposal } from '@momentum/routes/campaign/context/queries'
import { EmojiPeopleOutlined, PersonSearchOutlined, SlowMotionVideoOutlined, StarOutline } from '@mui/icons-material'
import { Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { CampaignProposalResults } from '@productwindtom/shared-momentum'
import { InvoicePaymentType, InvoiceStatus } from '@productwindtom/shared-momentum-zeus-types'
import { DateInput, RadioInput } from '@productwindtom/ui-base'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import { useCampaignContext } from '../../context/CampaignContext'

export const CampaignImpact = ({
  proposal,
  proposalResults,
  product
}: {
  proposal: Proposal
  proposalResults: CampaignProposalResults
  product?: Product
}) => {
  const {
    campaignDetails: { anticipatedStartDate }
  } = useCampaignContext()
  const invoicePaid = useWatch({ name: 'invoiceStatus' }) as InvoiceStatus

  const isLaunchDateDisabled = DateTime.fromISO(anticipatedStartDate).toISODate()! <= DateTime.now().toISODate()

  return (
    <Stack>
      <Stack spacing={3}>
        <Stack py={2} spacing={4}>
          {!proposal.hiddenSeo && (
            <MetricRow
              title={'Estimated organic & paid SEO placement wins'}
              subtitle={
                'ProductWind creators drive relevancy to win organic and paid slots on Page 1 of search results.'
              }
              icon={<PersonSearchOutlined color={'primary'} />}
              metricLabel={'Page 1 wins'}
              value={uniq(proposalResults?.estimatedPageOneWins)
                ?.map(v => v.toLocaleString())
                .join(' - ')}
            />
          )}

          {!proposal.hiddenUgcContent && (
            <MetricRow
              title={'Unpublished premium UGC content'}
              subtitle={'UGC and Premium UGC creators upload videos and photos directly to the Momentum platform.'}
              icon={<SlowMotionVideoOutlined color={'primary'} />}
              value={proposalResults.numUgcContent}
              metricLabel={'Photos & videos'}
            />
          )}

          {!proposal.hiddenNumReviews && (
            <MetricRow
              title={'Est. incremental reviews & ratings'}
              subtitle={
                'Momentum campaigns start the retail flywheel and UGC creators drive incremental reviews & ratings.'
              }
              icon={<StarOutline color={'primary'} />}
              value={proposalResults.numReviews}
              metricLabel={'Reviews & ratings'}
            />
          )}

          {!proposal.hiddenSocialContent && (
            <MetricRow
              title={'Published social content'}
              subtitle={'Social creators post videos on Instagram and/or Tiktok.'}
              icon={<SocialPortraitIcon color={'primary'} />}
              metricLabel={'Social posts & stories'}
              value={proposalResults.numSocialContent}
            />
          )}

          {!proposal.hiddenPdpTraffic && (
            <MetricRow
              title={'Est. traffic from ProductWind creators'}
              subtitle={'Brand Advocates drive traffic to the product detail page.'}
              icon={<EmojiPeopleOutlined color={'primary'} />}
              value={proposalResults.estimatedPdpViews}
              metricLabel={'Views'}
            />
          )}
        </Stack>

        <Stack spacing={4} py={2}>
          <Question primaryText={'Original date scheduled in proposal'}>
            <Box>
              <DateInput name="launchDate" disabled readOnly />
            </Box>
          </Question>
          <Question primaryText={'Select campaign launch date'}>
            <Box>
              <DateInput
                name="campaignLaunchDate"
                minDate={DateTime.now().plus({ days: 1 })}
                disabled={isLaunchDateDisabled}
                readOnly={isLaunchDateDisabled}
              />
            </Box>
          </Question>
          <Question
            primaryText={'How many units do you expect to sell during the first 30 days of the campaign?'}
            subtext={
              'Do NOT include expected sales from this ProductWind campaign. ProductWind uses this to estimate when you will receive reviews & ratings.'
            }
          >
            <Box>
              <TextField variant={'outlined'} value={proposal.estimatedUnitsSoldPerMonth} disabled />
            </Box>
          </Question>
          <Stack>
            <Typography variant={'label1'}>How would you like to pay for this campaign?</Typography>
            <RadioGroup>
              <FormControlLabel
                label={<Typography color={'black'}>Use existing credits</Typography>}
                control={
                  <Radio
                    readOnly
                    disabled
                    checked={proposal.paymentType === InvoicePaymentType.CREDITS}
                    sx={{ py: 0.5 }}
                    disableRipple
                  />
                }
              />
              <FormControlLabel
                label={<Typography color={'black'}>There is an existing invoice</Typography>}
                control={
                  <Radio
                    readOnly
                    checked={proposal.paymentType === InvoicePaymentType.EXISTING_INVOICE}
                    sx={{ py: 0.5 }}
                    disableRipple
                    disabled
                  />
                }
              />
              <FormControlLabel
                label={<Typography color={'black'}>Generate a new invoice</Typography>}
                control={
                  <Radio
                    readOnly
                    checked={proposal.paymentType === InvoicePaymentType.NEW_INVOICE}
                    sx={{ py: 0.5 }}
                    disableRipple
                    disabled
                  />
                }
              />
              {proposal.paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE && (
                <FormControlLabel
                  label={<Typography color={'black'}>Pay at the end of the month</Typography>}
                  control={
                    <Radio
                      readOnly
                      checked={proposal.paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE}
                      sx={{ py: 0.5 }}
                      disableRipple
                      disabled
                    />
                  }
                />
              )}
            </RadioGroup>
          </Stack>
          {proposal.paymentType === InvoicePaymentType.EXISTING_INVOICE && (
            <Stack>
              <Typography variant={'label1'}>Has the invoice already been paid?</Typography>
              <RadioInput
                name={'invoiceStatus'}
                radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
                options={[
                  { label: <Typography variant={'label3'}>Paid</Typography>, value: InvoiceStatus.PAID },
                  { label: <Typography variant={'label3'}>Not paid</Typography>, value: InvoiceStatus.NOT_PAID }
                ]}
              />
            </Stack>
          )}
          {proposal.paymentType === InvoicePaymentType.EXISTING_INVOICE && (
            <Question
              primaryText={'When is payment due?'}
              subtext={`The campaign start date is scheduled for ${DateTime.fromISO(proposal.launchDate).toLocaleString(DateTime.DATE_SHORT)}.`}
            >
              <Box sx={{ maxWidth: 165 }}>
                <DateInput fullWidth name={'invoiceDueDate'} />
              </Box>
            </Question>
          )}

          {proposal.paymentType === InvoicePaymentType.EXISTING_INVOICE && invoicePaid === InvoiceStatus.PAID && (
            <Question primaryText={'Date paid'}>
              <Box>
                <DateInput name={'invoicePaidDate'} />
              </Box>
            </Question>
          )}

          {proposal.paymentType === InvoicePaymentType.NEW_INVOICE && (
            <Question primaryText={'When is the total payment due?'}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    value={proposal.invoiceDueDate ? DateTime.fromISO(proposal.invoiceDueDate) : undefined}
                    readOnly
                    disabled
                  />
                </LocalizationProvider>
              </Box>
            </Question>
          )}
          {proposal.paymentType === InvoicePaymentType.NEW_INVOICE && (
            <Question
              primaryText={'Enter billing emails and contacts'}
              subtext={`An invoice will be generated and emailed to these contacts.`}
            >
              <Stack spacing={1}>
                {(proposal.billingContacts || []).map(bc => (
                  <Stack key={bc.email} direction={'row'} spacing={1}>
                    <TextField variant={'outlined'} value={bc.email} disabled sx={{ width: 250 }} />
                    <TextField variant={'outlined'} value={bc.name} disabled sx={{ width: 250 }} />
                  </Stack>
                ))}
              </Stack>
            </Question>
          )}

          {proposal.paymentType === InvoicePaymentType.NEW_INVOICE && (
            <Stack spacing={2}>
              <Typography variant={'label1'}>PO number</Typography>
              <Box>
                <TextField variant={'outlined'} value={proposal.invoicePONumber} disabled />
              </Box>
            </Stack>
          )}

          <Question
            primaryText={'What search terms are you tracking for this product? (optional)'}
            subtext={
              'Momentum will track your product across several search terms. We will include your search term when building our list.'
            }
          >
            <MultiTextListInput
              name={'searchTerms'}
              addText={'Add search term'}
              placeholder={'Enter search term'}
              max={5}
            />
          </Question>
          <Question
            primaryText={'What products are you benchmarking against? (optional)'}
            subtext={
              'Momentum will benchmark your product against other relevant products for traffic, sales, and revenue. Add up to 3 relevant products. '
            }
          >
            <BenchmarkProductsInput
              name={'benchmarkProducts'}
              addText={'Add product'}
              placeholder={'Enter product URL'}
              storeName={product?.store}
              max={3}
            />
          </Question>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  )
}

const MetricRow = ({
  icon,
  title,
  subtitle,
  metricLabel,
  value
}: {
  icon: ReactElement
  title: string
  subtitle: string
  metricLabel?: string
  value: string | number
}) => {
  return (
    <Grid container>
      <Grid item xs={8}>
        <Stack direction={'row'} spacing={1}>
          {icon}
          <Stack>
            <Typography variant={'label1'}>{title}</Typography>
            <Typography variant={'caption2'}>{subtitle}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
          <MetricsDisplay label={metricLabel} value={value} />
        </Stack>
      </Grid>
    </Grid>
  )
}

const MetricsDisplay = ({ value, label }: { value?: string | number | ReactElement; label?: string }) => {
  return (
    <Stack>
      <Typography variant={'label1'} color={'black'} data-cy="metricValue">
        {value ? (typeof value === 'number' ? value.toLocaleString() : value) : '--'}{' '}
      </Typography>
      {!!label && (
        <Typography variant={'caption2'} color={'black'}>
          {label}
        </Typography>
      )}
    </Stack>
  )
}
