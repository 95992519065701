import React from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { User, Brand, Company, Agency, BaseBrand } from '@momentum/routes/queries'
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export type CountsByBrands = {
  [key: string]: ModelTypes['SearchBrandCountsOutput']
}

export type BrandCounts = Omit<ModelTypes['SearchBrandCountsOutput'], 'brandId'>

export type SessionContext = {
  authUser: CognitoUser
  profile: User
  brands: (BaseBrand & BrandCounts)[]
  companies: Company[]
  loadingResources: boolean
  refreshBrand: (brandId: string) => Promise<void>
  refreshCompany: (companyId: string) => Promise<void>
  refreshAgency: (agencyId: string) => Promise<void>
  isAdmin: boolean
  isAdminView: boolean
  isAdminManagementView: boolean
  isViewOnly: boolean
  setIsAdminView: (isAdminView: boolean) => void
  selectedCompany?: Company
  selectedBrand?: Brand
  setSelectedBrand: (brandId?: string) => void
  agency?: Agency
  agencies: Agency[]
  agencyId?: string
  setAgencyId: (agencyId?: string) => void
  countsByBrand?: CountsByBrands
}

const UserSessionContext = React.createContext<SessionContext>({
  authUser: undefined as any,
  profile: undefined as any,
  brands: [],
  companies: [],
  loadingResources: false,
  refreshBrand: async () => console.log('Not implemented'),
  refreshCompany: async () => console.log('Not implemented'),
  refreshAgency: async () => console.log('Not implemented'),
  isAdmin: false,
  isAdminView: false,
  isAdminManagementView: false,
  isViewOnly: true,
  setIsAdminView: () => console.log('Not implemented'),
  selectedCompany: undefined,
  selectedBrand: undefined,
  setSelectedBrand: () => console.log('Not implemented'),
  agencies: [],
  setAgencyId: () => console.log('Not implemented')
})
UserSessionContext.displayName = 'UserSessionContext'

export default UserSessionContext

export const useUserSessionContext = () => React.useContext(UserSessionContext)
