import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product } from '@momentum/routes/brand/types'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Form } from '@productwindtom/ui-base'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  EtailerSkuMetrics,
  createCampaignBenchmark,
  getBenchmarkMetrics,
  getCampaignBenchmark,
  updateCampaignBenchmark
} from '../queries'
import { BenchmarkType, ProductCreationSource } from '@productwindtom/shared-momentum-zeus-types'
import { toast } from 'react-toastify'
import { Box, Typography } from '@mui/material'
import { GraphMetricsType } from '../types'
import Loading from '@momentum/components/loading'
import { DateTime } from 'luxon'
import { map } from 'lodash'
import { captureException } from '@sentry/react'

const BenchmarkProvider = ({ children }: { children?: React.ReactNode }) => {
  const { campaignDetails } = useCampaignContext()
  const { products = [] } = useBrandContext()
  const { setValue, getValues, reset, handleSubmit } = useFormContext()
  const [graphMetrics, setGraphMetrics] = useState<GraphMetricsType>()

  const [isLoading, setIsLoading] = useState(true)
  const [hasSavedMetrics, setHasSavedMetrics] = useState(false)

  const productId = useWatch({
    name: 'productId'
  })

  const benchmarkedProductIds = useWatch({
    name: 'benchmarkedProductIds'
  })

  const filteredProducts = useMemo(
    () =>
      (products || []).filter(
        product =>
          product.id === campaignDetails.skuId ||
          (product.creationSource &&
            [ProductCreationSource.VENDOR_INTEGRATION, ProductCreationSource.SELLER_INTEGRATION].includes(
              product.creationSource
            ) &&
            !!product.price &&
            product?.initialEtailerProductMetric?.date &&
            DateTime.fromISO(product.initialEtailerProductMetric.date).diff(DateTime.now().minus({ years: 2 }), 'days')
              .days > 60)
      ),
    [products, campaignDetails]
  )

  const handleCampaignBenchmarkMetrics = useCallback(
    async (benchmarkedProductIds: string[], metricType: BenchmarkType, metricGoal: number) => {
      try {
        const benchmarkMetrics = await Promise.all(
          benchmarkedProductIds.map(async id => {
            const skuMetrics =
              (await getBenchmarkMetrics(
                id,
                metricType,
                metricType === BenchmarkType.REVENUE ? metricGoal * 100 : metricGoal
              )) || []

            return {
              skuId: id,
              data: skuMetrics.length
                ? [
                    {
                      date: DateTime.fromISO(skuMetrics[0].date).minus({ days: 1 }).toISO()!,
                      skuId: id,
                      unitsSold: 0,
                      totalSalesAmount: 0,
                      pageViews: 0
                    },
                    ...skuMetrics
                  ]
                : []
            }
          })
        )

        const metricsData = benchmarkMetrics.reduce(
          (acc, item) => {
            return {
              ...acc,
              [item.skuId]: item.data
            }
          },
          {} as {
            [key: string]: EtailerSkuMetrics[]
          }
        )

        setGraphMetrics({
          metricType,
          metricGoal,
          data: metricsData,
          benchmarkedProductIds
        })
      } catch (err) {
        console.error(err)
        captureException(err)
        toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
          type: 'error'
        })
      }
    },
    []
  )

  useEffect(() => {
    if (filteredProducts.length) {
      ;(async () => {
        try {
          const campaignBenchmark = await getCampaignBenchmark(campaignDetails.id, campaignDetails.skuId)

          if (campaignBenchmark) {
            const { benchmarkedProductIds, benchmarkGoal, benchmarkType } = campaignBenchmark
            const productIds = map(filteredProducts, 'id')

            const filteredBenchmarkProductIds = benchmarkedProductIds.filter(id => productIds?.includes(id))

            await handleCampaignBenchmarkMetrics(filteredBenchmarkProductIds, benchmarkType, benchmarkGoal)

            setHasSavedMetrics(true)
            reset({
              metricGoal: benchmarkGoal,
              metricType: benchmarkType,
              benchmarkedProductIds: filteredBenchmarkProductIds
            })
          }
        } catch (err) {
          console.error(err)
          captureException(err)
          toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
            type: 'error'
          })
        } finally {
          setIsLoading(false)
        }
      })()
    }
  }, [campaignDetails, reset, handleCampaignBenchmarkMetrics, filteredProducts])

  useEffect(() => {
    if (productId) {
      const benchmarkedIds = getValues().benchmarkedProductIds
      setValue('productId', '')
      setValue('benchmarkedProductIds', [...benchmarkedIds, productId])
    }
  }, [productId, setValue, getValues])

  const { nonBenchmarkedProducts, benchmarkedProducts } = useMemo(() => {
    const { nonBenchmarkedProducts, benchmarkedProducts } = filteredProducts.reduce(
      (acc, product) => {
        const isBenchmarked = benchmarkedProductIds.includes(product.id)

        isBenchmarked ? acc.benchmarkedProducts.push(product) : acc.nonBenchmarkedProducts.push(product)

        return acc
      },
      {
        nonBenchmarkedProducts: [] as Product[],
        benchmarkedProducts: [] as Product[]
      }
    )

    return {
      nonBenchmarkedProducts,
      benchmarkedProducts: benchmarkedProducts.sort(
        (a, b) => benchmarkedProductIds.indexOf(a.id) - benchmarkedProductIds.indexOf(b.id)
      )
    }
  }, [benchmarkedProductIds, filteredProducts])

  const handleResetMetrics = useCallback(() => {
    reset({
      benchmarkedProductIds: [campaignDetails.skuId],
      metricType: BenchmarkType.TRAFFIC,
      metricGoal: 1000
    })
  }, [reset, campaignDetails])

  const handleApplyMetrics = handleSubmit(async data => {
    try {
      const { metricType, metricGoal, benchmarkedProductIds } = data
      const updateOperation = hasSavedMetrics ? updateCampaignBenchmark : createCampaignBenchmark

      await updateOperation({
        campaignId: campaignDetails.id,
        skuId: campaignDetails.skuId,
        benchmarkType: metricType as BenchmarkType,
        benchmarkGoal: metricGoal,
        benchmarkedProductIds
      })

      setHasSavedMetrics(true)

      await handleCampaignBenchmarkMetrics(benchmarkedProductIds, metricType, metricGoal)
    } catch (err) {
      console.error(err)
      captureException(err)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
    }
  })

  if (isLoading) {
    return (
      <Box mt={10}>
        <Loading />
      </Box>
    )
  }

  return (
    <BenchmarkContext.Provider
      value={{
        nonBenchmarkedProducts,
        benchmarkedProducts,
        promotedProductId: campaignDetails.skuId,
        handleResetMetrics,
        handleApplyMetrics,
        graphMetrics
      }}
    >
      {children}
    </BenchmarkContext.Provider>
  )
}

export default ({ children }: { children?: React.ReactNode }) => {
  const { campaignDetails } = useCampaignContext()

  return (
    <Form
      onSubmit={() => null}
      defaultValues={{
        benchmarkedProductIds: [campaignDetails.skuId],
        metricType: BenchmarkType.TRAFFIC,
        metricGoal: 1000
      }}
    >
      <BenchmarkProvider>{children}</BenchmarkProvider>
    </Form>
  )
}

type BenchmarkContextType = {
  nonBenchmarkedProducts: Product[]
  benchmarkedProducts?: Product[]
  promotedProductId: string
  handleResetMetrics: () => void
  handleApplyMetrics: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  graphMetrics?: GraphMetricsType
}

const BenchmarkContext = createContext<BenchmarkContextType>({} as BenchmarkContextType)
export const useBenchmarkContext = () => useContext(BenchmarkContext)
