import { notEmpty } from '@productwindtom/shared-node'
import { convertApprovalConfigToStagingApproval } from '@productwindtom/shared-user-roles'
import { COUNTRY_CURRENCY_MAP, COUNTRY_LOCALE_MAP } from '@productwindtom/shared-ws-currency'
import {
  Active,
  CampaignProductType,
  CampaignReferralBonusFlag,
  ContentApprovalType,
  ContentRequirementLevel,
  CreationSourceVersion,
  Currency,
  EnabledFlag,
  GraphQLTypes,
  InspirationalContentType,
  ModelTypes,
  PayForPerformanceFlag,
  PlatformSource,
  ProductQualityLevel,
  PromoDiscountType,
  RequiredProductLinkFlag,
  SocialChannel,
  SocialContentType,
  UserStagingApprovalType,
  ValidMccCodes,
  ValueTypes,
  WSLocale
} from '@productwindtom/shared-ws-zeus-types'
import { countBy, max, maxBy, min, some, sum } from 'lodash'
import {
  AppCampaignProduct,
  BrandEtailer,
  BrandV2,
  CampaignConfiguration,
  CampaignProductTypes,
  ContentRequirementConfiguration,
  EcommerceCampaignProduct,
  Etailer,
  FindProductMethod,
  FlowContentGuidance,
  FlowTypes,
  InspirationalContent,
  InStoreCampaignProduct,
  PurchaseProductMethod,
  ReceiveProductMethod,
  SponsorshipFlow,
  StoreType,
  WebsiteCampaignProduct
} from './types'

export const fromEtailerBrand = (
  brand: BrandV2,
  etailer: BrandEtailer
): Omit<GraphQLTypes['BrandApi'], '__typename' | 'createdAt' | 'updatedAt'> => {
  return {
    brandId: brand.id!,
    api: etailer.etailer,
    locale: etailer.locale,
    enabled: etailer.enabled,
    shop: etailer.shop
  }
}

export const fromBrandV2 = (brand: BrandV2): Omit<GraphQLTypes['Brand'], '__typename' | 'createdAt' | 'updatedAt'> => {
  return {
    id: brand.id!,
    active: Active.active,
    name: brand.name,
    brandInstaHandle: brand.brandInstaHandle,
    brandTikTokHandle: brand.brandTikTokHandle,
    isSeller: brand.etailers?.find(e => e.etailer.endsWith('seller')) ? 'true' : 'false',
    isVendor: brand.etailers?.find(e => e.etailer.endsWith('vendor')) ? 'true' : 'false',
    platform: PlatformSource.WESPONSORED,
    nameAudioKey: brand.nameAudioKey
  }
}

interface FromCampaignProductResponse {
  sku: ValueTypes['CreateSkuInput'] | ValueTypes['UpdateSkuInput']
  childSkus?: string[]
}

export const fromCampaignProduct = (
  campaignProduct: CampaignProductTypes,
  sellingCountry: WSLocale,
  brandId?: string
): FromCampaignProductResponse => {
  const base = {
    id: campaignProduct.id,
    active: campaignProduct.active || Active.active,
    brandId,
    product: campaignProduct.name,
    name: campaignProduct.name,
    nameAudioKey: campaignProduct.nameAudioKey,
    productImageKey: campaignProduct.primaryImageKey,
    mccValue: ValidMccCodes.not_set // overwritten in Ecommerce
  }

  if (campaignProduct.type === CampaignProductType.app) {
    const appCampaignProduct = campaignProduct as AppCampaignProduct
    return {
      sku: {
        ...base,
        skuId: appCampaignProduct.appStoreId,
        iosAppProviderId: appCampaignProduct.appStoreProvider,
        storeName: 'ios',
        listingLink: `https://apps.apple.com/ca/app/id${appCampaignProduct.appStoreId}`,
        priceCents: appCampaignProduct.sellingPrice && Math.round(appCampaignProduct.sellingPrice),
        currency: Currency.USD,
        approxRetailPrice: appCampaignProduct.sellingPrice
          ? `$${Math.round(appCampaignProduct.sellingPrice / 100)}`
          : undefined,
        exactRetailPrice: appCampaignProduct.sellingPrice ? `$${appCampaignProduct.sellingPrice / 100}` : undefined
      }
    }
  } else if (campaignProduct.type === CampaignProductType.website) {
    const websiteCampaignProduct = campaignProduct as WebsiteCampaignProduct
    return {
      sku: {
        ...base,
        storeName: 'dtc',
        listingLink: websiteCampaignProduct.websiteUrl
      }
    }
  } else if (campaignProduct.type === 'in_store') {
    const instoreCampaignProduct = campaignProduct as InStoreCampaignProduct
    return {
      sku: {
        ...base,
        skuId: instoreCampaignProduct.skuId,
        exactListingTitle: instoreCampaignProduct.listingTitle,
        storeName: 'in_store',
        priceCents: instoreCampaignProduct.sellingPrice && Math.round(instoreCampaignProduct.sellingPrice),
        currency: Currency.USD,
        approxRetailPrice: instoreCampaignProduct.sellingPrice
          ? `$${Math.round(instoreCampaignProduct.sellingPrice / 100)}`
          : undefined,
        exactRetailPrice: instoreCampaignProduct.sellingPrice
          ? `$${instoreCampaignProduct.sellingPrice / 100}`
          : undefined
      }
    }
  } else if (campaignProduct.type === 'ecommerce') {
    const ecommerceProduct = campaignProduct as EcommerceCampaignProduct

    const { etailer, childSkus, sellingPrice, shippingCost, minimumOrderQuantity, ignoreMccCode } = ecommerceProduct

    const storeName = getStoreName(etailer!, sellingCountry)
    const mccCode = ignoreMccCode ? ValidMccCodes.not_set : getMccCode(etailer!, sellingCountry)

    return {
      sku: {
        ...base,
        skuId: ecommerceProduct.parentSku,
        storeName,
        mccValue: mccCode,
        listingLink: ecommerceProduct.listingUrl,
        exactListingTitle: ecommerceProduct.listingTitle,
        nonSponsoredListingKey: ecommerceProduct.nonSponsoredAdImageKey,
        priceCents: sellingPrice && Math.round(sellingPrice),
        currency: Currency.USD,
        approxRetailPrice: sellingPrice ? `$${Math.round(sellingPrice / 100)}` : undefined,
        exactRetailPrice: sellingPrice ? `$${sellingPrice / 100}` : undefined,
        approxShippingCost: shippingCost,
        minimumOrderQuantity: minimumOrderQuantity,
        bundle: ecommerceProduct.isBundle ? 'true' : 'false'
      },
      childSkus
    }
  }
  throw new Error('Trying to create a product which is not supported')
}

const PROMO_CODE_PURCHASE_METHODS = [PurchaseProductMethod.PROMO_NO_CARD, PurchaseProductMethod.PROMO_WITH_CARD]

export const fromSponsorshipFlow = (
  flow: SponsorshipFlow,
  product: CampaignProductTypes,
  inspirationalContent?: InspirationalContent | null
): ValueTypes['UpdateProductInput'] | ValueTypes['CreateProductInput'] => {
  const flowType = determineFlowType(flow)

  return {
    id: flow.id,
    active: Active.active,
    skuId: product.id!,
    internalName: flow.name,
    internalDescription: flow.description,
    campaignStateMachine: flowType,
    qualityLevel: flow.isPremium ? ProductQualityLevel.PREMIUM : ProductQualityLevel.STANDARD,
    creatorCount: (flow.creatorsAllocated || 0) + (flow.liftCreatorsEnabled ? flow.liftCreatorCount || 0 : 0),

    visibleAt:
      typeof flow.visibleAt === 'string'
        ? flow.visibleAt
        : flow.visibleAt?.set({ hour: 12 }).startOf('hour').toJSDate().toISOString(),
    visibleEndsAt:
      typeof flow.visibleEndsAt === 'string'
        ? flow.visibleEndsAt
        : flow.visibleEndsAt?.set({ hour: 12 }).startOf('hour').toJSDate().toISOString(),
    brandAdvocateCount: flow.creatorsAllocated,

    // Find
    isDirectListingPurchase:
      (!!flow.purchasePromoCodeUrl && flow.findMethod === FindProductMethod.LINK_PROMO_CODE) ||
      flow.findMethod === FindProductMethod.LINK_TO_PRODUCT,
    directPurchaseLink: determineDirectPurchaseLink(flow, product),
    //Purchase
    purchaseDiscountCode: PROMO_CODE_PURCHASE_METHODS.includes(flow.purchaseMethod as PurchaseProductMethod)
      ? flow.purchasePromoCode
      : undefined,
    purchaseDiscountCodePercentage: PROMO_CODE_PURCHASE_METHODS.includes(flow.purchaseMethod as PurchaseProductMethod)
      ? flow.purchasePromoCodeDiscountPercentage?.toString()
      : undefined,
    purchaseDiscountCodeDetails: PROMO_CODE_PURCHASE_METHODS.includes(flow.purchaseMethod as PurchaseProductMethod)
      ? flow.purchasePromoCodeInstructions
      : undefined,
    purchaseByPromoCode: flow.purchaseMethod === PurchaseProductMethod.PROMO_NO_CARD,
    listingGuidance: flow.specialGuidance,
    autoApprovalStatusTiers: flow.autoApproveCreators ? flow.autoApproveCreatorsStatusTiers : undefined,
    brandTalkingPoints: null,
    productPostingGuidanceDocument: flow.marketingBriefFileKey,
    productPostingGuidance: flow.marketingBrief,
    postingDeadlineDaysRelativeToApproval: flow.durationToPostDays
      ? flow.durationToPostDays + (flow.durationToSubmitDraftDays ?? 0)
      : undefined,
    contentApprovalLeadDays: flow.durationToPostDays,
    customHashtags: flow.postingHashtags,
    customFtcHashtags: flow.ftcHashtags,
    instaHandle: flow.instaHandle,
    tikTokHandle: flow.tikTokHandle,
    socialMediaExampleUrls: flow.sampleContentUrls,
    maxDiscoveryBonus: (flow.receivingProductBonus || 0) / 100,
    closeoutFormBonus: (flow.closeoutBonus || 0) / 100,
    contentRequirement: flow.contentRequired
      ? (flow.contentRequirements || []).map(cr => fromContentRequirementConfiguration(cr, flow, inspirationalContent))
      : undefined,
    contentApprovalConfiguration: flow.contentApprovalConfiguration?.map(c => ({
      contentApprovalType: c.approvalType as ContentApprovalType,
      contentApprovalAssignee: c.id
    })),
    stagingApprovalConfiguration: flow.stagingApprovalConfiguration
      ?.map(convertApprovalConfigToStagingApproval)
      ?.filter(notEmpty),
    stagingApprovalRequiredNumCreatorsClientReviewed:
      some(
        flow.stagingApprovalConfiguration?.filter(notEmpty),
        c => c.approvalType === UserStagingApprovalType.BRAND_APPROVAL
      ) && flow.isStagingApprovalAllCreatorsClientReviewed !== 'TRUE'
        ? flow.stagingApprovalRequiredNumCreatorsClientReviewed
        : null,
    stagingSocialQualityRequirement: flow.stagingSocialQualityRequirement,
    payForImpressionsBonus: {
      enabled: flow.impressionsConfiguration?.enabled,
      impressionsBaseline: flow.impressionsConfiguration?.impressionsBaseline,
      impressionsCap: flow.impressionsConfiguration?.impressionsCap,
      impressionsIncrement: flow.impressionsConfiguration?.impressionsIncrement,
      initialBonus: flow.impressionsConfiguration?.initialBonus,
      growthRate: flow.impressionsConfiguration?.bonusGrowthRate
    },
    promoCodeType: flow.promoCodeConfiguration?.promoCodeType,
    promoCode: flow.promoCodeConfiguration?.promoCode,
    promoLink: flow.promoCodeConfiguration?.promoCodeUrl,
    promotionLinkId: flow.promoCodeConfiguration?.promoCodeUrlId,
    promoCodeGuidance: flow.promoCodeConfiguration?.promoCodeDetails,
    shopifyPromotionId: flow.promoCodeConfiguration?.promoIds
      ? flow.promoCodeConfiguration.promoIds.join(',')
      : undefined,
    shopifyPromotionType: flow.promoCodeConfiguration?.shopifyPromoCodeType,
    promoDiscountType: flow.promoCodeConfiguration?.shopifyPromoPercentageOff
      ? PromoDiscountType.percentage
      : flow.promoCodeConfiguration?.shopifyPromoAmountOff
        ? PromoDiscountType.fixed_amount
        : undefined,
    promoDiscountAmount:
      flow.promoCodeConfiguration?.shopifyPromoPercentageOff ?? flow.promoCodeConfiguration?.shopifyPromoAmountOff,
    requestProductFeedback: flow.closeoutQuestions?.requestProductFeedback || false,
    requestEarlyProductFeedback: flow.requestEarlyProductFeedback || false,
    nonRetailReviewLink: flow.overrideReviewLink ? flow.nonRetailReviewLink : null,
    nonRetailReviewWebsiteName: flow.overrideReviewLink ? flow.nonRetailReviewWebsiteName : null,
    isQualityRequirementsEnabled: flow.isQualityRequirementsEnabled,
    impressionsInstagramRangeRequirement: toMinMaxRange(flow.impressionsInstagramRangeRequirement),
    impressionsTiktokRangeRequirement: toMinMaxRange(flow.impressionsTiktokRangeRequirement),
    engagementRateInstagramRangeRequirement: toMinMaxRange(flow.engagementRateInstagramRangeRequirement),
    engagementRateTiktokRangeRequirement: toMinMaxRange(flow.engagementRateTiktokRangeRequirement),
    followersInstagramRangeRequirement: toMinMaxRange(flow.followersInstagramRangeRequirement),
    followersTiktokRangeRequirement: toMinMaxRange(flow.followersTiktokRangeRequirement),
    autoApprovalV2Config: flow.autoApprovalV2Config,
    payForPostingContentEarly: {
      enabled: flow.payForPostingEarlyEnabled,
      daysBeforeDeadline: flow.payForPostingEarlyDaysBeforeDeadline,
      bonusCents: flow.payForPostingEarlyBonus && flow.payForPostingEarlyBonus * 100
    },
    requiredProductLinkEnabled: flow.requiredProductLinkEnabled,
    productLinkId: flow.productLinkId,
    internalNotes: flow.internalNotes,
    internalCreatorsStagingGuidance: flow.internalCreatorsStagingGuidance,
    validateProductFoundVariations: flow.validateProductFoundVariations,
    payForClicksEnabled: flow.applicationClicksRequired ? EnabledFlag.TRUE : null,
    payForClicksMinClicks: flow.applicationClicksRequired ? flow.numApplicationClicksRequired : null
  }
}

const determineDirectPurchaseLink = (flow: SponsorshipFlow, product: CampaignProductTypes) => {
  if (flow.findMethod === FindProductMethod.LINK_TO_PRODUCT) {
    return (product as EcommerceCampaignProduct).listingUrl || (product as WebsiteCampaignProduct).websiteUrl
  } else if (flow.findMethod === FindProductMethod.LINK_PROMO_CODE) {
    return flow.purchasePromoCodeUrl
  }
}

const toMinMaxRange = (range?: ModelTypes['MinMaxRangeInput']) => {
  if (range) {
    return {
      min: range.min && range.min > 0 ? range.min : undefined,
      max: range.max && range.max > 0 ? range.max : undefined
    }
  }
  return null
}

const getFileInspirationalContentType = (url: string) => {
  const extension = url.split('.').pop()
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
      return InspirationalContentType.IMAGE
    default:
      return InspirationalContentType.VIDEO
  }
}

const fromInspirationalContent = (
  inspirationalContent: InspirationalContent,
  socialChannel: SocialChannel
): ModelTypes['InspirationalContent'][] | null => {
  if (
    [SocialChannel.INSTAGRAM, SocialChannel.TIKTOK, SocialChannel.UGC].includes(socialChannel) &&
    inspirationalContent &&
    inspirationalContent[socialChannel as SocialChannel.TIKTOK | SocialChannel.INSTAGRAM | SocialChannel.UGC].fileUrls
      .length > 0
  ) {
    return inspirationalContent[
      socialChannel as SocialChannel.TIKTOK | SocialChannel.INSTAGRAM | SocialChannel.UGC
    ].fileUrls.map(url => ({
      type: getFileInspirationalContentType(url),
      url: url,
      socialChannel
    }))
  }
  return null
}

const fromContentRequirementConfiguration = (
  contentRequirement: ContentRequirementConfiguration,
  flow: SponsorshipFlow,
  inspirationalContent?: InspirationalContent | null
): ValueTypes['ProductSocialContentInput'] => {
  const socialChannel = determineChannel(contentRequirement.type!)!
  return {
    type: contentRequirement.type!,
    channel: socialChannel,
    instances: contentRequirement.count!,
    requirementLevel: contentRequirement.required
      ? ContentRequirementLevel.IS_REQUIRED
      : ContentRequirementLevel.IS_OPTIONAL,
    requiresManualApproval: contentRequirement.requiresBrandReview,

    bonus: divideHundred(contentRequirement.bonusConfiguration?.baseBonus)!,
    silverBonus: divideHundred(contentRequirement.bonusConfiguration?.silverBonus),
    goldBonus: divideHundred(contentRequirement.bonusConfiguration?.goldBonus),
    diamondBonus: divideHundred(contentRequirement.bonusConfiguration?.diamondBonus),
    platinumBonus: divideHundred(contentRequirement.bonusConfiguration?.platinumBonus),
    inspirationalContent: inspirationalContent ? fromInspirationalContent(inspirationalContent, socialChannel) : null,

    brandContentGuidance: mapFlowGuidance(flow.contentRequirementGuidelines, contentRequirement),
    talkingPoints: mapFlowGuidance(flow.contentTalkingPoints, contentRequirement)
  }
}

// Maps form flow guidance to gql instance level guidance
const mapFlowGuidance = (
  flowGuidance: FlowContentGuidance[] | undefined,
  contentRequirement: ContentRequirementConfiguration
): Array<ModelTypes['ContentGuidance']> => {
  return (flowGuidance || [])
    .flatMap(g => g.contentTypes.map(ct => ({ text: g.text, ...ct })))
    .filter(g => g.type === contentRequirement.type!)
    .map(({ text, instance }) => ({
      text,
      instance
    }))
}

const divideHundred = (val?: number) => {
  if (val != null) {
    return val / 100
  }
}

export const determineChannel = (type: SocialContentType) => {
  switch (type) {
    case SocialContentType.IG_POST:
    case SocialContentType.IG_REEL:
    case SocialContentType.IG_STORY:
    case SocialContentType.IG_VIDEO:
    case SocialContentType.IG_MULTI_STORY:
      return SocialChannel.INSTAGRAM
    case SocialContentType.TT_VIDEO:
    case SocialContentType.TT_STORY:
      return SocialChannel.TIKTOK
    case SocialContentType.REVIEW_PHOTO:
    case SocialContentType.REVIEW_TEXT:
      return SocialChannel.RETAILER
    case SocialContentType.YT_VIDEO:
    case SocialContentType.YT_SHORT:
    case SocialContentType.YT_SHOUTOUT:
      return SocialChannel.YOUTUBE
    case SocialContentType.UGC_VIDEO:
    case SocialContentType.UGC_PHOTO:
      return SocialChannel.UGC
  }
}

export const fromCampaignConfiguration = (campaignConfiguration: CampaignConfiguration, brandId?: string) => {
  const sku = fromCampaignProduct(
    campaignConfiguration.product!,
    campaignConfiguration.sellingCountry!,
    campaignConfiguration.brand?.id
  )?.sku

  const productsAndSnapshots = campaignConfiguration.sponsorshipFlows.map(f => {
    const product = fromSponsorshipFlow(f, campaignConfiguration.product!, campaignConfiguration.inspirationalContent)
    let bonusSnapshot
    if (
      product.maxDiscoveryBonus ||
      product.closeoutFormBonus ||
      product.payForImpressionsBonus ||
      (product.contentRequirement && product.contentRequirement.length)
    ) {
      bonusSnapshot = {
        productId: product.id!,
        snapshotAt: new Date().toISOString(),
        discoveryBonus: product.maxDiscoveryBonus,
        contentBonuses: product.contentRequirement
          ? product.contentRequirement.map(({ ...rest }) => ({ ...rest }))
          : undefined,
        closeoutSurveyBonus: product.closeoutFormBonus,
        payForImpressionsBonus: product.payForImpressionsBonus
      }
    }

    return {
      product,
      bonusSnapshot
    }
  })

  const products = productsAndSnapshots.map(p => p.product)
  const productBonusSnapshots = productsAndSnapshots.map(p => p.bonusSnapshot).filter(notEmpty)

  const skuProductLink = campaignConfiguration.sponsorshipFlows
    .map(p => p.productLink)
    .filter(notEmpty)
    .pop()

  const countByFlow = countBy(products, p => p.campaignStateMachine)
  const campaignStateFlow = maxBy(Object.keys(countByFlow), v => countByFlow[v])

  const campaign: ValueTypes['CreateCampaignInput'] = {
    id: campaignConfiguration.id,
    active: Active.active,

    skuId: campaignConfiguration.product!.id!,

    goal: campaignConfiguration.goal,

    anticipatedStartDate: campaignConfiguration.startDate!.toJSDate().toISOString()!,

    amountPaidByClient: campaignConfiguration.productCost,
    authorizedUnits: sum(campaignConfiguration.sponsorshipFlows.map(s => s.creatorsAllocated)),
    marketingFee: campaignConfiguration.marketingFee,
    totalProductCost: campaignConfiguration.productCost,

    earlyReviewEnabled: campaignConfiguration.earlyReviewEnabled,
    totalEarlyReviewsAllowed: campaignConfiguration.totalEarlyReviewsAllowed,

    socialCommittedContentCount: campaignConfiguration.socialCommittedContentCount,
    lifestyleCommittedContentCount: campaignConfiguration.lifestyleCommittedContentCount,
    premiumLifestyleCommittedContentCount: campaignConfiguration.premiumLifestyleCommittedContentCount,
    committedReviewsCount: campaignConfiguration.committedReviewsCount,
    committedPlacementWinsCount: campaignConfiguration.committedPlacementWinsCount,

    campaignProductType: getCampaignProductType(campaignConfiguration.storeType!),
    creationSourceVersion: campaignConfiguration.creationSource || CreationSourceVersion.CAMPAIGN_SETUP_V2,
    title: campaignConfiguration.title || campaignConfiguration.brand?.name || 'Untitled Campaign',
    brandProductShort: campaignConfiguration.applicationProductCategory,
    brandId: brandId || campaignConfiguration.brand!.id!,
    attributionRefUrlSuffix: campaignConfiguration.attributionTag,
    localeV1: campaignConfiguration.sellingCountry,
    visibleToUser: campaignConfiguration.visible!,
    sourcingPriority: campaignConfiguration.discoverPlacementRanking,
    aboutSection: campaignConfiguration.applicationDescription,
    preApplicationProductImage: campaignConfiguration.applicationProductMainImageKey,
    preApplicationUgcProductImage: campaignConfiguration.applicationProductMainUgcImageKey,
    campaignStateMachine: campaignStateFlow,

    preApplicationProductPriceRange: determinePreApplicationProductPriceRange(
      campaignConfiguration.product ? [campaignConfiguration.product] : [],
      campaignConfiguration.sellingCountry
    ),

    badges: campaignConfiguration.displayBadges
      ? campaignConfiguration.displayBadges
          .filter(c => c.type && c.value && c.enabled)
          .map(c => ({
            type: c.type!,
            value: c.value!
          }))
      : undefined,
    campaignReferralBonusEnabled: campaignConfiguration.referralBonus
      ? CampaignReferralBonusFlag.TRUE
      : CampaignReferralBonusFlag.FALSE,
    campaignReferralBonusAmount: campaignConfiguration.referralBonus ? campaignConfiguration.referralBonus : undefined,
    payForPerformanceEnabled: campaignConfiguration.payForPerformanceEnabled
      ? PayForPerformanceFlag.TRUE
      : PayForPerformanceFlag.FALSE,
    commissionFlatRate: campaignConfiguration.payForPerformanceEnabled
      ? campaignConfiguration.payForPerformanceCommissionFlatRate
      : undefined,

    payForClicksEnabled: campaignConfiguration.sponsorshipFlows.find(
      s => s.applicationClicksRequired && s.numApplicationClicksRequired
    )
      ? EnabledFlag.TRUE
      : EnabledFlag.FALSE,

    contentCompleteDeadline: campaignConfiguration.contentCompleteDeadline?.toISODate() ?? undefined,

    applicationForm: JSON.stringify(campaignConfiguration.applicationForm ?? []),
    enableContentComplianceReview: campaignConfiguration.enableContentComplianceReview,
    committedClicks: campaignConfiguration.committedClicks,
    requiredProductLinksEnabled: campaignConfiguration.requiredProductLinksEnabled || RequiredProductLinkFlag.FALSE,

    closeoutReleaseRate: campaignConfiguration.closeoutReleaseRate
  }

  return {
    campaign,
    products,
    productBonusSnapshots,
    sku,
    skuProductLink
  }
}

const determinePreApplicationProductPriceRange = (skus: CampaignProductTypes[], locale?: WSLocale) => {
  const prices = skus.map(s => s.sellingPrice).filter(p => p !== null && p !== undefined)
  if (!prices.length) {
    return undefined
  }

  const minVal = min(prices)
  const maxVal = max(prices)
  if (minVal !== undefined && maxVal !== undefined && minVal !== maxVal) {
    return `${toCurrencyString(minVal, locale)}-${toCurrencyString(maxVal, locale)}`
  }
  if (!minVal && maxVal) {
    return `$${toCurrencyString(maxVal, locale)}`
  }
  if (minVal && !maxVal) {
    return `$${toCurrencyString(minVal, locale)}`
  }
  if (minVal === maxVal && minVal && maxVal) {
    return `${toCurrencyString(minVal, locale)}`
  }
}

const toCurrencyString = (amountCents: number, locale?: WSLocale) => {
  return `${Math.round(amountCents / 100)
    .toLocaleString(COUNTRY_LOCALE_MAP[locale || WSLocale.US] || 'en-US', {
      style: 'currency',
      currency: COUNTRY_CURRENCY_MAP[locale || WSLocale.US] || 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .trim()}`
}

const getCampaignProductType = (storeType: StoreType) => {
  switch (storeType) {
    case StoreType.E_COMMERCE:
      return CampaignProductType.product
    case StoreType.IN_STORE:
      return CampaignProductType.in_store
    case StoreType.IOS_APP:
      return CampaignProductType.app
    case StoreType.WEBSITE_SERVICE:
      return CampaignProductType.website
  }
}

const getStoreName = (etailer: Etailer, sellingCountry: WSLocale) => {
  switch (etailer) {
    case Etailer.AMAZON:
      switch (sellingCountry) {
        case WSLocale.US:
          return 'amazon'
        default:
          return 'amazon_' + sellingCountry.toLowerCase()
      }
    case Etailer.BESTBUY:
      return 'bestBuy'
    case Etailer.COSTCO:
      return 'costco'
    case Etailer.TARGET:
      return 'target'
    case Etailer.WALMART:
      return 'walmart'
    case Etailer.KROGER:
      return 'kroger'
    case Etailer.DTC_SHOPIFY:
      return 'shopify'
    case Etailer.DTC_NOT_SHOPIFY:
      return 'dtc'
    case Etailer.IN_STORE:
      return 'in_store'
    case Etailer.OTHER:
      return 'other'
  }
}

const getMccCode = (etailer: Etailer, sellingCountry: WSLocale) => {
  switch (etailer) {
    case Etailer.AMAZON:
      switch (sellingCountry) {
        case WSLocale.US:
          return ValidMccCodes.book_stores
        case WSLocale.CA:
          return ValidMccCodes.computer_network_services
        default:
          return ValidMccCodes.not_set
      }
    case Etailer.BESTBUY:
      return ValidMccCodes.electronics_stores
    case Etailer.TARGET:
    case Etailer.WALMART:
      return ValidMccCodes.discount_stores
    case Etailer.KROGER:
      return ValidMccCodes.grocery_stores_supermarkets
    case Etailer.COSTCO:
    case Etailer.DTC_SHOPIFY:
    case Etailer.DTC_NOT_SHOPIFY:
    case Etailer.OTHER:
      return ValidMccCodes.not_set
  }
}

const determineFlowType = (flow: SponsorshipFlow) => {
  if (flow.contentRequired) {
    if (flow.receiveMethod === ReceiveProductMethod.DIRECT_SHIPPING) {
      return FlowTypes.SOCIAL_WITHOUT_PURCHASE_WITHOUT_REVIEW
    }
    if (flow.closeoutQuestions?.leaveReview) {
      return FlowTypes.SOCIAL_WITH_REVIEW
    }
    return FlowTypes.SOCIAL_WITHOUT_REVIEW
  } else {
    return FlowTypes.NO_CONTENT
  }
}
