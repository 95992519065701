import {
  Stack,
  Typography,
  Chip,
  Stepper,
  Step,
  StepLabel,
  stepConnectorClasses,
  Button,
  Box,
  Dialog,
  IconButton,
  styled,
  StepConnector
} from '@mui/material'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import React, { useState, useEffect, memo } from 'react'
import {
  RocketLaunchOutlined,
  AssignmentTurnedInOutlined,
  RadioButtonUncheckedOutlined,
  Circle,
  Close
} from '@mui/icons-material'
import { DateTime } from 'luxon'
import { SubscriptionCreditAction } from '@momentum/routes/queries'
import { CreditAction, InvoicePaymentType } from '@productwindtom/shared-momentum-zeus-types'
import BonusIcon from '@momentum/components/icons/bonus'
import { AdjustCreditsForm, AdjustCreditsFormData } from '@momentum/routes/subscription/AdjustCreditsForm'
import { orderBy } from 'lodash'
import { toast } from 'react-toastify'
import { NotSubscribed } from '@momentum/routes/subscription/NotSubscribed'
import { SubscriptionEnded } from '@momentum/routes/subscription/SubscriptionEnded'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { SubscriptionBar } from '@momentum/routes/company/SubscriptionBar'
import { updateBrand } from './queries'
import { notEmpty } from '@productwindtom/shared-node'

export const Subscription = () => {
  const { selectedCompany, selectedBrand, brands, isAdminView, refreshBrand } = useUserSessionContext()
  const {
    startsAt,
    endsAt,
    getSubscriptionStatus,
    creditsRemaining,
    subscriptionCreditActions,
    addCredits,
    spendCredits
  } = useSubscriptionContext()

  const { isActive, hasStarted } = getSubscriptionStatus()

  const [adjustCreditsAction, setAdjustCreditsAction] = useState<CreditAction>(CreditAction.ADD)
  const [isAdjustCreditsOpen, setIsAdjustCreditsOpen] = useState(false)

  const crumbs = useClientBreadcrumbs(selectedCompany, selectedBrand)

  useEffect(() => {
    if (isActive && creditsRemaining === 0) {
      toast(<Typography variant={'subtitle2'}>You are out of credits!</Typography>, { type: 'warning' })
    }
  }, [])

  const onActivate = async (startDate: string, endDate: string) => {
    if (selectedBrand) {
      await updateBrand({ id: selectedBrand.id, subscriptionStartsAt: startDate, subscriptionEndsAt: endDate })
      refreshBrand(selectedBrand.id)
    }
  }

  const onEdit = async (startDate: string, endDate: string) => {
    if (selectedBrand) {
      await updateBrand({ id: selectedBrand.id, subscriptionStartsAt: startDate, subscriptionEndsAt: endDate })
      refreshBrand(selectedBrand.id)
    }
  }

  const onDeactivate = async () => {
    if (selectedBrand) {
      await updateBrand({ id: selectedBrand.id, subscriptionEndsAt: new Date().toISOString() })
      refreshBrand(selectedBrand.id)
    }
  }

  const handleAdjustCreditsClose = () => {
    setIsAdjustCreditsOpen(false)
  }

  const handleAdjustCreditsSubmit = async (data: AdjustCreditsFormData) => {
    try {
      if (data.action === CreditAction.ADD) {
        await addCredits(data.numCredits)
        toast(
          <Typography variant={'subtitle2'}>You have added {data.numCredits.toLocaleString()} credits</Typography>,
          {
            type: 'success'
          }
        )
      } else if (data.action === CreditAction.REMOVE) {
        await spendCredits(data.numCredits)
        toast(<Typography variant={'subtitle2'}>You have used {data.numCredits.toLocaleString()} credits</Typography>, {
          type: 'success'
        })
      }
    } catch (e) {
      toast(<Typography variant={'subtitle2'}>Failed to adjust credits!</Typography>, { type: 'error' })
    }
    setIsAdjustCreditsOpen(false)
  }

  const handleAddCreditsClick = () => {
    setAdjustCreditsAction(CreditAction.ADD)
    setIsAdjustCreditsOpen(true)
  }

  const handleUseCreditsClick = () => {
    setAdjustCreditsAction(CreditAction.REMOVE)
    setIsAdjustCreditsOpen(true)
  }

  return (
    <Stack py={3}>
      {isAdminView && (
        <Box mb={1}>
          <RoutesBreadcrumb crumbs={crumbs} />
        </Box>
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Typography variant={'h3'} mb={2}>
            Subscription
          </Typography>
          <Typography variant={'h5'} mb={5}>
            {selectedBrand?.name}
          </Typography>
        </Stack>
        {isAdminView && selectedBrand && (
          <SubscriptionBar
            name={selectedBrand.name}
            startDateTime={selectedBrand.subscriptionStartsAt}
            endDateTime={selectedBrand.subscriptionEndsAt}
            onActivate={onActivate}
            onEdit={onEdit}
            onDeactivate={onDeactivate}
          />
        )}
      </Stack>

      {isActive && (
        <>
          <Stack direction={'row'} spacing={4} mb={5}>
            <Chip color={'success'} label={'Active subscription'} />
            {!!startsAt && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <RocketLaunchOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
                <Typography variant={'label2'}>
                  {!isActive && !hasStarted ? 'Starting' : 'Started'} {startsAt.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </Stack>
            )}
            {!!endsAt && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <AssignmentTurnedInOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
                <Typography variant={'label2'}>
                  {isActive || !hasStarted ? 'Ending' : 'Ended'} {endsAt.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction={'row'} spacing={4} alignItems={'center'} mb={5}>
            <Stack sx={{ borderLeft: '8px solid #258987', pl: 1 }}>
              <Stack direction={'row'} spacing={1}>
                <BonusIcon />
                <Typography variant={'h3'}>{creditsRemaining.toLocaleString()}</Typography>
              </Stack>
              <Typography variant={'subtitle2'} color={theme => theme.palette.grey.A700}>
                Credits remaining
              </Typography>
            </Stack>
            {isAdminView && (
              <Stack direction={'row'} spacing={2}>
                <Box>
                  <Button variant={'contained'} onClick={handleUseCreditsClick}>
                    Use credits
                  </Button>
                </Box>
                <Box>
                  <Button variant={'outlined'} onClick={handleAddCreditsClick}>
                    Add credits
                  </Button>
                </Box>
              </Stack>
            )}
          </Stack>

          <Stepper orientation="vertical" connector={<StyledConnector />}>
            {orderBy(subscriptionCreditActions, 'createdAt', 'desc').map((step, index) => (
              <Step active={true} key={step.id}>
                <MemoCustomStep brandName={brands.find(b => b.id === step.id)?.name} creditAction={step} />
              </Step>
            ))}
          </Stepper>
        </>
      )}
      {!isActive && !startsAt && <NotSubscribed />}
      {!isActive && hasStarted && endsAt && <SubscriptionEnded endDate={endsAt} />}
      <Dialog open={isAdjustCreditsOpen} maxWidth={'xs'} fullWidth>
        <Stack p={3}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>{adjustCreditsAction === CreditAction.ADD ? 'Add' : 'Use'} credits</Typography>
            <IconButton onClick={handleAdjustCreditsClose}>
              <Close />
            </IconButton>
          </Stack>
          <AdjustCreditsForm
            action={adjustCreditsAction}
            onCancel={handleAdjustCreditsClose}
            onSubmit={handleAdjustCreditsSubmit}
            creditsRemaining={creditsRemaining}
          />
        </Stack>
      </Dialog>
    </Stack>
  )
}

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.vertical}`]: {
    marginLeft: '7px'
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#EAECF0',
    borderWidth: 2,
    borderRadius: 1
  },
  [`& .${stepConnectorClasses.active}`]: {
    borderColor: '#EAECF0'
  }
}))

const CustomStep = ({ brandName, creditAction }: { brandName?: string; creditAction: SubscriptionCreditAction }) => {
  let line
  if (creditAction.proposal?.paymentType === InvoicePaymentType.EXISTING_INVOICE) {
    line = <ExternalLine creditAction={creditAction} brandName={brandName} />
  } else if (creditAction.proposal?.paymentType === InvoicePaymentType.NEW_INVOICE) {
    line = <NewInvoiceLine creditAction={creditAction} brandName={brandName} />
  } else {
    line = <StandardLine creditAction={creditAction} brandName={brandName} />
  }

  return (
    <StepLabel
      StepIconComponent={props => (
        <RadioButtonUncheckedOutlined className={props.className} color={props.color as any} sx={{ ...props.sx }} />
      )}
      StepIconProps={{ color: 'primary', sx: { fontSize: '16px' } }}
      sx={{ py: 0 }}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography variant={'label1'}>
          {DateTime.fromISO(creditAction.createdAt).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
        <Circle sx={{ color: '#EAECF0', fontSize: '4px' }} />
        {line}
      </Stack>
    </StepLabel>
  )
}

const MemoCustomStep = memo(
  CustomStep,
  (prevProps, nextProps) => prevProps.creditAction.id === nextProps.creditAction.id
)

export const StandardLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const brandNameElement = brandName ? <Typography variant={'label2'}>{brandName} </Typography> : ''
  const actionMessage = creditAction.action === CreditAction.REMOVE ? 'used' : 'added'
  const proposalBrandMessage = brandName ? `${brandName} new ` : ''
  const proposalMessage = creditAction.proposal
    ? ` for ${proposalBrandMessage}${creditAction.proposal.title} campaign`
    : ''

  return (
    <Typography variant={'label3'}>
      {creditAction.actionCreatedByFirstName} {creditAction.actionCreatedByLastName} {brandNameElement ? `at ` : ''}
      {brandNameElement}
      {actionMessage} <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>
      {proposalMessage}
    </Typography>
  )
}

const NewInvoiceLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const credits = <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>

  const brandNameElement = brandName ? <Typography variant={'label2'}> {brandName}</Typography> : ''
  const name = [creditAction.actionCreatedByFirstName, creditAction.actionCreatedByLastName].filter(notEmpty).join(' ')
  const invoiceNumber = creditAction.proposal?.invoiceNumber ? `Invoice #${creditAction.proposal.invoiceNumber} ` : ''
  const proposalTitle = creditAction.proposal?.title ? ` ${creditAction.proposal.title} campaign` : ''

  let body
  if (creditAction.action === CreditAction.INFO_INVOICE_ISSUED) {
    body = (
      <>
        {invoiceNumber}
        for {credits} was issued to {name} for{brandNameElement}
        {proposalTitle}
      </>
    )
  } else if (creditAction.action === CreditAction.ADD) {
    body = (
      <>
        {invoiceNumber}was PAID for{brandNameElement}
        {proposalTitle} and {credits} were automatically deposited
      </>
    )
  } else if (creditAction.action === CreditAction.REMOVE) {
    body = (
      <>
        {credits} were automatically applied to{brandNameElement}
        {proposalTitle}
      </>
    )
  }

  return <Typography variant={'label3'}>{body}</Typography>
}

export const ExternalLine = ({
  creditAction,
  brandName
}: {
  creditAction: SubscriptionCreditAction
  brandName?: string
}) => {
  const name = [creditAction.actionCreatedByFirstName, creditAction.actionCreatedByLastName].filter(notEmpty).join(' ')
  const brandNameElement = brandName ? <Typography variant={'label2'}>{brandName} </Typography> : ''
  const credits = <Typography variant={'label2'}>{creditAction.numCredits.toLocaleString()} credits</Typography>
  const proposalTitle = creditAction.proposal?.title ? `${creditAction.proposal.title} campaign ` : ''
  const paymentDue = creditAction.proposal?.invoicePaidDate
    ? `with all payment due on ${DateTime.fromISO(creditAction.proposal.invoicePaidDate).toLocaleString(DateTime.DATE_SHORT)} `
    : ''
  let body
  if (creditAction.action === CreditAction.INFO_INVOICE_ISSUED) {
    body = (
      <>
        {name} at ProductWind stated an outside invoice was issued for {brandNameElement}
        {proposalTitle}
        {paymentDue}for {credits}
      </>
    )
  } else if (creditAction.action === CreditAction.INFO_INVOICE_PAID) {
    body = (
      <>
        {name} at ProductWind confirmed that the {brandNameElement}
        {proposalTitle}has been paid in full for {credits}
      </>
    )
  } else if (creditAction.action === CreditAction.INFO_INVOICE_NOT_PAID) {
    body = (
      <>
        {name} at ProductWind stated that an invoice was not paid for {brandNameElement}
        {proposalTitle}
        {paymentDue}for {credits}
      </>
    )
  }

  return <Typography variant={'label3'}>{body}</Typography>
}
