import { TextField, TextFieldProps } from '@mui/material'
import { withInputBase } from '../InputBase/withInputBase'
import { VariantInputBaseProps } from '../InputBase/types'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { forwardRef } from 'react'
import { useWatch } from 'react-hook-form'

type NumberInputProps = {
  returnAsNumber?: boolean
  returnAsCents?: boolean
  hideZero?: boolean
}

export const NumberInput = ({
  field,
  ...props
}: NumberInputProps & VariantInputBaseProps & TextFieldProps & NumericFormatProps) => {
  const value = useWatch({ name: props.name })

  return (
    <TextField
      {...field}
      {...props}
      value={props.returnAsCents && props.returnAsNumber && value ? (value as number) / 100 : value ?? ''}
      inputProps={{ ...props } as any}
      InputProps={{
        inputComponent: NumericFormatInput as any,
        ...props.InputProps
      }}
    />
  )
}

export default withInputBase(NumberInput)

interface CustomProps {
  onChange: (event: { target: { name: string; value: string | number | null } }) => void
  name: string
}

const NumericFormatInput = forwardRef<NumericFormatProps, CustomProps & NumberInputProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...rest } = props

    return (
      <NumericFormat
        thousandSeparator
        valueIsNumericString
        {...rest}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value:
                values.value && rest.returnAsNumber
                  ? rest.returnAsCents
                    ? Math.round(100 * Number.parseFloat(values.value))
                    : Number.parseFloat(values.value)
                  : rest.returnAsNumber
                    ? !rest.hideZero
                      ? 0
                      : null
                    : values.value
            }
          })
        }}
      />
    )
  }
)
