import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_KEYBOARD } from './keyboard'
import { SAMPLE_CAMPAIGN_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_MARKER } from './marker'
import { SAMPLE_CAMPAIGN_PILLOW } from './pillow'
import { SAMPLE_CAMPAIGN_WHITENING } from './whitening'
import { SAMPLE_CAMPAIGN_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER } from '@momentum/api/interceptors/getCampaign/sample-data/bluetooth-speaker'

export const SAMPLE_CAMPAIGNS: Record<string, ModelTypes['CampaignDetails']> = {
  'sample-campaign-laundry': SAMPLE_CAMPAIGN_LAUNDRY,
  'sample-campaign-keyboard': SAMPLE_CAMPAIGN_KEYBOARD,
  'sample-campaign-whitening': SAMPLE_CAMPAIGN_WHITENING,
  'sample-campaign-marker': SAMPLE_CAMPAIGN_MARKER,
  'sample-campaign-pillow': SAMPLE_CAMPAIGN_PILLOW,
  'sample-campaign-headphones': SAMPLE_CAMPAIGN_HEADPHONES,
  'sample-campaign-bluetooth-speaker': SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER
}
