import { mApi } from '@momentum/api'
import { creatorContentSelector } from '@momentum/sections/content/selectors'
import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

const socialProfileSelector = Selector('SocialProfile')({
  handle: true,
  followers: true,
  engagementRate: true,
  isPrimary: true
})

export const creatorPricingSelector = Selector('ProposalCreatorPricing')({
  type: true,
  price: true,
  numCreators: true,
  hidden: true,
  schedule: true,
  closeoutBonus: true,
  contentRequirements: {
    contentType: true,
    bonus: true
  }
})

export const proposalSelector = Selector('Proposal')({
  id: true,
  title: true,
  brandId: true,
  status: true,
  statusChangedAt: true,
  goal: true,
  ratingGoal: true,
  estimatedAverageRating: true,
  durationWeeks: true,
  productId: true,
  productPrice: true,
  productPriceOverride: true,
  exchangeRate: true,
  productRating: true,
  productRatingCount: true,
  launchDate: true,
  estimatedUnitsSoldPerMonth: true,
  searchTerms: true,
  benchmarkProducts: true,
  creatorPricing: creatorPricingSelector,
  paymentType: true,

  invoiceId: true,
  invoiceDueDate: true,
  invoiceLink: true,
  invoicePaidDate: true,
  invoiceStatus: true,
  invoicePONumber: true,
  invoiceNumber: true,

  totalCredits: true,
  totalProductCostCredits: true,
  totalCreditsPaid: true,

  billingContacts: {
    name: true,
    email: true
  },

  updatedBy: {
    firstName: true,
    lastName: true
  },
  submittedBy: {
    firstName: true,
    lastName: true
  },

  hiddenNumReviews: true,
  hiddenEndRating: true,
  hiddenPdpTraffic: true,
  hiddenRetailEfficiency: true,
  hiddenSeo: true,
  hiddenSocialContent: true,
  hiddenUgcContent: true,
  updatedAt: true,
  createdAt: true,
  referralCost: true,
  operatingCost: true,
  liftNumCreators: true,
  liftCloseoutBonus: true
})

export const campaignSelector = Selector('CampaignDetails')({
  id: true,
  createdAt: true,
  skuId: true,
  liftCampaignId: true,
  title: true,
  goal: true,
  numCreators: true,
  numCreatorsJoined: true,
  numAdditionalBrandAdvocateCreatorsJoined: true,
  numAdditionalBrandAdvocateCreators: true,
  numBrandAdvocateCreators: true,
  numSocialCreators: true,
  numPremiumUgcCreators: true,
  numUgcCreators: true,
  expectedSocialPostCount: true,
  expectedPremiumUgcPostCount: true,
  expectedUgcPostCount: true,
  expectedReviewsCount: true,
  expectedPlacementWins: true,
  expectedClicksCounts: true,
  releaseSchedule: {
    ugcWeeklySchedule: true,
    premiumUgcWeeklySchedule: true,
    socialWeeklySchedule: true,
    brandAdvocatesWeeklySchedule: true
  },
  anticipatedStartDate: true,
  startDate: true,
  preLaunchDate: true,
  endDate: true,
  cost: true,
  product: {
    id: true,
    name: true,
    skuId: true,
    price: true,
    image: true,
    listingLink: true,
    store: true,
    availabilityDate: true
  },
  creators: {
    ...creatorContentSelector,
    userCampaignId: true,
    purchaseDetails: {
      purchaseDate: true,
      amountCents: true
    },
    status: true,
    creatorType: true
  },
  proposal: proposalSelector
})

export type CampaignDetails = InputType<GraphQLTypes['CampaignDetails'], typeof campaignSelector>
export type Product = InputType<GraphQLTypes['Product'], typeof campaignSelector.product>
export type ContentGroup = InputType<GraphQLTypes['ContentGroup'], typeof campaignSelector.creators.content>
export type Content = InputType<GraphQLTypes['Content'], typeof campaignSelector.creators.content.content>
export type PurchaseDetails = InputType<
  GraphQLTypes['PurchaseDetails'],
  typeof campaignSelector.creators.purchaseDetails
>
export type SocialProfile = InputType<GraphQLTypes['SocialProfile'], typeof socialProfileSelector>
export type CampaignCreator = InputType<GraphQLTypes['CampaignCreator'], typeof campaignSelector.creators>
export type Proposal = InputType<GraphQLTypes['Proposal'], typeof proposalSelector>

const campaignClicksSelector = Selector('CampaignClick')({
  date: true,
  clicks: true
})
export type CampaignClick = InputType<GraphQLTypes['CampaignClick'], typeof campaignClicksSelector>

const etailerProductMetricsSelector = Selector('EtailerProductMetrics')({
  date: true,
  pageViews: true,
  totalSalesAmount: true,
  unitsSold: true
})

export type EtailerProductMetric = InputType<
  GraphQLTypes['EtailerProductMetrics'],
  typeof etailerProductMetricsSelector
>

export const getCampaignStartDate = async (campaignId: string) => {
  return (
    await mApi('query')({
      getCampaign: [
        { campaignId },
        {
          anticipatedStartDate: true
        }
      ]
    })
  )?.getCampaign
}

export const getCampaignDetails = async (campaignId: string) => {
  return await mApi('query')({
    getCampaign: [{ campaignId }, campaignSelector],
    listCampaignClicks: [{ campaignId }, { items: campaignClicksSelector, nextToken: true }]
  })
}

export const getEtailerProductMetrics = async (
  skuId: string,
  startDate: string,
  endDate?: string,
  nextToken?: string
): Promise<EtailerProductMetric[]> => {
  const arg = endDate ? { between: [startDate, endDate] } : { ge: startDate }

  const { listEtailerProductMetrics } = await mApi('query')({
    listEtailerProductMetrics: [
      { skuId, date: arg, nextToken },
      { items: etailerProductMetricsSelector, nextToken: true }
    ]
  })

  if (listEtailerProductMetrics.nextToken) {
    return [
      ...listEtailerProductMetrics.items,
      ...(await getEtailerProductMetrics(skuId, startDate, listEtailerProductMetrics.nextToken))
    ]
  }
  return listEtailerProductMetrics.items
}

export const getCampaignClicks = async (campaignId: string, nextToken?: string): Promise<CampaignClick[]> => {
  const { listCampaignClicks } = await mApi('query')({
    listCampaignClicks: [
      { campaignId, nextToken },
      { items: campaignClicksSelector, nextToken: true }
    ]
  })

  if (listCampaignClicks.nextToken) {
    return [...listCampaignClicks.items, ...(await getCampaignClicks(campaignId, listCampaignClicks.nextToken))]
  }
  return listCampaignClicks.items
}

const reviewSelector = Selector('Review')({
  title: true,
  text: true,
  name: true,
  rating: true,
  attributedUserId: true,
  reviewedAt: true
})

const reviewMetricsSelector = Selector('CampaignProductReviewRatingMetric')({
  fromDate: true,
  toDate: true,

  numRatings: true,
  numReviews: true,

  ratingsInPeriod: true,
  momentumRatingCount: true,

  vineReviewCount: true,

  momentumReviewCount: true,
  momentumReviewRating: true,

  organicRatingCount: true,

  reviewsInPeriod: true,

  reviews: reviewSelector,

  rating: true,
  momentumRating: true,
  organicRating: true,
  vineRating: true
})

export type Review = InputType<GraphQLTypes['Review'], typeof reviewSelector>
export type CampaignProductReviewRatingMetric = InputType<
  GraphQLTypes['CampaignProductReviewRatingMetric'],
  typeof reviewMetricsSelector
>

export const getReviewMetrics = async (campaignId: string, startDate: string, endDate?: string) => {
  return (
    await mApi('query')({
      getCampaignReviewMetrics: [{ campaignId, startDate, endDate }, reviewMetricsSelector]
    })
  ).getCampaignReviewMetrics
}

export const pwProductSeoSummarySelector = Selector('PWProductSeoSummary')({
  campaignId: true,
  productSEOSummaryRecords: {
    weekIndex: true,
    categoryRanks: {
      category: true,
      rank: true
    },
    impressions: true,
    topPageWins: true,
    isOutOfStock: true
  },
  keywords: {
    badges: {
      badge: true,
      date: true
    },
    impressions: true,
    keyword: true,
    pageOneWin: true,
    pageOneWinDate: true,
    startRank: true,
    highestRank: true,
    sponsoredStartRank: true,
    sponsoredHighestRank: true
  }
})

export type LegacyProductSEOSummary = InputType<GraphQLTypes['PWProductSeoSummary'], typeof pwProductSeoSummarySelector>

export const getLegacyProductSEOSummary = async (campaignId: string) => {
  return (
    await mApi('query')({
      getPWProductSeoSummary: [{ campaignId }, pwProductSeoSummarySelector]
    })
  ).getPWProductSeoSummary
}

export const creatorForApprovalSelector = Selector('CampaignCreatorForApproval')({
  userCampaignId: true,
  dateOfBirth: true,
  locale: true,
  creator: {
    instagramProfile: socialProfileSelector,
    tiktokProfile: socialProfileSelector,
    youtubeProfile: socialProfileSelector,
    sponsoredPosts: true,
    email: true,
    firstName: true,
    lastName: true
  },
  creatorType: true,
  approvalState: {
    status: true,
    updatedAt: true,
    reviewedByUserId: true,
    reviewedByUserEmail: true,
    reviewedByUserName: true,
    feedback: true
  }
})

export type CampaignCreatorForApproval = InputType<
  GraphQLTypes['CampaignCreatorForApproval'],
  typeof creatorForApprovalSelector
>

export const listCampaignCreatorsForApproval = async (campaignId: string) => {
  return (
    await mApi('query')({
      listCampaignCreatorsForApproval: [{ campaignId }, creatorForApprovalSelector]
    })
  ).listCampaignCreatorsForApproval
}

export const reviewFeedbackSelector = Selector('ReviewFeedback')({
  message: true,
  forAssetAtIndex: true,
  atTime: true,
  createdByFirstName: true,
  createdByLastName: true,
  visibleToUser: true,
  createdAt: true
})

export const draftContentSelector = Selector('DraftContent')({
  id: true,
  channel: true,
  contentType: true,
  draftSubmittedAt: true,
  draftStatus: true,
  draftMedia: true,
  draftText: true,
  isHidden: true,
  reviewFeedback: reviewFeedbackSelector,
  reviewedAt: true,
  reviewerFirstName: true,
  reviewerLastName: true
})

export const creatorSocialProfileSelector = Selector('SocialProfile')({
  handle: true,
  engagementRate: true,
  followers: true
})

export const creatorSelector = Selector('Creator')({
  firstName: true,
  lastName: true,
  instagramProfile: creatorSocialProfileSelector,
  tiktokProfile: creatorSocialProfileSelector,
  youtubeProfile: creatorSocialProfileSelector
})

export const creatorDraftContentSelector = Selector('CreatorDraftContent')({
  id: true,
  creator: creatorSelector,
  draftContent: draftContentSelector
})

export type ReviewFeedback = InputType<GraphQLTypes['ReviewFeedback'], typeof reviewFeedbackSelector>
export type CreatorSocialProfile = InputType<GraphQLTypes['SocialProfile'], typeof creatorSocialProfileSelector>
export type Creator = InputType<GraphQLTypes['DraftContent'], typeof creatorSelector>
export type DraftContent = InputType<GraphQLTypes['DraftContent'], typeof draftContentSelector>
export type CreatorDraftContent = InputType<GraphQLTypes['CreatorDraftContent'], typeof creatorDraftContentSelector>

export const listContentPendingApproval = async (campaignId: string) => {
  return (
    await mApi('query')({
      listContentPendingApproval: [{ campaignId }, creatorDraftContentSelector]
    })
  ).listContentPendingApproval
}

export const endCampaign = async (campaignId: string) => {
  return (
    await mApi('mutation')({
      endCampaign: [
        { campaignId },
        {
          endDate: true
        }
      ]
    })
  ).endCampaign
}
