import { Selector } from '@productwindtom/shared-momentum-zeus-types'

export const recommendationsSelector = Selector('ProductRecommendation')({
  recommendationType: true,
  createdAt: true
})

export const ratingSummarySelector = Selector('ProductRatingSnapshot')({
  rating: true,
  numRatings: true,
  numReviews: true
})

export const productSelector = Selector('Product')({
  id: true,
  skuId: true,
  name: true,
  price: true,
  priceCents: true,
  store: true,
  estimatedMonthlyUnitsSold: true,
  image: true,
  availabilityDate: true,
  categories: true,
  creationSource: true,
  creationSourceUrl: true,
  listingLink: true,
  listingFulfillmentType: true,
  isRecommendationsHidden: true,
  lastScrapedAt: true,
  ratingSummary: ratingSummarySelector,
  recommendations: recommendationsSelector,
  parentSkuId: true,
  initialEtailerProductMetric: {
    date: true
  },
  itemClassification: true
})

export const creatorPricingSelector = Selector('ProposalCreatorPricing')({
  type: true,
  price: true,
  numCreators: true,
  hidden: true,
  schedule: true,
  closeoutBonus: true,
  contentRequirements: {
    contentType: true,
    bonus: true
  }
})

export const proposalSelector = Selector('Proposal')({
  id: true,
  title: true,
  brandId: true,
  status: true,
  statusChangedAt: true,
  goal: true,
  ratingGoal: true,
  estimatedAverageRating: true,
  durationWeeks: true,
  productId: true,
  productPriceOverride: true,
  launchDate: true,
  estimatedUnitsSoldPerMonth: true,
  creatorPricing: creatorPricingSelector,
  paymentType: true,

  invoiceLink: true,
  invoiceDueDate: true,
  invoicePaidDate: true,
  invoiceStatus: true,

  totalCredits: true,
  totalCreditsPaid: true,

  createdBy: {
    firstName: true,
    lastName: true
  },
  updatedBy: {
    firstName: true,
    lastName: true
  },

  hiddenNumReviews: true,
  hiddenEndRating: true,
  hiddenPdpTraffic: true,
  hiddenRetailEfficiency: true,
  hiddenSeo: true,
  hiddenSocialContent: true,
  hiddenUgcContent: true,
  updatedAt: true,
  createdAt: true,
  referralCost: true,
  operatingCost: true,
  liftNumCreators: true,
  liftCloseoutBonus: true,
  product: {
    name: true,
    image: true,
    price: true,
    store: true,
    ratingSummary: {
      rating: true,
      numRatings: true
    }
  },
  exchangeRate: true
})

export const campaignSelector = Selector('Campaign')({
  id: true,
  title: true,
  startDate: true,
  endDate: true,
  goal: true,
  skuId: true,
  numCreators: true,
  numCreatorsJoined: true,
  product: {
    name: true,
    price: true,
    image: true,
    store: true,
    listingLink: true
  },
  proposal: {
    invoiceId: true,
    invoiceLink: true,
    invoiceDueDate: true,
    invoiceStatus: true,
    launchDate: true
  }
})
