import Metric from '@momentum/components/metric'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { countBy, sum } from 'lodash'
import { CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { SocialCreatorTypes } from '@momentum/utils/creatorUtils'

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const creatorsByType = countBy(
    campaignDetails.creators.filter(c => !!c.purchaseDetails),
    'creatorType'
  )

  const brandAdvocatesJoined =
    (campaignDetails.numAdditionalBrandAdvocateCreatorsJoined || 0) + (creatorsByType[CreatorType.ADVOCATE] || 0)
  const brandAdvocatesTotal =
    (campaignDetails.numBrandAdvocateCreators || 0) + (campaignDetails.numAdditionalBrandAdvocateCreators || 0)

  const socialJoined = sum(SocialCreatorTypes.map(type => creatorsByType[type] || 0))

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'}>Participating creators</Typography>
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            {DateTime.fromISO(campaignDetails.startDate).toLocaleString(DateTime.DATE_MED)} -{' '}
            {campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate).toLocaleString(DateTime.DATE_MED) : ''}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'Brand advocates'} value={getMetricOutOfString(brandAdvocatesJoined, brandAdvocatesTotal)} />
        <Metric
          label={'UGC creators'}
          value={getMetricOutOfString(creatorsByType[CreatorType.UGC] || 0, campaignDetails.numUgcCreators)}
        />
        <Metric
          label={'Premium UGC creators'}
          value={getMetricOutOfString(
            creatorsByType[CreatorType.PREMIUM_UGC] || 0,
            campaignDetails.numPremiumUgcCreators
          )}
        />
        <Metric
          label={`Social creators`}
          value={getMetricOutOfString(socialJoined, campaignDetails.numSocialCreators)}
        />
      </Stack>
    </Stack>
  )
}

const getMetricOutOfString = (joined: number, total?: number) => {
  if (!total) {
    return '--'
  }
  return `${joined} out of ${total}`
}
