import { ModelTypes, ProposalGoal, Store } from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'

export const SAMPLE_BRAND_CAMPAIGNS: Record<string, Array<ModelTypes['Campaign']>> = {
  [BRANDS.CPG]: [
    {
      id: 'sample-campaign-laundry',
      brandId: BRANDS.CPG,
      title: 'Laundry Detergent Launch',
      createdAt: '2023-07-20T16:44:19.000Z',
      startDate: '2023-08-01T16:44:19.000Z',
      endDate: '2023-09-20T17:28:38.448Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-laundry',
      numCreators: 500,
      numCreatorsJoined: 500,
      cost: 5000,
      product: {
        name: 'Laundry Detergent',
        price: '$14.98',
        priceCents: 1498,
        image: 'https://media.productwind.com/sample/images/laundry.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-laundry',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    },
    {
      id: 'sample-campaign-whitening',
      brandId: BRANDS.CPG,
      createdAt: '2024-01-20T21:34:07.000Z',
      startDate: '2024-01-31T21:34:07.000Z',
      endDate: undefined,
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-whitening',
      numCreators: 246,
      numCreatorsJoined: 246,
      cost: 5000,
      title: 'Teeth Whitening Kit Launch',
      product: {
        name: 'Teeth Whitening Kit',
        price: '$64.99',
        priceCents: 6499,
        image: 'https://media.productwind.com/sample/images/whitening.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/Colgate-ComfortFit-Whitening-Enamel-Android/dp/B0B9HVCMBB',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-whitening',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    },
    {
      id: 'sample-campaign-marker',
      brandId: BRANDS.CPG,
      createdAt: '2024-02-20T17:36:22.000Z',
      startDate: '2024-02-27T17:36:22.000Z',
      endDate: '2024-03-18T21:52:35.607Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-marker',
      numCreators: 150,
      numCreatorsJoined: 150,
      cost: 3000,
      title: 'Writing Marker Launch',
      product: {
        name: 'Writing Marker',
        price: '$33.48',
        priceCents: 3348,
        image: 'https://media.productwind.com/sample/images/marker.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/SHARPIE-Creative-Markers-Water-Based-Assorted/dp/B0CPMFV2ZF',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-marker',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    },
    {
      id: 'sample-campaign-pillow',
      brandId: BRANDS.CPG,
      createdAt: '2024-02-20T17:43:28.000Z',
      startDate: '2024-03-05T17:43:28.000Z',
      endDate: undefined,
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-pillow',
      numCreators: 230,
      numCreatorsJoined: 160,
      cost: 4000,
      title: 'Down Pillow Launch',
      product: {
        name: 'Down pillow',
        price: '$79',
        image: 'https://media.productwind.com/sample/images/pillow.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/dp/B0CJT69H2K?th=1',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CE,
        id: 'sample-sku-pillow',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    }
  ],
  [BRANDS.CE]: [
    {
      id: 'sample-campaign-keyboard',
      brandId: BRANDS.CE,
      title: 'Keyboard and Mouse Launch',
      createdAt: '2024-01-01T16:22:12.000Z',
      startDate: '2024-01-13T16:22:12.000Z',
      endDate: '2024-02-20T19:39:59.316Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-keyboard',
      numCreators: 155,
      numCreatorsJoined: 155,
      cost: 3000,
      product: {
        name: 'Keyboard and Mouse',
        price: '$49.99',
        priceCents: 4999,
        image: 'https://media.productwind.com/sample/images/keyboard.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/Logitech-Wireless-Customizable-Bluetooth-Easy-Switch/dp/B0BT4G499X',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CPG,
        id: 'sample-sku-keyboard',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    },
    {
      id: 'sample-campaign-headphones',
      brandId: BRANDS.CE,
      createdAt: '2024-02-20T17:43:28.000Z',
      startDate: '2024-03-05T17:43:28.000Z',
      endDate: '2024-04-05T17:43:28.000Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-headphones',
      numCreators: 330,
      numCreatorsJoined: 330,
      cost: 24399,
      title: 'Wireless Headphones Launch',
      product: {
        name: 'Wireless Headphones',
        price: '$243.99',
        priceCents: 24399,
        estimatedMonthlyUnitsSold: 1000,
        image: 'https://media.productwind.com/sample/images/headphones.png',
        store: Store.amazon,
        listingLink: 'https://www.amazon.com/dp/B0CJT69H2K?th=1',
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CPG,
        id: 'sample-sku-headphones',
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    },
    {
      id: 'sample-campaign-bluetooth-speaker',
      brandId: BRANDS.CE,
      createdAt: '2024-02-20T17:43:28.000Z',
      startDate: '2024-03-05T17:43:28.000Z',
      endDate: '2024-04-05T17:43:28.000Z',
      goal: ProposalGoal.PRODUCT_LAUNCH,
      skuId: 'sample-sku-headphones',
      numCreators: 280,
      numCreatorsJoined: 280,
      cost: 24399,
      title: 'Bluetooth Speaker Launch',
      product: {
        id: 'sample-sku-headphones',
        name: 'Bluetooth Speaker',
        skuId: 'B0BRXJ9WTD',
        price: '$243.99',
        priceCents: 24399,
        image: 'https://media.productwind.com/sample/images/speaker.png',
        listingLink: 'https://www.amazon.com/dp/B0BRXJ9WTD',
        availabilityDate: '2023-10-01T00:00:00.000Z',
        store: Store.amazon,
        createdAt: '2023-06-22T15:21:54.650Z',
        updatedAt: '2023-06-22T15:21:54.650Z',
        brandId: BRANDS.CPG,
        ratingSnapshots: {
          items: []
        },
        recommendations: []
      }
    }
  ]
}
