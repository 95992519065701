import { Button, Grid, Stack, Typography, Box, IconButton, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { BrandCard } from '@momentum/components/brand-card'
import { CompanyAgencyCard } from '@momentum/components/company-agency-card'
import { useNavigate, generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { countBy, orderBy, sum } from 'lodash'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Search, KeyboardArrowDown, KeyboardArrowUp, Close } from '@mui/icons-material'
import Loading from '@momentum/components/loading'
import AddIcon from '@mui/icons-material/Add'
import { BrandForm, BrandFormData } from '@momentum/forms/brand-form'
import { updateCompany, createCompany, createBrand, updateAgency, createAgency } from './queries'
import { toast } from 'react-toastify'
import { notEmpty } from '@productwindtom/shared-node'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import Row from '@momentum/components/row'
import AgencyForm from '@momentum/forms/agency-form'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { Agency } from '../queries'

export const Brands = () => {
  const navigate = useNavigate()
  const [search, setSearch] = React.useState('' as string)
  const [viewAllBrands, setViewAllBrands] = useState(false)
  const [viewAllCompanies, setViewAllCompanies] = useState(false)
  const [viewAllAgencies, setViewAllAgencies] = useState(false)
  const [isCreateBrandOpen, setIsCreateBrandOpen] = useState(false)
  const [isEditAgencyOpen, setIsEditAgencyOpen] = useState(false)
  const {
    isAdminView,
    isAdmin,
    agency,
    agencyId,
    brands,
    companies,
    agencies,
    loadingResources,
    refreshCompany,
    refreshBrand,
    refreshAgency
  } = useUserSessionContext()

  const handleAddBrandClick = () => {
    setIsCreateBrandOpen(true)
  }

  const handleClose = () => {
    setIsCreateBrandOpen(false)
  }

  const onCreateBrand = async (data: BrandFormData) => {
    let agency: Agency | undefined = undefined

    if (data.agency?.id) {
      const { existingAgency, ...agencyInput } = data.agency
      agency = existingAgency ? await updateAgency(agencyInput) : await createAgency(agencyInput)
    }

    const agencyId = agency?.id

    const companyInput = {
      id: data.companyId,
      websiteUrl: data.companyWebsiteUrl,
      name: data.companyName,
      logo: data.companyLogo,
      agencyId
    }

    const companyId = (data.existingCompany ? await updateCompany(companyInput) : await createCompany(companyInput)).id

    refreshCompany(companyId)

    const newBrand = await createBrand({
      name: data.name,
      logo: data.logo,
      companyId: companyId,
      region: data.region,
      amazonBrandStoreUrl: data.amazonBrandStoreUrl,
      isBrandStoreScrapeEnabled: data.isBrandStoreScrapeEnabled,
      walmartScrapeUrl: data.walmartScrapeUrl,
      isWalmartScrapeEnabled: data.isWalmartScrapeEnabled,
      brandApis: data.enabledBrandApiTypes
    })

    refreshBrand(newBrand.id)

    if (agency) {
      refreshAgency(agency.id)
    }

    handleClose()
    toast(<Typography variant={'subtitle2'}>New brand created!</Typography>, { type: 'success' })
  }

  const brandsPerCompany = countBy(brands, b => b.companyId)
  const productsPerCompany = brands.reduce((acc: Record<string, number>, b) => {
    if (b.companyId) {
      acc[b.companyId] = (acc[b.companyId] || 0) + (b.totalProducts || 0)
    }
    return acc
  }, {})

  if (loadingResources) {
    return <Loading />
  }

  const filteredCompanies = orderBy(
    companies.filter(c => c.name.toLowerCase().includes(search.toLowerCase())),
    v => v.createdAt,
    'desc'
  )

  const filteredBrands = orderBy(
    brands.filter(
      b => b.name.toLowerCase().includes(search.toLowerCase()) || filteredCompanies.find(c => c.id === b.companyId)
    ),
    v => v.createdAt,
    'desc'
  )

  const filteredAgencies = orderBy(
    agencies.filter(c => c.name.toLowerCase().includes(search.toLowerCase())),
    v => v.name,
    'desc'
  )

  return (
    <Stack py={2}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
        <Stack spacing={1}>
          <Typography variant={'h3'}>Companies and brands</Typography>
          <Row>
            <Typography variant={'h5'} data-cy={'homeTitle'}>
              {agencyId ? agency?.name : 'Momentum admin'}
            </Typography>
            {isAdminView && isAdmin && !!agencyId && (
              <Button
                data-cy={'editAgencyButton'}
                variant={'text'}
                color={'primary'}
                onClick={() => setIsEditAgencyOpen(true)}
                sx={{
                  top: 1
                }}
              >
                Edit
              </Button>
            )}
          </Row>
        </Stack>
        {isAdminView && isAdmin && (
          <Button variant={'contained'} size={'small'} onClick={handleAddBrandClick} startIcon={<AddIcon />}>
            Add brand
          </Button>
        )}
      </Stack>
      <DebouncedTextField
        fullWidth
        placeholder={'Search companies and brands'}
        sx={{ mb: 4, maxWidth: 330 }}
        onChange={setSearch}
        InputProps={{
          endAdornment: <Search />
        }}
      />

      <Stack direction={'row'} justifyContent={'space-between'} mb={2} zIndex={10}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'} data-cy={'companiesTitle'}>
            Companies
          </Typography>
          <Typography variant={'body1'}>{filteredCompanies.length} Companies</Typography>
        </Stack>
        <Button
          variant={'text'}
          size={'small'}
          onClick={() => setViewAllCompanies(!viewAllCompanies)}
          endIcon={viewAllCompanies ? <KeyboardArrowUp fontSize={'small'} /> : <KeyboardArrowDown fontSize={'small'} />}
        >
          View All Companies
        </Button>
      </Stack>
      <Grid container mb={5} spacing={4}>
        {filteredCompanies.slice(0, viewAllCompanies ? undefined : 12).map(company => (
          <Grid item key={company.id} xs={6} md={4} lg={3}>
            <CompanyAgencyCard
              resource={{
                ...company,
                brands: brandsPerCompany[company.id] || 0,
                products: productsPerCompany[company.id] || 0
              }}
              onClick={() => {
                if (isAdmin && agencyId) {
                  navigate(
                    generatePath(ROUTES.AGENCY_COMPANY, {
                      companyId: company.id,
                      agencyId
                    })
                  )
                } else {
                  navigate(
                    generatePath(ROUTES.COMPANY, {
                      companyId: company.id
                    })
                  )
                }
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Stack direction={'row'} justifyContent={'space-between'} mb={2} zIndex={10}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'} data-cy={'brandsTitle'}>
            Brands
          </Typography>
          <Typography variant={'body1'}>{filteredBrands.length} Brands</Typography>
        </Stack>
        <Button
          variant={'text'}
          size={'small'}
          onClick={() => setViewAllBrands(!viewAllBrands)}
          endIcon={viewAllBrands ? <KeyboardArrowUp fontSize={'small'} /> : <KeyboardArrowDown fontSize={'small'} />}
        >
          View All Brands
        </Button>
      </Stack>
      <Grid container spacing={3} display={'flex'}>
        {filteredBrands.slice(0, viewAllBrands ? undefined : 8).map(brand => (
          <Grid item key={brand.id} xs={6} md={4} lg={3}>
            <BrandCard
              brand={{
                ...brand,
                products: brand.totalProducts,
                proposals: brand.proposals.length || 0,
                recommendations: brand.totalRecommendations || 0
              }}
              onClick={() => {
                if (isAdmin && agencyId) {
                  navigate(
                    generatePath(ROUTES.AGENCY_COMPANY, {
                      companyId: brand.companyId,
                      agencyId
                    }),
                    { state: { brand } }
                  )
                } else {
                  navigate(
                    generatePath(ROUTES.COMPANY, {
                      companyId: brand.companyId
                    }),
                    { state: { brand } }
                  )
                }
              }}
            />
          </Grid>
        ))}
      </Grid>

      {!agencyId && isAdmin && !!agencies.length && (
        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'} zIndex={10} mt={4} mb={2}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant={'h4'} data-cy={'agenciesTitle'}>
                Agencies
              </Typography>
              <Typography variant={'body1'}>{filteredAgencies.length} Agencies</Typography>
            </Stack>
            <Button
              variant={'text'}
              size={'small'}
              onClick={() => setViewAllAgencies(!viewAllAgencies)}
              endIcon={
                viewAllAgencies ? <KeyboardArrowUp fontSize={'small'} /> : <KeyboardArrowDown fontSize={'small'} />
              }
            >
              View All Agencies
            </Button>
          </Stack>
          <Grid container mb={5} spacing={4}>
            {filteredAgencies.slice(0, viewAllAgencies ? undefined : 12).map(agency => {
              const agencyCompanies = companies
                .map(({ agencyId, id }) => (agency.id === agencyId ? id : null))
                .filter(notEmpty)

              return (
                <Grid item key={agency.id} xs={6} md={4} lg={3}>
                  <CompanyAgencyCard
                    resource={{
                      ...agency,
                      logo: getCdnImageUrl(agency.logo),
                      brands: sum(agencyCompanies.map(id => brandsPerCompany[id] || 0)),
                      products: sum(agencyCompanies.map(id => productsPerCompany[id] || 0))
                    }}
                    onClick={() => {
                      window.open(generatePath(ROUTES.AGENCY, { agencyId: agency.id }), '_blank')
                    }}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Stack>
      )}

      <Dialog open={isCreateBrandOpen} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <Stack p={3}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>Select or create new company</Typography>
            <Box>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <BrandForm onCancel={handleClose} onSubmit={onCreateBrand} />
        </Stack>
      </Dialog>
      <Dialog open={isEditAgencyOpen} onClose={() => setIsEditAgencyOpen(false)} maxWidth={'sm'} fullWidth>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>Edit Agency</Typography>
            <Box>
              <IconButton onClick={() => setIsEditAgencyOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <AgencyForm onClose={() => setIsEditAgencyOpen(false)} />
        </Stack>
      </Dialog>
    </Stack>
  )
}
