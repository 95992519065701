import { DateTime } from 'luxon'
import { round, max, zipWith, orderBy } from 'lodash'
import { getReviewsAllowedPerWeek } from '../configs'
import { Store } from '@productwindtom/shared-momentum-zeus-types'

export enum TimelineStepType {
  FIRST_CREATOR = 'FIRST_CREATOR',
  FIRST_PRODUCT_REVIEW = 'FIRST_PRODUCT_REVIEW',
  PURCHASING_COMPLETE = 'PURCHASING_COMPLETE',
  PRODUCT_SHARING_COMPLETE = 'PRODUCT_SHARING_COMPLETE',
  ALL_CONTENT_CREATED = 'ALL_CONTENT_CREATED',
  PRODUCT_AND_CONTENT_COMPLETE = 'PRODUCT_AND_CONTENT_COMPLETE',
  REVIEWS_PER_WEEK = 'REVIEWS_PER_WEEK',
  REVIEW_GOAL_HIT = 'REVIEW_GOAL_HIT',
  CLIENT_APPROVES_INITIAL_CREATORS = 'CLIENT_APPROVES_INITIAL_CREATORS',
  CLIENT_APPROVES_INITIAL_CONTENT = 'CLIENT_APPROVES_INITIAL_CONTENT',
  GOALS_COMPLETED = 'GOALS_COMPLETED'
}

export type TimelineStep = {
  type: TimelineStepType
  date?: DateTime
  value?: number
  isActual?: boolean
}
const STANDARD_DAYS_TO_SHARE = 3
const DAYS_TO_SEE_INITIAL_CONTENT = 10
export const LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS = 3
const DAYS_TO_GENERATE_PREMIUM_CONTENT = 14
const DAYS_TO_FIRST_REVIEW = 12
export const getTimelineSteps = (
  {
    brandAdvocatesWeeklySchedule,
    ugcWeeklySchedule,
    premiumUgcWeeklySchedule,
    socialWeeklySchedule,
    unitsSoldPerWeek,
    reviewsGoal,
    startDate,
    store,
    isVendor,
    isAdaptive
  }: {
    brandAdvocatesWeeklySchedule: number[]
    ugcWeeklySchedule: number[]
    premiumUgcWeeklySchedule: number[]
    socialWeeklySchedule: number[]
    startDate?: DateTime
    unitsSoldPerWeek?: number
    reviewsGoal?: number
    store?: Store
    isVendor?: boolean
    isAdaptive?: boolean
  },
  actuals?: {
    endDate?: DateTime
    lastPurchaseAt?: DateTime
    firstReviewAt?: DateTime
    allContentCompletedAt?: DateTime
    initialCreatorApprovedAt?: DateTime
    initialContentApprovedAt?: DateTime
    reviewsGoalHitAt?: DateTime
    reviewsPerWeek?: number
  }
): TimelineStep[] => {
  const hasBrandAdvocates = brandAdvocatesWeeklySchedule.some(u => u > 0)
  const hasUgc = ugcWeeklySchedule.some(u => u > 0)
  const hasPremiumUgc = premiumUgcWeeklySchedule.some(u => u > 0)
  const hasSocial = socialWeeklySchedule.some(u => u > 0)
  const hasReviewCreators = hasUgc || hasPremiumUgc

  const daysToCompletePurchasing =
    (max(
      [brandAdvocatesWeeklySchedule, ugcWeeklySchedule, premiumUgcWeeklySchedule, socialWeeklySchedule]
        .filter(cp => cp.some(u => u > 0))
        .map(
          cp =>
            cp.length -
            cp
              .slice()
              .reverse()
              .findIndex(s => s > 0)
        )
    ) || 0) * 7

  const steps: TimelineStep[] = [
    {
      type: TimelineStepType.FIRST_CREATOR,
      date: startDate,
      isActual: true
    },
    {
      type: TimelineStepType.PURCHASING_COMPLETE,
      date: actuals?.lastPurchaseAt || startDate?.plus({ days: daysToCompletePurchasing }),
      isActual: !!actuals?.lastPurchaseAt
    }
  ]

  if (hasSocial || hasPremiumUgc) {
    steps.push({
      type: TimelineStepType.CLIENT_APPROVES_INITIAL_CREATORS,
      date: actuals?.initialCreatorApprovedAt || startDate?.minus({ days: LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS }),
      isActual: !!actuals?.initialCreatorApprovedAt
    })
    steps.push({
      type: TimelineStepType.CLIENT_APPROVES_INITIAL_CONTENT,
      date: actuals?.initialContentApprovedAt || startDate?.plus({ days: DAYS_TO_SEE_INITIAL_CONTENT }),
      isActual: !!actuals?.initialContentApprovedAt
    })
    steps.push({
      type: TimelineStepType.ALL_CONTENT_CREATED,
      date:
        actuals?.allContentCompletedAt ||
        (actuals?.lastPurchaseAt
          ? actuals.lastPurchaseAt.plus({ days: DAYS_TO_GENERATE_PREMIUM_CONTENT })
          : startDate?.plus({ days: daysToCompletePurchasing + DAYS_TO_GENERATE_PREMIUM_CONTENT })),
      isActual: !!actuals?.allContentCompletedAt
    })
  }

  if (hasUgc) {
    steps.push({
      type: TimelineStepType.FIRST_PRODUCT_REVIEW,
      date: actuals?.firstReviewAt || startDate?.plus({ days: DAYS_TO_FIRST_REVIEW }),
      isActual: !!actuals?.firstReviewAt
    })
    if (!hasSocial && !hasPremiumUgc) {
      steps.push({
        type: TimelineStepType.ALL_CONTENT_CREATED,
        date:
          actuals?.allContentCompletedAt ||
          (actuals?.lastPurchaseAt
            ? actuals.lastPurchaseAt.plus({ days: STANDARD_DAYS_TO_SHARE })
            : startDate?.plus({ days: daysToCompletePurchasing + STANDARD_DAYS_TO_SHARE })),
        isActual: !!actuals?.allContentCompletedAt
      })
    }
  }

  if (hasBrandAdvocates) {
    const brandAdvocatesCompleted =
      !!actuals?.lastPurchaseAt && actuals.lastPurchaseAt.plus({ days: STANDARD_DAYS_TO_SHARE }) < DateTime.now()

    steps.push({
      type: TimelineStepType.PRODUCT_SHARING_COMPLETE,
      date:
        actuals?.allContentCompletedAt ||
        (actuals?.lastPurchaseAt
          ? actuals.lastPurchaseAt.plus({ days: STANDARD_DAYS_TO_SHARE })
          : startDate?.plus({ days: daysToCompletePurchasing + STANDARD_DAYS_TO_SHARE })),
      isActual: !!actuals?.allContentCompletedAt || brandAdvocatesCompleted
    })
  }

  const reviewCreators = zipWith(ugcWeeklySchedule || [], premiumUgcWeeklySchedule || [], (a, b) => (a || 0) + (b || 0))

  const reviewsPerWeek =
    actuals?.reviewsPerWeek ??
    (unitsSoldPerWeek ? getReviewsAllowedPerWeek(unitsSoldPerWeek, store, isVendor) : undefined)

  const daysToHitReviewGoal =
    reviewsGoal && reviewsPerWeek
      ? determineDaysToHitReviewGoal(reviewsGoal, reviewsPerWeek, reviewCreators)
      : undefined

  if (hasReviewCreators && reviewsGoal != null) {
    steps.push({
      type: TimelineStepType.REVIEW_GOAL_HIT,
      date:
        actuals?.reviewsGoalHitAt || (daysToHitReviewGoal ? startDate?.plus({ days: daysToHitReviewGoal }) : undefined),
      isActual: !!actuals?.reviewsGoalHitAt
    })
  }

  if (actuals?.endDate) {
    steps.push({
      type: TimelineStepType.GOALS_COMPLETED,
      date: actuals?.endDate,
      isActual: true
    })
  }

  const sortedSteps = orderBy(steps, 'date', 'asc')
  // TODO: Removed for now, will readd later. Having issues around how to calculated this with integrated sales, etc
  // if (hasReviewCreators && last(sortedSteps)?.type === TimelineStepType.REVIEW_GOAL_HIT) {
  //   sortedSteps.splice(steps.length - 1, 0, {
  //     type: TimelineStepType.REVIEWS_PER_WEEK,
  //     value: reviewsPerWeek != null && reviewsGoal != null ? Math.min(reviewsPerWeek, reviewsGoal) : undefined,
  //     isActual: !!actuals?.reviewsPerWeek
  //   })
  // }

  // If adaptive is turned on, any goals that are not complete and their date is in the past, move them to be tomorrow
  return !isAdaptive
    ? sortedSteps
    : sortedSteps.map(s => ({
        ...s,
        date: s.date && !s.isActual && s.date < DateTime.now() ? DateTime.now().plus({ days: 1 }) : s.date
      }))
}

const determineDaysToHitReviewGoal = (reviewGoal: number, reviewsPerWeek: number, weeklyCreatorSchedule: number[]) => {
  let days = 12
  let reviewsLeft = reviewGoal
  let carryOverCreators = 0
  let i = 0
  while (i < weeklyCreatorSchedule.length || (carryOverCreators && reviewsLeft)) {
    const creatorReviews = round(0.5 * (weeklyCreatorSchedule[i] || 0)) + carryOverCreators
    const reviews = Math.min(creatorReviews, reviewsPerWeek)
    carryOverCreators = Math.max(creatorReviews - reviews, 0)
    reviewsLeft -= reviews
    days += 7

    if (reviewsLeft <= 0) {
      return days
    }
    if (days > 365) {
      return 365
    }
    i++
  }
  // Will not be met
  return null
}
export const getStringForStep = (step: TimelineStep) => {
  const now = DateTime.now()
  const isPast = step.date && step.date < now

  switch (step.type) {
    case TimelineStepType.FIRST_CREATOR:
      return isPast ? 'First creator joined' : 'First creator joins'
    case TimelineStepType.FIRST_PRODUCT_REVIEW:
      return 'First product review'
    case TimelineStepType.PURCHASING_COMPLETE:
      return 'All creators received product'
    case TimelineStepType.PRODUCT_SHARING_COMPLETE:
      return 'Product sharing completed'
    case TimelineStepType.ALL_CONTENT_CREATED:
      return 'Content goal hit!'
    case TimelineStepType.REVIEWS_PER_WEEK:
      return `${step.value || '--'} incremental reviews per week`
    case TimelineStepType.REVIEW_GOAL_HIT:
      return 'Review goal hit!'
    case TimelineStepType.CLIENT_APPROVES_INITIAL_CREATORS:
      return isPast ? 'Client approved initial creators' : 'Client approves initial creators'
    case TimelineStepType.CLIENT_APPROVES_INITIAL_CONTENT:
      return isPast ? 'Client approved initial content' : 'Client approves initial content'
    case TimelineStepType.PRODUCT_AND_CONTENT_COMPLETE:
      return 'Content created and product sharing completed'
    case TimelineStepType.GOALS_COMPLETED:
      return 'Campaign goals completed!'
    default:
      return ''
  }
}
