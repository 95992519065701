import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_AGENCY: ModelTypes['Agency'] = {
  id: 'sample-agency',
  name: 'Sample Agency',
  logo: 'https://media.productwind.com/sample/images/sample-agency.png',
  websiteUrl: 'https://www.agency1.com',
  createdAt: '2024-02-01T17:33:20.413Z',
  updatedAt: '2024-02-01T17:33:20.413Z'
}
