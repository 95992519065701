import { Autocomplete, AutocompleteValue, Box, Chip, TextField, Typography, Stack } from '@mui/material'
import { SelectInputProps } from './types'
import { withInputBase } from '../InputBase/withInputBase'
import { SyntheticEvent } from 'react'
import { isArray, isString, map } from 'lodash'
import { useFormContext } from 'react-hook-form'

export const SelectInput = ({
  field,
  autocomplete,
  options,
  disableClearable,
  disableTags,
  ...props
}: SelectInputProps) => {
  const { trigger } = useFormContext()

  const fieldValue = stringifyNonStrings(field?.value)

  const currentValue = props.multiple
    ? options.filter(({ value }) => fieldValue?.includes(stringifyNonStrings(value)))
    : options.find(({ value }) => fieldValue === stringifyNonStrings(value))

  const handleChange = (_e: SyntheticEvent<Element, Event>, val: AutocompleteValue<any, false, false, false>) => {
    if (field?.onChange) {
      const newValue = isArray(val) ? map(val, 'value').sort() : val?.value
      field.onChange({ target: { name, value: newValue === undefined ? null : newValue } })
      trigger(field.name)
    }
  }

  return (
    <Autocomplete
      {...field}
      {...props}
      value={currentValue || null}
      {...{ disableClearable, options }}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) => {
        return disableTags
          ? null
          : tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} label={option.label} size="small" color="primary" />
            ))
      }}
      renderOption={(props, option, _state, ownerState) => (
        <Box
          component="li"
          {...props}
          key={option.key || JSON.stringify(option.value)}
          {...(option.sx && { sx: option.sx })}
        >
          {option.startAdornment}
          <Stack>
            {ownerState.getOptionLabel(option)}
            {option.sublabel &&
              (typeof option.sublabel === 'string' ? (
                <Typography variant="body1" color="grey.A700">
                  {option.sublabel}
                </Typography>
              ) : (
                option.sublabel
              ))}
          </Stack>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          inputProps={{
            ...params.inputProps,
            readOnly: !autocomplete
          }}
        />
      )}
    />
  )
}

export default withInputBase(SelectInput)

const stringifyNonStrings = (value: any) => (isString(value) ? value : JSON.stringify(value))
