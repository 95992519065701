import { CampaignDetails } from '@momentum/routes/campaign/context/queries'
import { Document, Font } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import { AISummary, FeedbackTheme } from '..//feedbackContext'
import { ProductFeedbackSummary } from '../queries'
import { FeedbackDataDisplayPDF } from './FeedbackDataDisplayPDF'
import { PagePDF } from './PagePDF'
import { SummaryPDF } from './SummaryPDF'
import { ThemeSummaryPDF } from './ThemeSummaryPDF'

export type FeedbackPDFProps = {
  productFeedbackSummary: ProductFeedbackSummary
  aiSummary?: AISummary
  themes: FeedbackTheme[]
  chartBase64Image?: string
  campaignDetails: CampaignDetails
}

export const FeedbackPDFDocument = (props: FeedbackPDFProps) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: '/font/Avenir/Avenir-Regular.ttf'
      },
      {
        src: '/font/Avenir/Avenir-Black.woff',
        fontWeight: 'bold'
      }
    ]
  })

  return (
    <Document
      pageLayout="singlePage"
      pageMode="useNone"
      title={`${props.campaignDetails.title} Feedback Report`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <PagePDF {...props}>
        <SummaryPDF {...props} />
        <FeedbackDataDisplayPDF {...props} />
      </PagePDF>

      <ThemeSummaryPDF {...props} />
    </Document>
  )
}
