import { Stack } from '@mui/system'
import { useState } from 'react'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Search } from '@mui/icons-material'
import { useBrandContext } from '../context/BrandContext'
import { CampaignCard } from '@momentum/components/campaign-card'
import { EmptyState } from './EmptyState'
import { orderBy } from 'lodash'
import Loading from '@momentum/components/loading'

export const BrandCampaigns = () => {
  const { campaigns, brand } = useBrandContext()
  const [search, setSearch] = useState('')

  const lowerSearch = search.toLowerCase()
  const filteredCampaigns = orderBy(
    lowerSearch
      ? (campaigns || []).filter(
          c => c.product?.name.toLowerCase().includes(lowerSearch) || c.title.toLowerCase().includes(lowerSearch)
        )
      : campaigns,
    c => c.startDate,
    'desc'
  )
  const isEmpty = filteredCampaigns.length === 0

  if (!campaigns) {
    return <Loading />
  }

  return !isEmpty ? (
    <Stack spacing={3} mt={2} pb={2}>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <DebouncedTextField
          placeholder={'Search campaigns'}
          sx={{ mb: 4, width: 330 }}
          onChange={setSearch}
          InputProps={{
            endAdornment: <Search />
          }}
        />
      </Stack>

      <Stack spacing={2}>
        {filteredCampaigns.map(c => (
          <CampaignCard key={c.id} campaign={c} brandId={brand.id} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <EmptyState brandId={brand.id} />
  )
}
