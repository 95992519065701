import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CampaignStatus } from '@productwindtom/shared-campaign'
import { CheckCircleOutlined, RocketLaunchOutlined, UpcomingOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, Chip, Link, Stack, Typography } from '@mui/material'
import { InvoiceStatus } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import EndCampaign from './EndCampaign'
import { ProductCard } from './ProductCard'

export const HeaderBar = () => {
  const { selectedCompany, selectedBrand, isAdminView } = useUserSessionContext()
  const {
    campaignDetails: { id, skuId, title, product, numCreatorsJoined, numCreators, startDate, endDate, proposal },
    activeStatus
  } = useCampaignContext()

  const crumbs = [...useClientBreadcrumbs(selectedCompany, selectedBrand, { id: id, name: title, skuId: skuId })]

  //  Going to wait on this for now
  // const nowDate = DateTime.now().toISODate()
  // const campaignsWithPaymentDue = campaigns?.find(
  //   c =>
  //     c.id !== id && c.proposal?.invoiceStatus === InvoiceStatus.NOT_PAID && (c.proposal.invoiceDueDate || '') > nowDate
  // )

  const paymentDueInformation =
    proposal?.invoiceStatus === InvoiceStatus.NOT_PAID && proposal.invoiceDueDate
      ? {
          scheduledLaunchDate: DateTime.fromISO(proposal.launchDate),
          dueDate: DateTime.fromISO(proposal.invoiceDueDate),
          invoiceLink: proposal.invoiceLink,
          invoiceId: proposal.invoiceId,
          invoiceNumber: proposal.invoiceNumber,
          daysTillDue: Math.floor(DateTime.fromISO(proposal.invoiceDueDate).diffNow('days').days)
        }
      : null

  return (
    <Stack spacing={2} data-cy={'campaignHeader'}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <RoutesBreadcrumb crumbs={crumbs} />
        {isAdminView && activeStatus !== CampaignStatus.FINISHED && <EndCampaign />}
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} spacing={2} alignItems={'flex-start'}>
        <Stack spacing={2}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography
              variant={'h3'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {title}
            </Typography>
            {proposal?.invoiceStatus === InvoiceStatus.NOT_PAID && <NotPaidBadge />}
            {proposal?.invoiceStatus === InvoiceStatus.PAID && <PaidBadge />}
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {activeStatus === CampaignStatus.ACTIVE && (
              <Chip icon={<RocketLaunchOutlined />} label={'Active'} color={'success'} size={'medium'} />
            )}
            {activeStatus === CampaignStatus.UPCOMING && (
              <Chip icon={<UpcomingOutlined />} label={'Upcoming'} color={'info'} size={'medium'} />
            )}
            {activeStatus === CampaignStatus.PRE_LAUNCH && (
              <Chip icon={<UpcomingOutlined />} label={'Pre-Launch'} color={'info'} size={'medium'} />
            )}
            {activeStatus === CampaignStatus.FINISHED && (
              <Chip icon={<RocketLaunchOutlined />} label={'Ended'} size={'medium'} />
            )}
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Typography variant={'label3'}>
                {numCreatorsJoined}/{numCreators}
              </Typography>
              <Typography variant={'caption2'}>creators joined</Typography>
            </Stack>
            <Typography variant={'label3'}>
              {DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_MED)} -
              {endDate
                ? ` ${DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}`
                : activeStatus === CampaignStatus.ACTIVE
                  ? ' today'
                  : ''}
            </Typography>
          </Stack>
        </Stack>
        <ProductCard product={product} />
      </Stack>

      {/*{campaignsWithPaymentDue && (*/}
      {/*  <Alert severity={'error'} variant={'outlined'}>*/}
      {/*    <AlertTitle>*/}
      {/*      <Typography variant={'label2'}>Payment for another campaign is overdue!</Typography>*/}
      {/*    </AlertTitle>*/}
      {/*    <Typography color={'black'}>*/}
      {/*      View invoice{' '}*/}
      {/*      <Link href={campaignsWithPaymentDue.proposal?.invoiceLink}>*/}
      {/*        #{campaignsWithPaymentDue.proposal?.invoiceId?.split('_').pop()}*/}
      {/*      </Link>*/}
      {/*      . This campaign will not run until your other invoice is paid.*/}
      {/*    </Typography>*/}
      {/*  </Alert>*/}
      {/*)}*/}
      {paymentDueInformation && <PaymentDueAlert {...paymentDueInformation} campaignStatus={activeStatus} />}
    </Stack>
  )
}

const getPaymentString = (activeStatus: CampaignStatus, dueDate: DateTime, scheduledLaunchDate: DateTime) => {
  switch (activeStatus) {
    case CampaignStatus.ACTIVE:
      return 'This campaign will stop running if payment is not received on the payment due date.'
    case CampaignStatus.DELAYED:
    case CampaignStatus.UPCOMING:
    case CampaignStatus.PRE_LAUNCH: {
      if (dueDate > scheduledLaunchDate) {
        return 'This campaign may stop running if payment is not received on the payment due date.'
      }
      return 'This campaign will not start until payment is received.'
    }
    default:
      return ''
  }
}

const PaymentDueAlert = ({
  dueDate,
  invoiceLink,
  invoiceNumber,
  campaignStatus,
  scheduledLaunchDate
}: {
  dueDate: DateTime
  invoiceLink?: string
  invoiceNumber?: string
  campaignStatus: CampaignStatus
  scheduledLaunchDate: DateTime
}) => {
  const daysTillDue = Math.ceil(dueDate.diff(DateTime.local(), 'days').days)

  const severity = daysTillDue <= 0 ? 'error' : daysTillDue >= 10 ? 'info' : 'warning'
  const message =
    severity === 'error'
      ? 'Payment for this campaign is overdue!'
      : `Payment for this campaign is due in ${daysTillDue} days!`
  return (
    <Alert severity={severity} variant={'outlined'}>
      <AlertTitle>
        <Typography variant={'label2'} color={'black'}>
          {message}
        </Typography>
      </AlertTitle>
      {!!invoiceNumber && (
        <Typography color={'black'}>
          View invoice{' '}
          <Link variant={'body1'} href={invoiceLink} target={'_blank'}>
            #{invoiceNumber}
          </Link>
          . {getPaymentString(campaignStatus, dueDate, scheduledLaunchDate)}
        </Typography>
      )}
      {!invoiceNumber && (
        <Typography color={'black'}>{getPaymentString(campaignStatus, dueDate, scheduledLaunchDate)}</Typography>
      )}
    </Alert>
  )
}

const PaidBadge = () => (
  <Stack
    direction={'row'}
    spacing={0.5}
    alignItems={'center'}
    borderRadius={'100px'}
    border={'1px solid #EBEDF6'}
    px={1}
    py={0.5}
    bgcolor={'#F8FBFF'}
    data-cy={'paidBadge'}
  >
    <Typography variant={'label3'}>Paid</Typography>
    <CheckCircleOutlined color={'success'} />
  </Stack>
)

const NotPaidBadge = () => (
  <Stack
    direction={'row'}
    spacing={0.5}
    alignItems={'center'}
    borderRadius={'4px'}
    border={'1px solid #F9E4A7'}
    px={1}
    py={0.5}
    bgcolor={'#FDF4DC'}
    data-cy={'notPaidBadge'}
  >
    <Typography variant={'label4'}>Unpaid</Typography>
  </Stack>
)
