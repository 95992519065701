import {
  CampaignCreatorStatus,
  Channel,
  ContentType,
  CreatorType,
  ModelTypes,
  Store
} from '@productwindtom/shared-momentum-zeus-types'
import { BRANDS } from '../../types'

export const SAMPLE_CAMPAIGN_KEYBOARD: ModelTypes['CampaignDetails'] = {
  id: 'sample-campaign-keyboard',
  brandId: BRANDS.CE,
  skuId: 'sample-sku-keyboard',
  title: 'Keyboard and Mouse',
  numCreators: 155,
  numCreatorsJoined: 155,
  numAdditionalBrandAdvocateCreatorsJoined: 0,
  numAdditionalBrandAdvocateCreators: 0,
  numBrandAdvocateCreators: 0,
  numSocialCreators: 0,
  numPremiumUgcCreators: 0,
  numUgcCreators: 155,
  releaseSchedule: {
    socialWeeklySchedule: [],
    premiumUgcWeeklySchedule: [],
    ugcWeeklySchedule: [75, 104, 35],
    brandAdvocatesWeeklySchedule: []
  },
  createdAt: '2024-01-01T16:22:12.000Z',
  anticipatedStartDate: '2024-01-13T16:22:12.000Z',
  startDate: '2024-01-13T16:22:12.000Z',
  endDate: '2024-02-20T19:39:59.316Z',
  cost: 1740205,
  product: {
    id: 'sample-sku-keyboard',
    name: 'Keyboard and Mouse',
    skuId: 'B0CM98L3QY',
    price: '$49.99',
    priceCents: 4999,
    image: 'https://media.productwind.com/sample/images/keyboard.png',
    listingLink: 'https://www.amazon.com/Logitech-Wireless-Customizable-Bluetooth-Easy-Switch/dp/B0BT4G499X',
    store: Store.amazon,
    ratingSnapshots: {
      items: []
    },
    brandId: BRANDS.CE,
    recommendations: [],
    createdAt: '2024-01-13T16:22:12.000Z',
    updatedAt: '2024-01-13T16:22:12.000Z'
  },
  creators: [
    {
      purchaseDetails: {
        purchaseDate: '2024-01-22T09:32:19.000Z',
        amountCents: 5411
      },
      status: CampaignCreatorStatus.IN_PROGRESS,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-02T18:37:46.822Z',
          createdAt: '2024-01-31T06:29:11.140Z',
          content: [
            {
              id: '07891908-aed7-4f3a-890d-b9e3083e5269',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/30ef9ad6-8ad2-4d49-b11e-ad929ee1350d/ugc/d5899bfe-5b07-4312-b859-85d448ed1458/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e0477b16-88a4-4df4-b372-4822c73ac90b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/30ef9ad6-8ad2-4d49-b11e-ad929ee1350d/ugc/d5899bfe-5b07-4312-b859-85d448ed1458/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '86fc1a98-ff34-4479-a94f-eae16d33d2e8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/30ef9ad6-8ad2-4d49-b11e-ad929ee1350d/ugc/d5899bfe-5b07-4312-b859-85d448ed1458/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd5899bfe-5b07-4312-b859-85d448ed1458'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-02T18:37:41.146Z',
          createdAt: '2024-01-28T06:51:39.831Z',
          content: [
            {
              id: '5a67f270-0174-467a-830a-032575957f5e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/30ef9ad6-8ad2-4d49-b11e-ad929ee1350d/ugc/fbe4be65-2068-458b-a664-5b72a91c5cb3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'fbe4be65-2068-458b-a664-5b72a91c5cb3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '30ef9ad6-8ad2-4d49-b11e-ad929ee1350d',
        firstName: 'Josue',
        lastName: 'Rodriguez',
        email: 'josue.rguez@gmail.com',
        instagramProfile: {
          handle: 'wildjuju',
          followers: 12854,
          engagementRate: 0.2077174420413879,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'wildjuju',
          followers: 11579,
          engagementRate: 2.041434082864086,
          isPrimary: false
        }
      },
      userCampaignId: '30ef9ad6-8ad2-4d49-b11e-ad929ee1350d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T18:44:29.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T16:16:34.586Z',
          createdAt: '2024-02-11T02:41:31.717Z',
          content: [
            {
              id: 'f82fe15e-8c1b-4644-a847-81e5353c32f7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/574a79c7-7c27-4475-92b2-d212b9bdc7a8/ugc/b71e2214-4fd7-4fa4-9319-782c1be84003/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '788a7659-c4e0-4bca-9d93-c904b1eeb697',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/574a79c7-7c27-4475-92b2-d212b9bdc7a8/ugc/b71e2214-4fd7-4fa4-9319-782c1be84003/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2196064f-b556-43d7-8e6b-fe614916bf47',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/574a79c7-7c27-4475-92b2-d212b9bdc7a8/ugc/b71e2214-4fd7-4fa4-9319-782c1be84003/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b71e2214-4fd7-4fa4-9319-782c1be84003'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-12T16:16:30.792Z',
          createdAt: '2024-02-11T04:21:07.678Z',
          content: [
            {
              id: '0c54f39e-6f74-40e4-a729-cfdafa3c095b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/574a79c7-7c27-4475-92b2-d212b9bdc7a8/ugc/df5a1c8f-aae7-4225-8a8b-d07123edac14/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'df5a1c8f-aae7-4225-8a8b-d07123edac14'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '574a79c7-7c27-4475-92b2-d212b9bdc7a8',
        firstName: 'Jasmine',
        lastName: 'Hall',
        email: 'lifewithjkiara@gmail.com',
        instagramProfile: {
          handle: 'lifewithjkiara',
          followers: 619,
          engagementRate: 1.0662358642972536,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'lifewithjkiara',
          followers: 4265,
          engagementRate: 7.724967566930063,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'lifewithjkiara',
          followers: 224
        }
      },
      userCampaignId: '574a79c7-7c27-4475-92b2-d212b9bdc7a8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T16:16:11.000Z',
        amountCents: 6125
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-07T02:15:47.154Z',
          createdAt: '2024-02-05T04:39:58.457Z',
          content: [
            {
              id: '2dd193e3-f51a-499b-a8e8-3a79afef03e3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bd184990-f458-4eb3-bcb9-24bddcd55703/ugc/2694a04a-5812-46a3-bdfa-bd7bca6baa6f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3c42ab4e-3fdb-459d-8567-ce54b105c81b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bd184990-f458-4eb3-bcb9-24bddcd55703/ugc/2694a04a-5812-46a3-bdfa-bd7bca6baa6f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1430e5db-8205-406a-b630-2072a011b6ef',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bd184990-f458-4eb3-bcb9-24bddcd55703/ugc/2694a04a-5812-46a3-bdfa-bd7bca6baa6f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2694a04a-5812-46a3-bdfa-bd7bca6baa6f'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T02:15:43.022Z',
          createdAt: '2024-02-05T04:39:02.960Z',
          content: [
            {
              id: 'fe48c045-c34b-4a1b-a047-0f7af7e8b60f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bd184990-f458-4eb3-bcb9-24bddcd55703/ugc/65213158-c27e-466d-ba64-12878f1cab73/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '65213158-c27e-466d-ba64-12878f1cab73'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bd184990-f458-4eb3-bcb9-24bddcd55703',
        firstName: 'Kelli',
        lastName: 'Hines',
        email: 'khines613@yahoo.com',
        instagramProfile: {
          handle: 'kelli_creates_content',
          followers: 408,
          engagementRate: 3.8235294117647056,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kelli_hines',
          followers: 5112,
          engagementRate: 14.779423018059381,
          isPrimary: true
        }
      },
      userCampaignId: 'bd184990-f458-4eb3-bcb9-24bddcd55703'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T20:05:49.000Z',
        amountCents: 6539
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'b9aa9544-ead4-4d17-b034-9bd80ba53a93',
        firstName: 'Karima',
        lastName: 'Gordon',
        email: 'craftingwithrima99@gmail.com',
        instagramProfile: {
          handle: 'everybodylovesrima',
          followers: 22457,
          engagementRate: 0.2364518858262457,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'heyyy.imrimaa',
          followers: 57574,
          engagementRate: 17.079917020646054,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'itssrimaa',
          followers: 1360
        }
      },
      userCampaignId: 'b9aa9544-ead4-4d17-b034-9bd80ba53a93'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T15:55:29.000Z',
        amountCents: 7355
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-31T05:40:41.357Z',
          createdAt: '2024-01-28T22:14:35.549Z',
          content: [
            {
              id: '16e33d0d-6586-4d6d-a933-fe75a9105e55',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8afc40ab-b2a0-4982-8a9b-d6ac8608610e/ugc/0880ab1f-eeb6-4efe-b2fb-9ce3fc2beb72/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a0b11db6-b3b1-4922-b29c-862ba4708afa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8afc40ab-b2a0-4982-8a9b-d6ac8608610e/ugc/0880ab1f-eeb6-4efe-b2fb-9ce3fc2beb72/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd6d58a6d-c2d0-4409-9699-dc32b0028d1c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8afc40ab-b2a0-4982-8a9b-d6ac8608610e/ugc/0880ab1f-eeb6-4efe-b2fb-9ce3fc2beb72/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0880ab1f-eeb6-4efe-b2fb-9ce3fc2beb72'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-31T05:40:35.682Z',
          createdAt: '2024-01-28T22:14:05.823Z',
          content: [
            {
              id: '2cd77fdd-fb18-4b7c-a7e0-991bc913e075',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8afc40ab-b2a0-4982-8a9b-d6ac8608610e/ugc/609e4dfd-0f3d-41e8-8143-932697bbb4a2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '609e4dfd-0f3d-41e8-8143-932697bbb4a2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8afc40ab-b2a0-4982-8a9b-d6ac8608610e',
        firstName: 'Christian',
        lastName: 'Roque',
        email: 'christian.b.roq@gmail.com',
        instagramProfile: {
          handle: 'christianbryanmusic',
          followers: 1318,
          engagementRate: 3.657056145675266,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'christianbryanmusic',
          followers: 13837,
          engagementRate: 11.627906976744185,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'christianbryan'
        }
      },
      userCampaignId: '8afc40ab-b2a0-4982-8a9b-d6ac8608610e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T16:14:25.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '61324e63-dbdf-47f0-a0ee-cc0f10260832',
        firstName: 'Brianna',
        lastName: 'Maxwell',
        email: 'collabwithbri@icloud.com',
        instagramProfile: {
          handle: 'bodybybrianna',
          followers: 11982,
          engagementRate: 0.16691704223001166,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'gijanebarbie',
          followers: 30078,
          engagementRate: 6.1972873310961685,
          isPrimary: false
        }
      },
      userCampaignId: '61324e63-dbdf-47f0-a0ee-cc0f10260832'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T19:33:09.000Z',
        amountCents: 6583
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'bddb5db8-b44d-4cdc-a031-eea9a25dcf26',
        firstName: 'renaco',
        lastName: 'jamieson',
        email: 'renacojamieson18@gmail.com',
        instagramProfile: {
          handle: 'reneditz_',
          followers: 59,
          engagementRate: 0.08103448275862069,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'naco.876',
          followers: 2144,
          engagementRate: 1.9642333626502493,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'renacojamieson4100',
          followers: 0
        }
      },
      userCampaignId: 'bddb5db8-b44d-4cdc-a031-eea9a25dcf26'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T06:14:56.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-03T23:50:01.039Z',
          createdAt: '2024-01-26T20:16:11.395Z',
          content: [
            {
              id: '330b5fc7-e83e-4f94-9880-d885c5ec47bc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f25582d6-6a86-4502-8cf7-5d636992c110/ugc/4318c9c2-c8e6-4764-a508-cf743cecf7c5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1c4d1277-3f68-49d9-972a-3cca33eff624',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f25582d6-6a86-4502-8cf7-5d636992c110/ugc/4318c9c2-c8e6-4764-a508-cf743cecf7c5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ff786239-e640-455f-9de9-df0bb42bc58c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f25582d6-6a86-4502-8cf7-5d636992c110/ugc/4318c9c2-c8e6-4764-a508-cf743cecf7c5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4318c9c2-c8e6-4764-a508-cf743cecf7c5'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T18:05:46.529Z',
          createdAt: '2024-01-27T04:42:15.487Z',
          content: [
            {
              id: '98a701b5-ba46-4344-8df6-611f5d39b068',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f25582d6-6a86-4502-8cf7-5d636992c110/ugc/bc01d624-9ab2-4ac2-a6f5-4cb8de7cb2c5/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bc01d624-9ab2-4ac2-a6f5-4cb8de7cb2c5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f25582d6-6a86-4502-8cf7-5d636992c110',
        firstName: 'Jennifer',
        lastName: 'Jarvie',
        email: 'jarvie.jennifer@gmail.com',
        tiktokProfile: {
          handle: 'worldlyjen',
          followers: 22504,
          engagementRate: 10.152895031604112,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'clubjarvie3708',
          followers: 25
        }
      },
      userCampaignId: 'f25582d6-6a86-4502-8cf7-5d636992c110'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-13T16:25:51.000Z',
        amountCents: 6359
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T14:58:57.283Z',
          createdAt: '2024-02-07T03:49:51.844Z',
          content: [
            {
              id: '4ad2081d-3315-436a-b4db-9558bb7d9746',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e9bf4448-f481-488d-a8cc-36e3204355b4/ugc/68bae5a4-093f-498a-ad0a-76e7589ae636/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '68bae5a4-093f-498a-ad0a-76e7589ae636'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-07T13:08:34.254Z',
          createdAt: '2024-02-07T03:50:21.623Z',
          content: [
            {
              id: 'ae7cf856-d9b7-4566-90da-e055b982cb3c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e9bf4448-f481-488d-a8cc-36e3204355b4/ugc/b98052d0-2eaa-46c7-87d0-e1b26d1c4253/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4b98b9a5-ac96-468f-9961-212ab757f631',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e9bf4448-f481-488d-a8cc-36e3204355b4/ugc/b98052d0-2eaa-46c7-87d0-e1b26d1c4253/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd4a9e3ee-8f32-4d70-a972-b465a40f9187',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e9bf4448-f481-488d-a8cc-36e3204355b4/ugc/b98052d0-2eaa-46c7-87d0-e1b26d1c4253/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b98052d0-2eaa-46c7-87d0-e1b26d1c4253'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e9bf4448-f481-488d-a8cc-36e3204355b4',
        firstName: 'C.M.',
        lastName: 'Williams',
        email: 'cmwilliams@hyperionprod.com',
        instagramProfile: {
          handle: 'techie.shooter',
          followers: 10877,
          engagementRate: 0.9037418405810426,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'techie.shooter',
          followers: 3697,
          engagementRate: 13.334888059701495,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'colormetech'
        }
      },
      userCampaignId: 'e9bf4448-f481-488d-a8cc-36e3204355b4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T09:35:52.000Z',
        amountCents: 5304
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'b05e312b-58de-45cd-bd8e-c7b5003175a2',
        firstName: 'Deya',
        lastName: 'Lopez',
        email: 'natureee99@gmail.com',
        instagramProfile: {
          handle: '_naturebabe',
          followers: 26628,
          engagementRate: 3.1496920534775423,
          isPrimary: false
        },
        tiktokProfile: {
          handle: '_natureee__',
          followers: 32305,
          engagementRate: 16.510966693744923,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'deya4382'
        }
      },
      userCampaignId: 'b05e312b-58de-45cd-bd8e-c7b5003175a2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-16T21:21:47.000Z',
        amountCents: 6389
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-20T19:40:50.201Z',
          createdAt: '2024-01-28T05:55:35.876Z',
          content: [
            {
              id: '83892b14-3ae8-4d03-a33e-b5978f8f7961',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700d508c-6946-4b20-b7a2-6b9b79b38b28/ugc/b5b4ccee-0ea6-4a97-b903-ba9bcfb49676/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a8920447-77d8-460a-a0d4-ca10139599a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700d508c-6946-4b20-b7a2-6b9b79b38b28/ugc/b5b4ccee-0ea6-4a97-b903-ba9bcfb49676/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c2f1db38-623f-4d85-85ff-8e1d18e8e8b3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700d508c-6946-4b20-b7a2-6b9b79b38b28/ugc/b5b4ccee-0ea6-4a97-b903-ba9bcfb49676/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b5b4ccee-0ea6-4a97-b903-ba9bcfb49676'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T19:40:55.005Z',
          createdAt: '2024-02-10T03:45:01.169Z',
          content: [
            {
              id: '52ca984d-b56e-49aa-99eb-59904a8f9773',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700d508c-6946-4b20-b7a2-6b9b79b38b28/ugc/671c7dc6-4c87-4232-8c93-a890328695aa/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '671c7dc6-4c87-4232-8c93-a890328695aa'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '700d508c-6946-4b20-b7a2-6b9b79b38b28',
        firstName: 'Samantha',
        lastName: 'Moly',
        email: 'samanthamoly@outlook.com',
        instagramProfile: {
          handle: 'xsamxsm',
          followers: 220,
          engagementRate: 12.727272727272727,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'xsamxsm',
          followers: 41778,
          engagementRate: 6.951521828949181,
          isPrimary: true
        }
      },
      userCampaignId: '700d508c-6946-4b20-b7a2-6b9b79b38b28'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T19:54:32.000Z',
        amountCents: 5324
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T17:20:51.175Z',
          createdAt: '2024-02-15T21:44:49.909Z',
          content: [
            {
              id: '3f058723-5664-44e0-805e-0f6f10c49516',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6834ea47-4ca3-46c4-bfbd-05aa74d41de1/ugc/4b6d5190-b454-40a1-8591-8e9c68db6fe2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '63df1912-06ef-4088-84d9-f22557cbd9d5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6834ea47-4ca3-46c4-bfbd-05aa74d41de1/ugc/4b6d5190-b454-40a1-8591-8e9c68db6fe2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '34dd4e1d-38eb-4a5d-bf74-2aa98c36c5cd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6834ea47-4ca3-46c4-bfbd-05aa74d41de1/ugc/4b6d5190-b454-40a1-8591-8e9c68db6fe2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4b6d5190-b454-40a1-8591-8e9c68db6fe2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-16T17:20:40.704Z',
          createdAt: '2024-02-15T22:35:39.919Z',
          content: [
            {
              id: 'e2b2e339-67df-409c-b2ed-78be00f91284',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6834ea47-4ca3-46c4-bfbd-05aa74d41de1/ugc/03bdfa7a-2bd7-4e79-a87b-9ea77bf79fb3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '03bdfa7a-2bd7-4e79-a87b-9ea77bf79fb3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6834ea47-4ca3-46c4-bfbd-05aa74d41de1',
        firstName: 'Sebastian',
        lastName: 'Orta',
        email: '7salvage7@gmail.com',
        tiktokProfile: {
          handle: 'thebashanator',
          followers: 40074,
          engagementRate: 14.198983957219252,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'thebashanator',
          followers: 1140
        }
      },
      userCampaignId: '6834ea47-4ca3-46c4-bfbd-05aa74d41de1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T20:06:45.000Z',
        amountCents: 6048
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-09T17:49:02.915Z',
          createdAt: '2024-02-09T04:49:38.568Z',
          content: [
            {
              id: '08ecd3e7-c3e6-469a-bf59-4eb8b46b7511',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56dd87ed-1867-4079-b43a-c722ffdff882/ugc/b060c29b-7e6c-4d3b-bff0-82e2dbd9f107/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '403a7eaf-cac3-4e34-9a68-8577cf32ffd8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56dd87ed-1867-4079-b43a-c722ffdff882/ugc/b060c29b-7e6c-4d3b-bff0-82e2dbd9f107/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '29539f2c-430d-4816-8220-d099c89ad4c8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56dd87ed-1867-4079-b43a-c722ffdff882/ugc/b060c29b-7e6c-4d3b-bff0-82e2dbd9f107/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b060c29b-7e6c-4d3b-bff0-82e2dbd9f107'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-09T17:48:56.935Z',
          createdAt: '2024-02-09T04:44:46.692Z',
          content: [
            {
              id: '9d052e10-68f8-4661-a52a-d2b21650ddb1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/56dd87ed-1867-4079-b43a-c722ffdff882/ugc/9e59777b-63b5-46e6-8e04-f4f0beefa7a7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9e59777b-63b5-46e6-8e04-f4f0beefa7a7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '56dd87ed-1867-4079-b43a-c722ffdff882',
        firstName: 'JONATHAN',
        lastName: 'YOUNG',
        email: 'jonathane.young@outlook.com',
        instagramProfile: {
          handle: 'youngutah',
          followers: 10930,
          engagementRate: 0.272644098810613,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'youngutah',
          followers: 2386,
          engagementRate: 2.959800393645997,
          isPrimary: true
        }
      },
      userCampaignId: '56dd87ed-1867-4079-b43a-c722ffdff882'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-17T18:33:14.759Z',
          createdAt: '2024-02-14T21:31:51.389Z',
          content: [
            {
              id: 'e8202453-84e9-407c-b635-ebe42fefda60',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fa42a7e2-7870-4390-82d0-16e82fb13b92/ugc/b321c9dd-229b-4dab-97b2-53e233969d79/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7f46036d-b320-4ece-80f7-31c7f77946a3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fa42a7e2-7870-4390-82d0-16e82fb13b92/ugc/b321c9dd-229b-4dab-97b2-53e233969d79/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '546224ef-c7d1-4501-907c-2d6769236cea',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fa42a7e2-7870-4390-82d0-16e82fb13b92/ugc/b321c9dd-229b-4dab-97b2-53e233969d79/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b321c9dd-229b-4dab-97b2-53e233969d79'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-17T18:33:08.522Z',
          createdAt: '2024-02-14T21:29:02.054Z',
          content: [
            {
              id: '3cabc9d5-380c-42a4-95b0-6ab5a038439e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fa42a7e2-7870-4390-82d0-16e82fb13b92/ugc/083d760c-b226-43ec-b7fc-fc2be48f5bf4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '083d760c-b226-43ec-b7fc-fc2be48f5bf4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'fa42a7e2-7870-4390-82d0-16e82fb13b92',
        firstName: 'Brenton',
        lastName: 'Roggow',
        email: 'brog83@gmail.com',
        instagramProfile: {
          handle: 'brenton.roggow',
          followers: 14154,
          engagementRate: 0.6704818425886675,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'brenton.roggow',
          followers: 257,
          engagementRate: 3.539468330652552,
          isPrimary: true
        }
      },
      userCampaignId: 'fa42a7e2-7870-4390-82d0-16e82fb13b92'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T01:35:41.000Z',
        amountCents: 6479
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'dc286ef0-e2f9-47a7-b228-bf4f128c1ef2',
        firstName: 'Laneishia',
        lastName: 'Maxwell',
        email: 'laneishia.maxwell@gmail.com',
        instagramProfile: {
          handle: 'laneishiamaxwell',
          followers: 2305,
          engagementRate: 0.8503253796095446,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'maxdesignsstudios',
          followers: 664,
          engagementRate: 4.846938775510204,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'babynesh05'
        }
      },
      userCampaignId: 'dc286ef0-e2f9-47a7-b228-bf4f128c1ef2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T18:33:32.000Z',
        amountCents: 5411
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-14T16:30:41.961Z',
          createdAt: '2024-02-04T01:29:20.761Z',
          content: [
            {
              id: '655638c4-55ac-4579-a839-38ecfb8bd555',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2295e91f-6e42-4be4-b96e-e84e7019109b/ugc/03c71f9f-bf3c-4c4b-97e9-e7e5c98d69a6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '03c71f9f-bf3c-4c4b-97e9-e7e5c98d69a6'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-14T16:30:46.742Z',
          createdAt: '2024-02-03T21:02:01.374Z',
          content: [
            {
              id: 'b45cfc94-6faf-4893-b653-8c59043139ee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2295e91f-6e42-4be4-b96e-e84e7019109b/ugc/65fbd640-6e6b-433f-abc5-67b44ef0f3ec/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5782e8a4-055a-4d99-a19f-ea1b3786cb8d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2295e91f-6e42-4be4-b96e-e84e7019109b/ugc/65fbd640-6e6b-433f-abc5-67b44ef0f3ec/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c9cf9550-5132-442d-8f2c-1262b8e96a98',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2295e91f-6e42-4be4-b96e-e84e7019109b/ugc/65fbd640-6e6b-433f-abc5-67b44ef0f3ec/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '65fbd640-6e6b-433f-abc5-67b44ef0f3ec'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2295e91f-6e42-4be4-b96e-e84e7019109b',
        firstName: 'Alexandria',
        lastName: 'Marshall',
        email: 'smalltownstylediaries@gmail.com',
        instagramProfile: {
          handle: 'shopsashimarshall',
          followers: 5593,
          engagementRate: 0.2467369926694082,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'sashimarshall',
          followers: 9277,
          engagementRate: 13.017751479289942,
          isPrimary: false
        }
      },
      userCampaignId: '2295e91f-6e42-4be4-b96e-e84e7019109b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T16:19:59.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T17:59:18.401Z',
          createdAt: '2024-01-30T03:14:58.109Z',
          content: [
            {
              id: '8aee65e3-4f38-4383-891d-7726c3b2fdd5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cd82a616-ee4a-49b7-93f8-aa6105de489a/ugc/9d6849d1-b3cc-46f1-bf8a-3825510c62bd/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9d6849d1-b3cc-46f1-bf8a-3825510c62bd'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-30T17:59:22.358Z',
          createdAt: '2024-01-30T02:19:16.192Z',
          content: [
            {
              id: '7950aeb5-5f0e-4dad-b7bf-da6dd60772ab',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cd82a616-ee4a-49b7-93f8-aa6105de489a/ugc/8ee40cae-a288-4110-b4ad-720e9de81a83/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cbb37c8c-3d19-4f3e-a449-411f8dfb6707',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cd82a616-ee4a-49b7-93f8-aa6105de489a/ugc/8ee40cae-a288-4110-b4ad-720e9de81a83/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f95c028c-4c9f-415c-bc12-372e6cb14d4a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cd82a616-ee4a-49b7-93f8-aa6105de489a/ugc/8ee40cae-a288-4110-b4ad-720e9de81a83/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8ee40cae-a288-4110-b4ad-720e9de81a83'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cd82a616-ee4a-49b7-93f8-aa6105de489a',
        firstName: 'Daniel',
        lastName: 'Jarvie',
        email: 'djarviechina@gmail.com',
        instagramProfile: {
          handle: 'travelingdannyboy',
          followers: 32790,
          engagementRate: 0.12930771576700212,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'travelingdannyboy',
          followers: 61619,
          engagementRate: 4.81651376146789,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'danieljarvie9588'
        }
      },
      userCampaignId: 'cd82a616-ee4a-49b7-93f8-aa6105de489a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T16:32:09.000Z',
        amountCents: 6327
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-01T18:05:41.158Z',
          createdAt: '2024-01-31T05:09:34.962Z',
          content: [
            {
              id: 'd372bdae-97d9-41b5-91c5-5984e6d9bff6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a462afe6-6cf0-49af-a6c3-30ccd5756933/ugc/94565d86-dcd3-45bc-87a1-d6a1547552ba/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd11354ba-0354-40c8-9a36-87fa9236a20a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a462afe6-6cf0-49af-a6c3-30ccd5756933/ugc/94565d86-dcd3-45bc-87a1-d6a1547552ba/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0970eccf-7597-4f58-958f-0a59b50d4a10',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a462afe6-6cf0-49af-a6c3-30ccd5756933/ugc/94565d86-dcd3-45bc-87a1-d6a1547552ba/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '94565d86-dcd3-45bc-87a1-d6a1547552ba'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-01T18:05:53.608Z',
          createdAt: '2024-01-31T05:09:01.616Z',
          content: [
            {
              id: '79811f65-0ef3-4f98-b546-e89c81cb5456',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a462afe6-6cf0-49af-a6c3-30ccd5756933/ugc/6f7369a2-69ad-4ddb-975a-5ce52475dbe4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6f7369a2-69ad-4ddb-975a-5ce52475dbe4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a462afe6-6cf0-49af-a6c3-30ccd5756933',
        firstName: 'Neesh',
        lastName: 'Riaz',
        email: 'neeshceo@gmail.com',
        instagramProfile: {
          handle: 'neesh__me',
          followers: 27423,
          engagementRate: 0.6206469022353498,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'neesh_me',
          followers: 35071,
          engagementRate: 12.95532105428758,
          isPrimary: true
        }
      },
      userCampaignId: 'a462afe6-6cf0-49af-a6c3-30ccd5756933'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd775735a-6f71-45c4-ad37-4d91e94261d8',
        firstName: 'Ty',
        lastName: 'Bell',
        email: 'edm.meister@gmail.com',
        instagramProfile: {
          handle: 'tyhabe3',
          followers: 3139,
          engagementRate: 14.221089518955079,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'tyhabe',
          followers: 260,
          isPrimary: false
        }
      },
      userCampaignId: 'd775735a-6f71-45c4-ad37-4d91e94261d8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T15:26:06.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-03T14:25:39.937Z',
          createdAt: '2024-01-30T15:06:27.479Z',
          content: [
            {
              id: '73a9966e-402f-4146-b636-ff94ba0d1249',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c92f0e79-202a-4d7f-bcd2-d9ec238876dc/ugc/330c7487-0b11-4496-a1db-9a1711cbb9de/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1bcab49f-c75d-49ce-8aa8-de3b81819f02',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c92f0e79-202a-4d7f-bcd2-d9ec238876dc/ugc/330c7487-0b11-4496-a1db-9a1711cbb9de/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd1fc60ed-5c33-4d5c-bc67-abf68dd8c0e1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c92f0e79-202a-4d7f-bcd2-d9ec238876dc/ugc/330c7487-0b11-4496-a1db-9a1711cbb9de/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '330c7487-0b11-4496-a1db-9a1711cbb9de'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-03T14:25:34.259Z',
          createdAt: '2024-01-30T15:07:45.800Z',
          content: [
            {
              id: '3ac437ce-cefc-42f5-a8d9-008a93bb5651',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c92f0e79-202a-4d7f-bcd2-d9ec238876dc/ugc/6f6b63df-3eee-4762-bf25-e9f6aa556ed3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6f6b63df-3eee-4762-bf25-e9f6aa556ed3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c92f0e79-202a-4d7f-bcd2-d9ec238876dc',
        firstName: 'Jaidah',
        lastName: 'Liverman',
        email: 'info@jaidahofficial.com',
        instagramProfile: {
          handle: 'jaidahofficial',
          followers: 2520,
          engagementRate: 1.0714285714285714,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jaidahofficial',
          followers: 417,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'jaidahofficial'
        }
      },
      userCampaignId: 'c92f0e79-202a-4d7f-bcd2-d9ec238876dc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T01:52:11.000Z',
        amountCents: 6614
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c651931e-bf43-48cd-afd8-9d07f85a7f9b',
        firstName: 'Davy',
        lastName: 'Sanchez',
        email: 'lamomdeals@gmail.com',
        instagramProfile: {
          handle: 'lamomdeals',
          followers: 5889,
          engagementRate: 3.143148242485991,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'lamomdeals',
          followers: 13231,
          engagementRate: 8.965693804403482,
          isPrimary: true
        }
      },
      userCampaignId: 'c651931e-bf43-48cd-afd8-9d07f85a7f9b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T07:31:45.000Z',
        amountCents: 7088
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-01T16:50:53.791Z',
          createdAt: '2024-01-23T04:35:20.882Z',
          content: [
            {
              id: '5816d98f-151b-45a5-963c-5c64c0329aeb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca39fa84-2b48-470d-bf15-9cc35e675430/ugc/8773ee96-5469-49f6-9c4f-9d89ef44ba04/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b378e828-f59b-4a9a-a3e0-43f398e55726',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca39fa84-2b48-470d-bf15-9cc35e675430/ugc/8773ee96-5469-49f6-9c4f-9d89ef44ba04/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'dd30d1fd-a643-44f8-bfa1-b3ed17ea2683',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca39fa84-2b48-470d-bf15-9cc35e675430/ugc/8773ee96-5469-49f6-9c4f-9d89ef44ba04/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8773ee96-5469-49f6-9c4f-9d89ef44ba04'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-01T16:50:38.773Z',
          createdAt: '2024-01-23T04:33:42.273Z',
          content: [
            {
              id: '37d8916b-9d13-47c5-98e7-7ace48dbd3d6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca39fa84-2b48-470d-bf15-9cc35e675430/ugc/1b213014-62ac-40ca-bfc5-5f4152f29759/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1b213014-62ac-40ca-bfc5-5f4152f29759'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ca39fa84-2b48-470d-bf15-9cc35e675430',
        firstName: 'Shanique',
        lastName: 'Barton',
        email: 'shaniqueb845@gmail.com',
        instagramProfile: {
          handle: 'destinydesignz',
          followers: 225,
          engagementRate: 1.5555555555555556,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'goddessblackgold',
          followers: 127,
          engagementRate: 3.5813038699600854,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'island-gyalcuisine7722',
          followers: 0
        }
      },
      userCampaignId: 'ca39fa84-2b48-470d-bf15-9cc35e675430'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-17T08:02:09.000Z',
        amountCents: 5386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T21:20:15.731Z',
          createdAt: '2024-01-24T21:34:17.488Z',
          content: [
            {
              id: '32ee773f-58c5-4b9a-b9e5-3c41c7030516',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2b5e7665-b441-4e03-8750-e410129fba52/ugc/b26be8aa-183a-48c0-90df-ce339fadbac0/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b26be8aa-183a-48c0-90df-ce339fadbac0'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T21:20:21.403Z',
          createdAt: '2024-01-24T21:58:03.866Z',
          content: [
            {
              id: '5e6f36b5-d2fa-4cc0-97d2-46e8b1002c51',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2b5e7665-b441-4e03-8750-e410129fba52/ugc/b7b30486-a9ee-4c47-8827-8ad451bd7fb0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'aedc7015-5dd3-4ce0-8b5c-5994a05b7ce0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2b5e7665-b441-4e03-8750-e410129fba52/ugc/b7b30486-a9ee-4c47-8827-8ad451bd7fb0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c468f8f0-0ae9-4415-a8bc-5b5ffdab02fc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2b5e7665-b441-4e03-8750-e410129fba52/ugc/b7b30486-a9ee-4c47-8827-8ad451bd7fb0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b7b30486-a9ee-4c47-8827-8ad451bd7fb0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2b5e7665-b441-4e03-8750-e410129fba52',
        firstName: 'Elliott',
        lastName: 'Tucker',
        email: 'elliott.james.tucker@gmail.com',
        instagramProfile: {
          handle: 'elliott_james.mp4',
          followers: 23244,
          engagementRate: 0.7365341593529512,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'elliott_james.mp4',
          followers: 1607,
          engagementRate: 8.949416342412452,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'elliott_james.mp4',
          followers: 100
        }
      },
      userCampaignId: '2b5e7665-b441-4e03-8750-e410129fba52'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T21:03:05.000Z',
        amountCents: 5884
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-20T19:52:41.158Z',
          createdAt: '2024-02-18T10:11:45.571Z',
          content: [
            {
              id: '432837a0-b088-4b30-9550-079de2c866bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ac6f3808-7670-4854-b696-3d59c32bb6ed/ugc/e3a6b60c-2059-42e1-97e6-b82a5a68cbbe/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '000d99a1-b53f-45c4-ba58-ade19d7265d9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ac6f3808-7670-4854-b696-3d59c32bb6ed/ugc/e3a6b60c-2059-42e1-97e6-b82a5a68cbbe/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b141d8a0-560b-43b1-a72a-ea7f7fb6fc05',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ac6f3808-7670-4854-b696-3d59c32bb6ed/ugc/e3a6b60c-2059-42e1-97e6-b82a5a68cbbe/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e3a6b60c-2059-42e1-97e6-b82a5a68cbbe'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T19:52:35.091Z',
          createdAt: '2024-02-18T11:26:44.718Z',
          content: [
            {
              id: '92b4ec19-5fdf-4b00-bc97-a0ce0927ccee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ac6f3808-7670-4854-b696-3d59c32bb6ed/ugc/ac5e4b29-b02f-4dba-83f0-b1455a77bdbe/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ac5e4b29-b02f-4dba-83f0-b1455a77bdbe'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ac6f3808-7670-4854-b696-3d59c32bb6ed',
        firstName: 'Marionette',
        lastName: 'Solorio',
        email: 'ladyshulammite@gmail.com',
        instagramProfile: {
          handle: 'thisismarionette',
          followers: 10627,
          engagementRate: 0.16373388538628023,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ladyshulammite016',
          followers: 673,
          engagementRate: 9.772727272727273,
          isPrimary: true
        }
      },
      userCampaignId: 'ac6f3808-7670-4854-b696-3d59c32bb6ed'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T17:12:38.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '65ca8914-3258-4a49-8aac-2e8c949ee690',
        firstName: 'Arielle',
        lastName: 'Vela',
        email: 'arielle.virtual@gmail.com',
        instagramProfile: {
          handle: 'ariellevelava',
          followers: 5205,
          engagementRate: 0.3823246878001921,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'miamimama305',
          followers: 207,
          engagementRate: 5.251396648044693,
          isPrimary: false
        }
      },
      userCampaignId: '65ca8914-3258-4a49-8aac-2e8c949ee690'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '41ef4512-c243-429e-9f5a-057c9a391377',
        firstName: 'DaiDai',
        lastName: 'Echols',
        email: 'daijonmcghee@gmail.com',
        instagramProfile: {
          handle: 'daijonnn',
          followers: 7580,
          engagementRate: 2.1284080914687777,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'heydai',
          followers: 33240,
          engagementRate: 9.040622299049266,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'daidai2'
        }
      },
      userCampaignId: '41ef4512-c243-429e-9f5a-057c9a391377'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T12:21:13.000Z',
        amountCents: 6569
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '10ee900e-41d2-444d-8c7f-7f2bc82b86a8',
        firstName: 'Bobby',
        lastName: 'Bonabi',
        email: 'babak4242@aol.com',
        instagramProfile: {
          handle: 'bobby.bonabi',
          followers: 2803,
          engagementRate: 13.053870852657864,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'bobbybonabi',
          followers: 102,
          isPrimary: true
        }
      },
      userCampaignId: '10ee900e-41d2-444d-8c7f-7f2bc82b86a8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T14:23:35.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-01T02:00:36.013Z',
          createdAt: '2024-01-29T19:06:12.327Z',
          content: [
            {
              id: 'd072c858-a69f-447e-aff9-a08ae4fbafba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/55ac306c-57d6-4749-8e72-208debe4168c/ugc/2c62a9b7-a096-4e67-bb84-47e757f72051/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5ae8692c-029a-4562-bae9-3d19396b9f42',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/55ac306c-57d6-4749-8e72-208debe4168c/ugc/2c62a9b7-a096-4e67-bb84-47e757f72051/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '03f73e6c-f0bb-429c-945e-3d4d7771f512',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/55ac306c-57d6-4749-8e72-208debe4168c/ugc/2c62a9b7-a096-4e67-bb84-47e757f72051/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2c62a9b7-a096-4e67-bb84-47e757f72051'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-01T02:00:31.277Z',
          createdAt: '2024-01-27T21:29:44.163Z',
          content: [
            {
              id: '6fa3c5ce-0e43-4623-8c49-a7ac1016c345',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/55ac306c-57d6-4749-8e72-208debe4168c/ugc/9a60f34f-bcc0-4cb5-8082-6a799f809671/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9a60f34f-bcc0-4cb5-8082-6a799f809671'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '55ac306c-57d6-4749-8e72-208debe4168c',
        firstName: 'Tez',
        lastName: 'B',
        email: 'tbyrnesmed@gmail.com',
        instagramProfile: {
          handle: 'thepnppathway',
          followers: 5041,
          engagementRate: 4.181709978178933,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thepnppathway',
          followers: 32072,
          engagementRate: 7.622608993465092,
          isPrimary: true
        }
      },
      userCampaignId: '55ac306c-57d6-4749-8e72-208debe4168c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T17:04:30.000Z',
        amountCents: 5443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-12T14:37:07.355Z',
          createdAt: '2024-02-11T02:52:29.670Z',
          content: [
            {
              id: '6a15f9e0-8590-4033-b44c-0e8885968bef',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/138edd48-df27-49c4-8ef0-e3d0e6b29d72/ugc/d974a3a7-6e61-4848-9821-49396816ca24/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd974a3a7-6e61-4848-9821-49396816ca24'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T14:37:11.892Z',
          createdAt: '2024-02-11T02:17:55.654Z',
          content: [
            {
              id: '60ef786a-1b7a-47f4-950b-ca6c871704b3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/138edd48-df27-49c4-8ef0-e3d0e6b29d72/ugc/9b474108-29b2-4eed-a98f-591e9d8c5edf/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c5762fb5-f05e-4646-a228-0d45e7f603bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/138edd48-df27-49c4-8ef0-e3d0e6b29d72/ugc/9b474108-29b2-4eed-a98f-591e9d8c5edf/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ea8a83f3-bed4-44fb-9fc4-6ee1de71e008',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/138edd48-df27-49c4-8ef0-e3d0e6b29d72/ugc/9b474108-29b2-4eed-a98f-591e9d8c5edf/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9b474108-29b2-4eed-a98f-591e9d8c5edf'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '138edd48-df27-49c4-8ef0-e3d0e6b29d72',
        firstName: 'Dianna',
        lastName: 'SilasQiu',
        email: 'diannaqiu@gmail.com',
        instagramProfile: {
          handle: 'silasqiu',
          followers: 14561,
          engagementRate: 0.7581896847743974,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'silasqiu',
          followers: 369,
          engagementRate: 1.5822784810126582,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'silasqiu'
        }
      },
      userCampaignId: '138edd48-df27-49c4-8ef0-e3d0e6b29d72'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-22T20:26:41.000Z',
        amountCents: 5411
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '43087c86-cdb8-42cc-995f-e64319c86c76',
        firstName: 'Stephanie',
        lastName: 'Dobson',
        email: 'mrs.stephanie.dobson@gmail.com',
        instagramProfile: {
          handle: 'stephdobsoninsta',
          followers: 11870,
          engagementRate: 0.6503791069924179,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'stephaniedobson03',
          followers: 12,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'stephaniedobson8824',
          followers: 0
        }
      },
      userCampaignId: '43087c86-cdb8-42cc-995f-e64319c86c76'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T10:44:34.000Z',
        amountCents: 7184
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-14T15:43:37.867Z',
          createdAt: '2024-02-13T19:40:50.032Z',
          content: [
            {
              id: '05735d7d-61de-4455-a52c-d1c98bfe47c4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1401e67-cd79-4e02-a92e-e07f851954e6/ugc/514ccae7-0341-4fba-b525-4be7628f8964/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e9fe373d-f5a2-44d3-8775-c4c779da0e54',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1401e67-cd79-4e02-a92e-e07f851954e6/ugc/514ccae7-0341-4fba-b525-4be7628f8964/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7155a806-0a47-495c-98ec-d0dd5ff8c1ba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1401e67-cd79-4e02-a92e-e07f851954e6/ugc/514ccae7-0341-4fba-b525-4be7628f8964/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '514ccae7-0341-4fba-b525-4be7628f8964'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-14T20:05:39.431Z',
          createdAt: '2024-02-13T20:56:54.985Z',
          content: [
            {
              id: '9142ba22-039a-4a54-ba71-fa3bcf699dd8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e1401e67-cd79-4e02-a92e-e07f851954e6/ugc/453c5df8-584d-4f9b-a1ac-054aef5ea8ee/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '453c5df8-584d-4f9b-a1ac-054aef5ea8ee'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e1401e67-cd79-4e02-a92e-e07f851954e6',
        firstName: 'Olivia',
        lastName: 'Tuggle',
        email: 'otugglecollabs@gmail.com',
        instagramProfile: {
          handle: 'oliviatuggle',
          followers: 941,
          engagementRate: 0.5526036131774709,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'oliviatuggle',
          followers: 2124,
          engagementRate: 8.761626197440302,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'oliviatuggle',
          followers: 53
        }
      },
      userCampaignId: 'e1401e67-cd79-4e02-a92e-e07f851954e6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T10:03:40.000Z',
        amountCents: 6554
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5352613c-7c09-434a-b946-c4297a435cde',
        firstName: 'Grace',
        lastName: 'Pelle ',
        email: 'grace.pelle.official@gmail.com',
        tiktokProfile: {
          handle: 'gracepellee',
          followers: 8611,
          engagementRate: 10.204920013532453,
          isPrimary: false
        }
      },
      userCampaignId: '5352613c-7c09-434a-b946-c4297a435cde'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T18:16:11.000Z',
        amountCents: 6494
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ff2b22ac-2eca-43fe-ba44-76d6b333ec21',
        firstName: 'Nhu',
        lastName: 'Bui',
        email: 'nhubui1115@gmail.com',
        instagramProfile: {
          handle: 'thatnewnhu',
          followers: 18103,
          engagementRate: 0.312102966359167,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thatnewnhu',
          followers: 26837,
          engagementRate: 11.805455397706787,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'thatnewnhu'
        }
      },
      userCampaignId: 'ff2b22ac-2eca-43fe-ba44-76d6b333ec21'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T17:03:43.000Z',
        amountCents: 6494
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '9ee08515-1cf9-4c46-a4f8-5f9ec02af6cb',
        firstName: 'cristina ',
        lastName: 'sisson',
        email: 'cristina_moralesw@yahoo.com',
        instagramProfile: {
          handle: 'cristina.sisson',
          followers: 13308,
          engagementRate: 1.178238653441539,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'cristinasiss',
          followers: 10400,
          engagementRate: 12.725566413077857,
          isPrimary: true
        }
      },
      userCampaignId: '9ee08515-1cf9-4c46-a4f8-5f9ec02af6cb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T20:43:57.000Z',
        amountCents: 5486
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '8774a6de-24ff-4611-855a-c70fd1a62e9b',
        firstName: 'Esther',
        lastName: 'Ferreira',
        email: 'estherrubimmoura@hotmail.com',
        instagramProfile: {
          handle: 'mrs.estherferreira',
          followers: 30746,
          engagementRate: 0.1772588304169648,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'mrs.estherferreira',
          followers: 11733,
          engagementRate: 4.891304347826087,
          isPrimary: false
        }
      },
      userCampaignId: '8774a6de-24ff-4611-855a-c70fd1a62e9b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T15:33:46.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '70308c3f-21a7-4e64-9ccd-7243117521db',
        firstName: 'Katherine',
        lastName: 'Nunez',
        email: 'misspetite@misspetitecosplay.com',
        instagramProfile: {
          handle: 'misspetitecosplay',
          followers: 6357,
          engagementRate: 6.254522573540979,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'misspetitecosplay',
          followers: 857,
          engagementRate: 10.850439882697946,
          isPrimary: false
        }
      },
      userCampaignId: '70308c3f-21a7-4e64-9ccd-7243117521db'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-18T21:58:35.075Z',
          createdAt: '2024-02-10T18:23:53.727Z',
          content: [
            {
              id: 'baf9ba8f-a50e-4813-9c15-dc492ce84b8b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f3eaafa3-0a59-4f70-9433-7dc420bae13d/ugc/58393fe0-2bfd-4036-b293-53d6c63b9ffc/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1ddf0f91-4ae3-4c05-9c38-982f54981dd5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f3eaafa3-0a59-4f70-9433-7dc420bae13d/ugc/58393fe0-2bfd-4036-b293-53d6c63b9ffc/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3ef4d28d-2b49-465e-a36f-1788b5188ac0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f3eaafa3-0a59-4f70-9433-7dc420bae13d/ugc/58393fe0-2bfd-4036-b293-53d6c63b9ffc/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '58393fe0-2bfd-4036-b293-53d6c63b9ffc'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-18T21:58:30.911Z',
          createdAt: '2024-02-10T18:57:05.147Z',
          content: [
            {
              id: 'bed996d2-7fca-42ad-a37e-6245bde108b9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f3eaafa3-0a59-4f70-9433-7dc420bae13d/ugc/98e36f85-4e70-46bf-b170-f85d0b8dce6e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '98e36f85-4e70-46bf-b170-f85d0b8dce6e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f3eaafa3-0a59-4f70-9433-7dc420bae13d',
        firstName: 'Malaya',
        lastName: 'Youngs',
        email: 'malaya.tiffany@gmail.com',
        instagramProfile: {
          handle: 'malayatiffany',
          followers: 11007,
          engagementRate: 0.7458889797401653,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'malayatiffany',
          followers: 455,
          engagementRate: 5.759439555528971,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'malaya47'
        }
      },
      userCampaignId: 'f3eaafa3-0a59-4f70-9433-7dc420bae13d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-21T03:33:16.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-24T20:03:22.666Z',
          createdAt: '2024-01-24T05:52:27.753Z',
          content: [
            {
              id: '421b9291-29a7-4978-bc84-65988d8a1a3a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dbdd31ef-1f41-400d-b8e2-ba60f34b3e50/ugc/956246da-fac3-4500-9926-d3133b3919de/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4c9f1f7f-26f1-4634-945d-893ed629b357',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dbdd31ef-1f41-400d-b8e2-ba60f34b3e50/ugc/956246da-fac3-4500-9926-d3133b3919de/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '077eedbd-05c8-4277-9ac0-cac416aa1bb9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dbdd31ef-1f41-400d-b8e2-ba60f34b3e50/ugc/956246da-fac3-4500-9926-d3133b3919de/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '956246da-fac3-4500-9926-d3133b3919de'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-24T20:03:15.303Z',
          createdAt: '2024-01-24T05:49:50.369Z',
          content: [
            {
              id: '12ead822-3bf8-4b6a-9b70-1e3286d60f61',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dbdd31ef-1f41-400d-b8e2-ba60f34b3e50/ugc/a468d4da-01ff-4ba4-a1c0-12260919a81b/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a468d4da-01ff-4ba4-a1c0-12260919a81b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'dbdd31ef-1f41-400d-b8e2-ba60f34b3e50',
        firstName: 'William',
        lastName: 'Chapman',
        email: 'zanivie22@gmail.com',
        instagramProfile: {
          handle: 'will.a.chapman',
          followers: 10573,
          engagementRate: 0.8994608909486427,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'zani_vie',
          followers: 1248,
          engagementRate: 4.702194357366771,
          isPrimary: false
        }
      },
      userCampaignId: 'dbdd31ef-1f41-400d-b8e2-ba60f34b3e50'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T22:52:15.000Z',
        amountCents: 5474
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-05T21:10:15.094Z',
          createdAt: '2024-01-30T17:08:21.608Z',
          content: [
            {
              id: 'b5b98ecf-2843-4578-8c10-a8a66e9caa82',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d8a480a8-9833-458c-914c-8ca0b774df14/ugc/41eec5fb-183a-4889-9718-5234565949fe/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c2571942-a7c1-4250-80d5-7e502faddefd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d8a480a8-9833-458c-914c-8ca0b774df14/ugc/41eec5fb-183a-4889-9718-5234565949fe/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a424bd9b-e760-4c29-8e2d-180e0725406b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d8a480a8-9833-458c-914c-8ca0b774df14/ugc/41eec5fb-183a-4889-9718-5234565949fe/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '41eec5fb-183a-4889-9718-5234565949fe'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-05T21:10:08.025Z',
          createdAt: '2024-01-30T17:43:44.281Z',
          content: [
            {
              id: '0023cec7-291a-40c5-8354-124d2a3bf975',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d8a480a8-9833-458c-914c-8ca0b774df14/ugc/3c78c7ea-5f80-438f-8082-46ab707576bf/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3c78c7ea-5f80-438f-8082-46ab707576bf'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd8a480a8-9833-458c-914c-8ca0b774df14',
        firstName: 'Roberto',
        lastName: 'Valderrama',
        email: 'rideboundless@gmail.com',
        instagramProfile: {
          handle: 'rideboundless',
          followers: 10390,
          engagementRate: 0.23965351299326276,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'rideboundless',
          followers: 378,
          engagementRate: 2.092511013215859,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'rideboundless'
        }
      },
      userCampaignId: 'd8a480a8-9833-458c-914c-8ca0b774df14'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T15:58:16.000Z',
        amountCents: 6845
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '955fc62f-6b09-4b26-99b8-44791e605e8d',
        firstName: 'Jackie',
        lastName: 'Bobbitt',
        email: 'mamafitnation@gmail.com',
        instagramProfile: {
          handle: 'coachjaqe',
          followers: 5532,
          engagementRate: 1.294287780187997,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'coachjaqe',
          followers: 225,
          engagementRate: 9.108945373161598,
          isPrimary: false
        }
      },
      userCampaignId: '955fc62f-6b09-4b26-99b8-44791e605e8d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T16:36:51.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T10:30:59.163Z',
          createdAt: '2024-02-09T22:15:57.093Z',
          content: [
            {
              id: '9239ccc8-564c-410c-b02c-56f795cb1b86',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01641fc5-ae39-46a6-ad47-403a1e63e13a/ugc/7b242b2a-ec7b-49ee-8cc5-5809c4b43f25/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e7ed00d0-a83c-43c6-a764-5a90e72cf9e0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01641fc5-ae39-46a6-ad47-403a1e63e13a/ugc/7b242b2a-ec7b-49ee-8cc5-5809c4b43f25/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6d23c5fc-adca-4e04-adfb-0362d7e2965a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01641fc5-ae39-46a6-ad47-403a1e63e13a/ugc/7b242b2a-ec7b-49ee-8cc5-5809c4b43f25/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7b242b2a-ec7b-49ee-8cc5-5809c4b43f25'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-12T12:09:28.301Z',
          createdAt: '2024-02-09T22:14:19.021Z',
          content: [
            {
              id: 'd055f647-2f6b-41ee-9fac-568b51b5f3f5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01641fc5-ae39-46a6-ad47-403a1e63e13a/ugc/b106e06b-0c84-49b8-9f13-56c94cc7e852/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b106e06b-0c84-49b8-9f13-56c94cc7e852'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '01641fc5-ae39-46a6-ad47-403a1e63e13a',
        firstName: 'Gabrielle',
        lastName: 'Angiolillo',
        email: 'gbbellis@gmail.com',
        instagramProfile: {
          handle: 'gabbymackincrafts',
          followers: 26,
          engagementRate: 3.8461538461538463,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'gabriellemackenzie_9',
          followers: 2798,
          engagementRate: 5.166695758422063,
          isPrimary: false
        }
      },
      userCampaignId: '01641fc5-ae39-46a6-ad47-403a1e63e13a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T05:42:18.000Z',
        amountCents: 6507
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T12:45:40.224Z',
          createdAt: '2024-02-16T02:04:00.018Z',
          content: [
            {
              id: 'f10ae811-3cf6-43c1-98ab-9b87d4055d74',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dddc5b-363e-4676-b615-34dc2786bcea/ugc/6a8a3bd4-7458-4fbd-bc47-efd26b83e147/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f23307d7-6a7b-478c-a7d3-a09aca500f97',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dddc5b-363e-4676-b615-34dc2786bcea/ugc/6a8a3bd4-7458-4fbd-bc47-efd26b83e147/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'fd5837b0-b76c-4ed1-8164-bbf38ff336ba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dddc5b-363e-4676-b615-34dc2786bcea/ugc/6a8a3bd4-7458-4fbd-bc47-efd26b83e147/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6a8a3bd4-7458-4fbd-bc47-efd26b83e147'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-19T18:32:11.095Z',
          createdAt: '2024-02-16T12:45:22.719Z',
          content: [
            {
              id: '9a48f99e-7c24-49b8-b089-b0a172e6619c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dddc5b-363e-4676-b615-34dc2786bcea/ugc/5ea140f2-17fe-447b-b638-7816602e55c7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5ea140f2-17fe-447b-b638-7816602e55c7'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '99dddc5b-363e-4676-b615-34dc2786bcea',
        firstName: 'ayantu',
        lastName: 'ibrahim',
        email: 'yant.00@icloud.com',
        tiktokProfile: {
          handle: '.yant',
          followers: 2020,
          engagementRate: 19.99740288890851,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'y.a.n.t',
          followers: 18
        }
      },
      userCampaignId: '99dddc5b-363e-4676-b615-34dc2786bcea'
    },
    {
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fd0973e9-82f8-4677-94eb-93d8efef5d45',
        firstName: 'Shyenne',
        lastName: 'Lewis',
        email: 'shyennnelewis@gmail.com',
        instagramProfile: {
          handle: 'shyennne_lewis',
          followers: 4027,
          engagementRate: 3.4541842562701763,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'shyennne_',
          followers: 13362,
          engagementRate: 10.818999503548172,
          isPrimary: true
        }
      },
      userCampaignId: 'fd0973e9-82f8-4677-94eb-93d8efef5d45'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-17T17:43:49.000Z',
        amountCents: 6599
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-25T15:42:41.261Z',
          createdAt: '2024-01-19T02:02:48.986Z',
          content: [
            {
              id: '06d2c3cf-4b65-4028-b05c-6288bd544aa9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cac023c2-601f-4e69-8140-f57ca7cf728f/ugc/c21e107f-d0bb-4c4c-8a85-6cbe79b7240d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c21e107f-d0bb-4c4c-8a85-6cbe79b7240d'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-25T15:42:45.900Z',
          createdAt: '2024-01-19T01:59:42.696Z',
          content: [
            {
              id: '46829603-f1fc-4189-b2df-081b42d9cd7f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cac023c2-601f-4e69-8140-f57ca7cf728f/ugc/c8fb1661-dd41-43fa-81a6-e2c62e6141e9/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ee7370cd-b234-4b67-866f-751aa1136244',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cac023c2-601f-4e69-8140-f57ca7cf728f/ugc/c8fb1661-dd41-43fa-81a6-e2c62e6141e9/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '19ddd896-e2c6-4c4d-b833-d2a8bc48d32e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cac023c2-601f-4e69-8140-f57ca7cf728f/ugc/c8fb1661-dd41-43fa-81a6-e2c62e6141e9/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c8fb1661-dd41-43fa-81a6-e2c62e6141e9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cac023c2-601f-4e69-8140-f57ca7cf728f',
        firstName: 'Tola',
        lastName: 'Anif',
        email: 'tolaanif@gmail.com',
        instagramProfile: {
          handle: 'tolaanif',
          followers: 11144,
          engagementRate: 0.01346015793251974,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'tolaanif',
          followers: 2297,
          engagementRate: 1.1816838995568686,
          isPrimary: false
        }
      },
      userCampaignId: 'cac023c2-601f-4e69-8140-f57ca7cf728f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T03:56:02.000Z',
        amountCents: 6569
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'e1c6fc08-8037-4948-a267-4d2e59b084c3',
        firstName: 'Stephanie',
        lastName: 'Thompson',
        email: 'sathompson1982@gmail.com',
        instagramProfile: {
          handle: 'the_ornery_one',
          followers: 19593,
          engagementRate: 0.5838819986729955,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'theorneryone',
          followers: 21692,
          engagementRate: 6.381039197812215,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'theorneryone',
          followers: 59700
        }
      },
      userCampaignId: 'e1c6fc08-8037-4948-a267-4d2e59b084c3'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T02:18:34.000Z',
        amountCents: 6501
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3e93527e-9018-462c-ae35-f95ab59ba16d',
        firstName: 'Frewena',
        lastName: 'Deloria',
        email: 'frenniedeloria@gmail.com',
        instagramProfile: {
          handle: 'frewena',
          followers: 100780,
          engagementRate: 0.3143480849374876,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'frewena',
          followers: 222769,
          engagementRate: 8.264934665804729,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'frewena'
        }
      },
      userCampaignId: '3e93527e-9018-462c-ae35-f95ab59ba16d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T17:03:46.000Z',
        amountCents: 6371
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T13:09:08.595Z',
          createdAt: '2024-01-26T04:50:03.878Z',
          content: [
            {
              id: 'caa888b2-6b79-4cf2-971f-d52326941a87',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/60b63ac6-10be-4c6c-8e99-6c4909486c1f/ugc/de8f30c2-21e2-4e8b-9dbe-98deda819a5f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2e9747a9-458a-44d2-bc15-5917fdd2355c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/60b63ac6-10be-4c6c-8e99-6c4909486c1f/ugc/de8f30c2-21e2-4e8b-9dbe-98deda819a5f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2618302a-d5da-4ee3-adb3-7c69007c2a26',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/60b63ac6-10be-4c6c-8e99-6c4909486c1f/ugc/de8f30c2-21e2-4e8b-9dbe-98deda819a5f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'de8f30c2-21e2-4e8b-9dbe-98deda819a5f'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-26T17:58:43.106Z',
          createdAt: '2024-01-26T04:49:21.302Z',
          content: [
            {
              id: '7e0524ba-c6ef-4579-8df0-6f3c87211f9d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/60b63ac6-10be-4c6c-8e99-6c4909486c1f/ugc/789f6cf8-51b8-4ef8-ba2b-b1df1b2e5490/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '789f6cf8-51b8-4ef8-ba2b-b1df1b2e5490'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '60b63ac6-10be-4c6c-8e99-6c4909486c1f',
        firstName: 'Kasey',
        lastName: 'Smith',
        email: 'lazydazey95@aol.com',
        instagramProfile: {
          handle: 'nascarkasey_22',
          followers: 58,
          engagementRate: 2.2413793103448274,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kaseyallison',
          followers: 1792,
          engagementRate: 6.382978723404255,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'kaseyallison'
        }
      },
      userCampaignId: '60b63ac6-10be-4c6c-8e99-6c4909486c1f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-17T17:33:22.000Z',
        amountCents: 6327
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T17:43:40.547Z',
          createdAt: '2024-02-06T00:50:39.674Z',
          content: [
            {
              id: 'db62b20d-faa8-4231-8330-511f4b477313',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5667d0d0-350a-45ee-9619-06572b787861/ugc/05ae1f7c-4ddf-4154-acc9-25283596d6c5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5bebbee7-2adc-424a-b57e-59d91c27acc1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5667d0d0-350a-45ee-9619-06572b787861/ugc/05ae1f7c-4ddf-4154-acc9-25283596d6c5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9342feef-43ed-4021-bb07-1c3cff4ab1d2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5667d0d0-350a-45ee-9619-06572b787861/ugc/05ae1f7c-4ddf-4154-acc9-25283596d6c5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '05ae1f7c-4ddf-4154-acc9-25283596d6c5'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T13:43:10.081Z',
          createdAt: '2024-02-06T01:22:25.949Z',
          content: [
            {
              id: 'e4612266-42d8-4c7d-a868-89e22aa7ff92',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5667d0d0-350a-45ee-9619-06572b787861/ugc/597a28c8-5b0c-42cd-8930-9cfd2428b272/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '597a28c8-5b0c-42cd-8930-9cfd2428b272'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5667d0d0-350a-45ee-9619-06572b787861',
        firstName: 'Crystal',
        lastName: 'Watts',
        email: 'crystalcwatts14@gmail.com',
        instagramProfile: {
          handle: 'crystalcwatts',
          followers: 2045,
          engagementRate: 1.9413202933985332,
          isPrimary: true
        }
      },
      userCampaignId: '5667d0d0-350a-45ee-9619-06572b787861'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T17:35:31.000Z',
        amountCents: 3285
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0aa0de0b-3f69-45de-b2c5-5f56d2a07894',
        firstName: 'Kayla',
        lastName: 'Smallcanyon',
        email: 'info.kaylasmallcanyon@gmail.com',
        instagramProfile: {
          handle: 'kaylasmallcanyon',
          followers: 11254,
          engagementRate: 0.1954860494046561,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kaylasmallcanyon',
          followers: 20228,
          engagementRate: 5.629940492050803,
          isPrimary: true
        }
      },
      userCampaignId: '0aa0de0b-3f69-45de-b2c5-5f56d2a07894'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T07:26:37.000Z',
        amountCents: 5316
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6d95618a-3d1e-4c0c-a296-67cfda67f1ca',
        firstName: 'Chynna',
        lastName: 'Carter',
        email: 'chynna93xo@gmail.com',
        instagramProfile: {
          handle: 'pinkxspartan',
          followers: 3403,
          engagementRate: 0.5113135468704084,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'pinkxspartan',
          followers: 15934,
          engagementRate: 12.646698973801533,
          isPrimary: false
        }
      },
      userCampaignId: '6d95618a-3d1e-4c0c-a296-67cfda67f1ca'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '343f4815-851b-4dcf-a9c9-69b8ea5b376f',
        firstName: 'Omar',
        lastName: 'Cruz',
        email: 'omarcruzmidence@icloud.com',
        instagramProfile: {
          handle: 'omxrcruz',
          followers: 919,
          engagementRate: 0.0463258785942492,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'celloox',
          followers: 11040,
          engagementRate: 2.5049155832898595,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'cellox'
        }
      },
      userCampaignId: '343f4815-851b-4dcf-a9c9-69b8ea5b376f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T14:23:15.000Z',
        amountCents: 6174
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-20T20:12:59.363Z',
          createdAt: '2024-02-11T20:29:12.643Z',
          content: [
            {
              id: 'a8c7dd38-17f6-45f6-a8a6-8e63568f5b70',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6b62fbb7-04a8-4c6f-bed5-85d990848e7c/ugc/a153290e-5791-453c-b004-95597da174e2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a4dc27d6-8ba0-4217-b628-8f1e5e294b17',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6b62fbb7-04a8-4c6f-bed5-85d990848e7c/ugc/a153290e-5791-453c-b004-95597da174e2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8b505db2-4654-4aad-834a-0a1e2bf2d6e5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6b62fbb7-04a8-4c6f-bed5-85d990848e7c/ugc/a153290e-5791-453c-b004-95597da174e2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a153290e-5791-453c-b004-95597da174e2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T20:12:53.221Z',
          createdAt: '2024-02-11T20:28:15.420Z',
          content: [
            {
              id: 'a366c22d-545f-4063-a772-066cc1b15e7e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6b62fbb7-04a8-4c6f-bed5-85d990848e7c/ugc/f6415de0-761f-4a47-8615-d714926e6940/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f6415de0-761f-4a47-8615-d714926e6940'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6b62fbb7-04a8-4c6f-bed5-85d990848e7c',
        firstName: 'Stephanie',
        lastName: 'Brigley',
        email: 'stephanie.brigley@gmail.com',
        tiktokProfile: {
          handle: 'stephanieeeeeeeeeeeeeeeb',
          followers: 21131,
          engagementRate: 1.9643246187363836,
          isPrimary: true
        }
      },
      userCampaignId: '6b62fbb7-04a8-4c6f-bed5-85d990848e7c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-20T19:15:29.000Z',
        amountCents: 6327
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T18:34:21.501Z',
          createdAt: '2024-01-25T18:03:22.228Z',
          content: [
            {
              id: '62b14bc2-7fb8-4a48-b37a-62077cba6d12',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0bcfa6d1-8052-40a1-9ccd-4958b49dd1f6/ugc/65d618e5-0805-4194-ab22-00e20d9d0cd3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'eba43492-791b-4f54-97a3-6ee4fde2c7f1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0bcfa6d1-8052-40a1-9ccd-4958b49dd1f6/ugc/65d618e5-0805-4194-ab22-00e20d9d0cd3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5139de16-0b28-4e87-97ea-3b1bae0f071d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0bcfa6d1-8052-40a1-9ccd-4958b49dd1f6/ugc/65d618e5-0805-4194-ab22-00e20d9d0cd3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '65d618e5-0805-4194-ab22-00e20d9d0cd3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-26T18:34:13.883Z',
          createdAt: '2024-01-26T05:17:14.638Z',
          content: [
            {
              id: '85c7f89f-6201-476f-9aeb-04fb01719047',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0bcfa6d1-8052-40a1-9ccd-4958b49dd1f6/ugc/d4373cd5-1a5d-4570-9037-ba8fa63e5816/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd4373cd5-1a5d-4570-9037-ba8fa63e5816'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0bcfa6d1-8052-40a1-9ccd-4958b49dd1f6',
        firstName: 'Christine',
        lastName: 'Brandal',
        email: 'cbat3295@aol.com',
        instagramProfile: {
          handle: 'therossifamily20',
          followers: 239,
          engagementRate: 1.6736401673640167,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'therossifamily',
          followers: 1743,
          engagementRate: 5.442679733040466,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'therossifamily-tiktok',
          followers: 2
        }
      },
      userCampaignId: '0bcfa6d1-8052-40a1-9ccd-4958b49dd1f6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T02:40:09.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '84ef81b8-b9cc-438f-908c-f7c084e30c19',
        firstName: 'Benjamin',
        lastName: 'Miller',
        email: 'wrekonizereal@gmail.com',
        instagramProfile: {
          handle: 'wrekonize',
          followers: 108264,
          engagementRate: 0.5056159018695041,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'wrekonizemusic',
          followers: 3953,
          engagementRate: 10.450038138825324,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'wrekonizethemusic',
          followers: 44900
        }
      },
      userCampaignId: '84ef81b8-b9cc-438f-908c-f7c084e30c19'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-14T06:43:06.000Z',
        amountCents: 7152
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '589ac9c9-c30c-4c9b-a039-95102dca7efe',
        firstName: 'Daneisha',
        lastName: 'Combs',
        email: 'feliciakatssonbusiness@gmail.com',
        instagramProfile: {
          handle: 'officialfeliciakatsson',
          followers: 4272,
          engagementRate: 0.7560861423220974,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'feliciakatsson',
          followers: 1697,
          engagementRate: 6.325301204819277,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'feliciakatsson',
          followers: 812
        }
      },
      userCampaignId: '589ac9c9-c30c-4c9b-a039-95102dca7efe'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T14:11:56.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-15T20:24:52.928Z',
          createdAt: '2024-02-10T02:23:07.239Z',
          content: [
            {
              id: '38843628-cdfe-42cc-842d-db2cea309ccf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a173821a-d3f8-4692-a23e-6910b39be906/ugc/feb3a3a1-db59-44f1-b9f7-f3ca7028d90b/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'feb3a3a1-db59-44f1-b9f7-f3ca7028d90b'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-15T20:24:57.506Z',
          createdAt: '2024-02-10T02:21:01.319Z',
          content: [
            {
              id: '17281c43-298c-4465-9fb0-bf9c250b6daf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a173821a-d3f8-4692-a23e-6910b39be906/ugc/2a3ec3c4-da85-4646-8077-f54da03a7566/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f0d52ea5-ca41-4733-93e2-4989dc6f4c2f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a173821a-d3f8-4692-a23e-6910b39be906/ugc/2a3ec3c4-da85-4646-8077-f54da03a7566/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '02bc8757-54d0-47b7-b525-0809f4a9670f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a173821a-d3f8-4692-a23e-6910b39be906/ugc/2a3ec3c4-da85-4646-8077-f54da03a7566/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2a3ec3c4-da85-4646-8077-f54da03a7566'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a173821a-d3f8-4692-a23e-6910b39be906',
        firstName: 'Crystal',
        lastName: 'Townsend',
        email: 'ctownpaint@gmail.com',
        instagramProfile: {
          handle: 'crystalchasms',
          followers: 20207,
          engagementRate: 0.4879497203939228,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'crystalchasms',
          followers: 25444,
          engagementRate: 14.842175957018133,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'crystalchasms_'
        }
      },
      userCampaignId: 'a173821a-d3f8-4692-a23e-6910b39be906'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T13:05:22.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cfc5a3d9-81fc-4abe-b6a9-e1ba3b83fb6f',
        firstName: 'Xavier',
        lastName: 'Henry',
        email: 'xavierchenry@gmail.com',
        instagramProfile: {
          handle: 'sirzave',
          followers: 7132,
          engagementRate: 1.079641054402692,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sirzave24',
          followers: 768,
          engagementRate: 4.225352112676056,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'sirzave24'
        }
      },
      userCampaignId: 'cfc5a3d9-81fc-4abe-b6a9-e1ba3b83fb6f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T16:02:09.000Z',
        amountCents: 5386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T14:41:14.468Z',
          createdAt: '2024-02-05T20:14:56.512Z',
          content: [
            {
              id: 'b8d37d60-699b-4888-939c-885aaa787b1b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/db0b94fd-ac17-4081-a7bb-2bb60f13c6d6/ugc/448834d6-4047-4944-b1a0-ded9aaf0c99e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1d83b9ca-7fc0-49ff-92bd-fe0ec9e63e1c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/db0b94fd-ac17-4081-a7bb-2bb60f13c6d6/ugc/448834d6-4047-4944-b1a0-ded9aaf0c99e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ae1e83a7-c5fc-4885-85ed-8815829eabca',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/db0b94fd-ac17-4081-a7bb-2bb60f13c6d6/ugc/448834d6-4047-4944-b1a0-ded9aaf0c99e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '448834d6-4047-4944-b1a0-ded9aaf0c99e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T14:41:07.579Z',
          createdAt: '2024-02-05T20:39:14.845Z',
          content: [
            {
              id: '08e5a6f6-0b18-4121-8acb-d819637eaf43',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/db0b94fd-ac17-4081-a7bb-2bb60f13c6d6/ugc/3c9ed2a0-31a4-44c2-a64f-e72752d0b579/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3c9ed2a0-31a4-44c2-a64f-e72752d0b579'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'db0b94fd-ac17-4081-a7bb-2bb60f13c6d6',
        firstName: 'Maleeka',
        lastName: 'Harris',
        email: 'maleeka14@gmail.com',
        instagramProfile: {
          handle: '_chefrabbit',
          followers: 4838,
          engagementRate: 0.9053327821413807,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'chefrabbit',
          followers: 334,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'maleeka'
        }
      },
      userCampaignId: 'db0b94fd-ac17-4081-a7bb-2bb60f13c6d6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T09:44:34.000Z',
        amountCents: 6501
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T11:55:15.502Z',
          createdAt: '2024-02-15T19:46:10.610Z',
          content: [
            {
              id: '93a39bb6-25e6-4066-8218-01ec3496a67c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59c6d0c6-44f5-42f2-9c98-86dbd3bd6607/ugc/6848a349-97bf-4b71-9f9f-6cf06d2638e5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2fd28bed-d920-49e8-8c2a-4b4cdcd40a44',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59c6d0c6-44f5-42f2-9c98-86dbd3bd6607/ugc/6848a349-97bf-4b71-9f9f-6cf06d2638e5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '158446a8-6046-4b14-b2cf-c075e927a47c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59c6d0c6-44f5-42f2-9c98-86dbd3bd6607/ugc/6848a349-97bf-4b71-9f9f-6cf06d2638e5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6848a349-97bf-4b71-9f9f-6cf06d2638e5'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-16T11:55:22.266Z',
          createdAt: '2024-02-16T00:13:53.068Z',
          content: [
            {
              id: '68c9d1c2-e8e8-499c-b65d-8d12f32a4149',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59c6d0c6-44f5-42f2-9c98-86dbd3bd6607/ugc/227ec574-042b-4645-9b35-9ffc1153fbd4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '227ec574-042b-4645-9b35-9ffc1153fbd4'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '59c6d0c6-44f5-42f2-9c98-86dbd3bd6607',
        firstName: 'Donnah',
        lastName: 'Lu',
        email: 'heydonnah@gmail.com',
        instagramProfile: {
          handle: 'heydonnah',
          followers: 57258,
          engagementRate: 0.19630444654022147,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'heydonnah',
          followers: 1267,
          engagementRate: 1.2345679012345678,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'heydonnah'
        }
      },
      userCampaignId: '59c6d0c6-44f5-42f2-9c98-86dbd3bd6607'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'de5bfd05-4176-4318-9ede-a1159fcf1c88',
        firstName: 'Esther',
        lastName: 'Mbilizi',
        email: 'remapretty2001@gmail.com',
        instagramProfile: {
          handle: 'blackie_smith12',
          followers: 20418,
          engagementRate: 0.36340483886766584,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'blackie_smith',
          followers: 49769,
          engagementRate: 9.340110576065634,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'blackiesmith',
          followers: 1740
        }
      },
      userCampaignId: 'de5bfd05-4176-4318-9ede-a1159fcf1c88'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T00:44:34.000Z',
        amountCents: 6327
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-18T19:17:57.174Z',
          createdAt: '2024-02-17T01:33:47.876Z',
          content: [
            {
              id: 'dec4895e-b3dc-4ae9-b592-63d439efd00e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5db63445-f87b-486f-9a78-23fccb787d71/ugc/8f51a1a8-ddcb-4b1a-a16a-593bc25564c5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd6264628-a24b-4961-8c28-0b32be85fb42',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5db63445-f87b-486f-9a78-23fccb787d71/ugc/8f51a1a8-ddcb-4b1a-a16a-593bc25564c5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b35acca6-677e-433a-9bd0-6309401b890a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5db63445-f87b-486f-9a78-23fccb787d71/ugc/8f51a1a8-ddcb-4b1a-a16a-593bc25564c5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8f51a1a8-ddcb-4b1a-a16a-593bc25564c5'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-18T19:17:50.550Z',
          createdAt: '2024-02-17T01:34:42.494Z',
          content: [
            {
              id: '98a53bac-c745-4666-9a0f-61d0f6f42b17',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5db63445-f87b-486f-9a78-23fccb787d71/ugc/d34fd65c-319b-4e45-af20-8402038824e8/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd34fd65c-319b-4e45-af20-8402038824e8'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5db63445-f87b-486f-9a78-23fccb787d71',
        firstName: 'Dwayne',
        lastName: 'Ingram',
        email: 'gamer@iamdjepic.com',
        instagramProfile: {
          handle: 'iamdjepic',
          followers: 4335,
          engagementRate: 0.11534025374855825,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'iamdeejayepic',
          followers: 12363,
          engagementRate: 6.840918766206122,
          isPrimary: false
        }
      },
      userCampaignId: '5db63445-f87b-486f-9a78-23fccb787d71'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T15:47:16.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-27T14:04:39.411Z',
          createdAt: '2024-01-22T23:14:27.587Z',
          content: [
            {
              id: '1a0df01b-98ab-4d0e-9041-e2f192a8b5be',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2d8e43c2-f219-47ff-abce-9e53acdad670/ugc/cc1e1bc6-6f80-43a3-a78a-9599c34cd5ef/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'cc1e1bc6-6f80-43a3-a78a-9599c34cd5ef'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-27T14:04:46.093Z',
          createdAt: '2024-01-22T03:26:51.797Z',
          content: [
            {
              id: '03f200b4-dc54-485f-bed6-b5d8d0286199',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2d8e43c2-f219-47ff-abce-9e53acdad670/ugc/c0a6613d-7cd0-42cc-ba54-31495b1f6838/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4d47472f-19b3-4608-89d6-bfcc03831b16',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2d8e43c2-f219-47ff-abce-9e53acdad670/ugc/c0a6613d-7cd0-42cc-ba54-31495b1f6838/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f0d4a50b-96f5-458c-91a3-7776b45c85f8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2d8e43c2-f219-47ff-abce-9e53acdad670/ugc/c0a6613d-7cd0-42cc-ba54-31495b1f6838/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c0a6613d-7cd0-42cc-ba54-31495b1f6838'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2d8e43c2-f219-47ff-abce-9e53acdad670',
        firstName: 'Kayla',
        lastName: 'Whitter',
        email: 'kaylakedavra@gmail.com',
        instagramProfile: {
          handle: 'kaylakedavra',
          followers: 1378,
          engagementRate: 2.111756168359942,
          isPrimary: true
        }
      },
      userCampaignId: '2d8e43c2-f219-47ff-abce-9e53acdad670'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-20T00:06:41.000Z',
        amountCents: 6359
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T22:32:08.097Z',
          createdAt: '2024-01-22T16:27:04.959Z',
          content: [
            {
              id: 'cacccf20-1931-446b-939b-99fb31379094',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9be45058-5078-4cdf-b44b-ae07ab582572/ugc/c1dab28b-8938-4ac8-9052-33c289b3f4a7/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'afb6e00b-6d4a-49b4-9bde-ea0576ddb005',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9be45058-5078-4cdf-b44b-ae07ab582572/ugc/c1dab28b-8938-4ac8-9052-33c289b3f4a7/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '184b507c-4a27-4db5-8987-32b7bcde737f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9be45058-5078-4cdf-b44b-ae07ab582572/ugc/c1dab28b-8938-4ac8-9052-33c289b3f4a7/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c1dab28b-8938-4ac8-9052-33c289b3f4a7'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-26T22:32:02.054Z',
          createdAt: '2024-01-25T21:48:05.825Z',
          content: [
            {
              id: '1b2f6fe6-3173-40c5-ad07-b5323dbbc9fe',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9be45058-5078-4cdf-b44b-ae07ab582572/ugc/5b2e0be0-f951-4776-a71e-ee3281391031/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5b2e0be0-f951-4776-a71e-ee3281391031'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '9be45058-5078-4cdf-b44b-ae07ab582572',
        firstName: 'Megan',
        lastName: 'Syed',
        email: 'meganlsyed@gmail.com',
        instagramProfile: {
          handle: 'enlighteningells',
          followers: 50267,
          engagementRate: 0.19794298446296774,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'enlighteningells',
          followers: 22,
          isPrimary: false
        }
      },
      userCampaignId: '9be45058-5078-4cdf-b44b-ae07ab582572'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T14:28:59.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T22:10:51.957Z',
          createdAt: '2024-02-15T15:13:35.174Z',
          content: [
            {
              id: 'ef2d876d-cdb4-4b74-b7ed-56c32c6fad1a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47c9aa32-ac3d-4799-b58c-9492e8da78ef/ugc/b7dec93a-29cd-46aa-b848-b1db464083c5/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2e26294c-e36d-44e2-81f4-5538ec5d6003',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47c9aa32-ac3d-4799-b58c-9492e8da78ef/ugc/b7dec93a-29cd-46aa-b848-b1db464083c5/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '78af50d5-b557-452b-b3d9-fab787053aff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47c9aa32-ac3d-4799-b58c-9492e8da78ef/ugc/b7dec93a-29cd-46aa-b848-b1db464083c5/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b7dec93a-29cd-46aa-b848-b1db464083c5'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-16T22:10:47.936Z',
          createdAt: '2024-02-15T15:11:13.374Z',
          content: [
            {
              id: '4df368d1-8d0c-4690-a7b5-fa42d90902b4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/47c9aa32-ac3d-4799-b58c-9492e8da78ef/ugc/6b44ae1c-ff76-4ddc-8e39-826cd4f12bd2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6b44ae1c-ff76-4ddc-8e39-826cd4f12bd2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '47c9aa32-ac3d-4799-b58c-9492e8da78ef',
        firstName: 'Denay',
        lastName: 'Dominic',
        email: 'denaydominicenterprises@gmail.com',
        instagramProfile: {
          handle: 'mydivinebeautyobsession',
          followers: 486,
          engagementRate: 0.2263374485596708,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thesacredlotustemple',
          followers: 3222,
          engagementRate: 2.9183427192276747,
          isPrimary: true
        }
      },
      userCampaignId: '47c9aa32-ac3d-4799-b58c-9492e8da78ef'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0ffc59be-e421-4720-9218-9f1cb0a49c37',
        firstName: 'Keniesha',
        lastName: 'Brown',
        email: 'kenieshabrown37@gmail.com',
        tiktokProfile: {
          handle: 'keniesha33',
          followers: 3263,
          engagementRate: 9.01639344262295,
          isPrimary: false
        }
      },
      userCampaignId: '0ffc59be-e421-4720-9218-9f1cb0a49c37'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T19:36:50.000Z',
        amountCents: 6539
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-23T22:32:56.081Z',
          createdAt: '2024-02-19T16:02:59.922Z',
          content: [
            {
              id: 'e8e82769-6b6c-408a-9167-a2a5fa326c76',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9a44dbcc-844f-4ed8-bc6b-2108dcacdf08/ugc/1a64bf84-2f4c-4d3e-9da3-f17ede341b7c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '62c879ff-2e55-4c31-8802-61859acc8060',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9a44dbcc-844f-4ed8-bc6b-2108dcacdf08/ugc/1a64bf84-2f4c-4d3e-9da3-f17ede341b7c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '04cb12eb-eda9-41b6-a80e-e3faf8c76285',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/9a44dbcc-844f-4ed8-bc6b-2108dcacdf08/ugc/1a64bf84-2f4c-4d3e-9da3-f17ede341b7c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1a64bf84-2f4c-4d3e-9da3-f17ede341b7c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '9a44dbcc-844f-4ed8-bc6b-2108dcacdf08',
        firstName: 'Briana',
        lastName: 'Anderson ',
        email: 'briana.anderson15@gmail.com',
        instagramProfile: {
          handle: 'brialexandria_',
          followers: 2611,
          engagementRate: 1.1030256606664113,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'brialexandria_',
          followers: 101,
          isPrimary: true
        }
      },
      userCampaignId: '9a44dbcc-844f-4ed8-bc6b-2108dcacdf08'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T16:32:32.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'b78eba4e-b651-438b-9cf4-43add923d993',
        firstName: 'Lauren',
        lastName: 'DeHaan',
        email: 'ourlittleroadschool@gmail.com',
        tiktokProfile: {
          handle: 'ourlittleroadschool',
          followers: 3355,
          engagementRate: 7.175903768226924,
          isPrimary: false
        }
      },
      userCampaignId: 'b78eba4e-b651-438b-9cf4-43add923d993'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-20T05:43:59.000Z',
        amountCents: 6531
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-29T19:46:04.299Z',
          createdAt: '2024-01-27T00:34:56.859Z',
          content: [
            {
              id: 'f42374b0-6637-4bbf-939e-7e2909244abd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fb7cc431-f9a6-4402-9dcc-9370b131624b/ugc/93c8ab8a-9afe-4a8a-ac36-b9ab4184bd01/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bff6da30-6164-49b9-bb48-321de1e13e2e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fb7cc431-f9a6-4402-9dcc-9370b131624b/ugc/93c8ab8a-9afe-4a8a-ac36-b9ab4184bd01/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6621e0c1-27ad-4d06-8f99-99f943e625c6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fb7cc431-f9a6-4402-9dcc-9370b131624b/ugc/93c8ab8a-9afe-4a8a-ac36-b9ab4184bd01/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '93c8ab8a-9afe-4a8a-ac36-b9ab4184bd01'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-31T22:33:24.743Z',
          createdAt: '2024-01-27T00:36:31.412Z',
          content: [
            {
              id: 'cf00b96c-9a56-447a-b3dc-7063c88ff857',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/fb7cc431-f9a6-4402-9dcc-9370b131624b/ugc/07298e1e-09fc-4fb4-bf18-9438d8e30139/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '07298e1e-09fc-4fb4-bf18-9438d8e30139'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'fb7cc431-f9a6-4402-9dcc-9370b131624b',
        firstName: 'James',
        lastName: 'Mitchell',
        email: 'gottinightlife@yahoo.com',
        instagramProfile: {
          handle: 'jaygmtv',
          followers: 101491,
          engagementRate: 0.194302943118109,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'jaygmtv',
          followers: 115,
          isPrimary: false
        }
      },
      userCampaignId: 'fb7cc431-f9a6-4402-9dcc-9370b131624b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T01:32:54.000Z',
        amountCents: 5999
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c805d954-023d-4e80-8162-3339257b38b6',
        firstName: 'Dani Marie',
        lastName: 'Trueba',
        email: 'truebadanielle@yahoo.com',
        instagramProfile: {
          handle: 'daniee.marieee',
          followers: 11603,
          engagementRate: 4.25751960699819,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'danielandpdx',
          followers: 228,
          engagementRate: 5.02092050209205,
          isPrimary: true
        }
      },
      userCampaignId: 'c805d954-023d-4e80-8162-3339257b38b6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T01:34:32.000Z',
        amountCents: 6501
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd81374a0-234a-4d6d-8bef-149664771f95',
        firstName: 'Monique',
        lastName: 'Duran',
        email: 'moniqueaduran56@gmail.com',
        instagramProfile: {
          handle: 'mo.mermaid_',
          followers: 1930,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'babymomo19',
          followers: 2214,
          engagementRate: 10.585949079411087,
          isPrimary: true
        }
      },
      userCampaignId: 'd81374a0-234a-4d6d-8bef-149664771f95'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-17T22:05:30.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-01T19:05:26.168Z',
          createdAt: '2024-01-31T17:57:54.933Z',
          content: [
            {
              id: 'f532daef-0baf-4756-973b-6f3958c2e30b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e529a150-62ba-404d-b84a-b10488c74974/ugc/f0607f70-49a9-4310-baef-ccd1f5d49f85/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2fd780b6-8868-4bcd-bb56-30ed3707a8a6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e529a150-62ba-404d-b84a-b10488c74974/ugc/f0607f70-49a9-4310-baef-ccd1f5d49f85/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a5660876-8861-4b54-b0ce-6cdf7f0fefef',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e529a150-62ba-404d-b84a-b10488c74974/ugc/f0607f70-49a9-4310-baef-ccd1f5d49f85/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f0607f70-49a9-4310-baef-ccd1f5d49f85'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-01T19:05:21.820Z',
          createdAt: '2024-01-31T18:20:36.513Z',
          content: [
            {
              id: '7c41f7e3-516d-47b1-8f20-b45a67f2016b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e529a150-62ba-404d-b84a-b10488c74974/ugc/2b7ee284-0e98-4c27-b2b3-57917896eb26/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2b7ee284-0e98-4c27-b2b3-57917896eb26'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e529a150-62ba-404d-b84a-b10488c74974',
        firstName: 'Rania',
        lastName: 'Hoteit',
        email: 'hoteit.rania@gmail.com',
        instagramProfile: {
          handle: 'raniahoteit_officialpublicpage',
          followers: 2588,
          engagementRate: 0.5757341576506955,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'raniahoteit_official',
          followers: 8,
          isPrimary: false
        }
      },
      userCampaignId: 'e529a150-62ba-404d-b84a-b10488c74974'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T17:46:39.000Z',
        amountCents: 5499
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'f19bf2f7-954d-45f1-8bbd-c1fed5ec57a8',
        firstName: 'Mack',
        lastName: 'Nepper',
        email: 'markknepper1@outlook.com',
        instagramProfile: {
          handle: 'badcomedypodcast',
          followers: 2557,
          engagementRate: 0.9385999217833398,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'badboyofcomedy',
          followers: 6377,
          engagementRate: 7.20549893339654,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'badcomedypodcast'
        }
      },
      userCampaignId: 'f19bf2f7-954d-45f1-8bbd-c1fed5ec57a8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T18:17:31.000Z',
        amountCents: 6359
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '90b39b37-3f2a-4d60-b637-eaf804306223',
        firstName: 'Laura',
        lastName: 'Keating',
        email: 'lilnarwhal97@gmail.com',
        tiktokProfile: {
          handle: 'lilnarwhal97',
          followers: 15036,
          engagementRate: 8.441558441558442,
          isPrimary: true
        }
      },
      userCampaignId: '90b39b37-3f2a-4d60-b637-eaf804306223'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T16:51:31.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T15:46:59.084Z',
          createdAt: '2024-02-01T21:23:17.932Z',
          content: [
            {
              id: '35d12101-eba9-46aa-a24c-f0f8f9fefeb2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8677d444-0897-4a2d-8bbe-64a3baf7f6d6/ugc/4018e8cc-6557-4f78-9dcf-fde500dfe04c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4e66780b-4f98-45cc-91a9-84f19203a70c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8677d444-0897-4a2d-8bbe-64a3baf7f6d6/ugc/4018e8cc-6557-4f78-9dcf-fde500dfe04c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4d5eda87-e18b-444e-99d3-2de5a60effe6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8677d444-0897-4a2d-8bbe-64a3baf7f6d6/ugc/4018e8cc-6557-4f78-9dcf-fde500dfe04c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4018e8cc-6557-4f78-9dcf-fde500dfe04c'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-02T16:14:08.744Z',
          createdAt: '2024-02-01T03:11:09.730Z',
          content: [
            {
              id: 'c5a76e76-d37f-4ae0-a3a0-a01465bafea7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8677d444-0897-4a2d-8bbe-64a3baf7f6d6/ugc/36d3eba6-6b47-4408-9e63-9c0a04e48d6a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '36d3eba6-6b47-4408-9e63-9c0a04e48d6a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8677d444-0897-4a2d-8bbe-64a3baf7f6d6',
        firstName: 'Thomas',
        lastName: 'Murphy',
        email: 'thomasmurphy27@gmail.com',
        instagramProfile: {
          handle: 'tmtsystems',
          followers: 1757,
          engagementRate: 1.7416050085372794,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thomasmurphy450',
          followers: 11,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'tmtsystemsatl'
        }
      },
      userCampaignId: '8677d444-0897-4a2d-8bbe-64a3baf7f6d6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T07:54:30.000Z',
        amountCents: 6584
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T14:39:21.835Z',
          createdAt: '2024-02-10T22:57:43.601Z',
          content: [
            {
              id: 'ffd57327-3e3a-428f-ae7c-fd2109fc0e19',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80fcc076-399a-4fc5-9096-53162e1ac8c4/ugc/f8cdf3e7-56b7-4431-b9e7-09e9dd65ad2c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '32aeb93a-a29e-46f8-9d0c-d1e802085a31',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80fcc076-399a-4fc5-9096-53162e1ac8c4/ugc/f8cdf3e7-56b7-4431-b9e7-09e9dd65ad2c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ea6d2251-5857-4898-a1c1-2b6ec404ae38',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80fcc076-399a-4fc5-9096-53162e1ac8c4/ugc/f8cdf3e7-56b7-4431-b9e7-09e9dd65ad2c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f8cdf3e7-56b7-4431-b9e7-09e9dd65ad2c'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-12T14:39:16.022Z',
          createdAt: '2024-02-10T22:57:21.482Z',
          content: [
            {
              id: '9838a4dc-527a-4aa1-ae08-84e6c5cf32ec',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/80fcc076-399a-4fc5-9096-53162e1ac8c4/ugc/f0696c93-ba1b-4fb1-bcaa-5737719597df/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f0696c93-ba1b-4fb1-bcaa-5737719597df'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '80fcc076-399a-4fc5-9096-53162e1ac8c4',
        firstName: 'Sara',
        lastName: 'Kastanteen',
        email: 'sark620@gmail.com',
        instagramProfile: {
          handle: 'parivibe__',
          followers: 8529,
          engagementRate: 1.4011021221714153,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'parivibe__',
          followers: 243,
          engagementRate: 10.653225217327781,
          isPrimary: true
        }
      },
      userCampaignId: '80fcc076-399a-4fc5-9096-53162e1ac8c4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T15:12:59.000Z',
        amountCents: 6459
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-21T21:00:58.731Z',
          createdAt: '2024-01-19T16:46:32.528Z',
          content: [
            {
              id: '66fa6563-529a-4948-9e79-925d74155e8a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6715a96b-55bc-4acd-912f-c35f32aed225/ugc/ef1b3a71-f637-48ba-b9ee-6a97544161f3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '136d6fdd-4f64-4ed5-8057-75a7ccd8dfce',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6715a96b-55bc-4acd-912f-c35f32aed225/ugc/ef1b3a71-f637-48ba-b9ee-6a97544161f3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a2bc1c34-17c8-4406-acee-250d19a9010d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6715a96b-55bc-4acd-912f-c35f32aed225/ugc/ef1b3a71-f637-48ba-b9ee-6a97544161f3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ef1b3a71-f637-48ba-b9ee-6a97544161f3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-21T21:03:32.713Z',
          createdAt: '2024-01-19T17:55:17.254Z',
          content: [
            {
              id: '20704a4b-c23c-4d98-a504-56e50ce62f96',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/6715a96b-55bc-4acd-912f-c35f32aed225/ugc/5f40d316-19ea-4083-9ee4-d14e44736842/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5f40d316-19ea-4083-9ee4-d14e44736842'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '6715a96b-55bc-4acd-912f-c35f32aed225',
        firstName: 'Erica',
        lastName: 'Rodriguez',
        email: 'ericaparis0713@gmail.com',
        instagramProfile: {
          handle: 'ericaaarodriguez',
          followers: 10048,
          engagementRate: 0.20103503184713375,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'ericarodriguezbeauty',
          followers: 8529,
          engagementRate: 5.569007263922518,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'ericarodriguezbeauty'
        }
      },
      userCampaignId: '6715a96b-55bc-4acd-912f-c35f32aed225'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T14:42:30.000Z',
        amountCents: 6253
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-21T21:01:03.899Z',
          createdAt: '2024-01-19T22:29:24.336Z',
          content: [
            {
              id: 'ef91aba2-8263-45b0-963b-0884ebcfb06c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f76b10e-c9fc-484d-971a-3a24b9eee917/ugc/bf0dc8a6-702b-4ab8-aaae-fc59dce5ffde/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'dd07c4de-f1a9-4a2a-ab76-c67986bdaacb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f76b10e-c9fc-484d-971a-3a24b9eee917/ugc/bf0dc8a6-702b-4ab8-aaae-fc59dce5ffde/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e8d2e565-97e2-45be-a8d5-b80227232f1a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f76b10e-c9fc-484d-971a-3a24b9eee917/ugc/bf0dc8a6-702b-4ab8-aaae-fc59dce5ffde/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bf0dc8a6-702b-4ab8-aaae-fc59dce5ffde'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-24T14:30:42.027Z',
          createdAt: '2024-01-19T22:27:20.435Z',
          content: [
            {
              id: 'ac91001e-528f-4c5c-8800-9aff81aafff2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1f76b10e-c9fc-484d-971a-3a24b9eee917/ugc/5bc6ba4a-8dd6-4fe4-b686-b7237db7ec4e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5bc6ba4a-8dd6-4fe4-b686-b7237db7ec4e'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1f76b10e-c9fc-484d-971a-3a24b9eee917',
        firstName: 'Kerry',
        lastName: 'Dunphy',
        email: 'kerryreneedunphy@gmail.com',
        instagramProfile: {
          handle: 'kdunph',
          followers: 5885,
          engagementRate: 7.969413763806288,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kdunphh',
          followers: 2378,
          engagementRate: 6.666666666666667,
          isPrimary: true
        }
      },
      userCampaignId: '1f76b10e-c9fc-484d-971a-3a24b9eee917'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-30T21:34:28.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '2a170a77-48fa-474e-8ee5-f48558ea2eff',
        firstName: 'Marisa',
        lastName: 'Levy',
        email: 'iammarisachrista@gmail.com',
        instagramProfile: {
          handle: 'iammarisachrista',
          followers: 3228,
          engagementRate: 3.506815365551425,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'iammarisachrista',
          followers: 1119,
          engagementRate: 7.91238751547185,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'iammarisachrista',
          followers: 86
        }
      },
      userCampaignId: '2a170a77-48fa-474e-8ee5-f48558ea2eff'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T20:07:04.000Z',
        amountCents: 5410
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-29T16:40:54.811Z',
          createdAt: '2024-01-28T19:26:04.545Z',
          content: [
            {
              id: '41eddcf3-c9af-40ec-98d9-f777f11ae8a3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d674e9c9-bf3b-4eda-8aa1-253911c9ad8e/ugc/4625abb3-6f2b-46d2-a98a-183a405c749d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5e0a5aa3-f95c-4938-aad7-eca6192698a5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d674e9c9-bf3b-4eda-8aa1-253911c9ad8e/ugc/4625abb3-6f2b-46d2-a98a-183a405c749d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '214c591e-009b-40aa-a45c-22c5de76bbb7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d674e9c9-bf3b-4eda-8aa1-253911c9ad8e/ugc/4625abb3-6f2b-46d2-a98a-183a405c749d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4625abb3-6f2b-46d2-a98a-183a405c749d'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T15:49:59.966Z',
          createdAt: '2024-01-28T19:22:15.923Z',
          content: [
            {
              id: '466fce48-c1fb-4fa5-ac67-088e57091197',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d674e9c9-bf3b-4eda-8aa1-253911c9ad8e/ugc/46826b06-3733-46e3-98b4-199fc0f6a8b0/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '46826b06-3733-46e3-98b4-199fc0f6a8b0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd674e9c9-bf3b-4eda-8aa1-253911c9ad8e',
        firstName: 'Haylie',
        lastName: 'Powers',
        email: 'omghaylie12@yahoo.com',
        instagramProfile: {
          handle: 'haylie.ugc',
          followers: 1197,
          engagementRate: 6.867167919799499,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'createwithhaylie',
          followers: 5190,
          engagementRate: 7.73889426528892,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'createwithhaylie',
          followers: 1
        }
      },
      userCampaignId: 'd674e9c9-bf3b-4eda-8aa1-253911c9ad8e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-29T20:45:53.000Z',
        amountCents: 5436
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'a6163c83-f883-4a3d-9fda-e0d9d5e916b1',
        firstName: 'Myelia',
        lastName: 'Zachary',
        email: 'zacharybusiness.solutions@gmail.com',
        instagramProfile: {
          handle: 'myelia_irl',
          followers: 14413,
          engagementRate: 0.20883924235065568,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'myelia_irl',
          followers: 11170,
          engagementRate: 5.0983567837500425,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'myelia_irl'
        }
      },
      userCampaignId: 'a6163c83-f883-4a3d-9fda-e0d9d5e916b1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T21:05:26.000Z',
        amountCents: 6516
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-29T10:49:20.531Z',
          createdAt: '2024-01-26T00:52:39.181Z',
          content: [
            {
              id: 'ccdabf28-d016-444c-a2da-7f427df07c74',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9360834-c2f4-4016-9a1f-63390c1db4df/ugc/6f0b4fec-3482-4e89-9e08-5f6c82cbab9e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6f0b4fec-3482-4e89-9e08-5f6c82cbab9e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T00:52:56.583Z',
          createdAt: '2024-01-22T11:53:22.520Z',
          content: [
            {
              id: '2c5bc058-2583-4412-98c3-780fa2e8d22e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9360834-c2f4-4016-9a1f-63390c1db4df/ugc/1177ae26-0298-41b5-b4c3-a269a451df5d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '372e3df9-cd33-4fd6-93a3-3ded6725bca6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9360834-c2f4-4016-9a1f-63390c1db4df/ugc/1177ae26-0298-41b5-b4c3-a269a451df5d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0029a25b-9423-43f3-ba49-200d906fd60d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9360834-c2f4-4016-9a1f-63390c1db4df/ugc/1177ae26-0298-41b5-b4c3-a269a451df5d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1177ae26-0298-41b5-b4c3-a269a451df5d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd9360834-c2f4-4016-9a1f-63390c1db4df',
        firstName: 'Anderson',
        lastName: 'Nguyen',
        email: 'andersonvannguyen.business@gmail.com',
        instagramProfile: {
          handle: 'andersonnguyen1',
          followers: 58849,
          engagementRate: 51.49909089364305,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'andersonnguyen.official',
          followers: 115100,
          engagementRate: 8.283661571204531,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'andersonnguyen.official',
          followers: 73600
        }
      },
      userCampaignId: 'd9360834-c2f4-4016-9a1f-63390c1db4df'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T14:22:45.000Z',
        amountCents: 6297
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-27T22:24:07.590Z',
          createdAt: '2024-01-26T00:22:47.934Z',
          content: [
            {
              id: 'c20d52c2-f95e-4a07-accb-eff3177ad94d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/089174f7-ad5f-4871-841f-33fa074d066c/ugc/f3ab3ae9-3287-404e-95ec-281497e3438c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3a03a854-e3ac-4684-893e-a41595dcc7b7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/089174f7-ad5f-4871-841f-33fa074d066c/ugc/f3ab3ae9-3287-404e-95ec-281497e3438c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '271af871-08b3-4f48-95eb-56cfe564b6f4',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/089174f7-ad5f-4871-841f-33fa074d066c/ugc/f3ab3ae9-3287-404e-95ec-281497e3438c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f3ab3ae9-3287-404e-95ec-281497e3438c'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-27T22:24:02.722Z',
          createdAt: '2024-01-26T00:32:31.794Z',
          content: [
            {
              id: 'd22fbf0a-47b8-44ba-9c1f-108c88e6dd27',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/089174f7-ad5f-4871-841f-33fa074d066c/ugc/a8f7a067-63fb-4731-a931-3115b7eeda11/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a8f7a067-63fb-4731-a931-3115b7eeda11'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '089174f7-ad5f-4871-841f-33fa074d066c',
        firstName: 'Jackson',
        lastName: 'Ramey',
        email: 'jramey777@gmail.com',
        instagramProfile: {
          handle: 'rameyoutdoors',
          followers: 2437,
          engagementRate: 2.2979072630283133,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'rameyoutdoors',
          followers: 26865,
          engagementRate: 5.548401042254303,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'rameyoutdoors',
          followers: 164
        }
      },
      userCampaignId: '089174f7-ad5f-4871-841f-33fa074d066c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T18:34:05.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-17T16:02:15.817Z',
          createdAt: '2024-02-14T17:29:28.216Z',
          content: [
            {
              id: '55fc7cec-b814-4aa9-8a1f-99014bf08509',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b4664881-a833-49bc-a0f6-bee94590bab0/ugc/13efc531-1f8b-455d-b935-e237402a5fde/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '13efc531-1f8b-455d-b935-e237402a5fde'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-17T16:02:19.621Z',
          createdAt: '2024-02-14T17:30:03.025Z',
          content: [
            {
              id: '9e5d6a40-9e43-4c96-912b-575f8f8393e9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b4664881-a833-49bc-a0f6-bee94590bab0/ugc/448cf2d4-66fb-45a7-aa90-3f752585355b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd5272762-82b5-497d-99c9-3a7f71079196',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b4664881-a833-49bc-a0f6-bee94590bab0/ugc/448cf2d4-66fb-45a7-aa90-3f752585355b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '82fa1855-fb5c-4483-a786-6a09a6da0c4f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b4664881-a833-49bc-a0f6-bee94590bab0/ugc/448cf2d4-66fb-45a7-aa90-3f752585355b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '448cf2d4-66fb-45a7-aa90-3f752585355b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b4664881-a833-49bc-a0f6-bee94590bab0',
        firstName: 'Chelsea',
        lastName: 'Onstott',
        email: 'curiouser.chelsea@gmail.com',
        instagramProfile: {
          handle: 'curiouser.chelsea',
          followers: 5850,
          engagementRate: 12.523076923076923,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'curiouser.chelsea',
          followers: 1292,
          engagementRate: 12.944983818770226,
          isPrimary: true
        }
      },
      userCampaignId: 'b4664881-a833-49bc-a0f6-bee94590bab0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T15:39:07.000Z',
        amountCents: 5399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-31T18:20:42.838Z',
          createdAt: '2024-01-29T17:43:29.537Z',
          content: [
            {
              id: 'f80a7052-b3d4-4558-85ca-d6dbf84c76e1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dded9a-2856-403d-b4f3-afb226063665/ugc/6d86fcdd-c90a-4114-a73c-7af6e157ccb3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e3752fb2-49be-4e88-95d9-52f8719c48bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dded9a-2856-403d-b4f3-afb226063665/ugc/6d86fcdd-c90a-4114-a73c-7af6e157ccb3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2576a54e-84ac-43ed-8250-97ba7844e1a1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dded9a-2856-403d-b4f3-afb226063665/ugc/6d86fcdd-c90a-4114-a73c-7af6e157ccb3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6d86fcdd-c90a-4114-a73c-7af6e157ccb3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-31T18:20:38.246Z',
          createdAt: '2024-01-29T17:57:12.763Z',
          content: [
            {
              id: 'b6aadb7c-a75c-4845-a31a-a29312e46a24',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99dded9a-2856-403d-b4f3-afb226063665/ugc/2af970f0-8170-4593-a314-89d965d5f313/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2af970f0-8170-4593-a314-89d965d5f313'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '99dded9a-2856-403d-b4f3-afb226063665',
        firstName: 'Yesenia',
        lastName: 'Mercado',
        email: 'yeseniadmercado@gmail.com',
        instagramProfile: {
          handle: 'yes_2fitness',
          followers: 9134,
          engagementRate: 0.12590321874315744,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'yes_2fitness',
          followers: 686
        }
      },
      userCampaignId: '99dded9a-2856-403d-b4f3-afb226063665'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T05:53:56.000Z',
        amountCents: 6359
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3374ab02-b02e-447c-8c77-f2509ff7fa7d',
        firstName: 'Katelyn',
        lastName: 'Swanson',
        email: 'thecandidlykatelyn@gmail.com',
        instagramProfile: {
          handle: 'candidlykatelyn',
          followers: 8711,
          engagementRate: 0.7599586729422569,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'candidlykatelyn',
          followers: 1786,
          isPrimary: true
        }
      },
      userCampaignId: '3374ab02-b02e-447c-8c77-f2509ff7fa7d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T12:53:45.000Z',
        amountCents: 5444
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-07T14:16:52.922Z',
          createdAt: '2024-02-06T17:16:12.690Z',
          content: [
            {
              id: '1b332a0c-73c2-46de-9116-04d89b3fcebd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5337668a-2a55-4bda-a9a3-4891a9ee20b5/ugc/c257d890-6915-44aa-8e88-fdc54051d017/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3ec98bc6-d10a-4c46-bbd9-8181113a59f0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5337668a-2a55-4bda-a9a3-4891a9ee20b5/ugc/c257d890-6915-44aa-8e88-fdc54051d017/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a76232ce-929a-49e7-aa06-1bc06b8bb85c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5337668a-2a55-4bda-a9a3-4891a9ee20b5/ugc/c257d890-6915-44aa-8e88-fdc54051d017/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c257d890-6915-44aa-8e88-fdc54051d017'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T14:16:47.680Z',
          createdAt: '2024-02-06T17:36:53.090Z',
          content: [
            {
              id: '7af0ab32-a807-41de-aef8-834db1b11b55',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5337668a-2a55-4bda-a9a3-4891a9ee20b5/ugc/6bdaf5c3-911e-4cb9-b161-f6525cb144b2/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6bdaf5c3-911e-4cb9-b161-f6525cb144b2'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5337668a-2a55-4bda-a9a3-4891a9ee20b5',
        firstName: 'Akeem',
        lastName: 'Muller',
        email: 'collabakeem@gmail.com',
        instagramProfile: {
          handle: 'akmmichs',
          followers: 8916,
          engagementRate: 4.634365186182144,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'akmmichs',
          followers: 6498,
          engagementRate: 8.604452054794521,
          isPrimary: true
        }
      },
      userCampaignId: '5337668a-2a55-4bda-a9a3-4891a9ee20b5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T08:23:59.000Z',
        amountCents: 5316
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-17T12:36:25.179Z',
          createdAt: '2024-02-12T02:52:43.842Z',
          content: [
            {
              id: '05397125-cadf-42d1-bb7d-7f9071abf2ab',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0bfbc96-037f-4d36-9ded-662745707cd8/ugc/50808e24-e062-47de-bcc0-de1fd5fbcae0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b773212e-ed7f-4172-8ae8-bb6c258646ac',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0bfbc96-037f-4d36-9ded-662745707cd8/ugc/50808e24-e062-47de-bcc0-de1fd5fbcae0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a3c00552-dfef-4ea4-89a7-326858d45a48',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0bfbc96-037f-4d36-9ded-662745707cd8/ugc/50808e24-e062-47de-bcc0-de1fd5fbcae0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '50808e24-e062-47de-bcc0-de1fd5fbcae0'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-17T12:36:14.784Z',
          createdAt: '2024-02-12T02:51:46.630Z',
          content: [
            {
              id: 'd8f6c69e-83e4-4516-b59c-30381668ff3c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e0bfbc96-037f-4d36-9ded-662745707cd8/ugc/d61f5cc3-f0e4-41aa-99f7-8da859184f75/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd61f5cc3-f0e4-41aa-99f7-8da859184f75'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e0bfbc96-037f-4d36-9ded-662745707cd8',
        firstName: 'Summer',
        lastName: 'Orlando',
        email: 'summerorlandoproductions@gmail.com',
        instagramProfile: {
          handle: 'misssummerorlando',
          followers: 6383,
          engagementRate: 0.39166536111546296,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'misssummerorlando',
          followers: 241,
          engagementRate: 6.299212598425196,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'summerorlando',
          followers: 558
        }
      },
      userCampaignId: 'e0bfbc96-037f-4d36-9ded-662745707cd8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T18:43:46.000Z',
        amountCents: 13899
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5332c4de-abdf-46b4-a43d-b7adc9f67218',
        firstName: 'Eyshila ',
        lastName: 'Tomaz',
        email: 'eyshilaltomaz@gmail.com',
        instagramProfile: {
          handle: 'eyshilatomaz_',
          followers: 8097,
          engagementRate: 3.6630850932444114,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'twoeyshila',
          followers: 1303,
          engagementRate: 9.510869565217392,
          isPrimary: false
        }
      },
      userCampaignId: '5332c4de-abdf-46b4-a43d-b7adc9f67218'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '1b5a51a8-fca2-482c-9fbe-4cae5d9c72f0',
        firstName: 'Christian',
        lastName: 'Gonzalez',
        email: 'info@dadlifegaming.com',
        instagramProfile: {
          handle: 'dad.life.gaming',
          followers: 2816,
          engagementRate: 2.279829545454546,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'dad.life.gaming',
          followers: 509,
          engagementRate: 3.4722222222222223,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'dadlifegaming'
        }
      },
      userCampaignId: '1b5a51a8-fca2-482c-9fbe-4cae5d9c72f0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-29T18:18:16.000Z',
        amountCents: 5386
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'd33537ca-258e-47b0-8fd3-5331e2034d73',
        firstName: 'Audrey',
        lastName: 'Cardenas',
        email: 'hello@photobyaudrey.com',
        instagramProfile: {
          handle: 'sdweddingphotographer',
          followers: 5173,
          engagementRate: 1.6779431664411366,
          isPrimary: false
        }
      },
      userCampaignId: 'd33537ca-258e-47b0-8fd3-5331e2034d73'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-17T18:46:51.000Z',
        amountCents: 6386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T20:23:01.317Z',
          createdAt: '2024-01-23T03:14:01.012Z',
          content: [
            {
              id: '721200e6-9e79-41bf-84ea-d6f68e1f4603',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697fb2af-811a-4e00-b511-9a7d8587f49e/ugc/3577a065-14f0-4831-a16c-64a7b2f08d3f/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9659cb11-664d-4be8-bd25-58060e9b53a6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697fb2af-811a-4e00-b511-9a7d8587f49e/ugc/3577a065-14f0-4831-a16c-64a7b2f08d3f/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '81b3c081-856c-4030-9f4d-45f3c7ce4063',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697fb2af-811a-4e00-b511-9a7d8587f49e/ugc/3577a065-14f0-4831-a16c-64a7b2f08d3f/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3577a065-14f0-4831-a16c-64a7b2f08d3f'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-26T20:22:54.065Z',
          createdAt: '2024-01-25T02:53:00.195Z',
          content: [
            {
              id: '9c9e2f10-cf89-4303-8e2a-89f67115acba',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/697fb2af-811a-4e00-b511-9a7d8587f49e/ugc/89d899da-c786-4377-9862-1c52408e6490/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '89d899da-c786-4377-9862-1c52408e6490'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '697fb2af-811a-4e00-b511-9a7d8587f49e',
        firstName: 'Niki',
        lastName: 'Tolliver',
        email: 'girlwiththespidertattoo@gmail.com',
        instagramProfile: {
          handle: 'the_black_widow3',
          followers: 2868,
          engagementRate: 0.2894002789400279,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'theblackwidow06',
          followers: 780,
          engagementRate: 1.7467248908296942,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'spiderlily'
        }
      },
      userCampaignId: '697fb2af-811a-4e00-b511-9a7d8587f49e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T03:46:29.000Z',
        amountCents: 5361
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'eb0e27fb-9d2f-413d-8578-08f530ed0c27',
        firstName: 'Brittney',
        lastName: 'Brooks',
        email: 'brittneybrooks123@gmail.com',
        instagramProfile: {
          handle: 'brittneyryannn',
          followers: 4446,
          engagementRate: 0.8479532163742691,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'brittneyryannn',
          followers: 15971,
          engagementRate: 16.15960334520578,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'brittneyryannn'
        }
      },
      userCampaignId: 'eb0e27fb-9d2f-413d-8578-08f530ed0c27'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T08:14:22.000Z',
        amountCents: 6494
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0c55bb2b-9531-4eae-bbdd-da80a63167ce',
        firstName: 'Kandee',
        lastName: 'Ellis',
        email: '3girlsandalegend@gmail.com',
        instagramProfile: {
          handle: '3girlsandalegend',
          followers: 7714,
          engagementRate: 4.646098003629763,
          isPrimary: true
        },
        tiktokProfile: {
          handle: '3girlsandalegend',
          followers: 1056,
          engagementRate: 4.106707060506606,
          isPrimary: false
        }
      },
      userCampaignId: '0c55bb2b-9531-4eae-bbdd-da80a63167ce'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T23:42:10.000Z',
        amountCents: 6569
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-29T18:42:55.235Z',
          createdAt: '2024-01-28T20:03:32.126Z',
          content: [
            {
              id: '650ae144-1a37-4854-938a-29b054528f3b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a87f44d2-1011-4d3c-8d5d-fc0505132669/ugc/d75e0d8a-9d98-478e-a3c3-122daaf096cf/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'eaea3098-512b-4a53-8f46-b0bed8cb6540',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a87f44d2-1011-4d3c-8d5d-fc0505132669/ugc/d75e0d8a-9d98-478e-a3c3-122daaf096cf/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a500b029-fd82-4b9c-a7ca-4e14d90dee02',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a87f44d2-1011-4d3c-8d5d-fc0505132669/ugc/d75e0d8a-9d98-478e-a3c3-122daaf096cf/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd75e0d8a-9d98-478e-a3c3-122daaf096cf'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-29T18:42:50.337Z',
          createdAt: '2024-01-28T20:01:39.199Z',
          content: [
            {
              id: 'a1380b81-8bcb-4379-aecb-91598457720b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a87f44d2-1011-4d3c-8d5d-fc0505132669/ugc/419d96dc-053e-46ee-b5aa-717d763ad66d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '419d96dc-053e-46ee-b5aa-717d763ad66d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a87f44d2-1011-4d3c-8d5d-fc0505132669',
        firstName: 'Kelly',
        lastName: 'Piliouras',
        email: 'piliouraskelly@gmail.com',
        instagramProfile: {
          handle: 'kelly_piliouras',
          followers: 1403,
          engagementRate: 2.202423378474697,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kelly_piliouras',
          followers: 546,
          isPrimary: true
        }
      },
      userCampaignId: 'a87f44d2-1011-4d3c-8d5d-fc0505132669'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-20T05:13:09.000Z',
        amountCents: 6253
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T06:18:56.814Z',
          createdAt: '2024-01-23T22:45:49.731Z',
          content: [
            {
              id: 'b19f1329-94f2-4bf5-99b4-a65868ebc827',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aa9676e6-8ae7-441a-84c6-050eb9b57021/ugc/85e45c50-ff0a-4742-8005-ee14dbe658ba/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2c19eaae-15bb-4d59-9b72-086c89c3fcd2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aa9676e6-8ae7-441a-84c6-050eb9b57021/ugc/85e45c50-ff0a-4742-8005-ee14dbe658ba/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '67607553-47a2-4080-8b44-33a6cf8e58e2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aa9676e6-8ae7-441a-84c6-050eb9b57021/ugc/85e45c50-ff0a-4742-8005-ee14dbe658ba/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '85e45c50-ff0a-4742-8005-ee14dbe658ba'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-26T17:48:38.570Z',
          createdAt: '2024-01-24T17:06:49.227Z',
          content: [
            {
              id: '00a61efe-6f3e-42a1-ba45-8588e020cdd0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/aa9676e6-8ae7-441a-84c6-050eb9b57021/ugc/37d6620d-e062-46e3-b660-c0978d672f8d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '37d6620d-e062-46e3-b660-c0978d672f8d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'aa9676e6-8ae7-441a-84c6-050eb9b57021',
        firstName: 'Britney',
        lastName: 'Occeus',
        email: 'britneyocceus@yahoo.com',
        instagramProfile: {
          handle: 'britthejewel',
          followers: 8840,
          engagementRate: 0.9276018099547512,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'haitianjewel',
          followers: 211,
          engagementRate: 16.544502617801047,
          isPrimary: false
        }
      },
      userCampaignId: 'aa9676e6-8ae7-441a-84c6-050eb9b57021'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T20:14:07.000Z',
        amountCents: 6449
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-30T00:23:27.417Z',
          createdAt: '2024-01-27T22:58:32.735Z',
          content: [
            {
              id: '6ebb1d1e-a3f3-4787-86cc-7495e85a5aa5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7d455117-bf80-4aeb-9c05-5eef4df8215e/ugc/54f9a82a-c4fd-4f6b-8003-401c8fa4de0a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '54ec21d0-892e-45b1-b4f1-8fe631d5c4be',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7d455117-bf80-4aeb-9c05-5eef4df8215e/ugc/54f9a82a-c4fd-4f6b-8003-401c8fa4de0a/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3b0a8d1d-9e38-4518-a579-02cc621be059',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7d455117-bf80-4aeb-9c05-5eef4df8215e/ugc/54f9a82a-c4fd-4f6b-8003-401c8fa4de0a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '54f9a82a-c4fd-4f6b-8003-401c8fa4de0a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7d455117-bf80-4aeb-9c05-5eef4df8215e',
        firstName: 'Edgardo',
        lastName: 'Velez',
        email: 'zone7_@outlook.com',
        instagramProfile: {
          handle: 'the_legend_of_kyo',
          followers: 12870,
          engagementRate: 3.1359751359751358,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'the_legend_of_kyo',
          followers: 202386,
          engagementRate: 10.862171438803212,
          isPrimary: true
        }
      },
      userCampaignId: '7d455117-bf80-4aeb-9c05-5eef4df8215e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-24T15:36:30.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-15T23:16:05.095Z',
          createdAt: '2024-01-30T18:39:45.248Z',
          content: [
            {
              id: '0c662f85-fb41-46f6-a2a8-f2b0ee6e9355',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ef28e7c4-bb57-4512-9da0-51d2c97a6054/ugc/f8a090ea-5667-4e26-9975-abf1d3f65ece/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '298b65b3-1358-43fd-9ccc-4db6bd44b271',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ef28e7c4-bb57-4512-9da0-51d2c97a6054/ugc/f8a090ea-5667-4e26-9975-abf1d3f65ece/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4c0ae1ba-f79c-4610-a5e2-0f7bb9a58b35',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ef28e7c4-bb57-4512-9da0-51d2c97a6054/ugc/f8a090ea-5667-4e26-9975-abf1d3f65ece/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f8a090ea-5667-4e26-9975-abf1d3f65ece'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ef28e7c4-bb57-4512-9da0-51d2c97a6054',
        firstName: 'Shelby',
        lastName: 'Reyes',
        email: 'aislareyescollabs@gmail.com',
        instagramProfile: {
          handle: 'aisla.sky',
          followers: 13902,
          engagementRate: 0.7516904042583801,
          isPrimary: true
        }
      },
      userCampaignId: 'ef28e7c4-bb57-4512-9da0-51d2c97a6054'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T22:55:46.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'efea4c44-7830-4652-aad8-6e549a3a902d',
        firstName: 'Kacy',
        lastName: 'Jones',
        email: 'kacydejones@gmail.com',
        instagramProfile: {
          handle: 'kacyjstylez',
          followers: 1165,
          engagementRate: 0.6180257510729614,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'misskacyde',
          followers: 38,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'kacyjones6449',
          followers: 0
        }
      },
      userCampaignId: 'efea4c44-7830-4652-aad8-6e549a3a902d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-30T07:23:42.000Z',
        amountCents: 6000
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-07T13:08:12.406Z',
          createdAt: '2024-02-06T22:38:37.193Z',
          content: [
            {
              id: '555ff0ab-28ee-4f55-8b7a-659d1d85b62e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ece48e0e-2e3a-4e15-ac5c-a856a1dd6de1/ugc/8a44ce45-e890-481a-82d3-18ecdcc09f90/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1342dd04-9e2d-4990-be96-d69396ed20aa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ece48e0e-2e3a-4e15-ac5c-a856a1dd6de1/ugc/8a44ce45-e890-481a-82d3-18ecdcc09f90/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4a78430f-94f4-401e-a102-5863b2621107',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ece48e0e-2e3a-4e15-ac5c-a856a1dd6de1/ugc/8a44ce45-e890-481a-82d3-18ecdcc09f90/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8a44ce45-e890-481a-82d3-18ecdcc09f90'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-08T17:54:42.456Z',
          createdAt: '2024-02-07T14:23:43.061Z',
          content: [
            {
              id: 'ccc6af5c-cae9-4b9a-82c0-a792f9d7dcc2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ece48e0e-2e3a-4e15-ac5c-a856a1dd6de1/ugc/fd2ad35a-5a9f-402d-a806-9a9081ff9182/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'fd2ad35a-5a9f-402d-a806-9a9081ff9182'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ece48e0e-2e3a-4e15-ac5c-a856a1dd6de1',
        firstName: 'Andrea',
        lastName: 'Sheridan',
        email: 'coachandreabehrer@gmail.com',
        instagramProfile: {
          handle: 'sweatingdiamonds',
          followers: 3807,
          engagementRate: 104.50485946939847,
          isPrimary: true
        }
      },
      userCampaignId: 'ece48e0e-2e3a-4e15-ac5c-a856a1dd6de1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T19:04:28.000Z',
        amountCents: 6584
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c8c127f7-01d6-4da4-8a7f-26bdf2a9166f',
        firstName: 'Emma',
        lastName: 'Sagor',
        email: 'em.sagor6@gmail.com',
        instagramProfile: {
          handle: 'em.sagor',
          followers: 2460,
          engagementRate: 1.2235772357723578,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'em.sagor',
          followers: 3800,
          engagementRate: 5.058365758754864,
          isPrimary: true
        }
      },
      userCampaignId: 'c8c127f7-01d6-4da4-8a7f-26bdf2a9166f'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-30T20:39:27.000Z',
        amountCents: 5330
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-20T15:58:04.014Z',
          createdAt: '2024-02-06T19:11:27.197Z',
          content: [
            {
              id: '9010e845-1268-4e5d-8263-bcdce5ffc86c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3d664d86-1b17-404b-abfc-25e51ed2db27/ugc/62bf2392-fe7f-4945-8f94-b497df8938d2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ebf472b3-7a50-4601-8af9-52d5b9a86a79',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3d664d86-1b17-404b-abfc-25e51ed2db27/ugc/62bf2392-fe7f-4945-8f94-b497df8938d2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd14c319f-12da-4420-8224-ba482341d7df',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3d664d86-1b17-404b-abfc-25e51ed2db27/ugc/62bf2392-fe7f-4945-8f94-b497df8938d2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '62bf2392-fe7f-4945-8f94-b497df8938d2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T15:58:07.789Z',
          createdAt: '2024-02-06T20:48:58.628Z',
          content: [
            {
              id: '4a409647-558d-4dd7-9048-32f66a429094',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3d664d86-1b17-404b-abfc-25e51ed2db27/ugc/85a5a9cd-eb93-4136-a727-c9d9d76343ee/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '85a5a9cd-eb93-4136-a727-c9d9d76343ee'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3d664d86-1b17-404b-abfc-25e51ed2db27',
        firstName: 'Marian',
        lastName: 'Betancourt',
        email: 'mbetancourts1924@gmail.com',
        instagramProfile: {
          handle: 'armaries.co',
          followers: 3352,
          engagementRate: 0.6264916467780429,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'armaries_',
          followers: 375,
          engagementRate: 2.8009799227877883,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'armaries8019'
        }
      },
      userCampaignId: '3d664d86-1b17-404b-abfc-25e51ed2db27'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T01:54:35.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-18T19:15:15.947Z',
          createdAt: '2024-02-16T23:59:43.414Z',
          content: [
            {
              id: 'c155a490-f433-450c-946c-877812940b55',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8a0605e4-8852-46d3-b93c-506d3be45fa2/ugc/1db907ef-db12-483b-8cb7-9d2c262f2d94/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1db907ef-db12-483b-8cb7-9d2c262f2d94'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-19T17:26:10.723Z',
          createdAt: '2024-02-16T18:11:27.397Z',
          content: [
            {
              id: 'bd6a63c0-6ce5-44e7-9585-ed63bdb89e3a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8a0605e4-8852-46d3-b93c-506d3be45fa2/ugc/49a34f39-db57-410b-86f6-1ef602eb54c0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'eb8a8684-c879-4d6e-bb73-d9106b464ba1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8a0605e4-8852-46d3-b93c-506d3be45fa2/ugc/49a34f39-db57-410b-86f6-1ef602eb54c0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3f4c0b51-8da7-49c8-81e5-a2d473d4494c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8a0605e4-8852-46d3-b93c-506d3be45fa2/ugc/49a34f39-db57-410b-86f6-1ef602eb54c0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '49a34f39-db57-410b-86f6-1ef602eb54c0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8a0605e4-8852-46d3-b93c-506d3be45fa2',
        firstName: 'Taylor',
        lastName: 'Cortis',
        email: 'contact@tslayz.com',
        instagramProfile: {
          handle: 'tslayz',
          followers: 73848,
          engagementRate: 0.5692774347307984,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'tslayz',
          followers: 115328,
          engagementRate: 3.4134007585335016,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'tslayz'
        }
      },
      userCampaignId: '8a0605e4-8852-46d3-b93c-506d3be45fa2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T11:53:17.000Z',
        amountCents: 6386
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '57015c6b-4902-4e05-8769-e8ca1dfdb9ae',
        firstName: 'Samantha',
        lastName: 'Ordonez',
        email: 'sam.alizey@icloud.com',
        instagramProfile: {
          handle: 'samanthaordot',
          followers: 17,
          engagementRate: 1.9607843137254901,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'sweartosam',
          followers: 740,
          engagementRate: 3.474903474903475,
          isPrimary: true
        }
      },
      userCampaignId: '57015c6b-4902-4e05-8769-e8ca1dfdb9ae'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-13T16:15:52.000Z',
        amountCents: 7575
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-24T10:24:40.599Z',
          createdAt: '2024-01-21T18:31:32.344Z',
          content: [
            {
              id: 'd9c6d08e-1c8e-493c-a54d-058f542ef674',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/51f2c138-abc3-4bb8-b510-8dbcaa3ac6b8/ugc/7ad88ccf-9b36-48cb-a597-f31a2573775a/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7ad88ccf-9b36-48cb-a597-f31a2573775a'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-21T18:32:05.511Z',
          createdAt: '2024-01-15T18:34:48.822Z',
          content: [
            {
              id: 'c0102ae7-7056-4b11-b6bc-ad109c3c1472',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/51f2c138-abc3-4bb8-b510-8dbcaa3ac6b8/ugc/94e39452-edae-4547-9b7f-5344a6819530/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7017e37e-bf69-46a4-8fe8-07e2f5bb8e2d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/51f2c138-abc3-4bb8-b510-8dbcaa3ac6b8/ugc/94e39452-edae-4547-9b7f-5344a6819530/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c9293362-2011-4858-aca1-382781c1ca96',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/51f2c138-abc3-4bb8-b510-8dbcaa3ac6b8/ugc/94e39452-edae-4547-9b7f-5344a6819530/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '94e39452-edae-4547-9b7f-5344a6819530'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '51f2c138-abc3-4bb8-b510-8dbcaa3ac6b8',
        firstName: 'Smiz',
        lastName: 'Smith',
        email: 'lordsmiz@icloud.com',
        instagramProfile: {
          handle: 'smzzurp',
          followers: 29342,
          engagementRate: 15.593006611682913,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'smiztok',
          followers: 219903,
          engagementRate: 5.304199894854536,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'lordsmiz'
        }
      },
      userCampaignId: '51f2c138-abc3-4bb8-b510-8dbcaa3ac6b8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T19:54:54.000Z',
        amountCents: 6494
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-21T21:03:25.745Z',
          createdAt: '2024-01-20T03:44:53.643Z',
          content: [
            {
              id: 'ac8d0804-6cfe-47fe-bcd1-9b345537b3b6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/731854e3-0940-42c7-9d85-6a151d86a7c2/ugc/b7e0c1ca-9fa1-4f17-82bb-afa73c6b4dc2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd03e0a03-7ef9-437d-8270-3eeaf12f0978',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/731854e3-0940-42c7-9d85-6a151d86a7c2/ugc/b7e0c1ca-9fa1-4f17-82bb-afa73c6b4dc2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4af78dec-78ce-49a7-aff9-5de4217c917b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/731854e3-0940-42c7-9d85-6a151d86a7c2/ugc/b7e0c1ca-9fa1-4f17-82bb-afa73c6b4dc2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b7e0c1ca-9fa1-4f17-82bb-afa73c6b4dc2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-24T15:32:16.006Z',
          createdAt: '2024-01-20T03:49:46.575Z',
          content: [
            {
              id: '4fc1a71c-0dbb-4c18-9266-fe546351df09',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/731854e3-0940-42c7-9d85-6a151d86a7c2/ugc/8200e8ae-675a-4679-8785-7de8bfd249cf/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8200e8ae-675a-4679-8785-7de8bfd249cf'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '731854e3-0940-42c7-9d85-6a151d86a7c2',
        firstName: 'Dariya',
        lastName: 'Abdrakhmanova',
        email: 'darij1991@msn.com',
        instagramProfile: {
          handle: 'dariya09',
          followers: 4120,
          engagementRate: 0.412621359223301,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'dariya1991',
          followers: 475,
          engagementRate: 6.493506493506493,
          isPrimary: true
        }
      },
      userCampaignId: '731854e3-0940-42c7-9d85-6a151d86a7c2'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T12:15:16.000Z',
        amountCents: 5399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T18:56:06.529Z',
          createdAt: '2024-01-24T18:40:45.126Z',
          content: [
            {
              id: '29a6ed3e-4fc4-478a-b932-d3e81f40bc48',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ba913897-a89c-408f-9931-70089c979bec/ugc/11c02daf-ae49-43c9-a9dc-1653b08e78c6/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4e8d1889-0480-415a-afb8-a42f611c9878',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ba913897-a89c-408f-9931-70089c979bec/ugc/11c02daf-ae49-43c9-a9dc-1653b08e78c6/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '418743db-689f-4302-8002-f1844c64b575',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ba913897-a89c-408f-9931-70089c979bec/ugc/11c02daf-ae49-43c9-a9dc-1653b08e78c6/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '11c02daf-ae49-43c9-a9dc-1653b08e78c6'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-26T18:56:02.826Z',
          createdAt: '2024-01-24T18:34:28.486Z',
          content: [
            {
              id: '5076718b-92b1-4b02-9d63-30755deb9da5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ba913897-a89c-408f-9931-70089c979bec/ugc/3b039bc6-4c00-47eb-84d3-9a692558b8f1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '3b039bc6-4c00-47eb-84d3-9a692558b8f1'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ba913897-a89c-408f-9931-70089c979bec',
        firstName: 'Makenzie',
        lastName: 'Benton',
        email: 'makenziesavoie@gmail.com',
        instagramProfile: {
          handle: 'makkbenton',
          followers: 3903,
          engagementRate: 4.698949526005637,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'makkbenton',
          followers: 149,
          engagementRate: 5.555555555555555,
          isPrimary: false
        }
      },
      userCampaignId: 'ba913897-a89c-408f-9931-70089c979bec'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T15:55:08.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0f05dcfb-ebb0-4a09-9e20-f06e2e111dbe',
        firstName: 'Krystal',
        lastName: 'Giraldo',
        email: 'krystaljanet@yahoo.com',
        instagramProfile: {
          handle: 'theinclusivemama',
          followers: 6610,
          engagementRate: 2.0015128593040847,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'theinclusivemama_',
          followers: 231,
          engagementRate: 3.0939226519337018,
          isPrimary: false
        }
      },
      userCampaignId: '0f05dcfb-ebb0-4a09-9e20-f06e2e111dbe'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-13T16:25:56.000Z',
        amountCents: 6561
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'aa3941ff-4c70-423f-a5ce-26a46265d507',
        firstName: 'Powell',
        lastName: 'Nguyen',
        email: 'pnwintech@gmail.com',
        instagramProfile: {
          handle: 'powhalen',
          followers: 1219,
          engagementRate: 8.720262510254306,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'pow.tech',
          followers: 34169,
          engagementRate: 2.353607290073021,
          isPrimary: true
        }
      },
      userCampaignId: 'aa3941ff-4c70-423f-a5ce-26a46265d507'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-06T18:30:21.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '3fdfe44a-2ac5-40e6-8938-c1f7fbf58c06',
        firstName: 'Melissa',
        lastName: 'Henry',
        email: 'melissahenry2104@gmail.com',
        instagramProfile: {
          handle: 'melissajeanjo',
          followers: 176315,
          engagementRate: 0.7827467884184555,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'melissajeanjo',
          followers: 2403,
          engagementRate: 17.060367454068242,
          isPrimary: false
        }
      },
      userCampaignId: '3fdfe44a-2ac5-40e6-8938-c1f7fbf58c06'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T14:52:03.000Z',
        amountCents: 5386
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'baaf4659-53f2-4ebf-aa13-46107db5767d',
        firstName: 'Misael',
        lastName: 'Miranda',
        email: 'mrmisaelmiranda@gmail.com',
        instagramProfile: {
          handle: 'veteamisa',
          followers: 4668,
          engagementRate: 1.803770351328192,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'veteamisa',
          followers: 39920,
          engagementRate: 8.608156317195542,
          isPrimary: true
        }
      },
      userCampaignId: 'baaf4659-53f2-4ebf-aa13-46107db5767d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T15:36:32.000Z',
        amountCents: 5443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-28T04:19:36.831Z',
          createdAt: '2024-01-24T19:47:08.135Z',
          content: [
            {
              id: '3a675a28-f36d-47f4-b78c-fb93d931c143',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/08e43e73-0970-4156-9cf3-3d6b05dfe220/ugc/669c9d0d-1ef2-4f33-a2d7-ed7a0b961d9e/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '820fe9e0-c69f-4099-a146-7385b4507bc6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/08e43e73-0970-4156-9cf3-3d6b05dfe220/ugc/669c9d0d-1ef2-4f33-a2d7-ed7a0b961d9e/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '112e3542-71e0-4927-9bcf-8d48e54f4391',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/08e43e73-0970-4156-9cf3-3d6b05dfe220/ugc/669c9d0d-1ef2-4f33-a2d7-ed7a0b961d9e/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '669c9d0d-1ef2-4f33-a2d7-ed7a0b961d9e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-28T04:19:32.171Z',
          createdAt: '2024-01-24T19:46:01.721Z',
          content: [
            {
              id: '5d3cb27b-2cdf-40bd-83f5-ed28352d919f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/08e43e73-0970-4156-9cf3-3d6b05dfe220/ugc/d903a7e8-7645-465c-a451-cba42459eb8b/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd903a7e8-7645-465c-a451-cba42459eb8b'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '08e43e73-0970-4156-9cf3-3d6b05dfe220',
        firstName: 'Leo',
        lastName: 'Young',
        email: 'leoyoungrealestate@gmail.com',
        instagramProfile: {
          handle: 'leoyoung.realestate',
          followers: 11149,
          engagementRate: 0.22872006457978294,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'leoyoung.realestate',
          followers: 3546,
          engagementRate: 6.584416362220759,
          isPrimary: true
        }
      },
      userCampaignId: '08e43e73-0970-4156-9cf3-3d6b05dfe220'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T21:42:14.000Z',
        amountCents: 5474
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T08:55:41.301Z',
          createdAt: '2024-02-11T05:20:04.290Z',
          content: [
            {
              id: '3bd6718c-152b-4d40-b48d-e7f8acb541d9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e8910957-16d2-4ab7-b59e-2b2953b9ccb4/ugc/f2bc544c-d56b-4d16-abdd-d1f749315603/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '16eef3e8-5137-4a2e-b954-3a3b8a9105a9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e8910957-16d2-4ab7-b59e-2b2953b9ccb4/ugc/f2bc544c-d56b-4d16-abdd-d1f749315603/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e2cdc134-5780-4b74-8c79-be35289d7925',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e8910957-16d2-4ab7-b59e-2b2953b9ccb4/ugc/f2bc544c-d56b-4d16-abdd-d1f749315603/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'f2bc544c-d56b-4d16-abdd-d1f749315603'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-12T17:15:02.708Z',
          createdAt: '2024-02-11T04:50:51.153Z',
          content: [
            {
              id: 'e081fffb-9c64-4767-a515-979f4f9d174d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/e8910957-16d2-4ab7-b59e-2b2953b9ccb4/ugc/4e6bf238-300a-416c-9332-21dc929764f3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4e6bf238-300a-416c-9332-21dc929764f3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'e8910957-16d2-4ab7-b59e-2b2953b9ccb4',
        firstName: 'David',
        lastName: 'Ford Jr',
        email: 'yaboilyriq@gmail.com',
        instagramProfile: {
          handle: 'crownmelyriqhill',
          followers: 7493,
          engagementRate: 2.666488722807954,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'lyriqhill',
          followers: 1469,
          engagementRate: 8.426966292134832,
          isPrimary: false
        }
      },
      userCampaignId: 'e8910957-16d2-4ab7-b59e-2b2953b9ccb4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T20:09:13.000Z',
        amountCents: 5361
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-02T19:32:38.260Z',
          createdAt: '2024-02-01T22:42:49.654Z',
          content: [
            {
              id: '17548340-5dcc-4929-8661-defb36465828',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8d0c966-e3e7-48dc-8070-66fc6e64b534/ugc/eb66fa16-927e-4c44-96b6-9aa2d1435239/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f44575d0-b931-4495-aaae-dc5af720ed61',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8d0c966-e3e7-48dc-8070-66fc6e64b534/ugc/eb66fa16-927e-4c44-96b6-9aa2d1435239/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0b471cae-1df0-4b46-94da-1818b967f036',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8d0c966-e3e7-48dc-8070-66fc6e64b534/ugc/eb66fa16-927e-4c44-96b6-9aa2d1435239/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'eb66fa16-927e-4c44-96b6-9aa2d1435239'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T13:55:46.691Z',
          createdAt: '2024-02-02T17:20:42.584Z',
          content: [
            {
              id: 'c6743348-0cb8-4f9c-94d9-518e93afeb10',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8d0c966-e3e7-48dc-8070-66fc6e64b534/ugc/54d72cee-0f21-43fe-83fc-1d53e97aa113/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '54d72cee-0f21-43fe-83fc-1d53e97aa113'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f8d0c966-e3e7-48dc-8070-66fc6e64b534',
        firstName: 'Alicia',
        lastName: 'Richmond',
        email: 'alirichy@yahoo.com',
        instagramProfile: {
          handle: 'thedomestichousecat',
          followers: 105,
          engagementRate: 6.571428571428571,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thedomestichousecat',
          followers: 47,
          engagementRate: 8.715596330275229,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'thedomestichousecat'
        }
      },
      userCampaignId: 'f8d0c966-e3e7-48dc-8070-66fc6e64b534'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T22:26:55.000Z',
        amountCents: 5511
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'fda1f716-5865-4785-ad95-da29c298d16c',
        firstName: 'Parshuram',
        lastName: 'Salunke ',
        email: 'maximuschronicles@gmail.com',
        instagramProfile: {
          handle: 'maximuschronicles',
          followers: 38034,
          engagementRate: 1.8144291949308513,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'maximuschronicles',
          followers: 1,
          engagementRate: 0,
          isPrimary: false
        }
      },
      userCampaignId: 'fda1f716-5865-4785-ad95-da29c298d16c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T20:58:32.000Z',
        amountCents: 5411
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-30T15:24:13.563Z',
          createdAt: '2024-01-30T05:30:21.004Z',
          content: [
            {
              id: '7d4118ef-9d6c-454c-8e77-9d7741d48899',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4de64c99-2a84-4f88-a113-91a2f2035929/ugc/d5766575-d9c8-4fb7-a473-2bd36f943211/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '56c93320-5004-4437-af03-402f65988fbf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4de64c99-2a84-4f88-a113-91a2f2035929/ugc/d5766575-d9c8-4fb7-a473-2bd36f943211/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8340cca1-24eb-4998-b38e-38a560acb718',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4de64c99-2a84-4f88-a113-91a2f2035929/ugc/d5766575-d9c8-4fb7-a473-2bd36f943211/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd5766575-d9c8-4fb7-a473-2bd36f943211'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T15:24:09.998Z',
          createdAt: '2024-01-30T05:29:01.291Z',
          content: [
            {
              id: '2d6be2b3-d968-4820-9dd8-ac95f8ed6aa8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4de64c99-2a84-4f88-a113-91a2f2035929/ugc/0b1323c7-47f9-49ad-b90a-e05b456fbbe5/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0b1323c7-47f9-49ad-b90a-e05b456fbbe5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4de64c99-2a84-4f88-a113-91a2f2035929',
        firstName: 'Selena',
        lastName: 'Navarro',
        email: 'lopezselena455@gmail.com',
        instagramProfile: {
          handle: 'seleshine',
          followers: 1405,
          engagementRate: 1.708185053380783,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'seleshine',
          followers: 4091,
          engagementRate: 19.866189719130894,
          isPrimary: true
        }
      },
      userCampaignId: '4de64c99-2a84-4f88-a113-91a2f2035929'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-26T10:04:03.000Z',
        amountCents: 5330
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T15:52:38.196Z',
          createdAt: '2024-02-02T17:46:47.155Z',
          content: [
            {
              id: '16c8d2e1-cb6f-46e4-8bf8-733a8da91542',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c21561c1-b0c4-4b2a-97aa-4cee1e41234e/ugc/9ffb2039-6d09-4903-a698-e1a478640f09/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '848d2ff1-942d-4b57-9616-8531184152c3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c21561c1-b0c4-4b2a-97aa-4cee1e41234e/ugc/9ffb2039-6d09-4903-a698-e1a478640f09/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '802b7aa4-8d13-49a8-8d2a-64ce4dfef0e3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c21561c1-b0c4-4b2a-97aa-4cee1e41234e/ugc/9ffb2039-6d09-4903-a698-e1a478640f09/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9ffb2039-6d09-4903-a698-e1a478640f09'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T15:52:29.804Z',
          createdAt: '2024-02-02T19:32:32.204Z',
          content: [
            {
              id: '254ed850-12bf-4b16-a3f8-48dacfabb664',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c21561c1-b0c4-4b2a-97aa-4cee1e41234e/ugc/c2eb88fd-db74-4b06-8fd4-d2954759e1d0/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c2eb88fd-db74-4b06-8fd4-d2954759e1d0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c21561c1-b0c4-4b2a-97aa-4cee1e41234e',
        firstName: 'Mariah',
        lastName: 'Deck',
        email: 'wearethedecks@gmail.com',
        instagramProfile: {
          handle: 'we.are.the.decks',
          followers: 3812,
          engagementRate: 2.589192025183631,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'the.decks',
          followers: 4278,
          engagementRate: 26.120857699805068,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'thedecks',
          followers: 20000
        }
      },
      userCampaignId: 'c21561c1-b0c4-4b2a-97aa-4cee1e41234e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T20:10:23.000Z',
        amountCents: 5511
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '849154c2-5dae-443d-a4d1-a6dda9ba1dc8',
        firstName: 'Jesse',
        lastName: 'Hixson',
        email: 'jessemariebusiness@gmail.com',
        instagramProfile: {
          handle: 'jessemariex',
          followers: 15345,
          engagementRate: 0.3629846855653307,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'jessemariex',
          followers: 8580,
          isPrimary: true
        }
      },
      userCampaignId: '849154c2-5dae-443d-a4d1-a6dda9ba1dc8'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-30T18:15:53.000Z',
        amountCents: 5430
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T19:42:45.796Z',
          createdAt: '2024-02-08T02:39:03.507Z',
          content: [
            {
              id: '83a5c088-08d8-4e9f-a68d-543e29d41266',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45d6e850-f818-4cc9-b768-4d3b05a7829b/ugc/b4ef9ea8-b9e5-4a73-91ce-8cb882019b09/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b4ef9ea8-b9e5-4a73-91ce-8cb882019b09'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-20T19:42:56.933Z',
          createdAt: '2024-02-07T11:19:29.616Z',
          content: [
            {
              id: '5b119ec6-8e95-4b2a-b1a2-e820f5f7e653',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45d6e850-f818-4cc9-b768-4d3b05a7829b/ugc/082a47ae-febe-43d4-9479-54ce9fd1aa57/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'ee941bba-0e90-4a6b-9ccb-da2604d4905d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45d6e850-f818-4cc9-b768-4d3b05a7829b/ugc/082a47ae-febe-43d4-9479-54ce9fd1aa57/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '29795b1d-6dd8-4f09-93c7-807706c9590b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/45d6e850-f818-4cc9-b768-4d3b05a7829b/ugc/082a47ae-febe-43d4-9479-54ce9fd1aa57/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '082a47ae-febe-43d4-9479-54ce9fd1aa57'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '45d6e850-f818-4cc9-b768-4d3b05a7829b',
        firstName: 'Miguel',
        lastName: 'Pagan',
        email: 'wordsbymiguel@gmail.com',
        instagramProfile: {
          handle: 'wordsbymiguel',
          followers: 15104,
          engagementRate: 1.175185381355932,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'wordsbymiguel',
          followers: 27094,
          engagementRate: 9.157780502615362,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'miguelandyvonne'
        }
      },
      userCampaignId: '45d6e850-f818-4cc9-b768-4d3b05a7829b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-13T16:22:11.000Z',
        amountCents: 6524
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-21T22:07:29.089Z',
          createdAt: '2024-01-20T22:12:47.405Z',
          content: [
            {
              id: 'b0a316de-1a39-475d-958f-b21ffd121d53',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0633629b-47c2-4d06-9468-286ee6813f95/ugc/8b41d7c6-34fb-45df-95ae-3b3c8d266b76/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '8b41d7c6-34fb-45df-95ae-3b3c8d266b76'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-21T22:07:36.272Z',
          createdAt: '2024-01-20T22:17:58.883Z',
          content: [
            {
              id: '32751e67-f0ef-4b17-a4c2-3f17cad8cd7f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0633629b-47c2-4d06-9468-286ee6813f95/ugc/ff3bb038-8fbc-430d-afeb-b752a2aab61c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c8be8bd0-b284-4f71-af2f-8bdcf1f9bca7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0633629b-47c2-4d06-9468-286ee6813f95/ugc/ff3bb038-8fbc-430d-afeb-b752a2aab61c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'de081841-60ed-4cb0-99c3-1e132ca345f5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0633629b-47c2-4d06-9468-286ee6813f95/ugc/ff3bb038-8fbc-430d-afeb-b752a2aab61c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ff3bb038-8fbc-430d-afeb-b752a2aab61c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0633629b-47c2-4d06-9468-286ee6813f95',
        firstName: 'Manuel',
        lastName: 'Viramontes',
        email: 'viramontes.manuel@gmail.com',
        instagramProfile: {
          handle: 'figurekingdom',
          followers: 32931,
          engagementRate: 1.9902219792900306,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'figurekingdom',
          followers: 1252,
          engagementRate: 8.909863142991977,
          isPrimary: true
        }
      },
      userCampaignId: '0633629b-47c2-4d06-9468-286ee6813f95'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T21:43:26.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-29T18:07:32.936Z',
          createdAt: '2024-01-27T02:10:15.589Z',
          content: [
            {
              id: '59b11a31-c71f-4f33-a7cf-6280e4203b47',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5436eb65-b6f8-4cf3-8174-e456f0ebc20a/ugc/51ba0110-4d42-4f4d-a9c0-803d2a5b7cf0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a814eb2e-7d87-40e1-869b-7eb7beeea28c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5436eb65-b6f8-4cf3-8174-e456f0ebc20a/ugc/51ba0110-4d42-4f4d-a9c0-803d2a5b7cf0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f2a62352-8934-4234-b2b0-c66981c7e9d2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5436eb65-b6f8-4cf3-8174-e456f0ebc20a/ugc/51ba0110-4d42-4f4d-a9c0-803d2a5b7cf0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '51ba0110-4d42-4f4d-a9c0-803d2a5b7cf0'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-29T18:07:29.026Z',
          createdAt: '2024-01-27T02:09:44.201Z',
          content: [
            {
              id: '6f132851-e6f4-4785-8bf8-5b149fec8729',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/5436eb65-b6f8-4cf3-8174-e456f0ebc20a/ugc/99cb50f8-4e9d-4fc1-ab40-a4bb07d38f53/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '99cb50f8-4e9d-4fc1-ab40-a4bb07d38f53'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '5436eb65-b6f8-4cf3-8174-e456f0ebc20a',
        firstName: 'Shelby',
        lastName: 'Henritzy',
        email: 'omhcreations@gmail.com',
        tiktokProfile: {
          handle: 'lowensteindesigns',
          followers: 5568,
          engagementRate: 6.727207922019557,
          isPrimary: true
        }
      },
      userCampaignId: '5436eb65-b6f8-4cf3-8174-e456f0ebc20a'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-05T17:34:48.000Z',
        amountCents: 6434
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5a583815-9b56-4c44-8c2b-6420d1233770',
        firstName: 'Ash',
        lastName: 'Tyn',
        email: 'contactash12tyn@gmail.com',
        instagramProfile: {
          handle: 'ash12tyn',
          followers: 8359,
          engagementRate: 3.49563344897715,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'ashtyynnn',
          followers: 367,
          engagementRate: 8.68596881959911,
          isPrimary: false
        }
      },
      userCampaignId: '5a583815-9b56-4c44-8c2b-6420d1233770'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T00:31:58.000Z',
        amountCents: 6434
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-21T04:46:39.157Z',
          createdAt: '2024-02-17T03:08:39.265Z',
          content: [
            {
              id: '446ca0a4-e166-43fb-8c6c-4c87dea48be1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3b5b908b-a0cc-455a-8682-577fa0ddc777/ugc/6fdb76de-1388-4eb6-b778-72d42decc9db/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '09824592-b82b-4bc5-80d1-efcc10301e57',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3b5b908b-a0cc-455a-8682-577fa0ddc777/ugc/6fdb76de-1388-4eb6-b778-72d42decc9db/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5827ae1c-e13a-4d92-a6d1-e52c97d4f42b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3b5b908b-a0cc-455a-8682-577fa0ddc777/ugc/6fdb76de-1388-4eb6-b778-72d42decc9db/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6fdb76de-1388-4eb6-b778-72d42decc9db'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-21T04:46:44.773Z',
          createdAt: '2024-02-20T04:14:25.635Z',
          content: [
            {
              id: 'cbb5d931-f357-40b5-8882-a03fcc361c78',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/3b5b908b-a0cc-455a-8682-577fa0ddc777/ugc/aabb8cb0-087e-41a3-925a-5a8d3fdb7270/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'aabb8cb0-087e-41a3-925a-5a8d3fdb7270'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '3b5b908b-a0cc-455a-8682-577fa0ddc777',
        firstName: 'Porshe',
        lastName: 'Minor',
        email: 'porshepower@aol.com',
        instagramProfile: {
          handle: 'dr_porshe911',
          followers: 10032,
          engagementRate: 0.5771531100478469,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'dr_porshe911',
          followers: 221,
          engagementRate: 1.954732510288066,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'porshesperspective',
          followers: 120
        }
      },
      userCampaignId: '3b5b908b-a0cc-455a-8682-577fa0ddc777'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T19:05:09.000Z',
        amountCents: 5311
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T14:42:13.449Z',
          createdAt: '2024-02-03T01:56:45.715Z',
          content: [
            {
              id: 'ef4a6d28-03c1-4ade-8b28-7062dcad3dd3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c33da0c8-02bd-4253-acd4-a0b3dee1b125/ugc/18d2dcc2-7b50-4303-8dbd-779bd86fc60b/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '56e354f4-da56-4789-8fd5-2b75b0893d36',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c33da0c8-02bd-4253-acd4-a0b3dee1b125/ugc/18d2dcc2-7b50-4303-8dbd-779bd86fc60b/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b0e1bc6e-cda2-4ab2-82c9-79a8f07b97a0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c33da0c8-02bd-4253-acd4-a0b3dee1b125/ugc/18d2dcc2-7b50-4303-8dbd-779bd86fc60b/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '18d2dcc2-7b50-4303-8dbd-779bd86fc60b'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T14:42:09.649Z',
          createdAt: '2024-02-03T02:04:43.588Z',
          content: [
            {
              id: '1e687864-3f9f-40f6-9b47-e8cac846ad54',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c33da0c8-02bd-4253-acd4-a0b3dee1b125/ugc/e502a256-3351-448c-b6e7-b8a101a6b5c6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e502a256-3351-448c-b6e7-b8a101a6b5c6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c33da0c8-02bd-4253-acd4-a0b3dee1b125',
        firstName: 'Jillian',
        lastName: 'Kaplan',
        email: 'fitresq@gmail.com',
        instagramProfile: {
          handle: 'therealjkaplan',
          followers: 18606,
          engagementRate: 0.22358379017521232,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'therealjkaplan',
          followers: 4691,
          engagementRate: 7.6158940397351,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'jilliankaplanfitresq'
        }
      },
      userCampaignId: 'c33da0c8-02bd-4253-acd4-a0b3dee1b125'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T21:05:11.000Z',
        amountCents: 5274
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-09T02:30:48.421Z',
          createdAt: '2024-02-08T03:59:27.143Z',
          content: [
            {
              id: '8913f4db-bb8d-4916-8f3f-5e5fa5af5b92',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d228c66b-f679-4275-bb32-ca0110e210cc/ugc/546a7466-9b30-47aa-8192-7123017b44a7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '546a7466-9b30-47aa-8192-7123017b44a7'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-09T02:30:56.458Z',
          createdAt: '2024-02-08T04:00:50.014Z',
          content: [
            {
              id: '1439ddd9-f5b7-4361-9429-1dbe5d152166',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d228c66b-f679-4275-bb32-ca0110e210cc/ugc/6de84209-fb80-4346-8a90-9d580fad28f8/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '412f588f-5808-4d61-b126-ad669eb33e8d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d228c66b-f679-4275-bb32-ca0110e210cc/ugc/6de84209-fb80-4346-8a90-9d580fad28f8/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bdcf0167-5c9e-47df-abc8-5b7d5a3e8aee',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d228c66b-f679-4275-bb32-ca0110e210cc/ugc/6de84209-fb80-4346-8a90-9d580fad28f8/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6de84209-fb80-4346-8a90-9d580fad28f8'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd228c66b-f679-4275-bb32-ca0110e210cc',
        firstName: 'Lily',
        lastName: 'Whitten',
        email: 'lilywhitten1@gmail.com',
        instagramProfile: {
          handle: 'lilyywhitten',
          followers: 12261,
          engagementRate: 0.01753311258278146,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'lilyywhitten',
          followers: 10453,
          isPrimary: true
        }
      },
      userCampaignId: 'd228c66b-f679-4275-bb32-ca0110e210cc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-30T19:12:11.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-08T18:20:46.626Z',
          createdAt: '2024-02-07T22:43:26.919Z',
          content: [
            {
              id: '5fa921f8-60f6-4d49-a2f2-c13e2b30f5ff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a86f20cc-8b66-40b1-919e-a2faa345ffd0/ugc/6cd33cef-605d-487c-86d6-51c5fdc1ea59/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '77efb916-6fc8-45db-b963-58760c1408b7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a86f20cc-8b66-40b1-919e-a2faa345ffd0/ugc/6cd33cef-605d-487c-86d6-51c5fdc1ea59/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f468ce52-f120-4e5c-b543-2d322a7de0ec',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a86f20cc-8b66-40b1-919e-a2faa345ffd0/ugc/6cd33cef-605d-487c-86d6-51c5fdc1ea59/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6cd33cef-605d-487c-86d6-51c5fdc1ea59'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-08T18:20:46.546Z',
          createdAt: '2024-02-07T22:42:59.242Z',
          content: [
            {
              id: 'cbd9bda4-db99-49ad-8091-4748b303df2a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a86f20cc-8b66-40b1-919e-a2faa345ffd0/ugc/2faa8d2f-d586-4afe-802e-ce60b77febc3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2faa8d2f-d586-4afe-802e-ce60b77febc3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a86f20cc-8b66-40b1-919e-a2faa345ffd0',
        firstName: 'Arabella',
        lastName: 'Malie',
        email: 'arabelllzrm@aol.com',
        instagramProfile: {
          handle: 'arabellamalie',
          followers: 2646,
          engagementRate: 11.181297915991793,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'arabellamalie',
          followers: 1422,
          engagementRate: 10.437409063554577,
          isPrimary: true
        }
      },
      userCampaignId: 'a86f20cc-8b66-40b1-919e-a2faa345ffd0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T04:06:43.000Z',
        amountCents: 5448
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '0c1e7c81-bda9-4618-80d4-b9c3f9f21ecb',
        firstName: 'Dan',
        lastName: 'Bailey',
        email: 'dbailey754@icloud.com',
        instagramProfile: {
          handle: 'dan_beardly',
          followers: 6509,
          engagementRate: 1.063143339990782,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'dan_beardly',
          followers: 1223,
          engagementRate: 3.6363172399714196,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'dbailey754'
        }
      },
      userCampaignId: '0c1e7c81-bda9-4618-80d4-b9c3f9f21ecb'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T17:52:30.000Z',
        amountCents: 6494
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '6b331e75-d388-4e54-9fd5-6d2ce6d485e6',
        firstName: 'Colin',
        lastName: 'Nelson',
        email: 'colinnelson5@gmail.com',
        instagramProfile: {
          handle: 'adventuresofcnelly',
          followers: 9848,
          engagementRate: 0.4427294882209586,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'adventuresofcnelly',
          followers: 77,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'cnellytx',
          followers: 3
        }
      },
      userCampaignId: '6b331e75-d388-4e54-9fd5-6d2ce6d485e6'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T22:34:05.000Z',
        amountCents: 5418
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-13T01:53:21.834Z',
          createdAt: '2024-02-10T19:51:57.470Z',
          content: [
            {
              id: '80755434-6237-41c8-8d10-ce91f267f77b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/500e4be6-7821-4f28-b27f-5dc226f815b1/ugc/ee86b9da-2e3a-44fa-a031-bcbf92017088/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'ee86b9da-2e3a-44fa-a031-bcbf92017088'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T01:53:14.912Z',
          createdAt: '2024-02-10T19:19:53.085Z',
          content: [
            {
              id: '5f9bda0c-bb4f-4fa5-9267-846c9fc85846',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/500e4be6-7821-4f28-b27f-5dc226f815b1/ugc/45fc4346-812d-46a6-be3d-2d7ef90847ee/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f5ab0487-2fe8-4d60-8022-aea6cf848584',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/500e4be6-7821-4f28-b27f-5dc226f815b1/ugc/45fc4346-812d-46a6-be3d-2d7ef90847ee/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '10e7e4e7-aa05-4fc6-bf02-48ca1616594b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/500e4be6-7821-4f28-b27f-5dc226f815b1/ugc/45fc4346-812d-46a6-be3d-2d7ef90847ee/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '45fc4346-812d-46a6-be3d-2d7ef90847ee'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '500e4be6-7821-4f28-b27f-5dc226f815b1',
        firstName: 'Sacha',
        lastName: 'Husein',
        email: 'kwgbusiness1@gmail.com',
        tiktokProfile: {
          handle: 'stxirry_vibes',
          followers: 105235,
          engagementRate: 3.6590808465421056,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'kidwithglasses',
          followers: 1470
        }
      },
      userCampaignId: '500e4be6-7821-4f28-b27f-5dc226f815b1'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-30T01:56:32.000Z',
        amountCents: 5443
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T18:35:13.388Z',
          createdAt: '2024-02-09T22:28:18.216Z',
          content: [
            {
              id: 'e95008c3-dd63-4ac7-b698-c485949eeb7d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f267b5f7-dc7a-4e52-a5a3-566af843a95a/ugc/32c1bb07-47f9-4434-a33e-272ff16c5df1/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b86e0801-7e8d-4425-9d3c-92c32aa4c536',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f267b5f7-dc7a-4e52-a5a3-566af843a95a/ugc/32c1bb07-47f9-4434-a33e-272ff16c5df1/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3a0ebd6a-0ed6-4a8c-8351-5a0fe64f714e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f267b5f7-dc7a-4e52-a5a3-566af843a95a/ugc/32c1bb07-47f9-4434-a33e-272ff16c5df1/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '32c1bb07-47f9-4434-a33e-272ff16c5df1'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-13T18:35:07.798Z',
          createdAt: '2024-02-09T22:24:34.926Z',
          content: [
            {
              id: '927a7782-e1d1-4559-b20b-68d336fd16fc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f267b5f7-dc7a-4e52-a5a3-566af843a95a/ugc/dc593c2e-92ff-4cc0-ab1a-d6452b4e7658/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'dc593c2e-92ff-4cc0-ab1a-d6452b4e7658'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f267b5f7-dc7a-4e52-a5a3-566af843a95a',
        firstName: 'Jason',
        lastName: 'Vargas',
        email: 'certifiedcoqui@gmail.com',
        instagramProfile: {
          handle: 'coquilivee',
          followers: 737,
          engagementRate: 1.0447761194029852,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'coquilivee',
          followers: 270,
          engagementRate: 6.826523923967665,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'coquilivee'
        }
      },
      userCampaignId: 'f267b5f7-dc7a-4e52-a5a3-566af843a95a'
    },
    {
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '92a9e35b-0203-4ea5-a84a-9280aa944da4',
        firstName: 'Megan',
        lastName: 'Gomersall',
        email: 'meganreneaaa@hotmail.com',
        instagramProfile: {
          handle: 'reviewswithme_',
          followers: 604,
          engagementRate: 5.4801324503311255,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'meganishername',
          followers: 111,
          engagementRate: 2.283539486203616,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'megangomersall4963',
          followers: 0
        }
      },
      userCampaignId: '92a9e35b-0203-4ea5-a84a-9280aa944da4'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T03:05:27.000Z',
        amountCents: 5211
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'c3057daa-e671-490c-b9bb-6cfb33302b13',
        firstName: 'Kylie',
        lastName: 'Brown-Talamantez',
        email: 'kylie@talamantezs.com',
        instagramProfile: {
          handle: 'kylie.talamantez',
          followers: 166575,
          engagementRate: 1.8418129971484314,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'crimewithkylie',
          followers: 162797,
          engagementRate: 4.705962540933536,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'kylietalamantez'
        }
      },
      userCampaignId: 'c3057daa-e671-490c-b9bb-6cfb33302b13'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T23:23:50.000Z',
        amountCents: 5330
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'bf5b5a7b-0c2e-41e8-99cc-ace140ac0d06',
        firstName: 'Mlamuli',
        lastName: 'Mnyandu',
        email: 'gedezam@icloud.com',
        instagramProfile: {
          handle: 'mlam_s',
          followers: 29946,
          engagementRate: 0.6915781740466173,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'leemlamz',
          followers: 2564,
          engagementRate: 11.954022988505747,
          isPrimary: true
        }
      },
      userCampaignId: 'bf5b5a7b-0c2e-41e8-99cc-ace140ac0d06'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T23:43:58.000Z',
        amountCents: 6528
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ee269a8b-d44b-4086-96ee-c9c5a8ef9976',
        firstName: 'Deiby',
        lastName: 'Magana',
        email: 'deibymagana.collab@yahoo.com',
        instagramProfile: {
          handle: 'deibyxo',
          followers: 453,
          engagementRate: 1.8763796909492272,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'deibysdailyvlogs',
          followers: 4121,
          engagementRate: 15.882811705266015,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'vlogswdeiby'
        }
      },
      userCampaignId: 'ee269a8b-d44b-4086-96ee-c9c5a8ef9976'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-26T13:54:04.000Z',
        amountCents: 6097
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T14:58:10.980Z',
          createdAt: '2024-02-06T23:14:23.176Z',
          content: [
            {
              id: '12650c34-7d96-4574-be3e-a56c2db9dc83',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/39110561-3c05-4e4e-831d-99895ea93472/ugc/4d8436d0-3d70-47d3-a10c-b5943671801e/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4d8436d0-3d70-47d3-a10c-b5943671801e'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-07T13:53:40.209Z',
          createdAt: '2024-02-06T23:15:17.054Z',
          content: [
            {
              id: '05f27e3a-5a98-4641-a22b-cd8227f7da95',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/39110561-3c05-4e4e-831d-99895ea93472/ugc/4ff6b3a9-2d90-47c3-9a98-df7d42caa454/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd381c247-1a82-4e41-8434-3ce9db51c047',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/39110561-3c05-4e4e-831d-99895ea93472/ugc/4ff6b3a9-2d90-47c3-9a98-df7d42caa454/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f18001e9-aa1b-451a-ad9b-22d437863ec7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/39110561-3c05-4e4e-831d-99895ea93472/ugc/4ff6b3a9-2d90-47c3-9a98-df7d42caa454/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4ff6b3a9-2d90-47c3-9a98-df7d42caa454'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '39110561-3c05-4e4e-831d-99895ea93472',
        firstName: 'Kai',
        lastName: 'Tejeda',
        email: 'kaienergyyugc@gmail.com',
        instagramProfile: {
          handle: 'kaienergyy',
          followers: 470,
          engagementRate: 1.468085106382979,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kaienergyyfinds',
          followers: 15814,
          engagementRate: 1.2491061157230359,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'kaienergyy',
          followers: 121
        }
      },
      userCampaignId: '39110561-3c05-4e4e-831d-99895ea93472'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-13T17:14:44.000Z',
        amountCents: 7467
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-24T07:45:04.320Z',
          createdAt: '2024-01-24T03:16:02.699Z',
          content: [
            {
              id: '5ae9f24f-01d0-46f7-b39d-80ca41e60fa8',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5c4d8d6-068b-4c7a-800f-48d46fa8350d/ugc/4b4ad1c1-e1c2-4b3d-b058-93664d9ac36f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4b4ad1c1-e1c2-4b3d-b058-93664d9ac36f'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-26T17:05:24.371Z',
          createdAt: '2024-01-24T04:38:29.217Z',
          content: [
            {
              id: 'f2f04af6-1e3c-4e98-971b-93522269c4d5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5c4d8d6-068b-4c7a-800f-48d46fa8350d/ugc/b4efc507-8979-4f00-ad9b-a715a1c49099/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4223ced0-3a48-4391-a470-775c5ef22a0b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5c4d8d6-068b-4c7a-800f-48d46fa8350d/ugc/b4efc507-8979-4f00-ad9b-a715a1c49099/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e3bacd29-daff-4311-9695-649426d26074',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/c5c4d8d6-068b-4c7a-800f-48d46fa8350d/ugc/b4efc507-8979-4f00-ad9b-a715a1c49099/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b4efc507-8979-4f00-ad9b-a715a1c49099'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'c5c4d8d6-068b-4c7a-800f-48d46fa8350d',
        firstName: 'Grant',
        lastName: 'Hazzard',
        email: 'gmhazzar07@icloud.com',
        instagramProfile: {
          handle: 'gorudenheizu_',
          followers: 1459,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'gorudenheizu',
          followers: 5093,
          engagementRate: 2.9576371583806527,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'gorudenheizu',
          followers: 109
        }
      },
      userCampaignId: 'c5c4d8d6-068b-4c7a-800f-48d46fa8350d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T10:13:58.000Z',
        amountCents: 6554
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'de199666-049b-43a2-8aac-3d682cb61d9e',
        firstName: 'Noah',
        lastName: 'Cook',
        email: 'noahjvmes@gmail.com',
        instagramProfile: {
          handle: 'noahjvmes',
          followers: 96329,
          engagementRate: 1.0038513843183259,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'noahjvmes',
          followers: 174484,
          engagementRate: 3.3358143311455444,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'noahjvmes',
          followers: 33600
        }
      },
      userCampaignId: 'de199666-049b-43a2-8aac-3d682cb61d9e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T19:42:32.000Z',
        amountCents: 6404
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-14T21:58:51.787Z',
          createdAt: '2024-02-13T22:11:14.115Z',
          content: [
            {
              id: '713f0933-701a-4209-9280-0d4866ef53ce',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7065194e-b37f-42d5-b577-346b0b1f8173/ugc/bca93c77-5059-4af2-9316-ee41e446d9a7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bca93c77-5059-4af2-9316-ee41e446d9a7'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T16:08:06.351Z',
          createdAt: '2024-02-09T22:15:17.768Z',
          content: [
            {
              id: 'c7940ae0-71b4-4dec-b041-cafd2f274efc',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7065194e-b37f-42d5-b577-346b0b1f8173/ugc/878d796b-30bb-4265-a4f1-5cec1160ae93/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '92d2b617-76e8-4308-8a42-9620bbc820fe',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7065194e-b37f-42d5-b577-346b0b1f8173/ugc/878d796b-30bb-4265-a4f1-5cec1160ae93/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f507332b-5167-4a66-92b4-e2b61f39ad7f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/7065194e-b37f-42d5-b577-346b0b1f8173/ugc/878d796b-30bb-4265-a4f1-5cec1160ae93/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '878d796b-30bb-4265-a4f1-5cec1160ae93'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '7065194e-b37f-42d5-b577-346b0b1f8173',
        firstName: 'Joshua',
        lastName: 'Lively',
        email: 'livelyworshipofficial@gmail.com',
        instagramProfile: {
          handle: 'livelyworship',
          followers: 1520,
          engagementRate: 1.8815789473684212,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'livelyworship',
          followers: 123,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'livelyworship',
          followers: 213
        }
      },
      userCampaignId: '7065194e-b37f-42d5-b577-346b0b1f8173'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-07T13:35:21.000Z',
        amountCents: 6359
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-16T11:25:27.470Z',
          createdAt: '2024-02-15T22:57:58.226Z',
          content: [
            {
              id: '9d5f43e4-0082-4bf7-b55e-23446b27794c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99ee909b-ca74-4bc6-944e-00593e9ae39b/ugc/022af4e7-bbc8-4fcd-b171-4109122d4fb7/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '022af4e7-bbc8-4fcd-b171-4109122d4fb7'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-16T11:25:38.334Z',
          createdAt: '2024-02-15T23:03:22.353Z',
          content: [
            {
              id: '336c8f3d-e22f-4972-b269-b59123cc6875',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99ee909b-ca74-4bc6-944e-00593e9ae39b/ugc/5acf06a2-7841-4dd2-a76e-3c0f568c945c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8a4c17ea-ae36-49d7-823b-30161b62712a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99ee909b-ca74-4bc6-944e-00593e9ae39b/ugc/5acf06a2-7841-4dd2-a76e-3c0f568c945c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '4ffd61d3-d9e8-4206-847e-500ba5a5a896',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/99ee909b-ca74-4bc6-944e-00593e9ae39b/ugc/5acf06a2-7841-4dd2-a76e-3c0f568c945c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5acf06a2-7841-4dd2-a76e-3c0f568c945c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '99ee909b-ca74-4bc6-944e-00593e9ae39b',
        firstName: 'Diana',
        lastName: 'Carrera',
        email: 'dianacarocarrera@yahoo.com',
        instagramProfile: {
          handle: 'dianacarocarrera_',
          followers: 11721,
          engagementRate: 3.6711884651480253,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'd.modestmama',
          followers: 1420,
          engagementRate: 4.979253112033195,
          isPrimary: false
        }
      },
      userCampaignId: '99ee909b-ca74-4bc6-944e-00593e9ae39b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-09T16:50:14.000Z',
        amountCents: 5987
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T18:10:56.918Z',
          createdAt: '2024-02-13T03:58:03.216Z',
          content: [
            {
              id: '1f269763-4fab-4942-9767-651b0ed96ef9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4cf3bc76-7605-46ec-a573-ce7214181d12/ugc/b8320012-7f79-40b2-bc19-b10ebfb3305c/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd1fac16e-7197-42ba-a225-4cd9b1915bbf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4cf3bc76-7605-46ec-a573-ce7214181d12/ugc/b8320012-7f79-40b2-bc19-b10ebfb3305c/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c3d6bee6-c2b0-4c84-ab67-d2d5c09578f1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4cf3bc76-7605-46ec-a573-ce7214181d12/ugc/b8320012-7f79-40b2-bc19-b10ebfb3305c/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b8320012-7f79-40b2-bc19-b10ebfb3305c'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-18T20:32:47.446Z',
          createdAt: '2024-02-17T04:59:31.121Z',
          content: [
            {
              id: '166b5327-19c2-455d-89ea-af7f375ddde7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/4cf3bc76-7605-46ec-a573-ce7214181d12/ugc/b8835c70-b9d5-43ae-a262-4caeabf9d39d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b8835c70-b9d5-43ae-a262-4caeabf9d39d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '4cf3bc76-7605-46ec-a573-ce7214181d12',
        firstName: 'Priscilla',
        lastName: 'P',
        email: 'sincerelypriscillaxo@gmail.com',
        tiktokProfile: {
          handle: 'sincerely.priscilla',
          followers: 6697,
          engagementRate: 14.50079239302694,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'sincerely.priscilla',
          followers: 27
        }
      },
      userCampaignId: '4cf3bc76-7605-46ec-a573-ce7214181d12'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T05:15:24.000Z',
        amountCents: 6040
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'cadd6c5f-ea0f-435c-ae26-749bff6f78d9',
        firstName: 'Marco',
        lastName: 'Washington',
        email: 'marcojw04@gmail.com',
        instagramProfile: {
          handle: 'marcoo4k',
          followers: 3934,
          engagementRate: 8.540925266903916,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'marcojw',
          followers: 442036,
          engagementRate: 3.4000000000000004,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'marcoswrld7315',
          followers: 212
        }
      },
      userCampaignId: 'cadd6c5f-ea0f-435c-ae26-749bff6f78d9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T21:45:49.000Z',
        amountCents: 6399
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-29T21:01:13.808Z',
          createdAt: '2024-01-24T19:25:05.540Z',
          content: [
            {
              id: '31d8c3b4-f4cb-4994-b380-c1f81e9399c6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bfeaafc9-524d-4ac2-afdf-c5df0873dd1e/ugc/4967584d-8334-4212-ab8b-f994ad51b719/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '19df679b-4d51-4f1d-bd9f-6913c45c2662',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bfeaafc9-524d-4ac2-afdf-c5df0873dd1e/ugc/4967584d-8334-4212-ab8b-f994ad51b719/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f6b930b5-38a2-4f6a-95fa-e6df1770d233',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bfeaafc9-524d-4ac2-afdf-c5df0873dd1e/ugc/4967584d-8334-4212-ab8b-f994ad51b719/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4967584d-8334-4212-ab8b-f994ad51b719'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-29T21:01:08.373Z',
          createdAt: '2024-01-25T18:16:14.508Z',
          content: [
            {
              id: '3ec55c2a-c8f8-44ef-a73e-371edba78ca7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/bfeaafc9-524d-4ac2-afdf-c5df0873dd1e/ugc/a1da7d24-1b13-4d51-a85e-18ed6124e526/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a1da7d24-1b13-4d51-a85e-18ed6124e526'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'bfeaafc9-524d-4ac2-afdf-c5df0873dd1e',
        firstName: 'Ashley',
        lastName: 'Mileur',
        email: 'ashley.mileur@gmail.com',
        tiktokProfile: {
          handle: 'ashleymileur2',
          followers: 1019,
          engagementRate: 4.430379746835443,
          isPrimary: true
        }
      },
      userCampaignId: 'bfeaafc9-524d-4ac2-afdf-c5df0873dd1e'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T17:53:01.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: 'ea1a6f5f-4c10-410a-9322-632fa0d399c9',
        firstName: 'Jessica',
        lastName: 'Guerra',
        email: 'ugc.jessicaguerra@gmail.com',
        instagramProfile: {
          handle: 'alebasi_g7',
          followers: 10509,
          engagementRate: 0.439623180131316,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'alebasig',
          followers: 1881,
          engagementRate: 3.0733206838179212,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'jessg7',
          followers: 9
        }
      },
      userCampaignId: 'ea1a6f5f-4c10-410a-9322-632fa0d399c9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T06:13:17.000Z',
        amountCents: 6223
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-13T18:20:19.787Z',
          createdAt: '2024-01-30T16:45:40.175Z',
          content: [
            {
              id: '4b268f98-b8db-4543-8d20-734592032c79',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca164445-3706-4692-a88f-421de2bf29d9/ugc/be2007ab-3d1b-470c-a617-f77fde388df3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'a2680606-4b9c-4723-a394-4525f40fe69e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca164445-3706-4692-a88f-421de2bf29d9/ugc/be2007ab-3d1b-470c-a617-f77fde388df3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '34c2bf73-5ee5-449b-b117-46ddce18d15d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/ca164445-3706-4692-a88f-421de2bf29d9/ugc/be2007ab-3d1b-470c-a617-f77fde388df3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'be2007ab-3d1b-470c-a617-f77fde388df3'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'ca164445-3706-4692-a88f-421de2bf29d9',
        firstName: 'Kayla',
        lastName: 'Cywinski',
        email: 'kaylacywinski@yahoo.com',
        instagramProfile: {
          handle: 'kaysconsin',
          followers: 1282,
          engagementRate: 5.538221528861154,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kaykaycywin',
          followers: 65,
          isPrimary: true
        }
      },
      userCampaignId: 'ca164445-3706-4692-a88f-421de2bf29d9'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-03T03:58:40.000Z',
        amountCents: 5474
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-09T17:19:49.379Z',
          createdAt: '2024-02-06T17:28:58.721Z',
          content: [
            {
              id: '59079ceb-bb48-451e-ad3d-6d6b70e74164',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/95caee35-1c09-4b29-a31b-af6c3d322c99/ugc/4d1533fb-0153-4e32-8900-00dd4ccae2b4/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '4d1533fb-0153-4e32-8900-00dd4ccae2b4'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-09T17:19:54.726Z',
          createdAt: '2024-02-06T17:26:03.095Z',
          content: [
            {
              id: '36e15561-bd42-4559-b509-a49f2c3c63ce',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/95caee35-1c09-4b29-a31b-af6c3d322c99/ugc/0d54e9f8-12ca-483f-aefa-1dff95dd792a/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1caf8a3d-c511-4e77-8f6e-597e83a15b75',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/95caee35-1c09-4b29-a31b-af6c3d322c99/ugc/0d54e9f8-12ca-483f-aefa-1dff95dd792a/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3e08c839-b3a6-4980-a35f-019cc225ed17',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/95caee35-1c09-4b29-a31b-af6c3d322c99/ugc/0d54e9f8-12ca-483f-aefa-1dff95dd792a/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '0d54e9f8-12ca-483f-aefa-1dff95dd792a'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '95caee35-1c09-4b29-a31b-af6c3d322c99',
        firstName: 'Elisa ',
        lastName: 'Yi',
        email: 'enlightenedelisa1@gmail.com',
        instagramProfile: {
          handle: 'enlightened.elisa',
          followers: 34276,
          engagementRate: 1.3936865445209474,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'enlightenedelisa',
          followers: 4410,
          isPrimary: false
        }
      },
      userCampaignId: '95caee35-1c09-4b29-a31b-af6c3d322c99'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-20T18:36:31.000Z',
        amountCents: 7217
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-30T02:26:38.350Z',
          createdAt: '2024-01-28T23:09:22.169Z',
          content: [
            {
              id: '329d8e0e-65f7-44a1-9b66-6a80b2762f18',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43a176-dc80-4d10-874b-af46b6010af0/ugc/87f9cc1c-a3d0-46ab-a91f-3ce619a9af22/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '818466a2-e557-42e0-b3eb-282674152209',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43a176-dc80-4d10-874b-af46b6010af0/ugc/87f9cc1c-a3d0-46ab-a91f-3ce619a9af22/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7c74e3a9-d3b0-461e-bb8e-df030ab341a0',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43a176-dc80-4d10-874b-af46b6010af0/ugc/87f9cc1c-a3d0-46ab-a91f-3ce619a9af22/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '87f9cc1c-a3d0-46ab-a91f-3ce619a9af22'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T02:26:29.930Z',
          createdAt: '2024-01-28T23:23:20.637Z',
          content: [
            {
              id: '2626c7c4-e027-4646-9330-bbaa397713af',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/0f43a176-dc80-4d10-874b-af46b6010af0/ugc/dbbf4bf3-a5e7-4d98-82c8-be706ea6bd9c/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'dbbf4bf3-a5e7-4d98-82c8-be706ea6bd9c'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '0f43a176-dc80-4d10-874b-af46b6010af0',
        firstName: 'Cindy',
        lastName: 'Phan',
        email: 'hellosimplycindy@gmail.com',
        instagramProfile: {
          handle: 'simplycindy',
          followers: 4529,
          engagementRate: 1.9827776551115037,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'cindysimply',
          followers: 5687,
          engagementRate: 5.198564478859237,
          isPrimary: true
        }
      },
      userCampaignId: '0f43a176-dc80-4d10-874b-af46b6010af0'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-29T16:37:23.000Z',
        amountCents: 5264
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T08:57:39.430Z',
          createdAt: '2024-02-11T19:46:32.259Z',
          content: [
            {
              id: 'da77b310-6a52-4014-aab6-7f44db5d5847',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b754c60d-4464-4543-8747-8a5e63d322f5/ugc/46d0ce49-e5c2-433d-bf8b-7aa90e0f3ac9/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '15e6dc67-f50f-429e-9335-d961ad1c5963',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b754c60d-4464-4543-8747-8a5e63d322f5/ugc/46d0ce49-e5c2-433d-bf8b-7aa90e0f3ac9/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '0a2b8fe0-f935-4427-beae-3c900066c43d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b754c60d-4464-4543-8747-8a5e63d322f5/ugc/46d0ce49-e5c2-433d-bf8b-7aa90e0f3ac9/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '46d0ce49-e5c2-433d-bf8b-7aa90e0f3ac9'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T17:07:21.653Z',
          createdAt: '2024-02-12T04:41:11.902Z',
          content: [
            {
              id: '36001c00-85b0-4ed1-b096-31898961fe5a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b754c60d-4464-4543-8747-8a5e63d322f5/ugc/49458bcf-cab0-4cd1-bea6-fd474449feb6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '49458bcf-cab0-4cd1-bea6-fd474449feb6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b754c60d-4464-4543-8747-8a5e63d322f5',
        firstName: 'Chelsie',
        lastName: 'Cahoon',
        email: 'chelsiecahoon@gmail.com',
        instagramProfile: {
          handle: 'thesoberraver',
          followers: 24380,
          engagementRate: 1.1345365053322396,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'thesoberraver',
          followers: 61365,
          engagementRate: 8.248548881497424,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'chelsiecahoon',
          followers: 115
        }
      },
      userCampaignId: 'b754c60d-4464-4543-8747-8a5e63d322f5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T16:11:42.000Z',
        amountCents: 5324
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T11:41:04.709Z',
          createdAt: '2024-02-11T22:08:32.929Z',
          content: [
            {
              id: 'fa832be3-1f31-4c7e-a0b3-f76a0629059b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1117eefe-b70f-4e08-bd6c-4dc60fc35522/ugc/7208693b-671b-4b95-8109-47c5465b08bb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'da0c2567-5e47-4ed8-8084-5375e7b9b3f1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1117eefe-b70f-4e08-bd6c-4dc60fc35522/ugc/7208693b-671b-4b95-8109-47c5465b08bb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1e7bb5a8-5b5b-47ee-abc4-62cc486db25b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1117eefe-b70f-4e08-bd6c-4dc60fc35522/ugc/7208693b-671b-4b95-8109-47c5465b08bb/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7208693b-671b-4b95-8109-47c5465b08bb'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-15T15:18:17.378Z',
          createdAt: '2024-02-11T21:51:21.196Z',
          content: [
            {
              id: 'd5889ced-33c7-499c-9478-c42a4eaf5f3a',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/1117eefe-b70f-4e08-bd6c-4dc60fc35522/ugc/74ce120c-03e1-4d2e-8ea0-75e9d2fdf90d/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '74ce120c-03e1-4d2e-8ea0-75e9d2fdf90d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '1117eefe-b70f-4e08-bd6c-4dc60fc35522',
        firstName: 'Chris',
        lastName: 'Pluhacek',
        email: 'chrispluhacek@gmail.com',
        instagramProfile: {
          handle: 'chris.pluhacek',
          followers: 37546,
          engagementRate: 0.12784317903371864,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'chris.pluhacek',
          followers: 6128,
          engagementRate: 2.681992337164751,
          isPrimary: true
        }
      },
      userCampaignId: '1117eefe-b70f-4e08-bd6c-4dc60fc35522'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T14:21:57.000Z',
        amountCents: 6740
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T14:13:51.124Z',
          createdAt: '2024-02-03T00:22:29.376Z',
          content: [
            {
              id: 'a6102b11-7930-4a5a-8896-1b08728d9745',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59ab72ce-f324-498b-a949-ba5bdec9ed5c/ugc/704b8fc5-4273-4964-b964-267cb6ce59f0/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e836d77d-41ef-4176-add2-e26ad731dff6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59ab72ce-f324-498b-a949-ba5bdec9ed5c/ugc/704b8fc5-4273-4964-b964-267cb6ce59f0/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e8700ade-a8ca-4ca2-be46-0527918ac9bd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/59ab72ce-f324-498b-a949-ba5bdec9ed5c/ugc/704b8fc5-4273-4964-b964-267cb6ce59f0/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '704b8fc5-4273-4964-b964-267cb6ce59f0'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '59ab72ce-f324-498b-a949-ba5bdec9ed5c',
        firstName: 'Suzette',
        lastName: 'Sanchez',
        email: 'suzette.wunsche@yahoo.com',
        instagramProfile: {
          handle: 'mrs.zette',
          followers: 2011,
          engagementRate: 0.42267528592739934,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'suzettewunschesanchez',
          followers: 188,
          engagementRate: 3.1141868512110724,
          isPrimary: true
        }
      },
      userCampaignId: '59ab72ce-f324-498b-a949-ba5bdec9ed5c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T02:24:06.000Z',
        amountCents: 6312
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-02T03:23:22.384Z',
          createdAt: '2024-01-31T16:36:11.890Z',
          content: [
            {
              id: '5cb4a46a-b893-4b4a-b283-c124494b7298',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01e2898d-edb8-45c5-85a5-71456f69365b/ugc/6acc3b43-ae34-4c49-8cf3-cf6ae7acba44/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8152a85b-2460-4156-8504-541377732337',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01e2898d-edb8-45c5-85a5-71456f69365b/ugc/6acc3b43-ae34-4c49-8cf3-cf6ae7acba44/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'abc69791-53aa-4628-ba82-fa0649ed6855',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01e2898d-edb8-45c5-85a5-71456f69365b/ugc/6acc3b43-ae34-4c49-8cf3-cf6ae7acba44/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6acc3b43-ae34-4c49-8cf3-cf6ae7acba44'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-02T03:23:18.130Z',
          createdAt: '2024-01-31T16:42:05.664Z',
          content: [
            {
              id: 'e1efca8d-eddf-4369-83f0-732fcda43a21',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/01e2898d-edb8-45c5-85a5-71456f69365b/ugc/156b5145-680e-44d5-945a-d240918e2463/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '156b5145-680e-44d5-945a-d240918e2463'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '01e2898d-edb8-45c5-85a5-71456f69365b',
        firstName: 'Michele',
        lastName: 'Katsaris',
        email: 'michelekatsaris@gmail.com',
        instagramProfile: {
          handle: 'michelekatsaris',
          followers: 1556,
          engagementRate: 1.5984654731457801,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'michelekatsaris',
          followers: 8972,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'michelekatsaris',
          followers: 295
        }
      },
      userCampaignId: '01e2898d-edb8-45c5-85a5-71456f69365b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T20:42:27.000Z',
        amountCents: 5299
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-14T02:03:18.364Z',
          createdAt: '2024-02-10T17:26:15.131Z',
          content: [
            {
              id: '32d9fbe9-dad6-476e-9834-c76d19e334cd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/64caa309-60e5-4832-bf3c-18505aa1ebcd/ugc/412328ab-bc38-474b-ac13-74c12e2e09ba/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c4da6591-7846-40a5-9bba-0b9d29f1e590',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/64caa309-60e5-4832-bf3c-18505aa1ebcd/ugc/412328ab-bc38-474b-ac13-74c12e2e09ba/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'b4f84fda-e08a-4e66-8d33-f2aab484ce2c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/64caa309-60e5-4832-bf3c-18505aa1ebcd/ugc/412328ab-bc38-474b-ac13-74c12e2e09ba/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '412328ab-bc38-474b-ac13-74c12e2e09ba'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-14T02:03:09.498Z',
          createdAt: '2024-02-10T17:20:08.446Z',
          content: [
            {
              id: 'd0f1a70a-d0fd-455c-8a03-5f564f146db2',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/64caa309-60e5-4832-bf3c-18505aa1ebcd/ugc/7e19ad10-abbf-42e0-a478-ff04c01b0b28/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '7e19ad10-abbf-42e0-a478-ff04c01b0b28'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '64caa309-60e5-4832-bf3c-18505aa1ebcd',
        firstName: 'Jeanie',
        lastName: 'McDonald',
        email: 'winniedapoohhh1@gmail.com',
        instagramProfile: {
          handle: 'thewinniedapoohhh',
          followers: 4786,
          engagementRate: 1.7467613873798578,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'thewinniedapoohhh',
          followers: 51178,
          engagementRate: 16.997518610421835,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'winniedapoohhh'
        }
      },
      userCampaignId: '64caa309-60e5-4832-bf3c-18505aa1ebcd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T20:25:16.000Z',
        amountCents: 6404
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-24T03:41:32.586Z',
          createdAt: '2024-01-30T03:01:34.710Z',
          content: [
            {
              id: '811d1609-3eed-4a8b-9050-6d694001611b',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6879398-a226-4344-b399-629a677982cf/ugc/5ccbd09b-e0f5-4338-9961-9b61a9839818/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '5a5396d3-13dc-44cd-b68b-95b774247999',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6879398-a226-4344-b399-629a677982cf/ugc/5ccbd09b-e0f5-4338-9961-9b61a9839818/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'f7e8402d-27ce-4879-a2a8-a1892d9dac85',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/b6879398-a226-4344-b399-629a677982cf/ugc/5ccbd09b-e0f5-4338-9961-9b61a9839818/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '5ccbd09b-e0f5-4338-9961-9b61a9839818'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'b6879398-a226-4344-b399-629a677982cf',
        firstName: 'Sabrina',
        lastName: 'Kozora',
        email: 'collabwithbrinadoe@gmail.com',
        instagramProfile: {
          handle: 'brinadoe',
          followers: 5002,
          engagementRate: 0.4278288684526189,
          isPrimary: true
        }
      },
      userCampaignId: 'b6879398-a226-4344-b399-629a677982cf'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-26T07:54:33.000Z',
        amountCents: 5486
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-07T16:19:36.661Z',
          createdAt: '2024-01-28T21:43:24.454Z',
          content: [
            {
              id: '68e5c2c4-f026-4406-97d2-48c7c1b69b68',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/81eb5b54-11c5-486c-893b-3765565f1a7c/ugc/1ea15d02-b34d-4b59-8ee9-1a7f08bae9c3/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'd8b11aea-3350-439b-8c8d-451fe5fa2b7e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/81eb5b54-11c5-486c-893b-3765565f1a7c/ugc/1ea15d02-b34d-4b59-8ee9-1a7f08bae9c3/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6c1dfa75-a279-4796-8a49-8cf2dee7435f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/81eb5b54-11c5-486c-893b-3765565f1a7c/ugc/1ea15d02-b34d-4b59-8ee9-1a7f08bae9c3/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '1ea15d02-b34d-4b59-8ee9-1a7f08bae9c3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-07T16:19:43.157Z',
          createdAt: '2024-02-03T00:13:06.625Z',
          content: [
            {
              id: '3b0bd161-9f70-4f71-b243-4be45a0c5de6',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/81eb5b54-11c5-486c-893b-3765565f1a7c/ugc/35681c7e-13bb-4612-bece-789322cb6b4f/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '35681c7e-13bb-4612-bece-789322cb6b4f'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '81eb5b54-11c5-486c-893b-3765565f1a7c',
        firstName: 'Danielle',
        lastName: 'Reid',
        email: 'danielle@drandassociates.com',
        instagramProfile: {
          handle: 'iamdaniellereid',
          followers: 9691,
          engagementRate: 0.9441750077391394,
          isPrimary: true
        }
      },
      userCampaignId: '81eb5b54-11c5-486c-893b-3765565f1a7c'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T15:35:54.000Z',
        amountCents: 5399
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '5933c09d-5c8c-4b70-ad6c-79ad64d0923d',
        firstName: 'Chanin',
        lastName: 'Rahming',
        email: 'chaninrahming@gmail.com',
        instagramProfile: {
          handle: 'chananigans24',
          followers: 2342,
          engagementRate: 14.30401366353544,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'chaninrahming',
          followers: 1352,
          engagementRate: 13.114754098360656,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'chaninrahming',
          followers: 1
        }
      },
      userCampaignId: '5933c09d-5c8c-4b70-ad6c-79ad64d0923d'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T22:23:11.000Z',
        amountCents: 5411
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-02T20:47:21.596Z',
          createdAt: '2024-02-02T00:58:59.516Z',
          content: [
            {
              id: 'caca7e89-bd5a-45b6-b8a9-ecd254e5b442',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2229eaac-3523-4ee9-a266-b15ef7fa43dc/ugc/bc489e36-d532-4170-9b4b-6a5193bfeeee/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'bc489e36-d532-4170-9b4b-6a5193bfeeee'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-02T20:47:29.485Z',
          createdAt: '2024-02-02T01:01:46.074Z',
          content: [
            {
              id: '9f9d5b73-35d4-4fe2-b460-7646c41b418f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2229eaac-3523-4ee9-a266-b15ef7fa43dc/ugc/aa056e0e-535f-4b20-963f-be1140556c09/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '267874f3-3b2c-4e87-8cc4-b33cd5831711',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2229eaac-3523-4ee9-a266-b15ef7fa43dc/ugc/aa056e0e-535f-4b20-963f-be1140556c09/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6d5da301-11c3-4388-baac-6cdc7f8e65d3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2229eaac-3523-4ee9-a266-b15ef7fa43dc/ugc/aa056e0e-535f-4b20-963f-be1140556c09/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'aa056e0e-535f-4b20-963f-be1140556c09'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2229eaac-3523-4ee9-a266-b15ef7fa43dc',
        firstName: 'Dannette',
        lastName: 'Cardenas',
        email: 'dannettemarquezz@icloud.com',
        instagramProfile: {
          handle: 'dxnnxtte',
          followers: 3160,
          engagementRate: 11.29746835443038,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'dxnnxtte',
          followers: 2951,
          engagementRate: 13.253619204691223,
          isPrimary: false
        }
      },
      userCampaignId: '2229eaac-3523-4ee9-a266-b15ef7fa43dc'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-26T06:16:58.000Z',
        amountCents: 5386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-01T19:22:20.996Z',
          createdAt: '2024-02-01T02:41:55.777Z',
          content: [
            {
              id: 'e8682607-f9f1-4c53-afe1-9a177772facf',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0a4521-c8ec-4985-a097-bbf805f7a102/ugc/d89d2c00-d9fd-44c9-b107-fecc10b3b141/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '6af3b5ca-945e-484c-82da-be2a83bc7ac3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0a4521-c8ec-4985-a097-bbf805f7a102/ugc/d89d2c00-d9fd-44c9-b107-fecc10b3b141/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '116d10fc-37d5-4668-a67f-f648abb1568f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0a4521-c8ec-4985-a097-bbf805f7a102/ugc/d89d2c00-d9fd-44c9-b107-fecc10b3b141/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd89d2c00-d9fd-44c9-b107-fecc10b3b141'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-01T19:22:15.469Z',
          createdAt: '2024-02-01T02:38:50.087Z',
          content: [
            {
              id: 'b77212ae-c71d-472d-80d7-e4f7cf8aef7d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/8d0a4521-c8ec-4985-a097-bbf805f7a102/ugc/23ea77db-6759-49a0-a05e-054f09c1fdb6/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '23ea77db-6759-49a0-a05e-054f09c1fdb6'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '8d0a4521-c8ec-4985-a097-bbf805f7a102',
        firstName: 'Kimberly',
        lastName: 'Sanchez',
        email: 'kimysanchez30@gmail.com',
        instagramProfile: {
          handle: 'kimysales_',
          followers: 23642,
          engagementRate: 0.911513408341088,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'kimysales_',
          followers: 787,
          engagementRate: 2.582522841143531,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'kimysales_'
        }
      },
      userCampaignId: '8d0a4521-c8ec-4985-a097-bbf805f7a102'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-23T18:40:44.000Z',
        amountCents: 5349
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-01T18:25:09.239Z',
          createdAt: '2024-01-31T21:03:40.127Z',
          content: [
            {
              id: '8b3c1736-40b8-44d7-8a86-2ff2e44ce214',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8502c74-e82f-41a1-bcc5-0072ee73d1bd/ugc/319ed3c3-5cf0-47b0-862f-85bafadcb202/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c125c82e-79c0-468f-85f8-da20834554de',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8502c74-e82f-41a1-bcc5-0072ee73d1bd/ugc/319ed3c3-5cf0-47b0-862f-85bafadcb202/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '8d678cc4-c2ff-4528-81b0-a0264728a60d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8502c74-e82f-41a1-bcc5-0072ee73d1bd/ugc/319ed3c3-5cf0-47b0-862f-85bafadcb202/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '319ed3c3-5cf0-47b0-862f-85bafadcb202'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-02T23:47:56.762Z',
          createdAt: '2024-02-01T01:19:01.788Z',
          content: [
            {
              id: '40edf8d3-d002-45b4-9701-1b0165526d42',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/f8502c74-e82f-41a1-bcc5-0072ee73d1bd/ugc/771d412f-6570-46f4-b525-8cf9d9434c24/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '771d412f-6570-46f4-b525-8cf9d9434c24'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'f8502c74-e82f-41a1-bcc5-0072ee73d1bd',
        firstName: 'Jenny',
        lastName: 'VARELA',
        email: 'heavenlytreasure1018@gmail.com',
        instagramProfile: {
          handle: 'heavenlytreasureliving',
          followers: 22628,
          engagementRate: 0.3402863708679512,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'heavenlytreasure310',
          followers: 61
        }
      },
      userCampaignId: 'f8502c74-e82f-41a1-bcc5-0072ee73d1bd'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-25T21:15:00.000Z',
        amountCents: 5434
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-06T14:29:45.688Z',
          createdAt: '2024-02-04T03:37:14.918Z',
          content: [
            {
              id: '63b1ca85-0a60-4d6f-8107-93000f34d926',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a96f9c36-2ad4-4089-8652-74767935d0db/ugc/a71d6f69-8dd4-4b16-a18a-0dfb07c8a1e2/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '1e2b945c-4216-4aae-8971-43529b8b6bd7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a96f9c36-2ad4-4089-8652-74767935d0db/ugc/a71d6f69-8dd4-4b16-a18a-0dfb07c8a1e2/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7b836c44-5855-400c-83c7-0a45e7a20576',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a96f9c36-2ad4-4089-8652-74767935d0db/ugc/a71d6f69-8dd4-4b16-a18a-0dfb07c8a1e2/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a71d6f69-8dd4-4b16-a18a-0dfb07c8a1e2'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-06T14:29:34.837Z',
          createdAt: '2024-02-04T03:28:59.666Z',
          content: [
            {
              id: '75adc8fc-400d-4ece-bb9d-d317f63db0aa',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/a96f9c36-2ad4-4089-8652-74767935d0db/ugc/a9fbe803-978c-42c8-bc42-21e033c77fbc/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'a9fbe803-978c-42c8-bc42-21e033c77fbc'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'a96f9c36-2ad4-4089-8652-74767935d0db',
        firstName: 'Stanley',
        lastName: 'Gettis',
        email: '1057fmthebox@gmail.com',
        instagramProfile: {
          handle: '1057theboxradio',
          followers: 10065,
          engagementRate: 10.247391952309986,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'stanclmg',
          followers: 1936,
          isPrimary: false
        },
        youtubeProfile: {
          handle: '1057theboxradio'
        }
      },
      userCampaignId: 'a96f9c36-2ad4-4089-8652-74767935d0db'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-28T03:54:00.000Z',
        amountCents: 5399
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [],
      creator: {
        sponsoredPosts: [],
        id: '84087ef0-61ec-40b5-bbfc-f8fff9822e88',
        firstName: 'Ocean',
        lastName: 'Brown',
        email: 'oceanpopstar@gmail.com',
        tiktokProfile: {
          handle: 'osie_thee_scientist',
          followers: 1981,
          engagementRate: 14.34108527131783,
          isPrimary: false
        },
        youtubeProfile: {
          handle: 'oceanbrown7453',
          followers: 0
        }
      },
      userCampaignId: '84087ef0-61ec-40b5-bbfc-f8fff9822e88'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-19T04:23:30.000Z',
        amountCents: 6327
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-31T03:59:48.274Z',
          createdAt: '2024-01-25T04:37:41.161Z',
          content: [
            {
              id: '5f81d9b3-d5a2-462e-a570-437c72a78c7d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cafbe5b9-804a-4b16-9ff4-1eb61d7bfba5/ugc/c854375c-accb-49f5-9ab2-d2f58c68a5cb/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'fd885b5b-f3b3-4283-b8e7-ef7defe77162',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cafbe5b9-804a-4b16-9ff4-1eb61d7bfba5/ugc/c854375c-accb-49f5-9ab2-d2f58c68a5cb/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'cd592e14-03a8-4cef-be6c-5c94c194b6fb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cafbe5b9-804a-4b16-9ff4-1eb61d7bfba5/ugc/c854375c-accb-49f5-9ab2-d2f58c68a5cb/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'c854375c-accb-49f5-9ab2-d2f58c68a5cb'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-31T18:46:33.154Z',
          createdAt: '2024-01-31T03:59:36.017Z',
          content: [
            {
              id: '82f58122-be28-447a-93d9-da24f6ca2a9f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/cafbe5b9-804a-4b16-9ff4-1eb61d7bfba5/ugc/43e421af-dc90-4754-875b-b5adb93639df/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '43e421af-dc90-4754-875b-b5adb93639df'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'cafbe5b9-804a-4b16-9ff4-1eb61d7bfba5',
        firstName: 'Kaila',
        lastName: 'DeRienzo',
        email: 'kderienzomedia@gmail.com',
        instagramProfile: {
          handle: 'kailaderienzo',
          followers: 3305,
          engagementRate: 1.6580937972768532,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'kailaderienzo',
          followers: 1309,
          engagementRate: 10.123136203719216,
          isPrimary: false
        }
      },
      userCampaignId: 'cafbe5b9-804a-4b16-9ff4-1eb61d7bfba5'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-18T21:59:24.000Z',
        amountCents: 6386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-01-30T05:21:47.060Z',
          createdAt: '2024-01-25T01:33:36.264Z',
          content: [
            {
              id: 'bcc595e5-f9bb-444b-a2a9-cbb4add15028',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9d77be3-71b7-4043-84bc-463a6e7fae17/ugc/6906b083-04a4-49d6-a6eb-8b63bbd4033b/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '6906b083-04a4-49d6-a6eb-8b63bbd4033b'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-01-30T05:21:52.368Z',
          createdAt: '2024-01-25T01:35:11.386Z',
          content: [
            {
              id: '48ea1901-f608-418f-aff4-b3ffb637e97c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9d77be3-71b7-4043-84bc-463a6e7fae17/ugc/b3037214-5d10-4263-81b1-84c646940d4d/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'df36deb3-e779-40f8-a06a-4775df9613c3',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9d77be3-71b7-4043-84bc-463a6e7fae17/ugc/b3037214-5d10-4263-81b1-84c646940d4d/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '79ed8d52-1ffd-4050-ab0b-2a6e77adcc43',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/d9d77be3-71b7-4043-84bc-463a6e7fae17/ugc/b3037214-5d10-4263-81b1-84c646940d4d/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'b3037214-5d10-4263-81b1-84c646940d4d'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'd9d77be3-71b7-4043-84bc-463a6e7fae17',
        firstName: 'Lillie',
        lastName: 'Kochalka',
        email: 'simplylillie59@gmail.com',
        instagramProfile: {
          handle: 'simplylillie',
          followers: 3481,
          engagementRate: 19.620798621085893,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'simplylillie',
          followers: 10141,
          engagementRate: 9.34462036418782,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'simplylillie'
        }
      },
      userCampaignId: 'd9d77be3-71b7-4043-84bc-463a6e7fae17'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-01T18:01:43.000Z',
        amountCents: 5336
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-12T13:19:04.318Z',
          createdAt: '2024-02-08T16:24:17.538Z',
          content: [
            {
              id: 'fbc910b3-1a52-4ef5-b376-2d7ea082be8e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/49559b0e-8a88-4fea-b8ad-0a1ea134e680/ugc/2f7394b4-129e-493b-82db-57989e98d4b7/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '7e33f377-be73-4980-83e1-e2044aed4427',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/49559b0e-8a88-4fea-b8ad-0a1ea134e680/ugc/2f7394b4-129e-493b-82db-57989e98d4b7/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '60515594-eafe-458c-bc74-a81506b85ede',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/49559b0e-8a88-4fea-b8ad-0a1ea134e680/ugc/2f7394b4-129e-493b-82db-57989e98d4b7/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2f7394b4-129e-493b-82db-57989e98d4b7'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-12T13:18:59.813Z',
          createdAt: '2024-02-10T22:03:41.067Z',
          content: [
            {
              id: '6a06c66a-03c6-44d9-881d-2aa6c1a0182e',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/49559b0e-8a88-4fea-b8ad-0a1ea134e680/ugc/990dbefc-27a6-450a-ba06-7b2c5f9cc0f5/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '990dbefc-27a6-450a-ba06-7b2c5f9cc0f5'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '49559b0e-8a88-4fea-b8ad-0a1ea134e680',
        firstName: 'Charnel ',
        lastName: 'Graham ',
        email: 'misscharnelg@highheelsandmotherhood.live',
        instagramProfile: {
          handle: 'charnelmariee',
          followers: 5041,
          engagementRate: 0.44435627851616744,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'misscharnelg',
          followers: 822,
          engagementRate: 4.714640198511166,
          isPrimary: true
        },
        youtubeProfile: {
          handle: 'misscharnelg',
          followers: 142
        }
      },
      userCampaignId: '49559b0e-8a88-4fea-b8ad-0a1ea134e680'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-08T04:44:28.000Z',
        amountCents: 6419
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-14T17:30:50.133Z',
          createdAt: '2024-02-13T23:57:19.378Z',
          content: [
            {
              id: 'e3d010b8-8a33-4a8b-8427-af47f7bbb8fd',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700b7580-d320-4a68-9f86-f27bf15ca08b/ugc/45a2b803-3370-47af-a235-06eaf70a4359/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '3bdcedcf-ab79-4b55-a9d3-f15ae95ddff7',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700b7580-d320-4a68-9f86-f27bf15ca08b/ugc/45a2b803-3370-47af-a235-06eaf70a4359/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'dc8f266f-efd0-4ee5-a72f-618d63bb68ff',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700b7580-d320-4a68-9f86-f27bf15ca08b/ugc/45a2b803-3370-47af-a235-06eaf70a4359/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '45a2b803-3370-47af-a235-06eaf70a4359'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-14T17:30:41.314Z',
          createdAt: '2024-02-13T23:56:56.516Z',
          content: [
            {
              id: '98a65a45-a7c4-40d4-b461-5f2ca6809e41',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/700b7580-d320-4a68-9f86-f27bf15ca08b/ugc/49aaad64-edb3-4317-ae47-924f0afd3a85/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '49aaad64-edb3-4317-ae47-924f0afd3a85'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '700b7580-d320-4a68-9f86-f27bf15ca08b',
        firstName: 'Juliet',
        lastName: 'Burnette',
        email: 'julietlucillebusiness@gmail.com',
        instagramProfile: {
          handle: 'julietlucille_',
          followers: 1042,
          engagementRate: 2.6007677543186185,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'julietlucille_',
          followers: 34,
          engagementRate: 2.3359288097886544,
          isPrimary: true
        }
      },
      userCampaignId: '700b7580-d320-4a68-9f86-f27bf15ca08b'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-31T12:41:50.000Z',
        amountCents: 5386
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-13T18:25:28.682Z',
          createdAt: '2024-02-08T19:05:35.015Z',
          content: [
            {
              id: '38e1211a-8bd2-4cad-b8c6-aa62d41d978d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/00d4c048-9753-4d11-b629-1fecfbe33b87/ugc/9b5a8bfd-c941-43a5-b5a9-a94ecffc3cb1/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9b5a8bfd-c941-43a5-b5a9-a94ecffc3cb1'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-08T19:05:54.552Z',
          createdAt: '2024-02-08T16:45:12.311Z',
          content: [
            {
              id: 'b3737919-2031-4070-bd6d-acf762308436',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/00d4c048-9753-4d11-b629-1fecfbe33b87/ugc/9b9ccd2e-0d91-4fa6-803f-e55771dad1b9/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '9843b911-8418-489c-a088-e34444feb3e5',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/00d4c048-9753-4d11-b629-1fecfbe33b87/ugc/9b9ccd2e-0d91-4fa6-803f-e55771dad1b9/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'c4aa29fe-9c0d-4185-87e5-6955ca63345d',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/00d4c048-9753-4d11-b629-1fecfbe33b87/ugc/9b9ccd2e-0d91-4fa6-803f-e55771dad1b9/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '9b9ccd2e-0d91-4fa6-803f-e55771dad1b9'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '00d4c048-9753-4d11-b629-1fecfbe33b87',
        firstName: 'Tasha',
        lastName: 'Guevara',
        email: 'msdjtabu@gmail.com',
        instagramProfile: {
          handle: 'tabu73',
          followers: 24477,
          engagementRate: 0.2937451485067615,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'mstabu73',
          followers: 168,
          engagementRate: 2.3777701368708266,
          isPrimary: false
        }
      },
      userCampaignId: '00d4c048-9753-4d11-b629-1fecfbe33b87'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-01-22T21:15:14.000Z',
        amountCents: 5461
      },
      status: CampaignCreatorStatus.COMPLETED,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-09T00:08:03.896Z',
          createdAt: '2024-02-07T20:36:37.779Z',
          content: [
            {
              id: '6387de15-6a68-449b-b2e3-a5f5de40cfcb',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2e2e7f3e-4b3d-4c51-8121-2c597a25c326/ugc/e81c60ef-da72-4979-8bf8-4dd2a13672e3/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'e81c60ef-da72-4979-8bf8-4dd2a13672e3'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-09T00:08:09.261Z',
          createdAt: '2024-01-30T15:15:14.372Z',
          content: [
            {
              id: '0b7c0384-e05c-4a16-9aef-b429c4ca8bc1',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2e2e7f3e-4b3d-4c51-8121-2c597a25c326/ugc/2ef67884-5b4b-465e-bd61-93d656a7f061/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '82bb1726-d0c5-410f-a263-ec394369140c',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2e2e7f3e-4b3d-4c51-8121-2c597a25c326/ugc/2ef67884-5b4b-465e-bd61-93d656a7f061/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'bc20ce4c-10b1-41f5-8456-1b3850699973',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/2e2e7f3e-4b3d-4c51-8121-2c597a25c326/ugc/2ef67884-5b4b-465e-bd61-93d656a7f061/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '2ef67884-5b4b-465e-bd61-93d656a7f061'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: '2e2e7f3e-4b3d-4c51-8121-2c597a25c326',
        firstName: 'Davis',
        lastName: 'Mallory',
        email: 'davis.mallory@gmail.com',
        instagramProfile: {
          handle: 'davismallory',
          followers: 29878,
          engagementRate: 0.5703192984804873,
          isPrimary: false
        },
        tiktokProfile: {
          handle: 'davismallory',
          followers: 2547,
          engagementRate: 2.547770700636943,
          isPrimary: true
        }
      },
      userCampaignId: '2e2e7f3e-4b3d-4c51-8121-2c597a25c326'
    },
    {
      purchaseDetails: {
        purchaseDate: '2024-02-02T10:13:47.000Z',
        amountCents: 5311
      },
      status: CampaignCreatorStatus.INCOMPLETE,
      creatorType: CreatorType.UGC,
      content: [
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_VIDEO,
          isHidden: true,
          isFavorite: false,
          submittedAt: '2024-02-20T16:07:35.188Z',
          createdAt: '2024-02-20T01:43:55.168Z',
          content: [
            {
              id: '3bf1a27a-458e-4f79-b58e-0a04becf4d22',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dfca4bb5-9834-46bc-b683-e29f1f673ed5/ugc/d01e52a4-65c8-4b72-9da2-dfd3a582e0ae/UGC-CONTENT-1.mp4',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: 'd01e52a4-65c8-4b72-9da2-dfd3a582e0ae'
        },
        {
          channel: Channel.UGC,
          contentType: ContentType.UGC_PHOTO,
          isHidden: false,
          isFavorite: false,
          submittedAt: '2024-02-20T15:23:57.697Z',
          createdAt: '2024-02-20T01:44:31.683Z',
          content: [
            {
              id: 'f6869e40-2c38-4464-ade4-966e7a0b338f',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dfca4bb5-9834-46bc-b683-e29f1f673ed5/ugc/960eda34-7b09-4f38-846d-086f63188785/UGC-CONTENT-2.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: 'e83e963d-bd88-41ce-b92c-edf9cf8084a9',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dfca4bb5-9834-46bc-b683-e29f1f673ed5/ugc/960eda34-7b09-4f38-846d-086f63188785/UGC-CONTENT-1.jpeg',
                  type: 'IMAGE'
                }
              ]
            },
            {
              id: '2b921c0c-f618-4e4e-81af-95b9951586ad',
              caption: '',
              assets: [
                {
                  url: 'https://media.productwind.com/dfca4bb5-9834-46bc-b683-e29f1f673ed5/ugc/960eda34-7b09-4f38-846d-086f63188785/UGC-CONTENT-3.jpeg',
                  type: 'IMAGE'
                }
              ]
            }
          ],
          groupId: '960eda34-7b09-4f38-846d-086f63188785'
        }
      ],
      creator: {
        sponsoredPosts: [],
        id: 'dfca4bb5-9834-46bc-b683-e29f1f673ed5',
        firstName: 'Klea',
        lastName: 'Demiraj',
        email: 'inasmith9999@gmail.com',
        instagramProfile: {
          handle: 'beauty_diary_by_klea',
          followers: 21200,
          engagementRate: 2.686320754716981,
          isPrimary: true
        },
        tiktokProfile: {
          handle: 'klea.kle28',
          followers: 10162,
          engagementRate: 9.084093464950643,
          isPrimary: true
        }
      },
      userCampaignId: 'dfca4bb5-9834-46bc-b683-e29f1f673ed5'
    }
  ]
}
