import { CreatorType } from '@productwindtom/shared-momentum-zeus-types'

export const CreatorTypeLabels: Record<CreatorType, string> = {
  [CreatorType.IG]: 'IG creator',
  [CreatorType.TT]: 'TT creator',
  [CreatorType.YOUTUBE]: 'YT creator',
  [CreatorType.UGC]: 'UGC creator',
  [CreatorType.PREMIUM_UGC]: 'Premium UGC creator',
  [CreatorType.ADVOCATE]: 'Brand advocate creator'
}

export const SocialCreatorTypes = [CreatorType.IG, CreatorType.TT, CreatorType.YOUTUBE]
