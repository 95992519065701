import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { uniqueId } from 'lodash'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_REVIEW_METRICS_HEADPHONES } from './headphones'

export const SAMPLE_CAMPAIGN_REVIEW_METRICS: Record<string, Array<ModelTypes['CampaignProductReviewRatingMetric']>> = {
  'sample-sku-laundry': SAMPLE_CAMPAIGN_REVIEW_METRICS_LAUNDRY,
  'sample-sku-headphones': SAMPLE_CAMPAIGN_REVIEW_METRICS_HEADPHONES,
  'sample-sku-keyboard': [
    {
      fromDate: '2024-01-09',
      toDate: '2024-01-10',
      rating: 4.6,
      numRatings: 137,
      numReviews: 67,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-10',
      toDate: '2024-01-11',
      rating: 4.6,
      numRatings: 139,
      numReviews: 67,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-11',
      toDate: '2024-01-12',
      rating: 4.6,
      numRatings: 141,
      numReviews: 67,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-12',
      toDate: '2024-01-13',
      rating: 4.6,
      numRatings: 145,
      numReviews: 68,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-13',
      toDate: '2024-01-14',
      rating: 4.6,
      numRatings: 150,
      numReviews: 69,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-14',
      toDate: '2024-01-15',
      rating: 4.6,
      numRatings: 155,
      numReviews: 71,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-15',
      toDate: '2024-01-16',
      rating: 4.6,
      numRatings: 158,
      numReviews: 73,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-16',
      toDate: '2024-01-17',
      rating: 4.6,
      numRatings: 160,
      numReviews: 73,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-17',
      toDate: '2024-01-18',
      rating: 4.6,
      numRatings: 167,
      numReviews: 74,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-18',
      toDate: '2024-01-19',
      rating: 4.6,
      numRatings: 171,
      numReviews: 77,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-19',
      toDate: '2024-01-20',
      rating: 4.6,
      numRatings: 176,
      numReviews: 79,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-20',
      toDate: '2024-01-21',
      rating: 4.6,
      numRatings: 178,
      numReviews: 80,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-21',
      toDate: '2024-01-22',
      rating: 4.6,
      numRatings: 183,
      numReviews: 82,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-22',
      toDate: '2024-01-23',
      rating: 4.6,
      numRatings: 187,
      numReviews: 82,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-23',
      toDate: '2024-01-24',
      rating: 4.6,
      numRatings: 190,
      numReviews: 85,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-24',
      toDate: '2024-01-25',
      rating: 4.6,
      numRatings: 194,
      numReviews: 86,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-25',
      toDate: '2024-01-26',
      rating: 4.6,
      numRatings: 199,
      numReviews: 88,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-26',
      toDate: '2024-01-27',
      rating: 4.6,
      numRatings: 207,
      numReviews: 91,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-27',
      toDate: '2024-01-28',
      rating: 4.6,
      numRatings: 212,
      numReviews: 94,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-28',
      toDate: '2024-01-29',
      rating: 4.6,
      numRatings: 215,
      numReviews: 97,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-29',
      toDate: '2024-01-30',
      rating: 4.6,
      numRatings: 220,
      numReviews: 102,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-30',
      toDate: '2024-01-31',
      rating: 4.6,
      numRatings: 227,
      numReviews: 105,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-31',
      toDate: '2024-02-01',
      rating: 4.6,
      numRatings: 229,
      numReviews: 107,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-01',
      toDate: '2024-02-02',
      rating: 4.6,
      numRatings: 236,
      numReviews: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-02',
      toDate: '2024-02-03',
      rating: 4.6,
      numRatings: 238,
      numReviews: 112,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-03',
      toDate: '2024-02-04',
      rating: 4.6,
      numRatings: 245,
      numReviews: 118,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-04',
      toDate: '2024-02-05',
      rating: 4.6,
      numRatings: 254,
      numReviews: 122,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-05',
      toDate: '2024-02-06',
      rating: 4.6,
      numRatings: 258,
      numReviews: 126,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-06',
      toDate: '2024-02-07',
      rating: 4.6,
      numRatings: 263,
      numReviews: 129,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-07',
      toDate: '2024-02-08',
      rating: 4.6,
      numRatings: 267,
      numReviews: 132,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-08',
      toDate: '2024-02-09',
      rating: 4.6,
      numRatings: 273,
      numReviews: 136,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-09',
      toDate: '2024-02-10',
      rating: 4.6,
      numRatings: 280,
      numReviews: 139,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-10',
      toDate: '2024-02-11',
      rating: 4.6,
      numRatings: 288,
      numReviews: 147,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-11',
      toDate: '2024-02-12',
      rating: 4.6,
      numRatings: 265,
      numReviews: 149,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-12',
      toDate: '2024-02-13',
      rating: 4.6,
      numRatings: 297,
      numReviews: 153,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-13',
      toDate: '2024-02-14',
      rating: 4.6,
      numRatings: 305,
      numReviews: 157,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-14',
      toDate: '2024-02-15',
      rating: 4.6,
      numRatings: 308,
      numReviews: 162,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-15',
      toDate: '2024-02-16',
      rating: 4.6,
      numRatings: 315,
      numReviews: 165,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 4,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 4,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-16',
      toDate: '2024-02-17',
      rating: 4.6,
      numRatings: 322,
      numReviews: 170,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-17',
      toDate: '2024-02-18',
      rating: 4.6,
      numRatings: 327,
      numReviews: 172,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 2,
      momentumReviewRating: 5,
      reviewsInPeriod: 2,
      reviews: [
        {
          title: 'Very comfortable',
          text: 'Super light and easy to use. Very practical with the buttons too and quiet. Love it',
          name: 'Sebastian O.',
          rating: 5,
          attributedUserId: '6434867a-91b2-4e6e-b96f-a3bb54996f9d',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        },
        {
          title: 'Great Product!',
          text: 'Overall very happy with this combo. They work great on both my Mac and my iPad. I love being able to bounce back and forth with the click of a button.',
          name: 'Nicole Roggow',
          rating: 5,
          attributedUserId: 'd569077b-a418-40c5-b681-a2bd64524f95',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        }
      ],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-18',
      toDate: '2024-02-19',
      rating: 4.6,
      numRatings: 331,
      numReviews: 174,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 2,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-19',
      toDate: '2024-02-26',
      rating: 4.6,
      numRatings: 364,
      numReviews: 191,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 13,
      momentumReviewRating: 4.846153846153845,
      reviewsInPeriod: 14,
      reviews: [
        {
          title: 'Compact and what I needed',
          text: 'I was looking for a very portable mouse and keyboard and this is definitely what I needed. It’s small so it fits easily in my backpack. I like how the keys feel too. It has a solid build so I’m not worried about it breaking. So far I’m very pleased with my purchase',
          name: 'DJ Epic',
          rating: 5,
          attributedUserId: 'b2a7e6c4-ecad-442e-93c0-17a775a29028',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        },
        {
          title: 'Easy to connect and lightweight',
          text: 'Easy to connect, save up to 3 devices. Lightweight',
          name: 'Stanley Gettis',
          rating: 5,
          attributedUserId: '2c229814-1fd2-4b5a-806e-d274116085ca',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        },
        {
          title: 'Band for your buck',
          text: 'Great and super portable for travel. A must have to connect to tablets.',
          name: 'Taylor Jordn',
          rating: 5,
          attributedUserId: 'abb07528-0fbd-40ad-94a6-67ab6ae94847',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        }
      ],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    }
    // {
    //   fromDate: '2024-02-26',
    //   toDate: '2024-03-04',
    //   rating: 4.6,
    //   numRatings: 399,
    //   numReviews: 201,
    //   vineReviewCount: 0,
    //   vineRating: 0,
    //   momentumReviewCount: 6,
    //   momentumReviewRating: 4.833333333333334,
    //   reviewsInPeriod: 6,
    //   reviews: [
    //     {
    //       title: 'Great keyboard and mouse combo',
    //       text: '* Super quiet typing and clicking - perfect for shared spaces * Sleek look with a nice rounded mouse. Feels good in the hand * Easy to switch between my laptop, iPad, and phone * Battery seems to last forever  **Highly recommend, especially for the price **',
    //       name: 'Hey Donnah',
    //       rating: 5,
    //       attributedUserId: '3e9dd8c3-fa78-4046-9598-44f72290b4fc',
    //       id: uniqueId(),
    //       reviewedAt: '2023-08-09T16:44:19.000Z',
    //       isVine: false
    //     }
    //   ],
    //   momentumRating: 0,
    //   organicRating: 0,
    //   organicRatingCount: 0
    // }
  ],
  'sample-sku-whitening': [
    {
      fromDate: '2024-01-17',
      toDate: '2024-01-18',
      rating: 4,
      numRatings: 936,
      numReviews: 153,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-18',
      toDate: '2024-01-19',
      rating: 4,
      numRatings: 938,
      numReviews: 153,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-19',
      toDate: '2024-01-20',
      rating: 4,
      numRatings: 943,
      numReviews: 153,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-20',
      toDate: '2024-01-21',
      rating: 4,
      numRatings: 946,
      numReviews: 153,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-21',
      toDate: '2024-01-22',
      rating: 4,
      numRatings: 950,
      numReviews: 155,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-22',
      toDate: '2024-01-23',
      rating: 4,
      numRatings: 950,
      numReviews: 155,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-23',
      toDate: '2024-01-24',
      rating: 4,
      numRatings: 957,
      numReviews: 156,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-24',
      toDate: '2024-01-25',
      rating: 4,
      numRatings: 961,
      numReviews: 158,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-25',
      toDate: '2024-01-26',
      rating: 4,
      numRatings: 967,
      numReviews: 158,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-26',
      toDate: '2024-01-27',
      rating: 4,
      numRatings: 970,
      numReviews: 158,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-27',
      toDate: '2024-01-28',
      rating: 4,
      numRatings: 973,
      numReviews: 158,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-28',
      toDate: '2024-01-29',
      rating: 4,
      numRatings: 975,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-29',
      toDate: '2024-01-30',
      rating: 4,
      numRatings: 975,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-30',
      toDate: '2024-01-31',
      rating: 4,
      numRatings: 977,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-01-31',
      toDate: '2024-02-01',
      rating: 4,
      numRatings: 981,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-01',
      toDate: '2024-02-02',
      rating: 4,
      numRatings: 983,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-02',
      toDate: '2024-02-03',
      rating: 4,
      numRatings: 986,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-03',
      toDate: '2024-02-04',
      rating: 4,
      numRatings: 990,
      numReviews: 159,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-04',
      toDate: '2024-02-05',
      rating: 4,
      numRatings: 995,
      numReviews: 160,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-05',
      toDate: '2024-02-06',
      rating: 4,
      numRatings: 997,
      numReviews: 160,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-06',
      toDate: '2024-02-07',
      rating: 4,
      numRatings: 1001,
      numReviews: 161,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-07',
      toDate: '2024-02-08',
      rating: 4,
      numRatings: 1001,
      numReviews: 161,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-08',
      toDate: '2024-02-09',
      rating: 4,
      numRatings: 1006,
      numReviews: 161,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-09',
      toDate: '2024-02-10',
      rating: 4,
      numRatings: 1009,
      numReviews: 162,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-10',
      toDate: '2024-02-11',
      rating: 4,
      numRatings: 1012,
      numReviews: 1003,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-11',
      toDate: '2024-02-12',
      rating: 4,
      numRatings: 1013,
      numReviews: 162,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-12',
      toDate: '2024-02-13',
      rating: 4,
      numRatings: 1015,
      numReviews: 163,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-13',
      toDate: '2024-02-14',
      rating: 4,
      numRatings: 1021,
      numReviews: 163,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 4,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-14',
      toDate: '2024-02-15',
      rating: 4,
      numRatings: 1025,
      numReviews: 166,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-15',
      toDate: '2024-02-16',
      rating: 4,
      numRatings: 1028,
      numReviews: 168,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-16',
      toDate: '2024-02-17',
      rating: 4,
      numRatings: 1030,
      numReviews: 168,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-17',
      toDate: '2024-02-18',
      rating: 4,
      numRatings: 1033,
      numReviews: 170,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-18',
      toDate: '2024-02-19',
      rating: 4,
      numRatings: 1038,
      numReviews: 171,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-19',
      toDate: '2024-02-20',
      rating: 4,
      numRatings: 1041,
      numReviews: 171,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-20',
      toDate: '2024-02-21',
      rating: 4,
      numRatings: 1047,
      numReviews: 172,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-21',
      toDate: '2024-02-22',
      rating: 4,
      numRatings: 1049,
      numReviews: 173,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-22',
      toDate: '2024-02-23',
      rating: 4,
      numRatings: 1050,
      numReviews: 173,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-23',
      toDate: '2024-02-24',
      rating: 4,
      numRatings: 1054,
      numReviews: 175,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-24',
      toDate: '2024-02-25',
      rating: 4,
      numRatings: 1057,
      numReviews: 175,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-25',
      toDate: '2024-02-26',
      rating: 4,
      numRatings: 1060,
      numReviews: 175,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-26',
      toDate: '2024-02-27',
      rating: 4,
      numRatings: 1064,
      numReviews: 177,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-02-27',
      toDate: '2024-03-02',
      rating: 4,
      numRatings: 1078,
      numReviews: 1061,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 4,
      momentumReviewRating: 4.4,
      reviewsInPeriod: 13,
      reviews: [
        {
          title: 'So easy to use!',
          text: 'I’ve used whitening systems in the past where you have to mold a mouthpiece and use a nasty gel that makes you drool and is so messy. This is beyond the easiest I’ve ever used. The whitening pen is a thin liquid and the led blue light plugs straight into your phone and buzzes halfway through and turns off on its own when it’s done. The fact that it plugs into your phone and you just bite down makes it so easy to so easy to sit and read while you wait! Definitely recommend!',
          name: 'Kasey & Haley',
          rating: 5,
          attributedUserId: '6d5feb16-1a33-4860-bfe8-12f449a3444f',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        },
        {
          title: 'Can see a difference!',
          text: 'I could tell a difference after several days. It’s hard to apply the pen sometimes. It doesn’t always click well and have gel come out. Then the pen starts drying out so makes it harder to apply. The light is comfortable. I like that it beeps 5 mins in and when done.',
          name: 'Meagan',
          rating: 3,
          attributedUserId: '5f912b38-d01b-4f50-8569-50f4183bb7d4',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        }
      ],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-02',
      toDate: '2024-03-03',
      rating: 4,
      numRatings: 1061,
      numReviews: 189,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 3,
      momentumReviewRating: 4.933333333333334,
      reviewsInPeriod: 3,
      reviews: [
        {
          title: 'My teeth look amazing',
          text: 'Wow I am so happy and excited about this product and the amazing wonders it has done for my teeth! I am able to smile and have confidence again! Super easy to use and has many benefits! Definitely recommend!',
          name: 'Ehab TRAISH',
          rating: 5,
          attributedUserId: 'e4d7c36f-198d-450f-b58d-26b62c0db55e',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        },
        {
          title: 'Love it',
          text: 'Amazing results & super convenient',
          name: 'Lexi',
          rating: 5,
          attributedUserId: '73d0fdc2-c17d-4cec-9e96-996b55e2bc83',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        }
      ],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-03',
      toDate: '2024-03-04',
      rating: 4,
      numRatings: 1090,
      numReviews: 191,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-04',
      toDate: '2024-03-05',
      rating: 4,
      numRatings: 1093,
      numReviews: 194,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-05',
      toDate: '2024-03-06',
      rating: 4,
      numRatings: 1100,
      numReviews: 196,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-06',
      toDate: '2024-03-07',
      rating: 4,
      numRatings: 1102,
      numReviews: 196,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-07',
      toDate: '2024-03-08',
      rating: 4,
      numRatings: 1109,
      numReviews: 198,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-08',
      toDate: '2024-03-09',
      rating: 4,
      numRatings: 1109,
      numReviews: 1091,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-09',
      toDate: '2024-03-10',
      rating: 4,
      numRatings: 1115,
      numReviews: 199,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-10',
      toDate: '2024-03-11',
      rating: 4,
      numRatings: 1091,
      numReviews: 203,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-11',
      toDate: '2024-03-12',
      rating: 4,
      numRatings: 1121,
      numReviews: 204,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 3,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-12',
      toDate: '2024-03-13',
      rating: 4,
      numRatings: 1129,
      numReviews: 209,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-13',
      toDate: '2024-03-14',
      rating: 4,
      numRatings: 1134,
      numReviews: 210,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 5,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-14',
      toDate: '2024-03-15',
      rating: 4,
      numRatings: 1136,
      numReviews: 211,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-15',
      toDate: '2024-03-16',
      rating: 4,
      numRatings: 1148,
      numReviews: 215,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-16',
      toDate: '2024-03-17',
      rating: 4,
      numRatings: 1153,
      numReviews: 218,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-17',
      toDate: '2024-03-18',
      rating: 4,
      numRatings: 1155,
      numReviews: 219,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-18',
      toDate: '2024-03-19',
      rating: 4,
      numRatings: 1155,
      numReviews: 219,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 3,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-19',
      toDate: '2024-03-20',
      rating: 4,
      numRatings: 1163,
      numReviews: 224,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-20',
      toDate: '2024-03-21',
      rating: 4,
      numRatings: 1168,
      numReviews: 226,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    },
    {
      fromDate: '2024-03-21',
      toDate: '2024-03-22',
      rating: 4,
      numRatings: 1177,
      numReviews: 228,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0,
      momentumRatingCount: 0,
      ratingsInPeriod: 0
    }
  ],
  'sample-sku-marker': [
    {
      fromDate: '2024-02-21',
      toDate: '2024-02-22',
      rating: 5,
      numRatings: 13,
      numReviews: 12,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-22',
      toDate: '2024-02-23',
      rating: 5,
      numRatings: 14,
      numReviews: 13,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-23',
      toDate: '2024-02-24',
      rating: 5,
      numRatings: 14,
      numReviews: 13,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-24',
      toDate: '2024-02-25',
      rating: 5,
      numRatings: 14,
      numReviews: 13,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-25',
      toDate: '2024-02-26',
      rating: 5,
      numRatings: 15,
      numReviews: 14,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-26',
      toDate: '2024-02-27',
      rating: 5,
      numRatings: 16,
      numReviews: 15,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-27',
      toDate: '2024-03-02',
      rating: 4.9,
      numRatings: 18,
      numReviews: 17,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 3,
      vineRating: 4.666666666666667,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 5,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-02',
      toDate: '2024-03-03',
      rating: 4.9,
      numRatings: 20,
      numReviews: 18,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-03',
      toDate: '2024-03-04',
      rating: 4.9,
      numRatings: 20,
      numReviews: 18,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-04',
      toDate: '2024-03-05',
      rating: 4.9,
      numRatings: 22,
      numReviews: 20,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-05',
      toDate: '2024-03-06',
      rating: 4.9,
      numRatings: 47,
      numReviews: 45,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-06',
      toDate: '2024-03-07',
      rating: 4.9,
      numRatings: 49,
      numReviews: 22,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-07',
      toDate: '2024-03-08',
      rating: 4.9,
      numRatings: 50,
      numReviews: 48,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-08',
      toDate: '2024-03-09',
      rating: 4.9,
      numRatings: 51,
      numReviews: 49,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-09',
      toDate: '2024-03-10',
      rating: 4.9,
      numRatings: 53,
      numReviews: 50,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-10',
      toDate: '2024-03-11',
      rating: 4.9,
      numRatings: 55,
      numReviews: 50,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-11',
      toDate: '2024-03-12',
      rating: 4.9,
      numRatings: 58,
      numReviews: 51,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-12',
      toDate: '2024-03-13',
      rating: 4.9,
      numRatings: 58,
      numReviews: 51,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 4,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 3,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-13',
      toDate: '2024-03-14',
      rating: 4.8,
      numRatings: 61,
      numReviews: 54,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-14',
      toDate: '2024-03-15',
      rating: 4.8,
      numRatings: 66,
      numReviews: 55,
      ratingsInPeriod: 4,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-15',
      toDate: '2024-03-16',
      rating: 4.8,
      numRatings: 66,
      numReviews: 55,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-16',
      toDate: '2024-03-17',
      rating: 4.8,
      numRatings: 69,
      numReviews: 57,
      ratingsInPeriod: 3,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-17',
      toDate: '2024-03-18',
      rating: 4.8,
      numRatings: 70,
      numReviews: 58,
      ratingsInPeriod: 1,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    }
  ],
  'sample-sku-pillow': [
    {
      fromDate: '2024-02-20',
      toDate: '2024-02-21',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-21',
      toDate: '2024-02-22',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-22',
      toDate: '2024-02-23',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-23',
      toDate: '2024-02-24',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-24',
      toDate: '2024-02-25',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-25',
      toDate: '2024-02-26',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-26',
      toDate: '2024-02-27',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-02-27',
      toDate: '2024-03-02',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-02',
      toDate: '2024-03-03',
      rating: 5,
      numRatings: 1,
      numReviews: 1,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-03',
      toDate: '2024-03-04',
      rating: 4.5,
      numRatings: 3793,
      numReviews: 1325,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-04',
      toDate: '2024-03-05',
      rating: 4.5,
      numRatings: 3796,
      numReviews: 1325,
      ratingsInPeriod: 3,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-05',
      toDate: '2024-03-06',
      rating: 4.5,
      numRatings: 3793,
      numReviews: 1325,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-06',
      toDate: '2024-03-07',
      rating: 4.5,
      numRatings: 3793,
      numReviews: 1325,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-07',
      toDate: '2024-03-08',
      rating: 4.5,
      numRatings: 3796,
      numReviews: 3793,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-08',
      toDate: '2024-03-09',
      rating: 4.5,
      numRatings: 3800,
      numReviews: 3793,
      ratingsInPeriod: 2,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-09',
      toDate: '2024-03-10',
      rating: 4.5,
      numRatings: 3810,
      numReviews: 3793,
      ratingsInPeriod: 5,
      momentumRatingCount: 0,
      vineReviewCount: 4,
      vineRating: 4.75,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 4,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-10',
      toDate: '2024-03-11',
      rating: 4.5,
      numRatings: 3814,
      numReviews: 1343,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 4,
      vineRating: 4.5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 4,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-11',
      toDate: '2024-03-12',
      rating: 4.5,
      numRatings: 3821,
      numReviews: 1350,
      ratingsInPeriod: 3,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-12',
      toDate: '2024-03-13',
      rating: 4.5,
      numRatings: 3827,
      numReviews: 1356,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-13',
      toDate: '2024-03-14',
      rating: 4.5,
      numRatings: 3834,
      numReviews: 1363,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-14',
      toDate: '2024-03-15',
      rating: 4.5,
      numRatings: 3838,
      numReviews: 1367,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 4.5,
      momentumReviewCount: 2,
      momentumReviewRating: 4.9,
      reviewsInPeriod: 4,
      reviews: [
        {
          title: 'so comfortable',
          text: 'I love how fluffy and comfortable these pillows are! I love that it also comes with extra fluff so you can adjust the firmness of it!',
          name: 'Denise Ferreira',
          rating: 5,
          attributedUserId: '64a80d6a-9f09-4c5d-ab07-9ddb51765fb2',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        }
      ],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-15',
      toDate: '2024-03-16',
      rating: 4.5,
      numRatings: 3843,
      numReviews: 1372,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-16',
      toDate: '2024-03-17',
      rating: 4.5,
      numRatings: 3849,
      numReviews: 1379,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 4.5,
      momentumReviewCount: 2,
      momentumReviewRating: 5,
      reviewsInPeriod: 4,
      reviews: [
        {
          title: 'Best down alternative pillow ever',
          text: 'These pillows are amazing! The down touch without the sneezing and itchy skin that down pillows give me! 10 out of 10!!!',
          name: 'kaci collins',
          rating: 5,
          attributedUserId: '2968eea7-6a78-41af-a0f2-c5a912b97069',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        },
        {
          title: 'New favorite pillows!',
          text: 'These pillows are so good! I love how you can customize them to your liking by either adding or taking out the fluff. Best find ever!',
          name: 'Rachel Meador',
          rating: 5,
          attributedUserId: '75dc42a7-0d62-4ccc-94da-f8c506af0b7c',
          id: uniqueId(),
          reviewedAt: '2023-08-09T16:44:19.000Z',
          isVine: false
        }
      ],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-17',
      toDate: '2024-03-18',
      rating: 4.5,
      numRatings: 3853,
      numReviews: 1382,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 6,
      vineRating: 4.666666666666667,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 7,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-18',
      toDate: '2024-03-19',
      rating: 4.5,
      numRatings: 3861,
      numReviews: 1390,
      ratingsInPeriod: 5,
      momentumRatingCount: 5,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 1,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-19',
      toDate: '2024-03-20',
      rating: 4.5,
      numRatings: 3868,
      numReviews: 1397,
      ratingsInPeriod: 4,
      momentumRatingCount: 2,
      vineReviewCount: 1,
      vineRating: 5,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-20',
      toDate: '2024-03-21',
      rating: 4.5,
      numRatings: 3871,
      numReviews: 1400,
      ratingsInPeriod: 0,
      momentumRatingCount: 0,
      vineReviewCount: 2,
      vineRating: 5,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 4,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-21',
      toDate: '2024-03-22',
      rating: 4.5,
      numRatings: 3874,
      numReviews: 1403,
      ratingsInPeriod: 1,
      momentumRatingCount: 1,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 1,
      momentumReviewRating: 4.8,
      reviewsInPeriod: 2,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-22',
      toDate: '2024-03-23',
      rating: 4.5,
      numRatings: 3877,
      numReviews: 1406,
      ratingsInPeriod: 3,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    },
    {
      fromDate: '2024-03-23',
      toDate: '2024-03-24',
      rating: 4.5,
      numRatings: 3880,
      numReviews: 1409,
      ratingsInPeriod: 3,
      momentumRatingCount: 0,
      vineReviewCount: 0,
      vineRating: 0,
      momentumReviewCount: 0,
      momentumReviewRating: 0,
      reviewsInPeriod: 0,
      reviews: [],
      momentumRating: 0,
      organicRating: 0,
      organicRatingCount: 0
    }
  ]
}
