import { Grid, Box, Stack, Typography, Divider } from '@mui/material'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { DateTime } from 'luxon'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { min, max, minBy, orderBy, sum, last, keyBy } from 'lodash'
import { notEmpty } from '@productwindtom/shared-node'
import {
  CreatorType,
  Channel,
  PricingCreatorType,
  CreatorApprovalStatus
} from '@productwindtom/shared-momentum-zeus-types'
import Loading from '@momentum/components/loading'
import {
  getStringForStep,
  getTimelineSteps,
  LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS,
  getAutoBuysDistribution,
  toWeeklyPartition
} from '@productwindtom/shared-momentum'

export const Timeline = () => {
  const { brand } = useBrandContext()
  const { campaignDetails, liftCampaignDetails, campaignCreatorsForApproval, reviewMetrics, productEtailerMetrics } =
    useCampaignContext()

  const now = DateTime.now()
  const weeklyUnitsSoldArray = (productEtailerMetrics || [])
    .filter(u => DateTime.fromISO(u.date).plus({ days: 7 }) > now.startOf('day'))
    .map(m => m.unitsSold)

  const weeklyUnitsSold = weeklyUnitsSoldArray.length > 5 ? sum(weeklyUnitsSoldArray) : undefined

  const liftBrandAdvocatesPurchaseDates =
    campaignDetails.numAdditionalBrandAdvocateCreators && liftCampaignDetails
      ? orderBy(
          liftCampaignDetails.creators.map(c => c.purchaseDetails?.purchaseDate).filter(notEmpty),
          c => c,
          'asc'
        ).slice(0, campaignDetails.numAdditionalBrandAdvocateCreators)
      : []

  const brandAdvocatesTotal =
    (campaignDetails.numBrandAdvocateCreators || 0) + (campaignDetails.numAdditionalBrandAdvocateCreators || 0)
  const autoBuysDistribution = getAutoBuysDistribution({
    creatorPricing: [
      { type: PricingCreatorType.ADVOCATE, numCreators: brandAdvocatesTotal },
      { type: PricingCreatorType.UGC, numCreators: campaignDetails.numUgcCreators || 0 },
      { type: PricingCreatorType.PREMIUM_UGC, numCreators: campaignDetails.numPremiumUgcCreators || 0 },
      { type: PricingCreatorType.SOCIAL, numCreators: campaignDetails.numSocialCreators || 0 }
    ]
  })
  const keyedAutoBuys = keyBy(autoBuysDistribution, 'type')

  const schedule = campaignDetails.releaseSchedule ?? {
    brandAdvocatesWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.ADVOCATE]?.dailySchedule),
    ugcWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.UGC]?.dailySchedule),
    premiumUgcWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.PREMIUM_UGC]?.dailySchedule),
    socialWeeklySchedule: toWeeklyPartition(keyedAutoBuys[PricingCreatorType.SOCIAL]?.dailySchedule)
  }

  const { expectedReviewsCount, expectedSocialPostCount, expectedUgcPostCount, expectedPremiumUgcPostCount } =
    campaignDetails

  const firstCreatorApprovalAction = min(
    (campaignCreatorsForApproval || []).map(c =>
      CreatorApprovalStatus.APPROVED === c.approvalState.status ? c.approvalState.updatedAt : undefined
    )
  )

  const contentCreators = campaignDetails.creators.filter(
    c => c.purchaseDetails && c.creatorType !== CreatorType.ADVOCATE
  )

  const socialContent = orderBy(
    contentCreators
      .flatMap(c => c.content)
      .filter(c => c.channel !== Channel.UGC)
      .flatMap(c => c.content.map(cc => ({ ...cc, submittedAt: c.submittedAt }))),
    c => c.submittedAt,
    'asc'
  )
  const ugcContent = orderBy(
    contentCreators
      .filter(c => c.creatorType !== CreatorType.PREMIUM_UGC)
      .flatMap(c => c.content)
      .filter(c => c.channel === Channel.UGC)
      .flatMap(c => c.content.map(cc => ({ ...cc, submittedAt: c.submittedAt }))),
    c => c.submittedAt,
    'asc'
  )
  const premiumUgcContent = orderBy(
    contentCreators
      .filter(c => c.creatorType === CreatorType.PREMIUM_UGC)
      .flatMap(c => c.content)
      .flatMap(c => c.content.map(cc => ({ ...cc, submittedAt: c.submittedAt }))),
    c => c.submittedAt,
    'asc'
  )

  const contentCompletedAt =
    (expectedSocialPostCount == null || socialContent.length >= expectedSocialPostCount) &&
    (expectedUgcPostCount == null || ugcContent.length >= expectedUgcPostCount) &&
    (expectedPremiumUgcPostCount == null || premiumUgcContent.length >= expectedPremiumUgcPostCount)
      ? max([
          ...(expectedSocialPostCount != null ? [socialContent[expectedSocialPostCount - 1]?.submittedAt] : []),
          ...(expectedUgcPostCount != null ? [ugcContent[expectedUgcPostCount - 1]?.submittedAt] : []),
          ...(expectedPremiumUgcPostCount != null
            ? [premiumUgcContent[expectedPremiumUgcPostCount - 1]?.submittedAt]
            : [])
        ])
      : undefined

  const content = contentCreators.flatMap(c => c.content)
  const firstApprovalContent = minBy(content, c => c.createdAt)?.createdAt

  const purchaseDates = campaignDetails.creators.map(c => c.purchaseDetails?.purchaseDate).filter(notEmpty)
  const firstPurchase = min([...purchaseDates, ...liftBrandAdvocatesPurchaseDates])
  const lastPurchase = max([...purchaseDates, ...liftBrandAdvocatesPurchaseDates])
  const purchasingComplete = campaignDetails.numCreatorsJoined >= campaignDetails.numCreators

  const reviewsDates = (reviewMetrics || []).filter(r => r.momentumReviewCount > 0).map(r => r.fromDate)
  const firstReview = min(reviewsDates)
  const reviewsAccumulated = (reviewMetrics || []).reduce(
    (acc: { date: string; momentumReviewCount: number }[], r) => [
      ...acc,
      { date: r.fromDate, momentumReviewCount: (last(acc)?.momentumReviewCount || 0) + r.momentumReviewCount }
    ],
    []
  )
  const reviewsGoalHit =
    expectedReviewsCount != null
      ? reviewsAccumulated.find(r => r.momentumReviewCount >= expectedReviewsCount)?.date
      : undefined

  const startDate = DateTime.fromISO(campaignDetails.startDate).startOf('day')
  // Handle when start is delayed
  const timelineStartDate =
    !firstCreatorApprovalAction && startDate.minus({ days: LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS }) < now
      ? now.plus({ days: 1 + LEADING_DAYS_TO_APPROVE_INITIAL_CREATORS }).startOf('day')
      : startDate

  const timelineSteps = getTimelineSteps(
    {
      ...schedule,
      reviewsGoal: expectedReviewsCount,
      unitsSoldPerWeek: weeklyUnitsSold,
      isVendor: !!brand.brandApis.find(api => api.enabled && api.api.includes('vendor')),
      startDate: firstPurchase ? DateTime.fromISO(firstPurchase) : timelineStartDate,
      isAdaptive: true
    },
    {
      lastPurchaseAt: purchasingComplete && lastPurchase ? DateTime.fromISO(lastPurchase) : undefined,
      firstReviewAt: firstReview ? DateTime.fromISO(firstReview) : undefined,
      allContentCompletedAt: contentCompletedAt ? DateTime.fromISO(contentCompletedAt) : undefined,
      initialCreatorApprovedAt: firstCreatorApprovalAction ? DateTime.fromISO(firstCreatorApprovalAction) : undefined,
      initialContentApprovedAt: firstApprovalContent ? DateTime.fromISO(firstApprovalContent) : undefined,
      reviewsGoalHitAt: reviewsGoalHit ? DateTime.fromISO(reviewsGoalHit) : undefined,
      reviewsPerWeek: reviewMetrics?.length,
      endDate: campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate) : undefined
    }
  )

  const subtractIndex = timelineSteps
    .slice()
    .reverse()
    .findIndex(s => s.date && s.date <= DateTime.now())

  const activeIndex = subtractIndex === -1 ? 0 : timelineSteps.length - subtractIndex

  if (campaignDetails.liftCampaignId && !liftCampaignDetails) {
    return <Loading />
  }

  return (
    <Grid container alignItems={'center'} columns={timelineSteps.length} height={'110px'}>
      {timelineSteps.map((s, index) => (
        <Grid key={index} item height={'100%'} xs={1} justifyItems={'center'} position={'relative'}>
          <Box sx={{ height: 'calc(100% - 50% - 12px)' }} alignContent={'end'}>
            <Stack height={'100%'} justifyContent={'flex-end'}>
              <Typography
                textAlign={'center'}
                variant={'caption1'}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 0 ? getStringForStep(s) : ''}
              </Typography>
              <Typography
                textAlign={'center'}
                variant={'caption2'}
                minHeight={'1em'}
                pt={0.5}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 0 ? s.date?.toLocaleString(DateTime.DATE_MED) : ''}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              my: 1,
              display: 'flex',
              marginX: 'auto',
              height: 24,
              width: 24,
              borderRadius: '50%',
              backgroundColor: theme => (index < activeIndex ? theme.palette.primary.main : theme.palette.grey.A400)
            }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography variant={'caption'} color={'white'}>
              {index + 1}
            </Typography>
          </Box>
          <Box sx={{ height: 'calc(100% - 50% - 12px)' }} alignContent={'top'}>
            <Stack>
              <Typography
                textAlign={'center'}
                variant={'caption1'}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 1 ? getStringForStep(s) : ''}
              </Typography>

              <Typography
                textAlign={'center'}
                pt={0.5}
                variant={'caption2'}
                color={theme => (index < activeIndex ? 'black' : theme.palette.grey.A400)}
              >
                {index % 2 === 1 ? s.date?.toLocaleString(DateTime.DATE_MED) : ' '}
              </Typography>
            </Stack>
          </Box>
          {index < timelineSteps.length - 1 && (
            <Divider
              sx={{
                my: 1,
                position: 'absolute',
                top: '50%',
                left: '70%',
                width: '60%',
                borderColor: theme => (index < activeIndex ? theme.palette.primary.main : 'action')
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}
