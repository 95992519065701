import { ContentType, CreatorType, PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { FinancialFlowSummary, FinancialSummaryCreatorType } from '../../types'
import { ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { isString, isNumber } from 'lodash'
import { getCreatorReviewRate } from '@productwindtom/shared-momentum'

export const useFlowSummaryConfig = (
  creatorPricing: ProposalCreatorPricingInput[],
  creatorsByType: FinancialSummaryCreatorType,
  liftNumCreators?: number,
  liftCloseoutBonus?: number
): FinancialFlowSummary[] => {
  return [
    {
      title: 'UGC creators',
      contentTypes: [ContentType.UGC_VIDEO, ContentType.UGC_PHOTO, ContentType.REVIEW_PHOTO, ContentType.REVIEW_TEXT],
      ...generateBaseProps({
        creatorPricing,
        creatorsByType,
        creatorType: CreatorType.UGC,
        pricingCreatorType: PricingCreatorType.UGC
      })
    },
    {
      title: 'Premium UGC creators',
      contentTypes: [ContentType.UGC_VIDEO, ContentType.UGC_PHOTO, ContentType.REVIEW_PHOTO, ContentType.REVIEW_TEXT],
      ...generateBaseProps({
        creatorPricing,
        creatorsByType,
        creatorType: CreatorType.PREMIUM_UGC,
        pricingCreatorType: PricingCreatorType.PREMIUM_UGC
      })
    },
    {
      title: 'Instagram creators',
      contentTypes: [
        ContentType.IG_STORY,
        ContentType.IG_POST,
        ContentType.IG_VIDEO,
        ContentType.IG_REEL,
        ContentType.REVIEW_PHOTO,
        ContentType.REVIEW_TEXT
      ],
      ...generateBaseProps({
        creatorPricing,
        creatorsByType,
        creatorType: CreatorType.IG,
        pricingCreatorType: PricingCreatorType.SOCIAL
      })
    },
    {
      title: 'Tiktok creators',
      contentTypes: [ContentType.TT_VIDEO, ContentType.TT_STORY, ContentType.REVIEW_PHOTO, ContentType.REVIEW_TEXT],
      ...generateBaseProps({
        creatorPricing,
        creatorsByType,
        creatorType: CreatorType.TT,
        pricingCreatorType: PricingCreatorType.SOCIAL
      })
    },
    {
      title: 'Brand advocates',
      ...generateBaseProps({
        creatorPricing,
        creatorsByType,
        creatorType: CreatorType.ADVOCATE,
        pricingCreatorType: PricingCreatorType.ADVOCATE
      })
    },
    {
      title: 'Thank-you creators',
      price: 0,
      numCreators: liftNumCreators || 0,
      expectedReviews: 0,
      totalPayment: liftCloseoutBonus || 0,
      totalContentPayment: 0
    }
  ]
}

const generateBaseProps = ({
  creatorPricing,
  creatorsByType,
  pricingCreatorType,
  creatorType
}: {
  creatorPricing: ProposalCreatorPricingInput[]
  creatorsByType: FinancialSummaryCreatorType
  pricingCreatorType: PricingCreatorType
  creatorType: CreatorType
}) => {
  const isSocial = pricingCreatorType === PricingCreatorType.SOCIAL

  const socialCreatorPricing = creatorsByType[PricingCreatorType.SOCIAL]?.socialCreatorPricing
  const pricing = socialCreatorPricing?.find(c => c.type === creatorType)

  const creatorPricingObj = isSocial ? pricing : creatorsByType?.[pricingCreatorType]
  const inputIndex = creatorPricing.findIndex(c => c.type === pricingCreatorType)

  const totalContentPayment =
    (creatorPricingObj?.contentRequirements || []).reduce((acc, item) => acc + (item.bonus || 0) / 100, 0) || 0

  const closeOutBonus = (creatorPricingObj?.closeoutBonus || 0) / 100
  const numCreators = creatorPricingObj?.numCreators || 0
  const price = creatorPricingObj?.price || 0

  return {
    creatorType,
    pricingCreatorType: pricingCreatorType,
    inputPath: [
      'creatorPricing',
      inputIndex,
      isSocial &&
        socialCreatorPricing &&
        `socialCreatorPricing.${socialCreatorPricing.findIndex(c => c.type === creatorType)}`
    ]
      .filter(val => isString(val) || isNumber(val))
      .join('.'),
    price,
    numCreators,
    expectedReviews: getCreatorReviewRate(creatorPricingObj?.numCreators, creatorType),
    totalContentPayment,
    totalPayment: totalContentPayment + closeOutBonus
  }
}
