import ReactDOM from 'react-dom/client'
import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material'
import { Amplify } from 'aws-amplify'
import { APPSYNC_CONFIG, ENV, UNLEASH_CLIENT_ID, MIXPANEL_TOKEN } from './env'
import { LicenseInfo } from '@mui/x-license'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { FlagProvider } from '@unleash/proxy-client-react'
import { KeyboardArrowDown } from '@mui/icons-material'
//@ts-ignore
import {} from '@mui/x-data-grid/themeAugmentation'
//@ts-ignore
import {} from '@mui/x-data-grid-pro/themeAugmentation'
//@ts-ignore
import {} from '@mui/x-data-grid-premium/themeAugmentation'
import { useNavigationType, useLocation, matchRoutes, createRoutesFromChildren } from 'react-router-dom'
import ErrorState from './components/error-state'

LicenseInfo.setLicenseKey(
  '2c23147fd54cccf10199c1fcb9232380Tz05NzAzNCxFPTE3NTY0MjM3NjYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
)

mixpanel.init(MIXPANEL_TOKEN, { track_pageview: true, persistence: 'localStorage', test: ENV === 'dev' })

if (ENV === 'dev') {
  mixpanel.disable()
}

Amplify.configure({
  aws_project_region: 'us-east-2',
  aws_cognito_region: 'us-east-2',
  aws_mandatory_sign_in: 'enable',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  ...APPSYNC_CONFIG
})

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    mSmall: true
  }
}

declare module '@mui/material/styles' {
  interface CustomPalette {}
  interface PaletteColor {}

  interface SimplePaletteColorOptions {}

  interface Palette extends CustomPalette {
    white1?: PaletteOptions['primary']
  }
  interface PaletteOptions extends CustomPalette {
    white1?: PaletteOptions['primary']
  }

  interface TypographyVariants {
    caption1: React.CSSProperties
    caption2: React.CSSProperties
    caption3: React.CSSProperties
    label1: React.CSSProperties
    label2: React.CSSProperties
    label3: React.CSSProperties
    label4: React.CSSProperties
    link1: React.CSSProperties
    link2: React.CSSProperties
    button1: React.CSSProperties
    button2: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption1: React.CSSProperties
    caption2: React.CSSProperties
    caption3: React.CSSProperties
    label1: React.CSSProperties
    label2: React.CSSProperties
    label3: React.CSSProperties
    label4: React.CSSProperties
    link1: React.CSSProperties
    link2: React.CSSProperties
    button1: React.CSSProperties
    button2: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption1: true
    caption2: true
    caption3: true
    label1: true
    label2: true
    label3: true
    label4: true
    link1: true
    link2: true
    button1: true
    button2: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white1: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    xSmall: true
  }
}

const typography = {
  fontFamily: ['"Avenir"'].join(','),
  allVariants: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    letterSpacing: '0.02em'
  },
  h1: {
    fontSize: '48px',
    fontWeight: 800,
    letterSpacing: '2px'
  },
  h2: {
    fontSize: '36px',
    fontWeight: 800,
    letterSpacing: '2px'
  },
  h3: {
    fontSize: '24px',
    fontWeight: 800,
    letterSpacing: '0.48px'
  },
  h4: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '0.4px'
  },
  h5: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '0.4px'
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px',
    lineHeight: 'normal'
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.28px'
  },
  formPrimaryText: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.28px'
  },
  formSubText: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 300,
    letterSpacing: '0.28px'
  },
  body1: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.24px',
    lineHeight: 'normal'
  },
  body2: {
    fontSize: '12px',
    fontWeight: 300,
    letterSpacing: '0.24px',
    lineHeight: 'normal'
  },
  caption1: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    fontWeight: 800,
    letterSpacing: '0.25px'
  },
  caption2: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    fontWeight: 400,
    letterSpacing: '0.25px'
  },
  caption3: {
    fontFamily: 'Avenir',
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.25px'
  },
  overline: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '1px'
  },
  label1: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  label2: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  label3: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.28px'
  },
  label4: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.28px'
  },
  link1: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  link2: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 800,
    letterSpacing: '0.24px'
  },
  button1: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  button2: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 800,
    letterSpacing: '0.24px',
    lineHeight: '24px'
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1084,
      lg: 1280,
      xl: 1536
    }
  },
  typography,
  palette: {
    primary: {
      light: '#7C8EE1',
      main: '#324AA8',
      dark: '#243C98',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#258987',
      light: '#92C4C3'
    },
    grey: {
      A100: '#FAFAFA',
      A200: '#EAEAEA',
      A400: '#C4C4C4',
      A700: '#767676'
    },
    success: {
      main: '#12B76A',
      light: '#AAD994',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#F3C94E',
      light: '#FDF4DC'
    },
    error: {
      main: '#EA0000'
    },
    info: {
      main: '#4668EE',
      contrastText: '#FFFFFF'
    },
    white1: {
      main: '#FFFFFF'
    }
  }
})
theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        sizeSmall: {
          height: 32,
          ...typography.button1
        },
        sizeMedium: {
          ...typography.button1
        },
        contained: {},
        text: {
          'padding': '0px',
          ':hover': {
            background: 'transparent'
          }
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          'borderBottom': `1px solid #767676`,
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight: '0px'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight: '0px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '3px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'textTransform': 'none',
          'color': 'black',
          ...typography.subtitle2,
          'paddingLeft': 0,
          'paddingRight': 0,
          'paddingTop': '3px',
          'paddingBottom': '3px',
          'minWidth': 0,
          'marginRight': '35px',

          '&.Mui-selected': {
            color: 'black',
            ...typography.subtitle1
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          border: 'none',
          height: '2px'
        },
        rail: {
          height: '2px'
        },
        mark: {
          backgroundColor: 'white',
          height: '2px',
          width: '2px',
          borderRadius: '50%'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'white'
        },
        input: {
          'height': '1.1em',
          'padding': '10px 8px 10px 8px',
          ...typography.subtitle2,
          '&::placeholder': {
            ...typography.subtitle2
          }
        },
        multiline: {
          padding: '0px'
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <KeyboardArrowDown />
      },
      styleOverrides: {
        inputRoot: {
          padding: '0px',
          paddingRight: '30px !important'
        },
        option: {
          ...typography.label3
        },
        input: {
          padding: '10px 10px 10px 10px !important'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDown
      },
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        variant: 'link1',
        underline: 'hover'
      }
    },
    MuiChip: {
      variants: [
        {
          props: { size: 'xSmall' },
          style: {
            'height': 20,
            'paddingLeft': '4px',
            'paddingRight': '0px',
            'paddingTop': '0px',
            'paddingBottom': '0px',
            '&.MuiChip-icon': {
              height: '16px',
              width: '16px'
            }
          }
        }
      ],
      styleOverrides: {
        filledPrimary: {
          backgroundColor: '#002880'
        },
        filledSecondary: {
          backgroundColor: '#EBEDF6',
          color: '#324AA8'
        },
        filledWarning: {
          backgroundColor: '#FDF4DC',
          color: 'black'
        },
        iconColorWarning: {
          color: theme.palette.warning.main
        },
        colorSuccess: {
          backgroundColor: '#AAD994',
          color: 'black'
        },
        colorInfo: {
          backgroundColor: '#B3DAFF',
          color: 'black'
        },
        labelSmall: {
          ...typography.body1
        },
        labelMedium: {
          ...typography.label3,
          padding: '4px 16px '
        },
        labelXSmall: {
          ...typography.caption3,
          paddingLeft: '8px',
          paddingRight: '8px'
        },
        iconMedium: {
          marginLeft: '16px'
        },
        iconXSmall: {
          height: '16px',
          width: '16px'
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {}
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'mSmall' },
          style: {
            fontSize: '16px'
          }
        }
      ],
      styleOverrides: {
        fontSizeSmall: {
          height: '12px',
          fontSize: '12px'
        },
        fontSizeMedium: {
          height: '24px',
          fontSize: '24px'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '4px'
        },
        elevation: {
          boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.12)',
          border: '1px solid #EAEAEA'
        },
        elevation0: {
          boxShadow: 'none !important'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {}
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px'
        }
      }
    },

    MuiIcon: {
      styleOverrides: {
        fontSizeSmall: {
          height: '12px',
          fontSize: '12px'
        },
        fontSizeLarge: {
          height: '24px',
          fontSize: '24px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '4px',
          border: '1px solid #EAEAEA',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        outlinedWarning: {
          borderColor: theme.palette.warning.main
        },
        outlinedSuccess: {
          borderColor: theme.palette.success.main
        }
      }
    },

    MuiDataGrid: {
      defaultProps: {
        disableRowSelectionOnClick: true,
        disableColumnSelector: true,
        disableDensitySelector: true
      }
    }
  }
})

Sentry.init({
  dsn: 'https://bbc71b94b236f5e894be96cd529f46d6@o506110.ingest.sentry.io/4506423963811840',
  environment: ENV,
  enabled: ['production', 'master'].includes(ENV || ''),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false
    }),
    Sentry.replayCanvasIntegration()
  ],
  normalizeDepth: 7,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const unleashConfig = {
  url: 'https://us.app.unleash-hosted.com/usee0001/api/frontend',
  clientKey: UNLEASH_CLIENT_ID!,
  appName: 'momentum',
  refreshInterval: 15 // How often (in seconds) the client should poll the proxy for updates
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorState disableLink>
          <FlagProvider config={unleashConfig}>
            <App />
          </FlagProvider>
        </ErrorState>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
export { getDefaultCreatorPricing } from '@momentum/utils/proposalUtils'
export { convertToCreatorPricing } from '@momentum/utils/proposalUtils'
export { PRICING_CREATOR_TO_CREATOR_TYPE } from '@momentum/utils/proposalUtils'
export { convertFromCreatorPricing } from '@momentum/utils/proposalUtils'
export { CREATOR_TYPE_TO_PRICING_CREATOR_TYPE } from '@momentum/utils/proposalUtils'
