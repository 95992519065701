import Metric from '@momentum/components/metric'
import { Button, Grid, Paper, Stack, Typography } from '@mui/material'
import useEstimatedDeliverables from '../useEstimatedDeliverables'
import { useFinancialSummaryContext } from '../../context/FinancialSummaryContext'
import { NumberInput } from '@productwindtom/ui-base'
import { useFormContext, useWatch } from 'react-hook-form'
import { getPercentValue, toLocaleCurrency } from '@productwindtom/shared-node'

const FinancialCampaignSummary = () => {
  const { flowSummary } = useFinancialSummaryContext()
  const { totalCreatorCostCredits = 0, totalProductCostCredits = 0, productPrice = 0 } = useEstimatedDeliverables()
  const { setValue } = useFormContext()

  const referralCost = (useWatch({ name: 'referralCost' }) || 0) / 100
  const operatingCost = (useWatch({ name: 'operatingCost' }) || 0) / 100

  const { costOfCreators, costOfProduct } = flowSummary.reduce(
    (acc, flow) => {
      if (flow.numCreators) {
        acc.costOfCreators += flow.numCreators * (flow.totalPayment || 0)
        acc.costOfProduct += flow.numCreators * ((productPrice || 0) / 100) * 1.1
      }

      return acc
    },
    {
      costOfCreators: 0,
      costOfProduct: 0
    }
  )

  const roundedCostOfProduct = Math.ceil(costOfProduct)

  const campaignFee = totalCreatorCostCredits + totalProductCostCredits
  const campaignCost = costOfCreators + roundedCostOfProduct || 0

  const campaignProfit = campaignFee - campaignCost
  const productProfit = totalProductCostCredits - roundedCostOfProduct
  const programProfit = totalCreatorCostCredits - costOfCreators

  
  const totalCampaignProfit = campaignProfit - referralCost - operatingCost

  const handleResetDefaults = () => {
    setValue('referralCost', 0)
    setValue('operatingCost', 0)
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h4">Campaign-level summary</Typography>
      <Paper sx={{ px: 4, py: 2 }}>
        <Stack spacing={3}>
          <Metric
            label="Campaign profit after referral fee"
            value={`${toLocaleCurrency(Math.round(totalCampaignProfit))} (${getPercentValue(totalCampaignProfit, campaignFee)}%)`}
          />
        </Stack>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={3}>
          <Grid
            container
            py={2}
            sx={{
              bgcolor: '#F8FBFF'
            }}
          >
            <Grid item flex={1}>
              <Metric label="Gross campaign revenue" value={toLocaleCurrency(Math.round(campaignFee))} />
            </Grid>
            <Grid item flex={1}>
              <Metric label="Gross cost of campaign" value={toLocaleCurrency(Math.round(campaignCost))} />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Gross campaign profit"
                value={`${toLocaleCurrency(Math.round(campaignProfit))} (${getPercentValue(campaignProfit, campaignFee)}%)`}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex={1}>
              <Metric label="Program revenue" value={toLocaleCurrency(Math.round(totalCreatorCostCredits))} />
            </Grid>
            <Grid item flex={1}>
              <Metric label="Cost of creator payments" value={toLocaleCurrency(Math.round(costOfCreators))} />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Program profit"
                value={`${toLocaleCurrency(Math.round(programProfit))} (${getPercentValue(programProfit, totalCreatorCostCredits)}%)`}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex={1}>
              <Metric
                label="Product fulfillment revenue"
                value={toLocaleCurrency(Math.round(totalProductCostCredits))}
              />
            </Grid>
            <Grid item flex={1}>
              <Metric label="Cost of product fulfillment" value={toLocaleCurrency(roundedCostOfProduct)} />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Product fulfillment profit"
                value={`${toLocaleCurrency(Math.round(productProfit))} (${getPercentValue(productProfit, totalProductCostCredits)}%)`}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex={1}>
              <Metric label="Referral revenue" value={'$0.00'} />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Cost of referral partner"
                value={
                  <NumberInput name="referralCost" InputProps={{ startAdornment: '-$' }} returnAsNumber returnAsCents />
                }
              />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Referral profit"
                value={`-${toLocaleCurrency(Math.round(referralCost))} (${referralCost ? '-100%' : '0%'})`}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex={1}>
              <Metric label="Managed services revenue" value={'$0.00'} />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Operating cost of campaign"
                value={
                  <NumberInput
                    name="operatingCost"
                    InputProps={{ startAdornment: '-$' }}
                    returnAsNumber
                    returnAsCents
                  />
                }
              />
            </Grid>
            <Grid item flex={1}>
              <Metric
                label="Managed services profit"
                value={`-${toLocaleCurrency(Math.round(operatingCost))} (${operatingCost ? '-100%' : '0%'})`}
              />
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Button sx={{ alignSelf: 'flex-start' }} variant="text" onClick={handleResetDefaults}>
        Reset to default
      </Button>
    </Stack>
  )
}

export default FinancialCampaignSummary
