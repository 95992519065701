import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'

// This
export const SUPPORTED_CREATOR_TYPES = [
  PricingCreatorType.ADVOCATE,
  PricingCreatorType.UGC,
  PricingCreatorType.PREMIUM_UGC,
  PricingCreatorType.SOCIAL
]

export const SUPPORTED_AGENCY_CREATOR_TYPES = [
  PricingCreatorType.ADVOCATE,
  PricingCreatorType.UGC
]

export const CREATOR_TYPE_PRICING_CONFIG: Record<
  PricingCreatorType,
  {
    title: string
    description: string
  }
> = {
  [PricingCreatorType.ADVOCATE]: {
    title: 'Brand Advocates',
    description:
      'Brand Advocates are excited to test and share your product. These creators are the best way to generate SEO wins, traffic and conversions.'
  },
  [PricingCreatorType.UGC]: {
    title: 'UGC Creators',
    description:
      'UGC creators love to write high-quality reviews. They create authentic content but do not publish it to social platforms.'
  },
  [PricingCreatorType.PREMIUM_UGC]: {
    title: 'Premium UGC Creators',
    description:
      'Premium UGC creators are professional content creators who produce high-quality content. They do not publish content to social platforms.'
  },
  [PricingCreatorType.SOCIAL]: {
    title: 'Social Creators',
    description:
      'Social creators are professional content creators who make and publish content to social platforms like Instagram and TikTok.'
  }
}

export const DEFAULT_PRICING: Record<
  PricingCreatorType,
  {
    min: number
    max: number
    default: number
  }
> = {
  [PricingCreatorType.UGC]: {
    min: 25000,
    max: 55000,
    default: 350
  },
  [PricingCreatorType.PREMIUM_UGC]: {
    min: 25000,
    max: 55000,
    default: 600
  },
  [PricingCreatorType.ADVOCATE]: {
    min: 3000,
    max: 7500,
    default: 50
  },
  [PricingCreatorType.SOCIAL]: {
    min: 120000,
    max: 180000,
    default: 1800
  }
}
