import { EstimatedTimeline } from '@momentum/routes/proposals-create/common/EstimatedTimeline'
import { Proposal } from '@momentum/routes/campaign/context/queries'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { DateTime } from 'luxon'
import { Box, Stack } from '@mui/system'
import { Divider } from '@mui/material'
import { useTimelineSteps } from '@momentum/routes/proposals-create/common/useTimelineSteps'

import { convertFromCreatorPricing } from '@momentum/utils/proposalUtils'

export const CampaignTimeline = ({ proposal }: { proposal: Proposal }) => {
  const { brand } = useBrandContext()

  const timelineSteps = useTimelineSteps({
    ...proposal,
    launchDate: DateTime.fromISO(proposal.launchDate),
    creatorPricing: convertFromCreatorPricing(proposal.creatorPricing),
    isVendor: brand.brandApis.some(b => b.api.includes('vendor') && b.enabled)
  })

  return (
    <Stack>
      <Box py={2}>
        <EstimatedTimeline {...timelineSteps} launchDate={DateTime.fromISO(proposal.launchDate)} />
      </Box>
      <Divider />
    </Stack>
  )
}
