import { yupResolver } from '@hookform/resolvers/yup'
import { BackButton } from '@momentum/components/back-button'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { updateAgency } from '@momentum/routes/brands/queries'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { Stack } from '@mui/material'
import { Form, SubmitButton } from '@productwindtom/ui-base'
import AgencyFormFields from './AgencyFormFields'
import { AgencyFormData } from './types'
import { agencyFormSchema } from './validation'

const AgencyForm = ({ onClose }: { onClose: () => void }) => {
  const { agency, refreshAgency } = useUserSessionContext()

  const handleSubmit = async (data: AgencyFormData) => {
    if (data.agency) {
      try {
        const { existingAgency: _, ...agency } = data.agency
        const result = await updateAgency(agency)

        await refreshAgency(result.id)
        successToast('Agency updated!')
        onClose()
      } catch (err) {
        errorToast()
      }
    }
  }
  return (
    <Form
      defaultValues={{
        agency: agency && {
          ...agency,
          existingAgency: true
        }
      }}
      onSubmit={handleSubmit}
      resolver={yupResolver(agencyFormSchema)}
    >
      <Stack spacing={2}>
        <AgencyFormFields agency={agency} />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onClose} text={'Cancel'} />
          <SubmitButton variant={'contained'}>Save</SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

export default AgencyForm
