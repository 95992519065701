import { mApi } from '@momentum/api'
import { GraphQLTypes, RecommendationType, Selector, InputType, Store } from '@productwindtom/shared-momentum-zeus-types'

export const productSelector = Selector('Product')({
  brandId: true,
  id: true,
  skuId: true,
  name: true,
  price: true,
  store: true,
  estimatedMonthlyUnitsSold: true,
  image: true,
  availabilityDate: true,
  categories: true,
  creationSource: true,
  creationSourceUrl: true,
  listingLink: true,
  listingFulfillmentType: true,
  isRecommendationsHidden: true,
  lastScrapedAt: true,
  ratingSummary: {
    rating: true,
    numRatings: true,
    numReviews: true
  },
  recommendations: {
    recommendationType: true,
    createdAt: true
  }
})

export type Product = InputType<GraphQLTypes['Product'], typeof productSelector>

export const searchRecommendations = async ({
  brandIds,
  recommendationTypes,
  stores,
  minShouldMatch,
  search,
  isArchived,
  archivedMinShouldMatch,
  sortBy
}: {
  brandIds: string[]
  stores: Store[]
  recommendationTypes: RecommendationType[]
  minShouldMatch: number
  search?: string
  isArchived?: boolean
  archivedMinShouldMatch: number
  sortBy: string
}) => {
  return (
    await mApi('query')({
      searchRecommendations: [
        { brandIds, stores, recommendationTypes, minShouldMatch, search, isArchived, archivedMinShouldMatch, sortBy },
        {
          items: productSelector
        }
      ]
    })
  ).searchRecommendations
}
