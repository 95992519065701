import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_CLICKS_LAUNDRY } from './laundry'

export const SAMPLE_CAMPAIGN_CLICKS: Record<string, ModelTypes['ModelItemsCampaignClick']> = {
  'sample-campaign-laundry': SAMPLE_CAMPAIGN_CLICKS_LAUNDRY,
  'sample-campaign-keyboard': {
    items: [
      {
        date: '2024-01-12',
        clicks: 162,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-13',
        clicks: 95,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-14',
        clicks: 134,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-15',
        clicks: 165,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-16',
        clicks: 133,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-17',
        clicks: 187,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-18',
        clicks: 87,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-19',
        clicks: 96,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-20',
        clicks: 116,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-21',
        clicks: 77,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-22',
        clicks: 79,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-23',
        clicks: 74,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-24',
        clicks: 64,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-25',
        clicks: 79,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-26',
        clicks: 204,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-27',
        clicks: 64,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-28',
        clicks: 104,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-29',
        clicks: 79,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-30',
        clicks: 70,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-31',
        clicks: 97,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-01',
        clicks: 89,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-02',
        clicks: 110,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-03',
        clicks: 39,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-04',
        clicks: 203,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-05',
        clicks: 318,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-06',
        clicks: 258,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-07',
        clicks: 181,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-08',
        clicks: 4,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-09',
        clicks: 6,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-10',
        clicks: 8,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-11',
        clicks: 4,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-12',
        clicks: 3,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-13',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-14',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-15',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-18',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-19',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-20',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-21',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-22',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-23',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-24',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-28',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-29',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-01',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-02',
        clicks: 3,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-03',
        clicks: 4,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-04',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-05',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-06',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-07',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-08',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-13',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-14',
        clicks: 4,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-15',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-22',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      }
    ]
  },
  'sample-campaign-whitening': {
    items: [
      {
        date: '2024-01-30',
        clicks: 582,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-01-31',
        clicks: 505,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-01',
        clicks: 539,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-02',
        clicks: 283,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-03',
        clicks: 186,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-04',
        clicks: 232,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-05',
        clicks: 504,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-06',
        clicks: 532,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-07',
        clicks: 546,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-08',
        clicks: 362,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-09',
        clicks: 280,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-10',
        clicks: 176,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-11',
        clicks: 110,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-12',
        clicks: 254,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-13',
        clicks: 357,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-14',
        clicks: 131,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-15',
        clicks: 187,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-16',
        clicks: 125,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-17',
        clicks: 112,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-18',
        clicks: 237,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-19',
        clicks: 171,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-20',
        clicks: 169,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-21',
        clicks: 405,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-22',
        clicks: 464,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-23',
        clicks: 285,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-24',
        clicks: 281,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-25',
        clicks: 378,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-26',
        clicks: 409,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-27',
        clicks: 427,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-28',
        clicks: 585,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-29',
        clicks: 253,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-01',
        clicks: 78,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-02',
        clicks: 33,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-03',
        clicks: 29,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-04',
        clicks: 28,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-05',
        clicks: 22,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-06',
        clicks: 20,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-07',
        clicks: 10,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-08',
        clicks: 16,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-09',
        clicks: 18,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-10',
        clicks: 21,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-11',
        clicks: 5,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-12',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-13',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-14',
        clicks: 3,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-15',
        clicks: 4,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-16',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-17',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-18',
        clicks: 3,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-21',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      }
    ]
  },
  'sample-campaign-marker': {
    items: [
      {
        date: '2024-02-26',
        clicks: 7,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-27',
        clicks: 10,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-28',
        clicks: 13,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-29',
        clicks: 125,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-01',
        clicks: 92,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-02',
        clicks: 63,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-03',
        clicks: 58,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-04',
        clicks: 75,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-05',
        clicks: 57,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-06',
        clicks: 54,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-07',
        clicks: 58,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-08',
        clicks: 36,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-09',
        clicks: 3,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-10',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-11',
        clicks: 2,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-13',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-15',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-20',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-23',
        clicks: 1,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      }
    ]
  },
  'sample-campaign-pillow': {
    items: [
      {
        date: '2024-02-26',
        clicks: 49,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-27',
        clicks: 48,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-28',
        clicks: 52,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-02-29',
        clicks: 45,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-01',
        clicks: 43,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-02',
        clicks: 33,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-03',
        clicks: 49,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-04',
        clicks: 68,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-05',
        clicks: 71,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-06',
        clicks: 64,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-07',
        clicks: 60,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-08',
        clicks: 46,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-09',
        clicks: 45,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-10',
        clicks: 38,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-11',
        clicks: 17,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-12',
        clicks: 10,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-13',
        clicks: 9,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-14',
        clicks: 9,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-15',
        clicks: 11,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-16',
        clicks: 6,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      },
      {
        date: '2024-03-17',
        clicks: 11,
        createdAt: '2024-01-12T00:00:00.000Z',
        updatedAt: '2024-01-12T00:00:00.000Z'
      }
    ]
  }
}
