import { CampaignStatus } from '@productwindtom/shared-ws-zeus-types'

export enum Flow {
  no_content_v1 = 'no_content_v1',
  no_content_no_bonus_v1 = 'no_content_no_bonus_v1',
  modularSocialContent_withReview_v1 = 'modularSocialContent_withReview_v1',
  modularSocialContent_noReview_v1 = 'modularSocialContent_noReview_v1',
  modularSocialContent_noPurchase_noReview_v1 = 'modularSocialContent_noPurchase_noReview_v1'
}

export type FlowDefinition = CampaignStatus[][]
const no_content_v1 = [
  [CampaignStatus.application_submitted],
  [CampaignStatus.purchase_holding_queue],
  [CampaignStatus.application_approved],
  [CampaignStatus.discovery_v2],
  [CampaignStatus.bonus_approved],
  [CampaignStatus.archived_completed, CampaignStatus.archived_not_completed]
]

const no_content_no_bonus_v1 = [
  [CampaignStatus.application_submitted],
  [CampaignStatus.purchase_holding_queue],
  [CampaignStatus.application_approved],
  [CampaignStatus.discovery_v2],
  [CampaignStatus.archived_completed, CampaignStatus.archived_not_completed]
]

const modularSocialContent_withReview_v1 = [
  [CampaignStatus.invite_offered],
  [CampaignStatus.application_submitted],
  [CampaignStatus.purchase_holding_queue],
  [CampaignStatus.application_approved],
  [CampaignStatus.discovery_v2],
  [CampaignStatus.create_content_v1],
  [CampaignStatus.assets_uploaded],
  [CampaignStatus.bonus_approved],
  [CampaignStatus.archived_completed, CampaignStatus.archived_not_completed]
]

const modularSocialContent_noReview_v1 = [
  [CampaignStatus.invite_offered],
  [CampaignStatus.application_submitted],
  [CampaignStatus.purchase_holding_queue],
  [CampaignStatus.application_approved],
  [CampaignStatus.discovery_v2],
  [CampaignStatus.create_content_v1],
  [CampaignStatus.bonus_approved],
  [CampaignStatus.archived_completed, CampaignStatus.archived_not_completed]
]

const modularSocialContent_noPurchase_noReview_v1 = [
  [CampaignStatus.invite_offered],
  [CampaignStatus.application_submitted],
  [CampaignStatus.purchase_holding_queue],
  [CampaignStatus.application_approved],
  [CampaignStatus.no_purchase_discovery_v1],
  [CampaignStatus.create_content_v1],
  [CampaignStatus.bonus_approved],
  [CampaignStatus.archived_completed, CampaignStatus.archived_not_completed]
]

export const FLOW_DEFINITIONS = {
  [Flow.no_content_v1]: no_content_v1,
  [Flow.no_content_no_bonus_v1]: no_content_no_bonus_v1,
  [Flow.modularSocialContent_withReview_v1]: modularSocialContent_withReview_v1,
  [Flow.modularSocialContent_noReview_v1]: modularSocialContent_noReview_v1,
  [Flow.modularSocialContent_noPurchase_noReview_v1]: modularSocialContent_noPurchase_noReview_v1
}

export const NO_CONTENT_FLOWS: { [key: string]: Flow } = Object.freeze({
  [Flow.no_content_v1]: Flow.no_content_v1,
  [Flow.no_content_no_bonus_v1]: Flow.no_content_no_bonus_v1
})

export const SOCIAL_ONLY_FLOWS = Object.freeze({
  [Flow.modularSocialContent_noReview_v1]: Flow.modularSocialContent_noReview_v1,
  [Flow.modularSocialContent_noPurchase_noReview_v1]: Flow.modularSocialContent_noPurchase_noReview_v1
})

export const SOCIAL_FLOWS = Object.freeze({
  ...SOCIAL_ONLY_FLOWS,
  [Flow.modularSocialContent_withReview_v1]: Flow.modularSocialContent_withReview_v1
})

export const NO_BONUS_FLOWS = Object.freeze({ [Flow.no_content_no_bonus_v1]: Flow.no_content_no_bonus_v1 })

export const NO_PURCHASE_FLOW = Object.freeze({
  [Flow.modularSocialContent_noPurchase_noReview_v1]: Flow.modularSocialContent_noPurchase_noReview_v1
})

export const REVIEW_FLOWS = Object.freeze({
  [Flow.modularSocialContent_withReview_v1]: Flow.modularSocialContent_withReview_v1
})
