import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'

export const calculateDatesToGoal = (
  product: CampaignPerformanceProduct,
  comparisonMetric?: PerformanceComparisonMetric,
  comparisonMetricValue?: number
) => {
  if (comparisonMetric && comparisonMetricValue) {
    let firstDate: string | undefined
    let finalDate: string | undefined
    if (comparisonMetric === PerformanceComparisonMetric.REVIEWS) {
      firstDate = product.productPerformanceRecords.find(r => r.reviewCount != null)?.date
      finalDate = product.productPerformanceRecords.find(
        r => r.reviewCount && r.reviewCount >= comparisonMetricValue
      )?.date
    } else if (comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK) {
      firstDate = product.productPerformanceRecords.find(r => r.salesRank != null)?.date
      finalDate = product.productPerformanceRecords.find(r => r.salesRank && r.salesRank <= comparisonMetricValue)?.date
    }
    if (firstDate && finalDate) {
      return { startDate: firstDate, endDate: finalDate }
    }
  }
}
