import {
  GraphQLTypes,
  InputType,
  PricingCreatorType,
  ProposalGoal,
  Selector,
  Store
} from '@productwindtom/shared-momentum-zeus-types'
import { first, groupBy, min, round, sum, sumBy } from 'lodash'

import { getPricingTypeFromCreatorType } from '../configs'
import { Retailer, STORE_TO_RETAILER } from '../types'

type CreatorInput = {
  type: PricingCreatorType
  numCreators: number
  price: number
}

type RatingSummary = {
  numRatings?: number
  rating?: number
}

export type CampaignProposalInput = {
  creators: CreatorInput[]
  productCost: number
  exchangeRate: number
  store?: Store
  ratingGoal?: number
  ratingsSummary?: RatingSummary
  estimatedAverageRating?: number
}

export type CampaignProposalResults = {
  numReviews: number
  endingRating?: number
  numUgcContent: number
  numSocialContent: number
  estimatedPdpViews: number
  campaignCpc: number
  durationWeeks: number
  improvesOrganicSeoPlacement: boolean
  improvesRetailMediaEfficiency: boolean
  estimatedPageOneWins: [number, number]

  totalCostCredits: number
  totalProductCostCredits: number
  totalCreatorCostCredits: number

  totalProductCostInLocalCurrency: number
}

const PRODUCT_COST_BUFFER = 1.1
const PAGE_ONE_WIN_PRICE_THRESHOLD = 40
const PAGE_ONE_WIN_ABOVE_THRESHOLD_INTERNAL_FACTOR = 0.07 // internal page one wins factor for products with price above threshold
const PAGE_ONE_WIN_BELOW_THRESHOLD_INTERNAL_FACTOR = 0.085
const PAGE_ONE_WIN_INTERNAL_MAX = 35 // maximum number of estimated internal page one wins
const PAGE_ONE_WIN_CLIENT_FACING_LOW_FACTOR = 0.25 // client facing page one wins factor over internal
const PAGE_ONE_WIN_CLIENT_FACING_HIGH_FACTOR = 0.5

export const calculateResults = (goal: ProposalGoal, data: CampaignProposalInput): CampaignProposalResults => {
  const numReviews = getNumReviews(data.creators, data.store)
  const endingRating = data.ratingsSummary
    ? getEndRating(numReviews, data.ratingsSummary, data.estimatedAverageRating)
    : undefined

  const ugcContent = sum(
    data.creators
      .filter(c => c.type === PricingCreatorType.UGC || c.type === PricingCreatorType.PREMIUM_UGC)
      .map(c => c.numCreators)
  )
  const socialContent = sum(data.creators.filter(c => c.type === PricingCreatorType.SOCIAL).map(c => c.numCreators)) * 2

  const totalCreatorCost = Math.ceil(sum(data.creators.map(c => c.numCreators * c.price)))
  const totalProductCost = Math.ceil(
    sum(data.creators.map(c => c.numCreators * (PRODUCT_COST_BUFFER * data.productCost * data.exchangeRate))) / 100
  )
  const totalProductCostInLocalCurrency = Math.ceil(
    sum(data.creators.map(c => c.numCreators * (PRODUCT_COST_BUFFER * data.productCost))) / 100
  )
  const viewsCalc = getViewsCalculations(data.productCost, data.creators)

  const durationWeeks = getDurationWeeks(goal, data.creators)

  const estimatedPageOneWins = getClientFacingEstimatedPageOneWins(data)

  return {
    numReviews,
    endingRating,
    numUgcContent: ugcContent,
    numSocialContent: socialContent,
    estimatedPdpViews: viewsCalc.pdp,
    estimatedPageOneWins,

    campaignCpc: viewsCalc.cpc,
    durationWeeks,
    improvesOrganicSeoPlacement: true,
    improvesRetailMediaEfficiency: true,
    totalCreatorCostCredits: totalCreatorCost,
    totalProductCostCredits: totalProductCost,
    totalCostCredits: totalCreatorCost + totalProductCost,

    totalProductCostInLocalCurrency
  }
}

export const getReviewRate = (retailer?: Retailer) => {
  switch (retailer) {
    case Retailer.AMAZON:
      return 0.5
    default:
      return 0.7
  }
}

const getNumReviews = (creators: CreatorInput[], store?: Store) => {
  const totalCreators = sum(
    creators
      .filter(c => [PricingCreatorType.UGC, PricingCreatorType.PREMIUM_UGC].includes(c.type))
      .map(c => c.numCreators)
  )

  return Math.round(totalCreators * getReviewRate(store ? STORE_TO_RETAILER[store] : undefined))
}

export const PW_RATING = 4.8
const getEndRating = (numReviews: number, ratingSummary: RatingSummary, averageEstimatedRating?: number) => {
  if (ratingSummary.rating == null) {
    return undefined
  }
  if (ratingSummary.rating >= (averageEstimatedRating || PW_RATING)) {
    return ratingSummary.rating
  }

  if (ratingSummary.numRatings == null) {
    return undefined
  }
  const newRating =
    (ratingSummary.numRatings * ratingSummary.rating + numReviews * (averageEstimatedRating || PW_RATING)) /
    (ratingSummary.numRatings + numReviews)

  return parseFloat(newRating.toFixed(1))
}

const PDP_FACTOR = 22
const getViewsCalculations = (productCost: number, creators: CreatorInput[]) => {
  const viewsPerCreatorType = creators.map(c => {
    const cost = c.numCreators * productCost
    const budget = c.numCreators * c.price
    const totalCost = budget + cost
    const pdp = c.type === PricingCreatorType.ADVOCATE ? c.numCreators * PDP_FACTOR : 0
    const cpc = (totalCost || 0) / ((pdp || 1) / 100)

    return { pdp, cpc }
  })
  return {
    pdp: sum(viewsPerCreatorType.map(c => c.pdp)),
    cpc: sum(viewsPerCreatorType.map(c => c.cpc))
  }
}

const getDurationWeeks = (goal: ProposalGoal, creators: CreatorInput[]) => {
  if (goal === ProposalGoal.BOOST_RATING) {
    //TODO: IMPLEMENT WHEN DETERMINED
    console.log('TODO: IMPLEMENT WHEN DETERMINED')
  }

  if (creators.find(c => c.type === PricingCreatorType.SOCIAL)) {
    return 6
  }
  return 4
}

const getClientFacingEstimatedPageOneWins = (data: CampaignProposalInput): [number, number] => {
  const internalPageOneWins = min([
    round(
      sumBy(data.creators, c => c.numCreators) *
        (data.productCost / 100 < PAGE_ONE_WIN_PRICE_THRESHOLD
          ? PAGE_ONE_WIN_BELOW_THRESHOLD_INTERNAL_FACTOR
          : PAGE_ONE_WIN_ABOVE_THRESHOLD_INTERNAL_FACTOR)
    ),
    PAGE_ONE_WIN_INTERNAL_MAX
  ])

  return [
    round(internalPageOneWins! * PAGE_ONE_WIN_CLIENT_FACING_LOW_FACTOR),
    round(internalPageOneWins! * PAGE_ONE_WIN_CLIENT_FACING_HIGH_FACTOR)
  ]
}

const proposalCreatorPricingSelector = Selector('ProposalCreatorPricing')({
  type: true,
  price: true,
  numCreators: true
})
type ProposalCreatorPricingConverterInput = InputType<
  GraphQLTypes['ProposalCreatorPricing'],
  typeof proposalCreatorPricingSelector
>

export const convertToCreatorInputFromProposalCreatorPricing = (
  proposalCreatorPricing: ProposalCreatorPricingConverterInput[]
): CreatorInput[] => {
  const mapped = proposalCreatorPricing.map(p => ({
    type: getPricingTypeFromCreatorType(p.type),
    price: p.price,
    numCreators: p.numCreators
  }))

  return Object.entries(groupBy(mapped, 'type')).map(([type, records]) => ({
    type: type as PricingCreatorType,
    price: first(records)!.price,
    numCreators: sumBy(records, 'numCreators')
  }))
}
