import ProductSelectorV2 from '@momentum/components/form-inputs/ProductSelector'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product, requestExchangeRate } from '@momentum/routes/proposals-create/context/queries'
import ProductVariationsInput from '@momentum/routes/proposals-create/product-creators/ProductVariationsInput'
import { useRecommendation } from '@momentum/routes/proposals-create/product-creators/useRecommendation'
import { ProposalCreateForm, ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { creatorProductsFormValidationSchema } from '@momentum/routes/proposals-create/validations'
import { CreatorPricing, Pricing } from '@momentum/routes/queries'
import { CREATOR_TYPE_PRICING_CONFIG, DEFAULT_PRICING } from '@momentum/utils/brandPricing'
import { CampaignGoalTypeToName } from '@momentum/utils/campaignGoalUtils'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import { mergeCreatorPricing, RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES } from '@momentum/utils/proposalUtils'
import { getCreatorPricingStore } from '@momentum/utils/storeUtils'
import { ArrowForwardOutlined, Cancel, CheckCircleOutlined, Close } from '@mui/icons-material'
import { Alert, Button, Grid, Tooltip, Typography, useTheme } from '@mui/material'
import { Box, Stack } from '@mui/system'
import {
  getAutoBuysDistribution,
  getSocialCreatorDistribution,
  STORE_TO_CURRENCY,
  toWeeklyPartition
} from '@productwindtom/shared-momentum'
import { CreatorType, PricingCreatorType, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { NumberInput, SubmitButton } from '@productwindtom/ui-base'
import { useFlag } from '@unleash/proxy-client-react'
import { keyBy, sumBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question } from '../../../components/proposal-common/Question'
import { useCreateProposalContext } from '../context/CreateProposalContext'

export const ProductCreators = () => {
  const selfServiceFlag = useFlag('ProposalSelfServiceSetup')
  const proposalRecommendationFlag = useFlag('ProposalRecommendation')
  const isProposalVariationsEnabled = useFlag('ProposalVariations')

  const { isAdminView } = useUserSessionContext()
  const { company, brand, loadingProducts, setIsAddProductActiveOrCallback } = useBrandContext()
  const { selectableProducts, selectedProduct, setSelectedProduct, recommendationType, proposal } =
    useCreateProposalContext()
  const { setValue, getValues, watch, trigger } = useFormContext<ProposalCreateForm>()

  const selectedProductId = watch('productId')
  const selectedGoal = watch('goal')
  const estimatedUnitsSoldPerMonth = watch('estimatedUnitsSoldPerMonth')

  const productPricingStore = selectedProduct?.store ? getCreatorPricingStore(selectedProduct.store) : undefined
  const brandPricing = brand.pricing.find(b => b.store === productPricingStore)
  const companyPricing = company.pricing.find(p => p.store === productPricingStore)
  const pricing = mergeCreatorPricing(brandPricing, companyPricing)

  const {
    warnings,
    isDifferent,
    creatorCounts: recommendationCreatorCounts,
    isSelfServiceDisabled,
    isSelfServiceSupported,
    saveRecommendation,
    savedRecommendation,
    clearSavedRecommendation
  } = useRecommendation()

  useEffect(() => {
    if (selectedProductId) {
      const foundProduct = selectableProducts?.find(p => p.id === selectedProductId)
      if (foundProduct) {
        setSelectedProduct(foundProduct)
      }
    } else if (selectedProduct && !selectedProductId) {
      // This is to handle when setting the selected product from add manual product.
      setSelectedProduct(undefined)
    }
  }, [selectedProduct, selectedProductId, selectableProducts, setSelectedProduct])

  // Refresh exchange rate every time product currency changes
  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.id === proposal?.productId) {
        setValue('exchangeRate', proposal.exchangeRate || 1)
      } else {
        requestExchangeRate(STORE_TO_CURRENCY[selectedProduct.store]).then(exchangeRate => {
          setValue('exchangeRate', exchangeRate)
        })
      }
    } else {
      setValue('exchangeRate', undefined)
    }
  }, [STORE_TO_CURRENCY[selectedProduct?.store!]])

  useEffect(() => {
    clearSavedRecommendation()
  }, [selectedGoal])

  useEffect(() => {
    if (selectedProduct) {
      setValue('productPrice', selectedProduct.priceCents)
      setValue('productRating', selectedProduct.ratingSummary?.rating)
      setValue('productRatingCount', selectedProduct.ratingSummary?.numRatings)
    }
  }, [selectedProduct, selectedGoal, setValue, getValues, recommendationType, pricing?.creatorPricing])

  const handleOnSelectProduct = (product?: Product) => {
    if (product) {
      setValue('estimatedUnitsSoldPerMonth', product?.estimatedMonthlyUnitsSold)
    }
  }

  const onAddProduct = () => {
    setIsAddProductActiveOrCallback(() => (product: Product) => {
      if (product) {
        setValue('productId', product.id)
        setValue('estimatedUnitsSoldPerMonth', product.estimatedMonthlyUnitsSold || estimatedUnitsSoldPerMonth)
      }
    })
  }

  const onGenerateRecommendation = () => {
    const vals = getValues()

    if (selectedProduct && recommendationCreatorCounts) {
      setValue(
        'creatorPricing',
        vals.creatorPricing.map(cp => ({ ...cp, numCreators: recommendationCreatorCounts[cp.type] || 0 }))
      )
      saveRecommendation?.()
      trigger('creatorPricing')
    }
  }

  const getDisabledGenerateButtonMessage = () => {
    if (!estimatedUnitsSoldPerMonth) {
      return 'Please enter how many units you expect to sell during the first 30 days of the campaign'
    }
    if (!selectedProduct) {
      return 'Please select a product'
    }
    if (!RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES.includes(selectedGoal)) {
      return 'Recommendations are not available for this campaign type'
    }
  }
  const disabledButtonMessage = getDisabledGenerateButtonMessage()
  const isEstimatedUnitSoldPerMonthError = !estimatedUnitsSoldPerMonth

  return (
    <Stack spacing={4} pb={2}>
      <Question
        primaryText={'Add product'}
        subtext={'Momentum helps products selling on Amazon, Walmart, Target and other retailers.'}
      >
        {!selectedProduct && (
          <Box data-cy={'productSelector'}>
            <ProductSelectorV2
              width={450}
              products={(selectableProducts || []).filter(p => p.priceCents)}
              onAddProductClick={onAddProduct}
              disableAddProduct={!isAdminView}
              loadingProducts={loadingProducts}
              onSelectProduct={handleOnSelectProduct}
            />
          </Box>
        )}
        {selectedProduct && <SelectedProduct product={selectedProduct} onRemove={() => setValue('productId', '')} />}
      </Question>
      {isProposalVariationsEnabled &&
        (selectableProducts.find(p => selectedProduct?.parentSkuId === p.id)?.childrenIds?.length ||
          selectedProduct?.childrenIds?.length ||
          0) > 1 && (
          <Question primaryText={'Select eligible product variations'}>
            <ProductVariationsInput />
          </Question>
        )}
      <Question primaryText={'Select campaign goal'}>
        <Box>
          <CampaignGoalSelector name={'goal'} isAgency={!!company.agencyId} />
        </Box>
      </Question>

      {!!selectedProduct && (
        <Question
          primaryText={
            'Without ProductWind, how many units would your product typically sell in the first 30 days of this campaign?'
          }
          subtext={
            'Do NOT include expected sales from this ProductWind campaign. ProductWind uses this to estimate when you will receive reviews & ratings.'
          }
        >
          <NumberInput
            name={'estimatedUnitsSoldPerMonth'}
            data-cy={'estimatedUnitsSoldPerMonth'}
            sx={{ maxWidth: '165px' }}
            placeholder={'Units sold'}
            returnAsNumber
            hideError
            hideZero
            error={isEstimatedUnitSoldPerMonthError}
            allowNegative={false}
          />
        </Question>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.BOOST_RATING && (
        <Question
          primaryText={'What is the product’s desired star rating?'}
          subtext={`The product currently has a ${selectedProduct.ratingSummary?.rating ?? '?'} star rating with ${selectedProduct.ratingSummary?.numRatings?.toLocaleString() ?? '?'} reviews and ratings.`}
        >
          <NumberInput
            name={'ratingGoal'}
            sx={{ maxWidth: '165px' }}
            placeholder={'Desired rating'}
            returnAsNumber
            allowNegative={false}
            max={5}
          />
        </Question>
      )}

      {!!selectedProduct && (
        <Stack spacing={2}>
          <Question
            subtext={'You can add creators or generate a recommendation based on your monthly sales'}
            primaryText={'Add creators'}
          >
            {proposalRecommendationFlag && isSelfServiceSupported && (
              <Stack direction={'row'} spacing={2} mb={2}>
                <Tooltip disableHoverListener={!disabledButtonMessage} title={disabledButtonMessage}>
                  <Grid item xs={'auto'}>
                    <Button
                      variant={'outlined'}
                      onClick={onGenerateRecommendation}
                      disabled={
                        (!isDifferent && !!savedRecommendation) ||
                        !RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES.includes(selectedGoal) ||
                        !selectedProduct ||
                        !estimatedUnitsSoldPerMonth
                      }
                      data-cy={'generateRecommendationButton'}
                    >
                      Generate recommendation
                    </Button>
                  </Grid>
                </Tooltip>
                {!!savedRecommendation && !isDifferent && (
                  <Alert
                    variant={'outlined'}
                    severity={'success'}
                    icon={<CheckCircleOutlined color={'success'} />}
                    sx={{ py: 0, px: 1 }}
                  >
                    <Typography variant={'caption2'} lineHeight={'18px'} flexShrink={1} color={'black'}>
                      {savedRecommendation.recommendationMessage}
                    </Typography>
                  </Alert>
                )}
              </Stack>
            )}

            <CreatorPricingInputList />
            {isAdminView && (
              <Box mt={2}>
                <OverridePricingSwitch brandPricing={pricing} />
              </Box>
            )}
          </Question>

          {isEstimatedUnitSoldPerMonthError && (
            <Alert variant={'outlined'} severity={'error'} color={'error'}>
              <Typography color={'black'} variant={'label3'}>
                You must enter your estimated units sold in order to proceed to the next step.
              </Typography>
            </Alert>
          )}

          {proposalRecommendationFlag && !!warnings.length && (
            <Stack spacing={1}>
              {warnings.map(w => (
                <Alert key={w.message} variant={'outlined'} severity={'warning'} color={'warning'}>
                  <Typography color={'black'} variant={'label3'}>
                    {w.message}
                  </Typography>
                </Alert>
              ))}
            </Stack>
          )}
        </Stack>
      )}
      <Stack direction={'row'} mt={4} justifyContent={'flex-end'} alignItems={'top'}>
        {selfServiceFlag && isSelfServiceDisabled && isSelfServiceSupported && (
          <Alert severity={'info'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              Self service is unavailable for campaigns with Premium UGC and Social creators.
            </Typography>
          </Alert>
        )}
        {selfServiceFlag && !isSelfServiceSupported && (
          <Alert severity={'info'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              Self service is unavailable for this campaign type.
            </Typography>
          </Alert>
        )}
        <Box ml={'auto'} pl={2}>
          {selfServiceFlag && isSelfServiceSupported && !isSelfServiceDisabled ? (
            <NextButton disabled={isSelfServiceDisabled} />
          ) : (
            <SubmitButton variant={'contained'} disableOnDirty={false} data-cy="saveProposal" sx={{ minWidth: 130 }}>
              Save proposal
            </SubmitButton>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

const OverridePricingSwitch = ({ brandPricing }: { brandPricing?: Pricing }) => {
  const { setValue, getValues, watch } = useFormContext()

  const isOverrideEnabled = watch('isOverridePricing')
  const resetPricing = () => {
    setValue(
      'creatorPricing',
      getValues('creatorPricing').map((p: CreatorPricing) => ({
        ...p,
        price: brandPricing?.creatorPricing.find(c => c.type === p.type)?.price || DEFAULT_PRICING[p.type].default
      }))
    )
    setValue('isOverridePricing', false)
  }

  const setOverrideEnabled = () => {
    setValue('isOverridePricing', true)
  }

  return (
    <Stack direction={'row'} spacing={2}>
      <Button variant={'text'} disabled={isOverrideEnabled} onClick={setOverrideEnabled}>
        Override pricing
      </Button>
      {!!isOverrideEnabled && (
        <Button variant={'text'} onClick={resetPricing}>
          Reset to default
        </Button>
      )}
    </Stack>
  )
}

const CampaignGoalSelector = ({ name, isAgency }: { name: string; isAgency?: boolean }) => {
  const proposalRecommendationFlag = useFlag('ProposalRecommendation')
  const goalTypes = [
    ProposalGoal.PRODUCT_LAUNCH,
    ...(proposalRecommendationFlag ? [ProposalGoal.EXISTING_PRODUCT] : []),
    ProposalGoal.BOOST_RATING,
    ...(!isAgency ? [ProposalGoal.EVERGREEN] : [])
  ]

  const { control } = useFormContext()
  const {
    field: { value, onChange }
  } = useController({ name, control })
  return (
    <Grid container spacing={2} alignItems={'center'}>
      {goalTypes.map(type => (
        <Grid item xs={3} key={type}>
          <CampaignGoalOption type={type} onClick={() => onChange(type)} selected={value === type} />
        </Grid>
      ))}
    </Grid>
  )
}

const CampaignGoalOption = ({
  type,
  onClick,
  selected
}: {
  type: ProposalGoal
  selected?: boolean
  onClick: () => void
}) => {
  const theme = useTheme()
  return (
    <Box
      borderRadius={'8px'}
      border={`${selected ? 2 : 1}px solid ${selected ? theme.palette.primary.main : '#EBEDF6'}`}
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <Box bgcolor={'#F8FBFF'} p={1} alignItems={'center'} borderRadius={'8px 0px 0px 8px'}>
          {CampaignGoalTypeToIcon[type]?.({ color: 'primary' })}
        </Box>
        <Box px={1}>
          <Typography variant={'body1'}>{CampaignGoalTypeToName[type]}</Typography>
        </Box>
      </Stack>
    </Box>
  )
}

const SelectedProduct = ({ product, onRemove }: { product: Product; onRemove: () => void }) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <CheckCircleOutlined color={'success'} />
      <Typography variant={'label3'}>{product?.name}</Typography>
      <Cancel
        sx={{ cursor: 'pointer', marginLeft: 'auto !important' }}
        onClick={onRemove}
        fontSize={'mSmall'}
        color={'action'}
      />
    </Stack>
  )
}

const CreatorPricingInputList = () => {
  const { watch } = useFormContext<ProposalCreateForm>()
  const pricing = watch('creatorPricing')
  const { warnings } = useRecommendation()
  const proposalRecommendationFlag = useFlag('ProposalRecommendation')

  return (
    <Stack border={'1px solid #EBEDF6'} borderBottom={'none'} borderRadius={'4px'}>
      {pricing.map((p, index) => (
        <CreatorPricingInput
          key={`${p.type}`}
          type={p.type}
          index={index}
          warning={proposalRecommendationFlag && warnings.some(w => w.type === p.type)}
        />
      ))}
    </Stack>
  )
}

const NextButton = ({ disabled }: { disabled?: boolean }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { watch } = useFormContext<ProposalCreateForm>()
  const data = watch()

  const handleNext = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.TIMELINE].join('/'))
  }

  const totalCreators = sumBy(data.creatorPricing || [], v => v.numCreators)

  return (
    <Button
      variant={'contained'}
      onClick={handleNext}
      disabled={disabled || !creatorProductsFormValidationSchema.isValidSync(data) || !totalCreators}
      endIcon={<ArrowForwardOutlined />}
      data-cy={'nextButton'}
    >
      Next
    </Button>
  )
}

const CreatorPricingInput = ({
  index,
  type,
  warning
}: {
  index: number
  type: PricingCreatorType
  warning?: boolean
}) => {
  const { setValue } = useFormContext()
  const data = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}` }) as ProposalCreatorPricingInput
  const isOverridePricing = useWatch<ProposalCreateForm, 'isOverridePricing'>({ name: 'isOverridePricing' })

  const cost = data.numCreators * data.price

  useEffect(() => {
    if (type === PricingCreatorType.SOCIAL) {
      const numCreators = data.numCreators
      const socialNumCreators = sumBy(data.socialCreatorPricing, 'numCreators')

      if (numCreators !== socialNumCreators) {
        data?.socialCreatorPricing?.forEach((pricing, socialIndex) => {
          const type = pricing.type

          if ([CreatorType.IG, CreatorType.TT].includes(type)) {
            const creatorType = pricing.type as CreatorType.IG | CreatorType.TT

            setValue(
              `creatorPricing.${index}.socialCreatorPricing.${socialIndex}.numCreators`,
              getSocialCreatorDistribution(numCreators, creatorType)
            )
            setValue(
              `creatorPricing.${index}.socialCreatorPricing.${socialIndex}.schedule`,
              pricing.schedule.map(s => getSocialCreatorDistribution(s, creatorType))
            )
          }
        })
      }
    }
  }, [type, data, setValue, index])

  return (
    <Stack direction={'row'}>
      <Box
        width={'100%'}
        sx={{ borderBottom: '1px solid #EBEDF6', borderRight: '1px solid #EBEDF6' }}
        px={1}
        py={3}
        display={'flex'}
      >
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={7} alignSelf={'center'}>
            <Stack spacing={0.5} pl={1}>
              <Typography variant={'label2'}>{CREATOR_TYPE_PRICING_CONFIG[type]?.title}</Typography>
              <Typography variant={'body2'}>{CREATOR_TYPE_PRICING_CONFIG[type]?.description}</Typography>
            </Stack>
          </Grid>
          <Grid item alignSelf={'center'}>
            <Stack direction={'row'}>
              {isOverridePricing ? (
                <Grid item alignSelf={'center'} px={1} pb={0.5}>
                  <Stack alignItems={'center'} spacing={1}>
                    <NumberInput
                      sx={{ width: '45px', height: '24px', px: 0, py: 0, textAlign: 'center' }}
                      name={`creatorPricing.${index}.price`}
                      data-cy={`creatorPricing.${index}.price`}
                      decimalScale={2}
                      returnAsNumber
                      allowNegative={false}
                    />
                    <Typography variant={'caption2'}>credits</Typography>
                  </Stack>
                </Grid>
              ) : (
                <Grid item alignSelf={'center'} px={1}>
                  <Stack spacing={0.5} alignItems={'center'}>
                    <Typography variant={'label4'} pb={0.5}>
                      {data.price.toLocaleString()}
                    </Typography>
                    <Typography variant={'caption2'}>credits</Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item alignSelf={'center'} px={1}>
                <Close fontSize={'mSmall'} />
              </Grid>
              <Grid item alignSelf={'center'} px={1} pb={0.5}>
                <Stack alignItems={'center'} spacing={1}>
                  <NumCreatorsInput index={index} warning={warning} />
                  <Typography variant={'caption2'}>creators</Typography>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        bgcolor={'#F8FBFF'}
        display={'flex'}
        sx={{
          borderBottom: '1px solid #EBEDF6',
          borderLeft: 'none',
          width: 100
        }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack alignItems={'center'} px={1} pt={1} spacing={1} pb={0.5}>
          <Typography variant={'label2'}>{cost.toLocaleString()}</Typography>
          <Typography variant={'caption2'}>credits</Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

const NumCreatorsInput = ({ index, warning }: { index: number; warning?: boolean }) => {
  const { setValue, getValues } = useFormContext<ProposalCreateForm>()
  const [startingValue, setStartingValue] = useState<number>(getValues(`creatorPricing.${index}.numCreators`))
  const numCreators = useWatch({ name: `creatorPricing.${index}.numCreators` }) as number

  useEffect(() => {
    const creatorPricing = getValues('creatorPricing')
    const autoBuyDistribution = keyBy(getAutoBuysDistribution({ creatorPricing }), 'type')
    setValue(
      'creatorPricing',
      creatorPricing.map(c => ({ ...c, schedule: toWeeklyPartition(autoBuyDistribution[c.type]?.dailySchedule) }))
    )
    if (numCreators !== startingValue) {
      setStartingValue(numCreators)
    }
  }, [numCreators])

  return (
    <NumberInput
      focused={warning}
      color={warning ? 'warning' : 'primary'}
      sx={{
        width: '45px',
        height: '24px',
        px: 0,
        py: 0,
        textAlign: 'center'
      }}
      name={`creatorPricing.${index}.numCreators`}
      decimalScale={0}
      returnAsNumber
      allowNegative={false}
      max={5000}
    />
  )
}
