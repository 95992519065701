import { yupResolver } from '@hookform/resolvers/yup'
import CheckOutlineIcon from '@momentum/components/icons/check-outline'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import {
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { Form, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Brand } from '../queries'
import { updateBrand } from './queries'
import { amazonBrandStoreCheck, walmartBrandStoreCheck } from '@momentum/components/brand-store-url-input'

type BrandStoreFormValues = {
  amazonBrandStoreUrl?: string | null
  walmartScrapeUrl?: string | null 
}

const schema = yup.object({
  amazonBrandStoreUrl: amazonBrandStoreCheck.optional(),
  walmartScrapeUrl: walmartBrandStoreCheck.optional()
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  padding: '8px 0'
}))

export const BrandStore = ({ brand }: { brand: Brand }) => {
  const { refreshBrand } = useUserSessionContext()

  const defaultValues = useMemo(
    () => ({
      amazonBrandStoreUrl: brand.amazonBrandStoreUrl ?? '',
      walmartScrapeUrl: brand.walmartScrapeUrl ?? ''
    }),
    [brand]
  )

  const onSubmit = async (submitValues: BrandStoreFormValues) => {
    try {
      await updateBrand({ id: brand.id, ...submitValues })
    } catch (error) {
      console.error(error)
      toast(<Typography variant={'subtitle2'}>Failed to save stores!</Typography>, { type: 'error' })
    }
    await refreshBrand(brand.id)
  }

  return (
    <Form defaultValues={defaultValues} resolver={yupResolver<BrandStoreFormValues>(schema)} onSubmit={onSubmit}>
      <BrandStoreForm brand={brand} defaultValues={defaultValues} />
    </Form>
  )
}

const BrandStoreForm = ({ brand, defaultValues }: { brand: Brand; defaultValues: BrandStoreFormValues }) => {
  const { isAdminView } = useUserSessionContext()
  const { reset } = useFormContext()

  useEffect(() => reset(defaultValues), [brand])

  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>Brand store</Typography>

      <Stack border={1} borderRadius={'4px'} borderColor={'#EAEAEA'} p={3} spacing={1}>
        {isAdminView && (
          <Typography variant={'label3'}>Add the links to your brand stores so we can review your products.</Typography>
        )}

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="label2">Retailer</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="label2">Brand Store URL</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 0 }}>
              <TableRow>
                <StyledTableCell
                  style={{
                    width: '1%',
                    whiteSpace: 'nowrap',
                    paddingTop: '12px',
                    paddingRight: '32px',
                    verticalAlign: 'top'
                  }}
                >
                  <Stack direction={'row'} spacing={1}>
                    <CheckOutlineIcon color={'success'} style={{ fontSize: '16px' }} />
                    <Typography variant="label3" style={{ lineHeight: '25px' }}>
                      {' '}
                      Amazon brand store
                    </Typography>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  {isAdminView ? (
                    <TextInput name={'amazonBrandStoreUrl'} fullWidth />
                  ) : (
                    !!brand.amazonBrandStoreUrl && (
                      <Link href={brand.amazonBrandStoreUrl} target={'_blank'}>
                        {brand.amazonBrandStoreUrl}
                      </Link>
                    )
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
            <TableBody sx={{ border: 0 }}>
              <TableRow>
                <StyledTableCell
                  style={{
                    width: '1%',
                    whiteSpace: 'nowrap',
                    paddingTop: '12px',
                    paddingRight: '32px',
                    verticalAlign: 'top'
                  }}
                >
                  <Stack direction={'row'} spacing={1}>
                    <CheckOutlineIcon color={'success'} style={{ fontSize: '16px' }} />
                    <Typography variant="label3" style={{ lineHeight: '25px' }}>
                      {' '}
                      Walmart brand store
                    </Typography>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  {isAdminView ? (
                    <TextInput name={'walmartScrapeUrl'} fullWidth />
                  ) : (
                    !!brand.walmartScrapeUrl && (
                      <Link href={brand.walmartScrapeUrl} target={'_blank'}>
                        {brand.walmartScrapeUrl}
                      </Link>
                    )
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {isAdminView && (
          <SubmitButton
            variant={'contained'}
            style={{
              alignSelf: 'start',
              marginTop: '24px'
            }}
          >
            Save brand store
          </SubmitButton>
        )}
      </Stack>
    </Stack>
  )
}
