import Loading from '@momentum/components/loading'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  CampaignCreatorApprovalAction,
  CreatorApprovalStatus,
  ValueTypes
} from '@productwindtom/shared-momentum-zeus-types'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useCampaignContext } from '../../context/CampaignContext'
import { ApproveCreatorsFilterBar } from './FilterBar'
import { PendingApprovalsCard } from './PendingApprovalsCard'
import { ApproveCreatorsSummary } from './Summary'
import ApproveCreatorsContext, { CreatorTypeFilter } from './approveCreatorsContext'
import { ApproveCreatorsTable } from './creators-table'
import { submitCampaignCreatorApproval } from './mutations'
import { SOCIAL_CREATOR_TYPES, UGC_CREATOR_TYPES } from './utils'
import { EmptyState } from './empty-state'
import { captureException } from '@sentry/react'

export const ApproveCreators = () => {
  const { campaignCreatorsForApproval, updateCampaignCreatorsForApproval } = useCampaignContext()

  const [search, setSearch] = useState<string>()
  const [approvalStatus, setApprovalStatus] = useState<CreatorApprovalStatus | undefined>(CreatorApprovalStatus.PENDING)
  const [creatorTypeFilter, setCreatorTypeFilter] = useState<CreatorTypeFilter>()

  useEffect(() => {
    if (!creatorTypeFilter && campaignCreatorsForApproval) {
      setCreatorTypeFilter(
        campaignCreatorsForApproval?.find(c => SOCIAL_CREATOR_TYPES.includes(c.creatorType))
          ? CreatorTypeFilter.SOCIAL
          : campaignCreatorsForApproval?.find(c => UGC_CREATOR_TYPES.includes(c.creatorType))
            ? CreatorTypeFilter.UGC
            : CreatorTypeFilter.SOCIAL
      )
    }
  }, [campaignCreatorsForApproval, creatorTypeFilter])

  if (!campaignCreatorsForApproval) {
    return <Loading />
  }

  const creators = campaignCreatorsForApproval.filter(creator => creator.approvalState)

  const hasCreatorsToApprove = !!campaignCreatorsForApproval.length

  const submitApproval = async (input: ValueTypes['SubmitCampaignCreatorApprovalInput']) => {
    try {
      const updatedCreator = await submitCampaignCreatorApproval(input)
      updateCampaignCreatorsForApproval(updatedCreator)
      toast(
        <Typography variant={'subtitle2'}>
          Creator has been {input.action === CampaignCreatorApprovalAction.APPROVE ? 'approved' : 'rejected'}
        </Typography>,
        {
          type: 'success'
        }
      )
    } catch (e) {
      captureException(e)
      toast(<Typography variant={'subtitle2'}>An error has occurred, please try again later!</Typography>, {
        type: 'error'
      })
      console.error(e)
    }
  }

  return (
    <ApproveCreatorsContext.Provider
      value={{
        creators,
        submitApproval,
        search,
        setSearch,
        approvalStatus,
        setApprovalStatus,
        creatorTypeFilter,
        setCreatorTypeFilter
      }}
    >
      <Stack spacing={2}>
        {hasCreatorsToApprove && <PendingApprovalsCard />}
        {hasCreatorsToApprove && <ApproveCreatorsSummary />}
        {hasCreatorsToApprove && <ApproveCreatorsFilterBar />}
        {hasCreatorsToApprove && <ApproveCreatorsTable />}
        {!hasCreatorsToApprove && <EmptyState />}
      </Stack>
    </ApproveCreatorsContext.Provider>
  )
}
