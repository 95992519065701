import ViewComment from '@momentum/components/icons/view-comment'
import { CampaignCreatorForApproval } from '@momentum/routes/campaign/context/queries'
import { CreatorTypeLabels } from '@momentum/utils/creatorUtils'
import { RegionToFlagIcon } from '@momentum/utils/regionMappings'
import { SocialChannelToIcon, getHandleLink } from '@momentum/utils/socialUtils'
import LaunchIcon from '@mui/icons-material/Launch'
import { Button, ButtonGroup, IconButton, Link, Stack, TableCell, TableRow, Typography, styled } from '@mui/material'
import {
  CampaignCreatorApprovalAction,
  Channel,
  CreatorApprovalStatus,
  CreatorType
} from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { ApproveCreatorDialog } from '../ApproveCreatorDialog'
import { ViewCreatorFeedbackDialog } from '../ViewCreatorFeedbackDialog'
import { useApproveCreatorsContext } from '../approveCreatorsContext'
import { SOCIAL_CREATOR_TYPES, UGC_CREATOR_TYPES } from '../utils'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.label4,
  verticalAlign: 'center',
  alignItems: 'center',
  alignContent: 'center'
}))

const StyledLaunchIcon = styled(LaunchIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 16,
  height: 16
}))

export const CreatorRow = ({ creator }: { creator: CampaignCreatorForApproval }) => {
  const { submitApproval } = useApproveCreatorsContext()
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false)
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const [approvalActionSelected, setApprovalActionSelected] = useState<CampaignCreatorApprovalAction>()

  const creatorProfiles = useMemo(
    () => ({
      ...(creator.creator.instagramProfile && {
        [Channel.INSTAGRAM]: {
          ...creator.creator.instagramProfile,
          link: getHandleLink(Channel.INSTAGRAM, creator.creator)!,
          icon: SocialChannelToIcon[Channel.INSTAGRAM]
        }
      }),
      ...(creator.creator.tiktokProfile && {
        [Channel.TIKTOK]: {
          ...creator.creator.tiktokProfile,
          link: getHandleLink(Channel.TIKTOK, creator.creator)!,
          icon: SocialChannelToIcon[Channel.TIKTOK]
        }
      }),
      ...(creator.creator.youtubeProfile && {
        [Channel.YOUTUBE]: {
          ...creator.creator.youtubeProfile,
          link: getHandleLink(Channel.YOUTUBE, creator.creator)!,
          icon: SocialChannelToIcon[Channel.YOUTUBE]
        }
      })
    }),
    [creator]
  )

  const mainSocialProfile = useMemo(() => {
    switch (creator.creatorType) {
      case CreatorType.IG:
        return creatorProfiles[Channel.INSTAGRAM]
      case CreatorType.TT:
        return creatorProfiles[Channel.TIKTOK]
      case CreatorType.YOUTUBE:
        return creatorProfiles[Channel.YOUTUBE]
      case CreatorType.UGC:
      case CreatorType.PREMIUM_UGC:
      case CreatorType.ADVOCATE:
      default:
        return undefined
    }
  }, [creator.creatorType])

  const otherProfiles = Object.values(creatorProfiles)
    .filter(p => p.link !== mainSocialProfile?.link)
    .filter(notEmpty)

  const handleApproveActionClicked = (action: CampaignCreatorApprovalAction) => {
    setApprovalActionSelected(action)
    setApprovalDialogOpen(true)
  }

  const handleClose = () => {
    setApprovalActionSelected(undefined)
    setApprovalDialogOpen(false)
  }

  const handleConfirm = async (
    id: string,
    action: CampaignCreatorApprovalAction,
    feedback?: string | undefined | null
  ) => {
    await submitApproval({
      id,
      action,
      feedback
    })
    setApprovalActionSelected(undefined)
    setApprovalDialogOpen(false)
  }

  const statusChangeDate = DateTime.fromISO(creator.approvalState.updatedAt)

  return (
    <TableRow>
      <BodyTableCell>
        <Stack direction="column" spacing={0.5}>
          {creator.approvalState.status === CreatorApprovalStatus.PENDING && (
            <Typography variant="caption2" color={'error'}>
              {getTimeInPendingState(creator)}
            </Typography>
          )}
          {mainSocialProfile && (
            <Stack direction="row" spacing={1}>
              {mainSocialProfile.icon({ fontSize: 'mSmall' })}
              <Link
                href={mainSocialProfile.link}
                target="_blank"
                sx={{ display: 'flex' }}
                variant="body2"
                underline="none"
                color="inherit"
              >
                <Typography mr={0.5}>{mainSocialProfile.handle}</Typography>
                <StyledLaunchIcon />
              </Link>
            </Stack>
          )}
          {[CreatorType.PREMIUM_UGC, CreatorType.UGC].includes(creator.creatorType) && (
            <Typography variant="inherit">
              {`${creator.creator.firstName} ${creator.creator.lastName?.[0]}`.trim()}
            </Typography>
          )}
          <Stack direction="row" spacing={1.5}>
            {creator.locale && RegionToFlagIcon[creator.locale]({ fontSize: 'mSmall' })}

            {otherProfiles.map((otherProfile, idx) => (
              <Link key={idx} href={otherProfile.link} target="_blank">
                {otherProfile.icon({ fontSize: 'mSmall' })}
                <StyledLaunchIcon sx={{ ml: 0.5 }} />
              </Link>
            ))}
          </Stack>
        </Stack>
      </BodyTableCell>
      <BodyTableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          {SOCIAL_CREATOR_TYPES.includes(creator.creatorType) && mainSocialProfile && <mainSocialProfile.icon />}
          {UGC_CREATOR_TYPES.includes(creator.creatorType) && SocialChannelToIcon[Channel.UGC]({})}
          <Typography>{CreatorTypeLabels[creator.creatorType!]}</Typography>
        </Stack>
      </BodyTableCell>
      <BodyTableCell>{getAgeRange(creator)}</BodyTableCell>
      {SOCIAL_CREATOR_TYPES.includes(creator.creatorType) && (
        <>
          <BodyTableCell>{creator.creator.instagramProfile?.followers?.toLocaleString() ?? '--'}</BodyTableCell>
          <BodyTableCell>
            {creator.creator.instagramProfile?.engagementRate
              ? `${creator.creator.instagramProfile?.engagementRate?.toFixed(2)}%`
              : '--'}
          </BodyTableCell>
        </>
      )}
      <BodyTableCell
        sx={{
          width: 200
        }}
      >
        {creator.approvalState.status === CreatorApprovalStatus.PENDING ? (
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => handleApproveActionClicked(CampaignCreatorApprovalAction.APPROVE)}
              style={{ minWidth: 100 }}
            >
              Approve
            </Button>
            <Button
              onClick={() => handleApproveActionClicked(CampaignCreatorApprovalAction.REJECT)}
              style={{ minWidth: 100 }}
            >
              Reject
            </Button>
          </ButtonGroup>
        ) : (
          <Stack direction="row" spacing={2} alignItems={'center'} width={'100%'}>
            <Typography variant="caption2">
              {creator.approvalState.status === CreatorApprovalStatus.APPROVED ? 'Approved by' : 'Rejected by'}{' '}
              {creator.approvalState.reviewedByUserName || creator.approvalState.reviewedByUserEmail}
              <br />
              on {statusChangeDate.toLocaleString(DateTime.DATE_SHORT)} at{' '}
              {statusChangeDate.toLocaleString(DateTime.TIME_SIMPLE)}
            </Typography>
            {creator.approvalState.feedback && (
              <IconButton onClick={() => setFeedbackDialogOpen(true)}>
                <ViewComment />
              </IconButton>
            )}
          </Stack>
        )}
      </BodyTableCell>
      {approvalActionSelected && approvalDialogOpen && (
        <ApproveCreatorDialog
          creator={creator}
          action={approvalActionSelected}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
      <ViewCreatorFeedbackDialog
        open={feedbackDialogOpen}
        creator={creator}
        handleClose={() => setFeedbackDialogOpen(false)}
      />
    </TableRow>
  )
}

const getAgeRange = (creator: CampaignCreatorForApproval): string => {
  // per spec, return this age range by default when unavailable
  if (!creator.dateOfBirth) {
    return '25-36'
  }
  const age = Math.abs(DateTime.fromISO(creator.dateOfBirth).diffNow('years').as('years'))
  if (age >= 13 && age <= 17) {
    return '13-17'
  } else if (age >= 18 && age <= 24) {
    return '18-24'
  } else if (age >= 25 && age <= 36) {
    return '25-36'
  } else {
    return '37+'
  }
}

const getTimeInPendingState = (creator: CampaignCreatorForApproval): string | undefined => {
  if (creator.approvalState.status === CreatorApprovalStatus.PENDING) {
    const today = DateTime.now()
    const statusChangeDate = DateTime.fromISO(creator.approvalState.updatedAt)
    const elapsed = today.diff(statusChangeDate, ['days', 'hours']).toObject()
    return `Waiting ${Math.floor(elapsed.days!)} days, ${Math.floor(elapsed.hours!)} hours`
  }
}
