import { first } from 'lodash'
import { DateTime } from 'luxon'

export const determineDaysToHitGoal = <T extends { date: DateTime; isWithinCampaignDates?: boolean }>(
  series: T[],
  getter: (t: T) => number,
  goal: number
) => {
  if (!series.length) return undefined

  const seriesWithinCampaginDate = series.filter(s => !!s.isWithinCampaignDates)


  const cumulativeSum = seriesWithinCampaginDate.map(
    (p => value => ({ sum: (p.sum += getter(value)), date: value.date }))({ date: undefined, sum: 0 })
  )
  const dateWhenMet = cumulativeSum.find(s => s.sum >= goal)?.date

  if (dateWhenMet) {
    return dateWhenMet.diff(first(seriesWithinCampaginDate)!.date, 'days').days
  }
}

export const isWithinPeriod = (date: string, startDate: string, endDate?: string) => {
  return date >= startDate && (!endDate || date <= endDate)
}
