import { yupResolver } from '@hookform/resolvers/yup'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { REGION_STORES } from '@momentum/utils/storeUtils'
import { Typography } from '@mui/material'
import { PricingCreatorType, Store } from '@productwindtom/shared-momentum-zeus-types'
import { Form } from '@productwindtom/ui-base'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Brand } from '../queries'
import { createBrandPricing, updateBrandPricing, deleteBrandPricing } from './queries'
import { PricingForm, schema } from '@momentum/components/pricing-form'
import { SUPPORTED_CREATOR_TYPES } from '@momentum/utils/brandPricing'

type PricingFormValues = {
  pricing: {
    store: Store
    creatorPricing: {
      price?: number | null
      type: PricingCreatorType
    }[]
  }[]
}

const SUPPORTED_STORES = [
  Store.amazon,
  Store.amazon_ca,
  Store.amazon_uk,
  Store.amazon_de,
  Store.walmart,
  Store.target,
  Store.other
]

export const BrandPricing = ({ brand }: { brand: Brand }) => {
  const { refreshBrand, selectedCompany } = useUserSessionContext()

  const defaultValues = useMemo(
    () => ({
      pricing: REGION_STORES[brand.region]
        .filter(s => SUPPORTED_STORES.includes(s))
        .map(store => ({
          store,
          creatorPricing: SUPPORTED_CREATOR_TYPES.map(type => ({
            price: brand.pricing.find(bp => bp.store === store)?.creatorPricing.find(cp => cp.type === type)?.price,
            type
          }))
        }))
    }),
    [brand]
  )

  const onSubmit = async (submitValues: PricingFormValues) => {
    try {
      await Promise.all(
        submitValues.pricing.map(bp => {
          const input = {
            id: brand.id,
            store: bp.store,
            creatorPricing: bp.creatorPricing
              .filter(cp => cp.price != null)
              .map(cp => ({
                type: cp.type,
                price: cp.price!
              }))
          }

          const anyNotBlank = !!bp.creatorPricing.find(cp => cp.price)

          if (brand.pricing.find(p => p.store === bp.store)) {
            if (anyNotBlank) {
              return updateBrandPricing(input)
            } else {
              return deleteBrandPricing({ id: brand.id, store: bp.store })
            }
          } else {
            return createBrandPricing(input)
          }
        })
      )
    } catch (error) {
      console.error(error)
      toast(<Typography variant={'subtitle2'}>Failed to save brand pricing!</Typography>, { type: 'error' })
    }
    await refreshBrand(brand.id)
  }

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit} resolver={yupResolver(schema)}>
      <PricingForm
        defaultValues={selectedCompany?.pricing}
        initialValues={defaultValues}
        title={'Brand pricing'}
        submitText={'Save brand pricing'}
      />
    </Form>
  )
}
