import { Stack } from '@mui/material'
import FinancialCommitments from './FinancialCommitments'
import FinancialSummaryProvider from '../../context/FinancialSummaryContext'
import FinancialFlowSummary from './FinancialFlowSummary'
import FinancialCampaignSummary from './FinancialCampaignSummary'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

const FinancialSummary = () => {
  const { isAdmin, isAdminView } = useUserSessionContext()

  if (!isAdmin || !isAdminView) return null

  return (
    <FinancialSummaryProvider>
      <Stack spacing={5} pb={10} pt={4}>
        <FinancialCommitments />
        <FinancialCampaignSummary />
        <FinancialFlowSummary />
      </Stack>
    </FinancialSummaryProvider>
  )
}

export default FinancialSummary
