import { DateTime } from 'luxon'

export const addBusinessDays = (startDate: DateTime, businessDays: number) => {
  let currentDate = startDate
  let addedDays = 0

  while (addedDays < businessDays) {
    currentDate = currentDate.plus({ days: 1 })

    // Check if it's a weekday (Monday-Friday)
    if (currentDate.weekday <= 5) {
      addedDays += 1
    }
  }

  return currentDate
}

export const isWeekend = (dateTime: DateTime) => [6, 7].includes(dateTime.weekday)

export const isHoliday = (dateTime: DateTime) => {
  // Get the year of the given date
  const year = dateTime.year

  // Calculate the date for Thanksgiving (last Thursday of November)
  let thanksgivingWeek = DateTime.fromObject({ year: year, month: 11, day: 1 }).endOf('month')

  if (thanksgivingWeek.weekday < 4) {
    thanksgivingWeek = thanksgivingWeek.minus({ weeks: 1 })
  }

  const thanksgiving = thanksgivingWeek.startOf('week').plus({ days: 3 })
  const dayAfterThanksgiving = thanksgiving.plus({ days: 1 })

  // Check if the date is Thanksgiving, the day after Thanksgiving, Christmas, New Year's Eve, or New Year's Day
  return (
    dateTime.equals(thanksgiving) ||
    dateTime.equals(dayAfterThanksgiving) ||
    (dateTime.month === 12 && dateTime.day === 25) ||
    (dateTime.month === 12 && dateTime.day === 31) ||
    (dateTime.month === 1 && dateTime.day === 1)
  )
}
