// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_PERFORMANCE_HEADPHONES = ({
  campaignId,
  promotedProductId,
  skuId
}: {
  campaignId: string
  promotedProductId: string
  skuId: string
}): ModelTypes['CampaignPerformance'] => ({
  campaignId,
  skuId,
  promotedProductId: promotedProductId,
  isVisible: false,
  comparisonMetric: 'REVIEWS',
  comparisonMetricValue: 50,
  summaryTitle: 'SIMILAR_PRODUCT',
  products: [
    {
      id: '6607cc56-2a3c-4869-89df-bca3528ac8a5',
      productName: 'HomeSpot JY508 Wireless Neckband Speaker',
      productUrl: 'https://www.amazon.com/HomeSpot-JY508-Wireless-Neckband-Speaker/dp/B0CDG81TH2/',
      productImage: 'campaigns/7ba8cff1-109c-4a81-b8fd-398f2cebbad0/images/61MPyIeLJKL._AC_SL1500__1713885138356.jpg',
      productPerformanceRecords: [
        {
          date: '2023-12-20',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-21',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-22',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-23',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-24',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-25',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-26',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-27',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-28',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-29',
          reviewCount: null,
          rating: null,
          salesRank: 48869
        },
        {
          date: '2023-12-30',
          reviewCount: null,
          rating: null,
          salesRank: 39764
        },
        {
          date: '2023-12-31',
          reviewCount: null,
          rating: null,
          salesRank: 41418
        },
        {
          date: '2024-01-01',
          reviewCount: null,
          rating: null,
          salesRank: 32680
        },
        {
          date: '2024-01-02',
          reviewCount: null,
          rating: null,
          salesRank: 28314
        },
        {
          date: '2024-01-03',
          reviewCount: null,
          rating: null,
          salesRank: 26721
        },
        {
          date: '2024-01-04',
          reviewCount: null,
          rating: null,
          salesRank: 23530
        },
        {
          date: '2024-01-05',
          reviewCount: null,
          rating: null,
          salesRank: 25686
        },
        {
          date: '2024-01-06',
          reviewCount: null,
          rating: null,
          salesRank: 26042
        },
        {
          date: '2024-01-07',
          reviewCount: null,
          rating: null,
          salesRank: 27945
        },
        {
          date: '2024-01-08',
          reviewCount: null,
          rating: null,
          salesRank: 30300
        },
        {
          date: '2024-01-09',
          reviewCount: null,
          rating: null,
          salesRank: 28563
        },
        {
          date: '2024-01-10',
          reviewCount: null,
          rating: null,
          salesRank: 30520
        },
        {
          date: '2024-01-11',
          reviewCount: null,
          rating: null,
          salesRank: 27944
        },
        {
          date: '2024-01-12',
          reviewCount: null,
          rating: null,
          salesRank: 30556
        },
        {
          date: '2024-01-13',
          reviewCount: null,
          rating: null,
          salesRank: 30699
        },
        {
          date: '2024-01-14',
          reviewCount: null,
          rating: null,
          salesRank: 32146
        },
        {
          date: '2024-01-15',
          reviewCount: null,
          rating: null,
          salesRank: 27678
        },
        {
          date: '2024-01-16',
          reviewCount: 3,
          rating: 3,
          salesRank: 22481
        },
        {
          date: '2024-01-17',
          reviewCount: 3,
          rating: 3,
          salesRank: 25256
        },
        {
          date: '2024-01-18',
          reviewCount: 3,
          rating: 3,
          salesRank: 30281
        },
        {
          date: '2024-01-19',
          reviewCount: 3,
          rating: 3,
          salesRank: 36825
        },
        {
          date: '2024-01-20',
          reviewCount: 3,
          rating: 3,
          salesRank: 34393
        },
        {
          date: '2024-01-21',
          reviewCount: 3,
          rating: 3,
          salesRank: 40611
        },
        {
          date: '2024-01-22',
          reviewCount: 6,
          rating: 3,
          salesRank: 45909
        },
        {
          date: '2024-01-23',
          reviewCount: 6,
          rating: 3,
          salesRank: 58602
        },
        {
          date: '2024-01-24',
          reviewCount: 6,
          rating: 3,
          salesRank: 70860
        },
        {
          date: '2024-01-25',
          reviewCount: 6,
          rating: 3,
          salesRank: 71236
        },
        {
          date: '2024-01-26',
          reviewCount: 6,
          rating: 3,
          salesRank: 58618
        },
        {
          date: '2024-01-27',
          reviewCount: 9,
          rating: 4,
          salesRank: 42381
        },
        {
          date: '2024-01-28',
          reviewCount: 9,
          rating: 4,
          salesRank: 50022
        },
        {
          date: '2024-01-29',
          reviewCount: 9,
          rating: 4,
          salesRank: 54344
        },
        {
          date: '2024-01-30',
          reviewCount: 9,
          rating: 4,
          salesRank: 58753
        },
        {
          date: '2024-01-31',
          reviewCount: 9,
          rating: 4,
          salesRank: 65654
        },
        {
          date: '2024-02-01',
          reviewCount: 14,
          rating: 4,
          salesRank: 68817
        },
        {
          date: '2024-02-02',
          reviewCount: 14,
          rating: 4,
          salesRank: 56212
        },
        {
          date: '2024-02-03',
          reviewCount: 14,
          rating: 4,
          salesRank: 57103
        },
        {
          date: '2024-02-04',
          reviewCount: 14,
          rating: 4,
          salesRank: 43813
        },
        {
          date: '2024-02-05',
          reviewCount: 14,
          rating: 4,
          salesRank: 31880
        },
        {
          date: '2024-02-06',
          reviewCount: 16,
          rating: 4,
          salesRank: 38338
        },
        {
          date: '2024-02-07',
          reviewCount: 16,
          rating: 4,
          salesRank: 34700
        },
        {
          date: '2024-02-08',
          reviewCount: 16,
          rating: 4,
          salesRank: 32504
        },
        {
          date: '2024-02-09',
          reviewCount: 16,
          rating: 4,
          salesRank: 19365
        },
        {
          date: '2024-02-10',
          reviewCount: 16,
          rating: 4,
          salesRank: 20625
        },
        {
          date: '2024-02-11',
          reviewCount: 16,
          rating: 4,
          salesRank: 24401
        },
        {
          date: '2024-02-12',
          reviewCount: 19,
          rating: 4,
          salesRank: 28384
        },
        {
          date: '2024-02-13',
          reviewCount: 19,
          rating: 4,
          salesRank: 31098
        },
        {
          date: '2024-02-14',
          reviewCount: 19,
          rating: 4,
          salesRank: 31658
        },
        {
          date: '2024-02-15',
          reviewCount: 19,
          rating: 4,
          salesRank: 40262
        },
        {
          date: '2024-02-16',
          reviewCount: 19,
          rating: 4,
          salesRank: 37732
        },
        {
          date: '2024-02-17',
          reviewCount: 22,
          rating: 4,
          salesRank: 43533
        },
        {
          date: '2024-02-18',
          reviewCount: 22,
          rating: 4,
          salesRank: 43200
        },
        {
          date: '2024-02-19',
          reviewCount: 22,
          rating: 4,
          salesRank: 52693
        },
        {
          date: '2024-02-20',
          reviewCount: 22,
          rating: 4,
          salesRank: 51252
        },
        {
          date: '2024-02-21',
          reviewCount: 22,
          rating: 4,
          salesRank: 46001
        },
        {
          date: '2024-02-22',
          reviewCount: 24,
          rating: 4,
          salesRank: 48025
        },
        {
          date: '2024-02-23',
          reviewCount: 24,
          rating: 4,
          salesRank: 40060
        },
        {
          date: '2024-02-24',
          reviewCount: 24,
          rating: 4,
          salesRank: 41277
        },
        {
          date: '2024-02-25',
          reviewCount: 24,
          rating: 4,
          salesRank: 43875
        },
        {
          date: '2024-02-26',
          reviewCount: 24,
          rating: 4,
          salesRank: 45181
        },
        {
          date: '2024-02-27',
          reviewCount: 24,
          rating: 4,
          salesRank: 38539
        },
        {
          date: '2024-02-28',
          reviewCount: 40,
          rating: 4,
          salesRank: 33838
        },
        {
          date: '2024-02-29',
          reviewCount: 40,
          rating: 4,
          salesRank: 38283
        },
        {
          date: '2024-03-01',
          reviewCount: 40,
          rating: 4,
          salesRank: 44448
        },
        {
          date: '2024-03-02',
          reviewCount: 40,
          rating: 4,
          salesRank: 42075
        },
        {
          date: '2024-03-03',
          reviewCount: 40,
          rating: 4,
          salesRank: 37798
        },
        {
          date: '2024-03-04',
          reviewCount: 40,
          rating: 4,
          salesRank: 37943
        },
        {
          date: '2024-03-05',
          reviewCount: 41,
          rating: 4,
          salesRank: 35039
        },
        {
          date: '2024-03-06',
          reviewCount: 41,
          rating: 4,
          salesRank: 29900
        },
        {
          date: '2024-03-07',
          reviewCount: 41,
          rating: 4,
          salesRank: 35056
        },
        {
          date: '2024-03-08',
          reviewCount: 41,
          rating: 4,
          salesRank: 31792
        },
        {
          date: '2024-03-09',
          reviewCount: 41,
          rating: 4,
          salesRank: 23758
        },
        {
          date: '2024-03-10',
          reviewCount: 46,
          rating: 4,
          salesRank: 27008
        },
        {
          date: '2024-03-11',
          reviewCount: 46,
          rating: 4,
          salesRank: 25874
        },
        {
          date: '2024-03-12',
          reviewCount: 46,
          rating: 4,
          salesRank: 29689
        },
        {
          date: '2024-03-13',
          reviewCount: 46,
          rating: 4,
          salesRank: 32691
        },
        {
          date: '2024-03-14',
          reviewCount: 46,
          rating: 4,
          salesRank: 27798
        },
        {
          date: '2024-03-15',
          reviewCount: 46,
          rating: 4,
          salesRank: 30666
        },
        {
          date: '2024-03-16',
          reviewCount: 48,
          rating: 4,
          salesRank: 30075
        },
        {
          date: '2024-03-17',
          reviewCount: 48,
          rating: 4,
          salesRank: 28858
        },
        {
          date: '2024-03-18',
          reviewCount: 48,
          rating: 4,
          salesRank: 25196
        },
        {
          date: '2024-03-19',
          reviewCount: 48,
          rating: 4,
          salesRank: 28509
        },
        {
          date: '2024-03-20',
          reviewCount: 48,
          rating: 4,
          salesRank: 29605
        },
        {
          date: '2024-03-21',
          reviewCount: 53,
          rating: 4,
          salesRank: 28786
        },
        {
          date: '2024-03-22',
          reviewCount: 53,
          rating: 4,
          salesRank: 32226
        },
        {
          date: '2024-03-23',
          reviewCount: 53,
          rating: 4,
          salesRank: 35355
        },
        {
          date: '2024-03-24',
          reviewCount: 53,
          rating: 4,
          salesRank: 33582
        },
        {
          date: '2024-03-25',
          reviewCount: 53,
          rating: 4,
          salesRank: 35604
        },
        {
          date: '2024-03-26',
          reviewCount: 53,
          rating: 4,
          salesRank: 37672
        },
        {
          date: '2024-03-27',
          reviewCount: 55,
          rating: 4,
          salesRank: 31994
        },
        {
          date: '2024-03-28',
          reviewCount: 55,
          rating: 4,
          salesRank: 34448
        },
        {
          date: '2024-03-29',
          reviewCount: 55,
          rating: 4,
          salesRank: 33106
        },
        {
          date: '2024-03-30',
          reviewCount: 55,
          rating: 4,
          salesRank: 24493
        },
        {
          date: '2024-03-31',
          reviewCount: 55,
          rating: 4,
          salesRank: 23296
        },
        {
          date: '2024-04-01',
          reviewCount: 58,
          rating: 4,
          salesRank: 27312
        },
        {
          date: '2024-04-02',
          reviewCount: 58,
          rating: 4,
          salesRank: 29392
        },
        {
          date: '2024-04-03',
          reviewCount: 58,
          rating: 4,
          salesRank: 17398
        },
        {
          date: '2024-04-04',
          reviewCount: 58,
          rating: 4,
          salesRank: 19229
        },
        {
          date: '2024-04-05',
          reviewCount: 58,
          rating: 4,
          salesRank: 19264
        },
        {
          date: '2024-04-06',
          reviewCount: 58,
          rating: 4,
          salesRank: 19831
        },
        {
          date: '2024-04-07',
          reviewCount: 35,
          rating: 4,
          salesRank: 20404
        },
        {
          date: '2024-04-08',
          reviewCount: 35,
          rating: 4,
          salesRank: 14750
        },
        {
          date: '2024-04-09',
          reviewCount: 35,
          rating: 4,
          salesRank: 13433
        },
        {
          date: '2024-04-10',
          reviewCount: 35,
          rating: 4,
          salesRank: 13825
        },
        {
          date: '2024-04-11',
          reviewCount: 35,
          rating: 4,
          salesRank: 13589
        },
        {
          date: '2024-04-12',
          reviewCount: 35,
          rating: 4,
          salesRank: 13180
        },
        {
          date: '2024-04-13',
          reviewCount: 35,
          rating: 4,
          salesRank: 12766
        },
        {
          date: '2024-04-14',
          reviewCount: 35,
          rating: 4,
          salesRank: 13645
        },
        {
          date: '2024-04-15',
          reviewCount: 35,
          rating: 4,
          salesRank: 13031
        },
        {
          date: '2024-04-16',
          reviewCount: 35,
          rating: 4,
          salesRank: 13500
        },
        {
          date: '2024-04-17',
          reviewCount: 35,
          rating: 4,
          salesRank: 13555
        },
        {
          date: '2024-04-18',
          reviewCount: 38,
          rating: 4,
          salesRank: 13587
        },
        {
          date: '2024-04-19',
          reviewCount: 38,
          rating: 4,
          salesRank: 13850
        },
        {
          date: '2024-04-20',
          reviewCount: 38,
          rating: 4,
          salesRank: 13592
        },
        {
          date: '2024-04-21',
          reviewCount: 38,
          rating: 4,
          salesRank: 13940
        },
        {
          date: '2024-04-22',
          reviewCount: 38,
          rating: 4,
          salesRank: 14413
        },
        {
          date: '2024-04-23',
          reviewCount: null,
          rating: null,
          salesRank: 13555
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      createdAt: '2024-04-16T17:54:57.111Z'
    },
    {
      id: '69d0615e-b6a1-4103-ac70-955e24e90c90',
      productName:
        'Panasonic Product (RED) Compact Stereo System with CD Player, Bluetooth, FM Radio and USB with Bass and Treble Control, 80W Stereo System for Home with Remote Control - SC-PM700PP-K (Black)',
      productUrl: 'https://www.amazon.com/Panasonic-Compact-Stereo-Bluetooth-Control/dp/B0CFVXZJZV?ref_=ast_sto_dp',
      productImage: 'campaigns/7ba8cff1-109c-4a81-b8fd-398f2cebbad0/images/715CyTqB2UL._AC_SL1500__1713885345788.jpg',
      productPerformanceRecords: [
        {
          date: '2023-09-06',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-07',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-08',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-09',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-10',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-11',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-12',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-13',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-14',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-09-15',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-16',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-17',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-18',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-19',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-20',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-21',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-22',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-23',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-24',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-25',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-26',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-27',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-28',
          reviewCount: 9,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-29',
          reviewCount: 15,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-09-30',
          reviewCount: 15,
          rating: 4,
          salesRank: 41850
        },
        {
          date: '2023-10-01',
          reviewCount: 15,
          rating: 4,
          salesRank: 33181
        },
        {
          date: '2023-10-02',
          reviewCount: 15,
          rating: 4,
          salesRank: 31819
        },
        {
          date: '2023-10-03',
          reviewCount: 15,
          rating: 4,
          salesRank: 30886
        },
        {
          date: '2023-10-04',
          reviewCount: 15,
          rating: 4,
          salesRank: 25350
        },
        {
          date: '2023-10-05',
          reviewCount: 16,
          rating: 4,
          salesRank: 29773
        },
        {
          date: '2023-10-06',
          reviewCount: 16,
          rating: 4,
          salesRank: 31637
        },
        {
          date: '2023-10-07',
          reviewCount: 16,
          rating: 4,
          salesRank: 34118
        },
        {
          date: '2023-10-08',
          reviewCount: 16,
          rating: 4,
          salesRank: 34260
        },
        {
          date: '2023-10-09',
          reviewCount: 16,
          rating: 4,
          salesRank: 39599
        },
        {
          date: '2023-10-10',
          reviewCount: 16,
          rating: 4,
          salesRank: 41017
        },
        {
          date: '2023-10-11',
          reviewCount: 21,
          rating: 4,
          salesRank: 46454
        },
        {
          date: '2023-10-12',
          reviewCount: 21,
          rating: 4,
          salesRank: 45558
        },
        {
          date: '2023-10-13',
          reviewCount: 21,
          rating: 4,
          salesRank: 48763
        },
        {
          date: '2023-10-14',
          reviewCount: 21,
          rating: 4,
          salesRank: 33911
        },
        {
          date: '2023-10-15',
          reviewCount: 21,
          rating: 4,
          salesRank: 35045
        },
        {
          date: '2023-10-16',
          reviewCount: 21,
          rating: 4,
          salesRank: 32805
        },
        {
          date: '2023-10-17',
          reviewCount: 21,
          rating: 4,
          salesRank: 33999
        },
        {
          date: '2023-10-18',
          reviewCount: 21,
          rating: 4,
          salesRank: 32831
        },
        {
          date: '2023-10-19',
          reviewCount: 21,
          rating: 4,
          salesRank: 37508
        },
        {
          date: '2023-10-20',
          reviewCount: 21,
          rating: 4,
          salesRank: 34670
        },
        {
          date: '2023-10-21',
          reviewCount: 21,
          rating: 4,
          salesRank: 29452
        },
        {
          date: '2023-10-22',
          reviewCount: 21,
          rating: 4,
          salesRank: 32633
        },
        {
          date: '2023-10-23',
          reviewCount: 21,
          rating: 4,
          salesRank: 33572
        },
        {
          date: '2023-10-24',
          reviewCount: 21,
          rating: 4,
          salesRank: 41343
        },
        {
          date: '2023-10-25',
          reviewCount: 21,
          rating: 4,
          salesRank: 28033
        },
        {
          date: '2023-10-26',
          reviewCount: 21,
          rating: 4,
          salesRank: 26057
        },
        {
          date: '2023-10-27',
          reviewCount: 21,
          rating: 4,
          salesRank: 27684
        },
        {
          date: '2023-10-28',
          reviewCount: 24,
          rating: 4,
          salesRank: 29797
        },
        {
          date: '2023-10-29',
          reviewCount: 24,
          rating: 4,
          salesRank: 28232
        },
        {
          date: '2023-10-30',
          reviewCount: 24,
          rating: 4,
          salesRank: 25440
        },
        {
          date: '2023-10-31',
          reviewCount: 24,
          rating: 4,
          salesRank: 27045
        },
        {
          date: '2023-11-01',
          reviewCount: 24,
          rating: 4,
          salesRank: 25496
        },
        {
          date: '2023-11-02',
          reviewCount: 24,
          rating: 4,
          salesRank: 19048
        },
        {
          date: '2023-11-03',
          reviewCount: 27,
          rating: 4,
          salesRank: 23216
        },
        {
          date: '2023-11-04',
          reviewCount: 27,
          rating: 4,
          salesRank: 21089
        },
        {
          date: '2023-11-05',
          reviewCount: 27,
          rating: 4,
          salesRank: 22825
        },
        {
          date: '2023-11-06',
          reviewCount: 27,
          rating: 4,
          salesRank: 24376
        },
        {
          date: '2023-11-07',
          reviewCount: 27,
          rating: 4,
          salesRank: 23712
        },
        {
          date: '2023-11-08',
          reviewCount: 27,
          rating: 4,
          salesRank: 23509
        },
        {
          date: '2023-11-09',
          reviewCount: 27,
          rating: 4,
          salesRank: 23686
        },
        {
          date: '2023-11-10',
          reviewCount: 27,
          rating: 4,
          salesRank: 25046
        },
        {
          date: '2023-11-11',
          reviewCount: 27,
          rating: 4,
          salesRank: 25716
        },
        {
          date: '2023-11-12',
          reviewCount: 27,
          rating: 4,
          salesRank: 24274
        },
        {
          date: '2023-11-13',
          reviewCount: 27,
          rating: 4,
          salesRank: 25561
        },
        {
          date: '2023-11-14',
          reviewCount: 27,
          rating: 4,
          salesRank: 23068
        },
        {
          date: '2023-11-15',
          reviewCount: 27,
          rating: 4,
          salesRank: 22768
        },
        {
          date: '2023-11-16',
          reviewCount: 27,
          rating: 4,
          salesRank: 22606
        },
        {
          date: '2023-11-17',
          reviewCount: 29,
          rating: 4,
          salesRank: 23931
        },
        {
          date: '2023-11-18',
          reviewCount: 29,
          rating: 4,
          salesRank: 21086
        },
        {
          date: '2023-11-19',
          reviewCount: 29,
          rating: 4,
          salesRank: 22766
        },
        {
          date: '2023-11-20',
          reviewCount: 29,
          rating: 4,
          salesRank: 19898
        },
        {
          date: '2023-11-21',
          reviewCount: 29,
          rating: 4,
          salesRank: 22088
        },
        {
          date: '2023-11-22',
          reviewCount: 29,
          rating: 4,
          salesRank: 21290
        },
        {
          date: '2023-11-23',
          reviewCount: 29,
          rating: 4,
          salesRank: 20845
        },
        {
          date: '2023-11-24',
          reviewCount: 29,
          rating: 4,
          salesRank: 18705
        },
        {
          date: '2023-11-25',
          reviewCount: 29,
          rating: 4,
          salesRank: 19287
        },
        {
          date: '2023-11-26',
          reviewCount: 29,
          rating: 4,
          salesRank: 18335
        },
        {
          date: '2023-11-27',
          reviewCount: 29,
          rating: 4,
          salesRank: 19356
        },
        {
          date: '2023-11-28',
          reviewCount: 29,
          rating: 4,
          salesRank: 18261
        },
        {
          date: '2023-11-29',
          reviewCount: 29,
          rating: 4,
          salesRank: 19406
        },
        {
          date: '2023-11-30',
          reviewCount: 29,
          rating: 4,
          salesRank: 19152
        },
        {
          date: '2023-12-01',
          reviewCount: 29,
          rating: 4,
          salesRank: 22203
        },
        {
          date: '2023-12-02',
          reviewCount: 29,
          rating: 4,
          salesRank: 22323
        },
        {
          date: '2023-12-03',
          reviewCount: 31,
          rating: 4,
          salesRank: 22340
        },
        {
          date: '2023-12-04',
          reviewCount: 31,
          rating: 4,
          salesRank: 21977
        },
        {
          date: '2023-12-05',
          reviewCount: 31,
          rating: 4,
          salesRank: 19793
        },
        {
          date: '2023-12-06',
          reviewCount: 31,
          rating: 4,
          salesRank: 22458
        },
        {
          date: '2023-12-07',
          reviewCount: 31,
          rating: 4,
          salesRank: 18474
        },
        {
          date: '2023-12-08',
          reviewCount: 31,
          rating: 4,
          salesRank: 19800
        },
        {
          date: '2023-12-09',
          reviewCount: 31,
          rating: 4,
          salesRank: 23592
        },
        {
          date: '2023-12-10',
          reviewCount: 31,
          rating: 4,
          salesRank: 23447
        },
        {
          date: '2023-12-11',
          reviewCount: 31,
          rating: 4,
          salesRank: 23362
        },
        {
          date: '2023-12-12',
          reviewCount: 31,
          rating: 4,
          salesRank: 21763
        },
        {
          date: '2023-12-13',
          reviewCount: 31,
          rating: 4,
          salesRank: 22544
        },
        {
          date: '2023-12-14',
          reviewCount: 31,
          rating: 4,
          salesRank: 20697
        },
        {
          date: '2023-12-15',
          reviewCount: 31,
          rating: 4,
          salesRank: 19264
        },
        {
          date: '2023-12-16',
          reviewCount: 39,
          rating: 4,
          salesRank: 19874
        },
        {
          date: '2023-12-17',
          reviewCount: 39,
          rating: 4,
          salesRank: 20729
        },
        {
          date: '2023-12-18',
          reviewCount: 39,
          rating: 4,
          salesRank: 20607
        },
        {
          date: '2023-12-19',
          reviewCount: 39,
          rating: 4,
          salesRank: 18821
        },
        {
          date: '2023-12-20',
          reviewCount: 39,
          rating: 4,
          salesRank: 17292
        },
        {
          date: '2023-12-21',
          reviewCount: 39,
          rating: 4,
          salesRank: 17939
        },
        {
          date: '2023-12-22',
          reviewCount: 39,
          rating: 4,
          salesRank: 17588
        },
        {
          date: '2023-12-23',
          reviewCount: 39,
          rating: 4,
          salesRank: 18383
        },
        {
          date: '2023-12-24',
          reviewCount: 39,
          rating: 4,
          salesRank: 17946
        },
        {
          date: '2023-12-25',
          reviewCount: 39,
          rating: 4,
          salesRank: 19353
        },
        {
          date: '2023-12-26',
          reviewCount: 39,
          rating: 4,
          salesRank: 18391
        },
        {
          date: '2023-12-27',
          reviewCount: 39,
          rating: 4,
          salesRank: 20691
        },
        {
          date: '2023-12-28',
          reviewCount: 39,
          rating: 4,
          salesRank: 25157
        },
        {
          date: '2023-12-29',
          reviewCount: 39,
          rating: 4,
          salesRank: 21625
        },
        {
          date: '2023-12-30',
          reviewCount: 39,
          rating: 4,
          salesRank: 19546
        },
        {
          date: '2023-12-31',
          reviewCount: 39,
          rating: 4,
          salesRank: 20240
        },
        {
          date: '2024-01-01',
          reviewCount: 39,
          rating: 4,
          salesRank: 22220
        },
        {
          date: '2024-01-02',
          reviewCount: 41,
          rating: 4,
          salesRank: 21170
        },
        {
          date: '2024-01-03',
          reviewCount: 41,
          rating: 4,
          salesRank: 22360
        },
        {
          date: '2024-01-04',
          reviewCount: 41,
          rating: 4,
          salesRank: 19981
        },
        {
          date: '2024-01-05',
          reviewCount: 41,
          rating: 4,
          salesRank: 20765
        },
        {
          date: '2024-01-06',
          reviewCount: 41,
          rating: 4,
          salesRank: 22771
        },
        {
          date: '2024-01-07',
          reviewCount: 41,
          rating: 4,
          salesRank: 22048
        },
        {
          date: '2024-01-08',
          reviewCount: 43,
          rating: 4,
          salesRank: 20596
        },
        {
          date: '2024-01-09',
          reviewCount: 43,
          rating: 4,
          salesRank: 19382
        },
        {
          date: '2024-01-10',
          reviewCount: 43,
          rating: 4,
          salesRank: 20041
        },
        {
          date: '2024-01-11',
          reviewCount: 43,
          rating: 4,
          salesRank: 20229
        },
        {
          date: '2024-01-12',
          reviewCount: 43,
          rating: 4,
          salesRank: 19903
        },
        {
          date: '2024-01-13',
          reviewCount: 44,
          rating: 4,
          salesRank: 21445
        },
        {
          date: '2024-01-14',
          reviewCount: 44,
          rating: 4,
          salesRank: 23593
        },
        {
          date: '2024-01-15',
          reviewCount: 44,
          rating: 4,
          salesRank: 24859
        },
        {
          date: '2024-01-16',
          reviewCount: 44,
          rating: 4,
          salesRank: 21694
        },
        {
          date: '2024-01-17',
          reviewCount: 44,
          rating: 4,
          salesRank: 21112
        },
        {
          date: '2024-01-18',
          reviewCount: 46,
          rating: 4,
          salesRank: 21698
        },
        {
          date: '2024-01-19',
          reviewCount: 46,
          rating: 4,
          salesRank: 21593
        },
        {
          date: '2024-01-20',
          reviewCount: 46,
          rating: 4,
          salesRank: 20992
        },
        {
          date: '2024-01-21',
          reviewCount: 46,
          rating: 4,
          salesRank: 19053
        },
        {
          date: '2024-01-22',
          reviewCount: 46,
          rating: 4,
          salesRank: 18777
        },
        {
          date: '2024-01-23',
          reviewCount: 48,
          rating: 4,
          salesRank: 19260
        },
        {
          date: '2024-01-24',
          reviewCount: 48,
          rating: 4,
          salesRank: 18505
        },
        {
          date: '2024-01-25',
          reviewCount: 48,
          rating: 4,
          salesRank: 20128
        },
        {
          date: '2024-01-26',
          reviewCount: 48,
          rating: 4,
          salesRank: 21157
        },
        {
          date: '2024-01-27',
          reviewCount: 48,
          rating: 4,
          salesRank: 21037
        },
        {
          date: '2024-01-28',
          reviewCount: 51,
          rating: 4,
          salesRank: 21530
        },
        {
          date: '2024-01-29',
          reviewCount: 51,
          rating: 4,
          salesRank: 22675
        },
        {
          date: '2024-01-30',
          reviewCount: 51,
          rating: 4,
          salesRank: 20895
        },
        {
          date: '2024-01-31',
          reviewCount: 51,
          rating: 4,
          salesRank: 21145
        },
        {
          date: '2024-02-01',
          reviewCount: 51,
          rating: 4,
          salesRank: 22502
        },
        {
          date: '2024-02-02',
          reviewCount: 51,
          rating: 4,
          salesRank: 24932
        },
        {
          date: '2024-02-03',
          reviewCount: 51,
          rating: 4,
          salesRank: 25120
        },
        {
          date: '2024-02-04',
          reviewCount: 51,
          rating: 4,
          salesRank: 24235
        },
        {
          date: '2024-02-05',
          reviewCount: 51,
          rating: 4,
          salesRank: 25554
        },
        {
          date: '2024-02-06',
          reviewCount: 51,
          rating: 4,
          salesRank: 22784
        },
        {
          date: '2024-02-07',
          reviewCount: 51,
          rating: 4,
          salesRank: 22021
        },
        {
          date: '2024-02-08',
          reviewCount: 51,
          rating: 4,
          salesRank: 22824
        },
        {
          date: '2024-02-09',
          reviewCount: 51,
          rating: 4,
          salesRank: 21872
        },
        {
          date: '2024-02-10',
          reviewCount: 51,
          rating: 4,
          salesRank: 21292
        },
        {
          date: '2024-02-11',
          reviewCount: 61,
          rating: 4,
          salesRank: 21160
        },
        {
          date: '2024-02-12',
          reviewCount: 61,
          rating: 4,
          salesRank: 25805
        },
        {
          date: '2024-02-13',
          reviewCount: 61,
          rating: 4,
          salesRank: 25792
        },
        {
          date: '2024-02-14',
          reviewCount: 61,
          rating: 4,
          salesRank: 26670
        },
        {
          date: '2024-02-15',
          reviewCount: 61,
          rating: 4,
          salesRank: 23584
        },
        {
          date: '2024-02-16',
          reviewCount: 61,
          rating: 4,
          salesRank: 22083
        },
        {
          date: '2024-02-17',
          reviewCount: 61,
          rating: 4,
          salesRank: 23173
        },
        {
          date: '2024-02-18',
          reviewCount: 61,
          rating: 4,
          salesRank: 24843
        },
        {
          date: '2024-02-19',
          reviewCount: 61,
          rating: 4,
          salesRank: 27071
        },
        {
          date: '2024-02-20',
          reviewCount: 61,
          rating: 4,
          salesRank: 28977
        },
        {
          date: '2024-02-21',
          reviewCount: 61,
          rating: 4,
          salesRank: 28260
        },
        {
          date: '2024-02-22',
          reviewCount: 61,
          rating: 4,
          salesRank: 25731
        },
        {
          date: '2024-02-23',
          reviewCount: 67,
          rating: 4,
          salesRank: 24534
        },
        {
          date: '2024-02-24',
          reviewCount: 67,
          rating: 4,
          salesRank: 24672
        },
        {
          date: '2024-02-25',
          reviewCount: 67,
          rating: 4,
          salesRank: 22584
        },
        {
          date: '2024-02-26',
          reviewCount: 67,
          rating: 4,
          salesRank: 23908
        },
        {
          date: '2024-02-27',
          reviewCount: 67,
          rating: 4,
          salesRank: 19648
        },
        {
          date: '2024-02-28',
          reviewCount: 67,
          rating: 4,
          salesRank: 19080
        },
        {
          date: '2024-02-29',
          reviewCount: 67,
          rating: 4,
          salesRank: 19853
        },
        {
          date: '2024-03-01',
          reviewCount: 67,
          rating: 4,
          salesRank: 20955
        },
        {
          date: '2024-03-02',
          reviewCount: 67,
          rating: 4,
          salesRank: 21706
        },
        {
          date: '2024-03-03',
          reviewCount: 67,
          rating: 4,
          salesRank: 21907
        },
        {
          date: '2024-03-04',
          reviewCount: 67,
          rating: 4,
          salesRank: 23575
        },
        {
          date: '2024-03-05',
          reviewCount: 67,
          rating: 4,
          salesRank: 23023
        },
        {
          date: '2024-03-06',
          reviewCount: 68,
          rating: 4,
          salesRank: 21595
        },
        {
          date: '2024-03-07',
          reviewCount: 68,
          rating: 4,
          salesRank: 24009
        },
        {
          date: '2024-03-08',
          reviewCount: 68,
          rating: 4,
          salesRank: 28577
        },
        {
          date: '2024-03-09',
          reviewCount: 68,
          rating: 4,
          salesRank: 27207
        },
        {
          date: '2024-03-10',
          reviewCount: 68,
          rating: 4,
          salesRank: 25452
        },
        {
          date: '2024-03-11',
          reviewCount: 68,
          rating: 4,
          salesRank: 25237
        },
        {
          date: '2024-03-12',
          reviewCount: 68,
          rating: 4,
          salesRank: 26194
        },
        {
          date: '2024-03-13',
          reviewCount: 68,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-14',
          reviewCount: 68,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-15',
          reviewCount: 68,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-16',
          reviewCount: 68,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-17',
          reviewCount: 70,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-18',
          reviewCount: 70,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-19',
          reviewCount: 70,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-20',
          reviewCount: 70,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-21',
          reviewCount: 70,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-22',
          reviewCount: 70,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-23',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-24',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-25',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-26',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-27',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-28',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-29',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-30',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-03-31',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-01',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-02',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-03',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-04',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-05',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-06',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-07',
          reviewCount: 72,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-08',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-09',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-10',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-11',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-12',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-13',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-14',
          reviewCount: 76,
          rating: 4,
          salesRank: null
        },
        {
          date: '2024-04-15',
          reviewCount: null,
          rating: null,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      createdAt: '2024-04-15T15:24:50.205Z'
    },
    {
      id: promotedProductId,
      productName: 'SoundSlayer Wireless Wearable Speaker System',
      productUrl: 'https://www.amazon.com/Panasonic-SoundSlayer-Lightweight-Microphone-Dimensional/dp/B0CKDFM5J2',
      productImage: 'https://media.productwind.com/products/fe79c63c-77bd-4e13-9655-e2e8eae1cb69/images/1701373007521',
      productPerformanceRecords: [
        {
          date: '2023-12-01',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-02',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-03',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-04',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-05',
          reviewCount: null,
          rating: null,
          salesRank: null
        },
        {
          date: '2023-12-06',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-07',
          reviewCount: 1,
          rating: 4,
          salesRank: null
        },
        {
          date: '2023-12-08',
          reviewCount: 1,
          rating: 4,
          salesRank: 52901
        },
        {
          date: '2023-12-09',
          reviewCount: 1,
          rating: 4,
          salesRank: 41842
        },
        {
          date: '2023-12-10',
          reviewCount: 1,
          rating: 4,
          salesRank: 50006
        },
        {
          date: '2023-12-11',
          reviewCount: 1,
          rating: 4,
          salesRank: 34757
        },
        {
          date: '2023-12-12',
          reviewCount: 17,
          rating: 4,
          salesRank: 37031
        },
        {
          date: '2023-12-13',
          reviewCount: 17,
          rating: 4,
          salesRank: 37999
        },
        {
          date: '2023-12-14',
          reviewCount: 17,
          rating: 4,
          salesRank: 30873
        },
        {
          date: '2023-12-15',
          reviewCount: 17,
          rating: 4,
          salesRank: 21825
        },
        {
          date: '2023-12-16',
          reviewCount: 17,
          rating: 4,
          salesRank: 23208
        },
        {
          date: '2023-12-17',
          reviewCount: 17,
          rating: 4,
          salesRank: 25119
        },
        {
          date: '2023-12-18',
          reviewCount: 19,
          rating: 4,
          salesRank: 19978
        },
        {
          date: '2023-12-19',
          reviewCount: 19,
          rating: 4,
          salesRank: 15383
        },
        {
          date: '2023-12-20',
          reviewCount: 19,
          rating: 4,
          salesRank: 14981
        },
        {
          date: '2023-12-21',
          reviewCount: 19,
          rating: 4,
          salesRank: 10680
        },
        {
          date: '2023-12-22',
          reviewCount: 19,
          rating: 4,
          salesRank: 8129
        },
        {
          date: '2023-12-23',
          reviewCount: 19,
          rating: 4,
          salesRank: 5755
        },
        {
          date: '2023-12-24',
          reviewCount: 28,
          rating: 4,
          salesRank: 5509
        },
        {
          date: '2023-12-25',
          reviewCount: 28,
          rating: 4,
          salesRank: 5414
        },
        {
          date: '2023-12-26',
          reviewCount: 28,
          rating: 4,
          salesRank: 4472
        },
        {
          date: '2023-12-27',
          reviewCount: 28,
          rating: 4,
          salesRank: 4110
        },
        {
          date: '2023-12-28',
          reviewCount: 28,
          rating: 4,
          salesRank: 3157
        },
        {
          date: '2023-12-29',
          reviewCount: 28,
          rating: 4,
          salesRank: 3331
        },
        {
          date: '2023-12-30',
          reviewCount: 33,
          rating: 4,
          salesRank: 2979
        },
        {
          date: '2023-12-31',
          reviewCount: 33,
          rating: 4,
          salesRank: 2207
        },
        {
          date: '2024-01-01',
          reviewCount: 33,
          rating: 4,
          salesRank: 1839
        },
        {
          date: '2024-01-02',
          reviewCount: 33,
          rating: 4,
          salesRank: 1858
        },
        {
          date: '2024-01-03',
          reviewCount: 33,
          rating: 4,
          salesRank: 1538
        },
        {
          date: '2024-01-04',
          reviewCount: 39,
          rating: 4,
          salesRank: 1501
        },
        {
          date: '2024-01-05',
          reviewCount: 39,
          rating: 4,
          salesRank: 1167
        },
        {
          date: '2024-01-06',
          reviewCount: 39,
          rating: 4,
          salesRank: 1119
        },
        {
          date: '2024-01-07',
          reviewCount: 39,
          rating: 4,
          salesRank: 857
        },
        {
          date: '2024-01-08',
          reviewCount: 39,
          rating: 4,
          salesRank: 907
        },
        {
          date: '2024-01-09',
          reviewCount: 39,
          rating: 4,
          salesRank: 934
        },
        {
          date: '2024-01-10',
          reviewCount: 42,
          rating: 4,
          salesRank: 938
        },
        {
          date: '2024-01-11',
          reviewCount: 42,
          rating: 4,
          salesRank: 993
        },
        {
          date: '2024-01-12',
          reviewCount: 42,
          rating: 4,
          salesRank: 866
        },
        {
          date: '2024-01-13',
          reviewCount: 42,
          rating: 4,
          salesRank: 1015
        },
        {
          date: '2024-01-14',
          reviewCount: 42,
          rating: 4,
          salesRank: 860
        },
        {
          date: '2024-01-15',
          reviewCount: 46,
          rating: 4,
          salesRank: 967
        },
        {
          date: '2024-01-16',
          reviewCount: 46,
          rating: 4,
          salesRank: 834
        },
        {
          date: '2024-01-17',
          reviewCount: 46,
          rating: 4,
          salesRank: 836
        },
        {
          date: '2024-01-18',
          reviewCount: 46,
          rating: 4,
          salesRank: 541
        },
        {
          date: '2024-01-19',
          reviewCount: 46,
          rating: 4,
          salesRank: 763
        },
        {
          date: '2024-01-20',
          reviewCount: 46,
          rating: 4,
          salesRank: 532
        },
        {
          date: '2024-01-21',
          reviewCount: 48,
          rating: 4,
          salesRank: 638
        },
        {
          date: '2024-01-22',
          reviewCount: 48,
          rating: 4,
          salesRank: 608
        },
        {
          date: '2024-01-23',
          reviewCount: 48,
          rating: 4,
          salesRank: 735
        },
        {
          date: '2024-01-24',
          reviewCount: 48,
          rating: 4,
          salesRank: 656
        },
        {
          date: '2024-01-25',
          reviewCount: 48,
          rating: 4,
          salesRank: 812
        },
        {
          date: '2024-01-26',
          reviewCount: 49,
          rating: 4,
          salesRank: 606
        },
        {
          date: '2024-01-27',
          reviewCount: 49,
          rating: 4,
          salesRank: 757
        },
        {
          date: '2024-01-28',
          reviewCount: 49,
          rating: 4,
          salesRank: 637
        },
        {
          date: '2024-01-29',
          reviewCount: 49,
          rating: 4,
          salesRank: 675
        },
        {
          date: '2024-01-30',
          reviewCount: 49,
          rating: 4,
          salesRank: 644
        },
        {
          date: '2024-01-31',
          reviewCount: 50,
          rating: 4,
          salesRank: 713
        },
        {
          date: '2024-02-01',
          reviewCount: 50,
          rating: 4,
          salesRank: 414
        },
        {
          date: '2024-02-02',
          reviewCount: 50,
          rating: 4,
          salesRank: 497
        },
        {
          date: '2024-02-03',
          reviewCount: 50,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2024-02-04',
          reviewCount: 50,
          rating: 4,
          salesRank: 347
        },
        {
          date: '2024-02-05',
          reviewCount: 50,
          rating: 4,
          salesRank: 344
        },
        {
          date: '2024-02-06',
          reviewCount: 50,
          rating: 4,
          salesRank: 361
        },
        {
          date: '2024-02-07',
          reviewCount: 50,
          rating: 4,
          salesRank: 279
        },
        {
          date: '2024-02-08',
          reviewCount: 50,
          rating: 4,
          salesRank: 315
        },
        {
          date: '2024-02-09',
          reviewCount: 50,
          rating: 4,
          salesRank: 264
        },
        {
          date: '2024-02-10',
          reviewCount: 50,
          rating: 4,
          salesRank: 358
        },
        {
          date: '2024-02-11',
          reviewCount: 50,
          rating: 4,
          salesRank: 256
        },
        {
          date: '2024-02-12',
          reviewCount: 52,
          rating: 4,
          salesRank: 338
        },
        {
          date: '2024-02-13',
          reviewCount: 52,
          rating: 4,
          salesRank: 221
        },
        {
          date: '2024-02-14',
          reviewCount: 52,
          rating: 4,
          salesRank: 286
        },
        {
          date: '2024-02-15',
          reviewCount: 52,
          rating: 4,
          salesRank: 206
        },
        {
          date: '2024-02-16',
          reviewCount: 52,
          rating: 4,
          salesRank: 288
        },
        {
          date: '2024-02-17',
          reviewCount: 52,
          rating: 4,
          salesRank: 215
        },
        {
          date: '2024-02-18',
          reviewCount: 52,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2024-02-19',
          reviewCount: 52,
          rating: 4,
          salesRank: 370
        },
        {
          date: '2024-02-20',
          reviewCount: 52,
          rating: 4,
          salesRank: 337
        },
        {
          date: '2024-02-21',
          reviewCount: 52,
          rating: 4,
          salesRank: 364
        },
        {
          date: '2024-02-22',
          reviewCount: 53,
          rating: 4,
          salesRank: 339
        },
        {
          date: '2024-02-23',
          reviewCount: 53,
          rating: 4,
          salesRank: 328
        },
        {
          date: '2024-02-24',
          reviewCount: 53,
          rating: 4,
          salesRank: 245
        },
        {
          date: '2024-02-25',
          reviewCount: 53,
          rating: 4,
          salesRank: 298
        },
        {
          date: '2024-02-26',
          reviewCount: 53,
          rating: 4,
          salesRank: 249
        },
        {
          date: '2024-02-27',
          reviewCount: 53,
          rating: 4,
          salesRank: 292
        },
        {
          date: '2024-02-28',
          reviewCount: 54,
          rating: 4,
          salesRank: 180
        },
        {
          date: '2024-02-29',
          reviewCount: 54,
          rating: 4,
          salesRank: 231
        },
        {
          date: '2024-03-01',
          reviewCount: 54,
          rating: 4,
          salesRank: 196
        },
        {
          date: '2024-03-02',
          reviewCount: 54,
          rating: 4,
          salesRank: 220
        },
        {
          date: '2024-03-03',
          reviewCount: 54,
          rating: 4,
          salesRank: 212
        },
        {
          date: '2024-03-04',
          reviewCount: 54,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2024-03-05',
          reviewCount: 58,
          rating: 4,
          salesRank: 276
        },
        {
          date: '2024-03-06',
          reviewCount: 58,
          rating: 4,
          salesRank: 326
        },
        {
          date: '2024-03-07',
          reviewCount: 58,
          rating: 4,
          salesRank: 295
        },
        {
          date: '2024-03-08',
          reviewCount: 58,
          rating: 4,
          salesRank: 336
        },
        {
          date: '2024-03-09',
          reviewCount: 58,
          rating: 4,
          salesRank: 304
        },
        {
          date: '2024-03-10',
          reviewCount: 59,
          rating: 4,
          salesRank: 327
        },
        {
          date: '2024-03-11',
          reviewCount: 59,
          rating: 4,
          salesRank: 276
        },
        {
          date: '2024-03-12',
          reviewCount: 59,
          rating: 4,
          salesRank: 263
        },
        {
          date: '2024-03-13',
          reviewCount: 59,
          rating: 4,
          salesRank: 260
        },
        {
          date: '2024-03-14',
          reviewCount: 59,
          rating: 4,
          salesRank: 302
        },
        {
          date: '2024-03-15',
          reviewCount: 59,
          rating: 4,
          salesRank: 274
        },
        {
          date: '2024-03-16',
          reviewCount: 61,
          rating: 4,
          salesRank: 322
        },
        {
          date: '2024-03-17',
          reviewCount: 61,
          rating: 4,
          salesRank: 331
        },
        {
          date: '2024-03-18',
          reviewCount: 61,
          rating: 4,
          salesRank: 388
        },
        {
          date: '2024-03-19',
          reviewCount: 61,
          rating: 4,
          salesRank: 342
        },
        {
          date: '2024-03-20',
          reviewCount: 61,
          rating: 4,
          salesRank: 406
        },
        {
          date: '2024-03-21',
          reviewCount: 63,
          rating: 4,
          salesRank: 379
        },
        {
          date: '2024-03-22',
          reviewCount: 63,
          rating: 4,
          salesRank: 418
        },
        {
          date: '2024-03-23',
          reviewCount: 63,
          rating: 4,
          salesRank: 394
        },
        {
          date: '2024-03-24',
          reviewCount: 63,
          rating: 4,
          salesRank: 404
        },
        {
          date: '2024-03-25',
          reviewCount: 63,
          rating: 4,
          salesRank: 317
        },
        {
          date: '2024-03-26',
          reviewCount: 63,
          rating: 4,
          salesRank: 349
        },
        {
          date: '2024-03-27',
          reviewCount: 65,
          rating: 4,
          salesRank: 270
        },
        {
          date: '2024-03-28',
          reviewCount: 65,
          rating: 4,
          salesRank: 284
        },
        {
          date: '2024-03-29',
          reviewCount: 65,
          rating: 4,
          salesRank: 261
        },
        {
          date: '2024-03-30',
          reviewCount: 65,
          rating: 4,
          salesRank: 300
        },
        {
          date: '2024-03-31',
          reviewCount: 65,
          rating: 4,
          salesRank: 344
        },
        {
          date: '2024-04-01',
          reviewCount: 66,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2024-04-02',
          reviewCount: 66,
          rating: 4,
          salesRank: 354
        },
        {
          date: '2024-04-03',
          reviewCount: 66,
          rating: 4,
          salesRank: 287
        },
        {
          date: '2024-04-04',
          reviewCount: 66,
          rating: 4,
          salesRank: 231
        },
        {
          date: '2024-04-05',
          reviewCount: 66,
          rating: 4,
          salesRank: 203
        },
        {
          date: '2024-04-06',
          reviewCount: 66,
          rating: 4,
          salesRank: 228
        },
        {
          date: '2024-04-07',
          reviewCount: 66,
          rating: 4,
          salesRank: 241
        },
        {
          date: '2024-04-08',
          reviewCount: 66,
          rating: 4,
          salesRank: 228
        },
        {
          date: '2024-04-09',
          reviewCount: 66,
          rating: 4,
          salesRank: 212
        },
        {
          date: '2024-04-10',
          reviewCount: 66,
          rating: 4,
          salesRank: 231
        },
        {
          date: '2024-04-11',
          reviewCount: 66,
          rating: 4,
          salesRank: 225
        },
        {
          date: '2024-04-12',
          reviewCount: 66,
          rating: 4,
          salesRank: 218
        },
        {
          date: '2024-04-13',
          reviewCount: 66,
          rating: 4,
          salesRank: 217
        },
        {
          date: '2024-04-14',
          reviewCount: 66,
          rating: 4,
          salesRank: 236
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      createdAt: '2024-04-15T15:14:05.198Z'
    }
  ]
})
