import { Box, Button, Dialog, Stack, Typography } from '@mui/material'
import { useCreateProposalContext } from '../context/CreateProposalContext'
import Row from '@momentum/components/row'
import { CheckCircleOutline } from '@mui/icons-material'
import { useEffect, useMemo, useState } from 'react'
import CloseButton from '@momentum/components/close-button'
import { CheckInput } from '@productwindtom/ui-base'
import { useFormContext, useWatch } from 'react-hook-form'
import { orderBy, uniq, uniqBy, keyBy } from 'lodash'
import { ProposalCreateForm } from '../types'
import { notEmpty } from '@productwindtom/shared-node'
import VariationPriceAlert, { useVariationPriceAlert } from './VariationPriceAlert'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product } from '@momentum/routes/proposals-create/context/queries'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { getCdnImageUrl, noProductImageSmallAlt } from '@momentum/utils/imageUtils'

const ProductVariationsInput = () => {
  const { products } = useBrandContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { selectedProduct, setSelectedProduct, selectableProducts } = useCreateProposalContext()
  const { setValue } = useFormContext<ProposalCreateForm>()
  const productVariationSkus = useWatch<ProposalCreateForm, 'productVariationSkus'>({ name: 'productVariationSkus' })

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const findParent = (currentProduct: Product): Product | undefined => {
    const potentialParentSku = products?.find(p => p.id === currentProduct?.parentSkuId)

    if (
      !potentialParentSku ||
      potentialParentSku.id === currentProduct?.id ||
      potentialParentSku.skuId === currentProduct?.skuId
    ) {
      return currentProduct
    }
    return findParent(potentialParentSku)
  }

  const highestParentSku = useMemo(() => {
    if (selectedProduct) {
      return findParent(selectedProduct)
    }
  }, [selectedProduct])

  const findChildren = (currentProduct: Product): Product[] => {
    const children = products?.filter(p => p.parentSkuId === currentProduct.id && p.parentSkuId !== p.id) || []
    if (children.length) {
      return children.map(child => findChildren(child)).flat()
    }
    return [currentProduct]
  }

  const lowestChildren = useMemo(() => {
    if (highestParentSku) {
      return uniqBy(findChildren(highestParentSku), 'id')
    }
    return []
  }, [highestParentSku, products])

  useEffect(() => {
    if (selectedProduct?.skuId && !productVariationSkus?.length) {
      if (lowestChildren.find(lc => lc.id === selectedProduct.id)) {
        setValue('productVariationSkus', [selectedProduct.skuId])
      } else {
        setValue('productVariationSkus', lowestChildren.map(lc => lc.skuId).filter(notEmpty))
      }
    } else if (!productVariationSkus?.length) {
      setValue('productVariationSkus', [])
    }
  }, [selectedProduct, productVariationSkus, setValue, lowestChildren])

  const handleSubmit = (variations: { [key: string]: boolean }) => {
    const productVariationSkus = Object.entries(variations)
      .map(([key, value]) => (value ? key : ''))
      .filter(val => !!val)

    if (productVariationSkus.length > 1) {
      if (highestParentSku) {
        setValue('productId', highestParentSku.id)
        setSelectedProduct(highestParentSku)
      }
      setValue('productVariationSkus', uniq(productVariationSkus))
    } else if (productVariationSkus.length === 1) {
      const selectedVariation = lowestChildren.find(v => v.skuId === productVariationSkus[0])

      if (selectedVariation) {
        setValue('productId', selectedVariation.id)
        setSelectedProduct(selectedVariation)
      }
      setValue('productVariationSkus', [])
    }
    handleClose()
  }

  const keyedSelectableProducts = keyBy(selectableProducts, 'id')

  const selectedVariations = lowestChildren.filter(lc => productVariationSkus?.includes(lc.skuId!)).map(s => s.id)

  const prices = selectedVariations?.map(v => ({
    priceCents: keyedSelectableProducts[v]?.priceCents,
    store: keyedSelectableProducts[v]?.store
  }))

  return (
    <Stack spacing={1}>
      <Row spacing={1}>
        <CheckCircleOutline color="success" />
        <Typography variant="subtitle2">
          Creators can purchase {productVariationSkus?.length} of {lowestChildren?.length} variations of this product.
        </Typography>
        <Button variant="text" onClick={() => setIsModalOpen(true)}>
          Change
        </Button>
        <Dialog open={isModalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <Stack p={3} spacing={2}>
            <Row justifyContent={'space-between'}>
              <Typography variant="h4">Product variations</Typography>
              <CloseButton
                iconButtonProps={{
                  onClick: () => setIsModalOpen(false)
                }}
              />
            </Row>
            <VariationsFields
              variations={lowestChildren}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              initialVariations={productVariationSkus}
            />
          </Stack>
        </Dialog>
      </Row>
      {prices && <VariationPriceAlert prices={prices} />}
    </Stack>
  )
}

export default ProductVariationsInput

const VariationsFields = ({
  variations,
  handleClose,
  handleSubmit,
  initialVariations
}: {
  variations: Product[]
  initialVariations?: ProposalCreateForm['productVariationSkus']
  handleClose: () => void
  handleSubmit: (variations: { [key: string]: boolean }) => void
}) => {
  const { selectedProduct } = useCreateProposalContext()

  const { setValue } = useFormContext()
  const variationsData = useWatch({ name: 'variations' })

  useEffect(() => {
    setValue(
      'variations',
      initialVariations?.reduce(
        (acc, variation) => ({
          ...acc,
          [variation]: true
        }),
        {}
      )
    )

    return () => {
      setValue('variations', undefined)
    }
  }, [initialVariations, setValue])

  const sortedVariations = useMemo(
    () =>
      orderBy(
        uniqBy(variations, 'id'),
        [a => (a.id === selectedProduct?.id ? 0 : 1), a => a.parentSkuId, a => a.name],
        ['asc', 'asc', 'asc']
      ),
    [variations]
  )

  const selectedVariations = sortedVariations.filter(v => v.skuId && variationsData?.[v.skuId])
  const prices = selectedVariations.map(v => ({
    priceCents: v.priceCents,
    store: v.store
  }))

  const { isOutSideBounds } = useVariationPriceAlert(prices)

  return (
    <Stack spacing={2}>
      {sortedVariations.map(variation => (
        <Row key={variation.id} spacing={1} alignItems={'flex-start'}>
          <Box>
            <img
              src={getCdnImageUrl(variation.image) || '/images/no-product-small.png'}
              loading="lazy"
              width={40}
              height={40}
              alt={variation.name}
              style={{ objectFit: 'contain' }}
              onError={noProductImageSmallAlt}
            />
          </Box>
          <Row spacing={2} justifyContent={'space-between'} width={'100%'} alignItems={'flex-start'}>
            <Stack spacing={0.2} maxWidth={300} flex={1}>
              <Typography variant="label3">{variation.skuId}</Typography>
              <Typography variant="body2">{variation.name}</Typography>
            </Stack>
            {variation.priceCents && (
              <Stack>
                <Typography variant="label1">
                  {toCurrencyStringCents(variation.priceCents, STORE_TO_LOCALE[variation.store])}
                </Typography>
                <Typography variant="body2" textAlign={'right'}>
                  ASP
                </Typography>
              </Stack>
            )}
            <Stack width={40} alignItems={'center'}>
              <CheckInput
                name={`variations.${variation.skuId}`}
                controlLabelProps={{
                  sx: {
                    m: 0
                  }
                }}
                checkboxProps={{
                  size: 'medium'
                }}
              />
            </Stack>
          </Row>
        </Row>
      ))}
      <VariationPriceAlert prices={prices} />
      <Row spacing={1} justifyContent={'flex-end'}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={isOutSideBounds || !prices.length}
          onClick={() => handleSubmit(variationsData)}
          variant="contained"
        >
          Save
        </Button>
      </Row>
    </Stack>
  )
}
