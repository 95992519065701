import { ProposalGoal, PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { DateTime } from 'luxon'
import { keyBy, max } from 'lodash'

import { getTimelineSteps } from '@productwindtom/shared-momentum'

export const useTimelineSteps = ({
  goal,
  creatorPricing,
  launchDate,
  isVendor,
  estimatedUnitsSoldPerWeek,
  reviewsGoal
}: {
  goal?: ProposalGoal
  creatorPricing: ProposalCreatorPricingInput[]
  launchDate?: DateTime
  isVendor?: boolean
  estimatedUnitsSoldPerWeek?: number
  reviewsGoal?: number
}) => {
  const isMonthly = goal === ProposalGoal.EVERGREEN

  const creatorPricingByType = keyBy(creatorPricing, 'type')

  const timelineSteps = getTimelineSteps({
    premiumUgcWeeklySchedule: creatorPricingByType[PricingCreatorType.PREMIUM_UGC]?.schedule || [],
    ugcWeeklySchedule: creatorPricingByType[PricingCreatorType.UGC]?.schedule || [],
    socialWeeklySchedule: creatorPricingByType[PricingCreatorType.SOCIAL]?.schedule || [],
    brandAdvocatesWeeklySchedule: creatorPricingByType[PricingCreatorType.ADVOCATE]?.schedule || [],
    startDate: launchDate,
    unitsSoldPerWeek: estimatedUnitsSoldPerWeek,
    reviewsGoal,
    isVendor: isVendor
  })
  const lastDate = max(timelineSteps.map(step => step.date))

  const durationWeeks = launchDate && lastDate ? Math.ceil(lastDate.diff(launchDate, 'weeks').weeks) : undefined

  return {
    isMonthly,
    timelineSteps,
    durationWeeks
  }
}
