import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { Search } from '@mui/icons-material'
import { Autocomplete, Avatar, Button, ButtonGroup, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { CreatorApprovalStatus } from '@productwindtom/shared-momentum-zeus-types'
import { useMemo } from 'react'
import { CreatorTypeFilter, useApproveCreatorsContext } from './approveCreatorsContext'
import { SOCIAL_CREATOR_TYPES, UGC_CREATOR_TYPES } from './utils'

const ApprovalStatusLabels: Record<CreatorApprovalStatus, string> = {
  [CreatorApprovalStatus.APPROVED]: 'Approved',
  [CreatorApprovalStatus.REJECTED]: 'Rejected',
  [CreatorApprovalStatus.PENDING]: 'Pending review'
}

export const ApproveCreatorsFilterBar = () => {
  const { creators, search, setSearch, approvalStatus, setApprovalStatus, creatorTypeFilter, setCreatorTypeFilter } =
    useApproveCreatorsContext()

  const pendingSocialCount = useMemo(() => {
    return creators.filter(
      creator =>
        creator.approvalState.status === CreatorApprovalStatus.PENDING &&
        SOCIAL_CREATOR_TYPES.includes(creator.creatorType)
    ).length
  }, [creators])

  const pendingUgcCount = useMemo(() => {
    return creators.filter(
      creator =>
        creator.approvalState.status === CreatorApprovalStatus.PENDING &&
        UGC_CREATOR_TYPES.includes(creator.creatorType)
    ).length
  }, [creators])

  return (
    <Stack direction={'row'} spacing={2} pt={2}>
      <DebouncedTextField
        placeholder={'Search'}
        sx={{ mb: 4 }}
        onChange={setSearch}
        value={search}
        style={{ flex: 2 }}
        InputProps={{
          endAdornment: <Search />
        }}
      />

      <Autocomplete
        value={approvalStatus}
        onChange={(_e, value) => setApprovalStatus(value ?? undefined)}
        options={[CreatorApprovalStatus.APPROVED, CreatorApprovalStatus.REJECTED, CreatorApprovalStatus.PENDING]}
        getOptionLabel={option => ApprovalStatusLabels[option]}
        sx={{ flex: 1 }}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              sx: {
                cursor: 'pointer'
              }
            }}
            placeholder="All statuses"
          />
        )}
      />

      <ButtonGroup
        color="primary"
        variant="outlined"
        size="medium"
        disableRipple
        disableElevation
        sx={{
          alignSelf: 'flex-end'
        }}
      >
        <Button
          variant={creatorTypeFilter === CreatorTypeFilter.SOCIAL ? 'contained' : 'outlined'}
          value={CreatorTypeFilter.SOCIAL}
          sx={{ pr: 2 }}
          onClick={() => setCreatorTypeFilter(CreatorTypeFilter.SOCIAL)}
        >
          <Typography variant="inherit" sx={{ mr: pendingSocialCount ? 1 : 0 }}>
            Social creators
          </Typography>
          {pendingSocialCount > 0 && (
            <Avatar sx={{ height: 18, width: 18, bgcolor: theme => theme.palette.error.main }}>
              <Typography variant={'caption2'}>{pendingSocialCount}</Typography>
            </Avatar>
          )}
        </Button>
        <Button
          variant={creatorTypeFilter === CreatorTypeFilter.UGC ? 'contained' : 'outlined'}
          value={CreatorTypeFilter.UGC}
          sx={{ pr: 2 }}
          onClick={() => setCreatorTypeFilter(CreatorTypeFilter.UGC)}
        >
          <Typography variant="inherit" sx={{ mr: pendingUgcCount ? 1 : 0 }}>
            UGC creators
          </Typography>
          {pendingUgcCount > 0 && (
            <Avatar sx={{ height: 18, width: 18, bgcolor: theme => theme.palette.error.main }}>
              <Typography variant={'caption2'}>{pendingUgcCount}</Typography>
            </Avatar>
          )}
        </Button>
      </ButtonGroup>
    </Stack>
  )
}
