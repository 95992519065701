import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'
import { SAMPLE_CAMPAIGN_PERFORMANCE_HEADPHONES } from './headphones'
import { SAMPLE_CAMPAIGN_PERFORMANCE_LAUNDRY } from './laundry'
import { SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER } from '@momentum/api/interceptors/getCampaign/sample-data/bluetooth-speaker'
import { SAMPLE_CAMPAIGN_HEADPHONES } from '@momentum/api/interceptors/getCampaign/sample-data/headphones'
import { SAMPLE_CAMPAIGN_LAUNDRY } from '@momentum/api/interceptors/getCampaign/sample-data/laundry'

export const SAMPLE_CAMPAIGN_PERFORMANCE: Record<string, ModelTypes['CampaignPerformance']> = {
  [SAMPLE_CAMPAIGN_BLUETOOTH_SPEAKER.id]: SAMPLE_CAMPAIGN_PERFORMANCE_HEADPHONES({
    campaignId: 'sample-campaign-headphones',
    promotedProductId: 'sample-sku-headphone',
    skuId: 'sample-sku-headphone'
  }),
  [SAMPLE_CAMPAIGN_HEADPHONES.id]: SAMPLE_CAMPAIGN_PERFORMANCE_HEADPHONES({
    campaignId: 'sample-campaign-headphones',
    promotedProductId: 'sample-sku-headphones',
    skuId: 'sample-sku-headphones'
  }),
  [SAMPLE_CAMPAIGN_LAUNDRY.id]: SAMPLE_CAMPAIGN_PERFORMANCE_LAUNDRY
}
