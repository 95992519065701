import Metric from '@momentum/components/metric'
import Row from '@momentum/components/row'
import { Paper, Stack, Typography } from '@mui/material'
import { useFinancialSummaryContext } from '../../context/FinancialSummaryContext'
import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { getOrBlank } from '@productwindtom/shared-node'

const FinancialCommitments = () => {
  const { creatorsByType } = useFinancialSummaryContext()

  return (
    <Paper sx={{ px: 4, py: 2 }}>
      <Stack spacing={3}>
        <Typography variant="h4">Client commitments</Typography>
        <Row justifyContent={'space-around'}>
          <Metric label="UGC creators" value={getOrBlank(creatorsByType[PricingCreatorType.UGC]?.numCreators)} />
          <Metric label="premium UGC" value={getOrBlank(creatorsByType[PricingCreatorType.PREMIUM_UGC]?.numCreators)} />
          <Metric label="social" value={getOrBlank(creatorsByType[PricingCreatorType.SOCIAL]?.numCreators)} />
          <Metric
            label="brand advocates"
            value={getOrBlank(creatorsByType[PricingCreatorType.ADVOCATE]?.numCreators)}
          />
        </Row>
      </Stack>
    </Paper>
  )
}

export default FinancialCommitments
