import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { OrganicRankSummary } from '@momentum/routes/campaign/e-commerce/seoV2/organic/OrganicRankSummary'
import { Paper, Stack } from '@mui/material'
import { useEffect } from 'react'
import { CollectingSEOResults } from '../common/collecting-rank-results'
import { KeywordTable } from '../common/keyword-table'
import { SeoTable } from '../common/seo-table'
import { SeoReportMode, useSeoContext } from '../context'
import { SeoOrganicImpressionsGraph } from './search-impressions-graph'

export const OrganicSeo = () => {
  const { isAdminView } = useUserSessionContext()
  const { isLoading, productSeoSummary, seoReportMode, setSeoReportMode } = useSeoContext()

  useEffect(() => {
    setSeoReportMode(SeoReportMode.ORGANIC)
    return () => setSeoReportMode(undefined)
  }, [])

  if (isLoading || !productSeoSummary || seoReportMode !== SeoReportMode.ORGANIC) {
    return <Loading />
  }

  if (!isAdminView && !productSeoSummary?.productSeoSummaryRecords?.length) {
    return <CollectingSEOResults />
  }

  return (
    <Stack spacing={5}>
      <Paper sx={{ px: 4, py: 2 }}>
        <OrganicRankSummary />
      </Paper>
      <SeoOrganicImpressionsGraph />
      <SeoTable />
      <Stack>
        <KeywordTable />
      </Stack>
    </Stack>
  )
}
