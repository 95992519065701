import { yupResolver } from '@hookform/resolvers/yup'
import { ImageSelect } from '@momentum/components/image-select'
import { ManualProduct, ProductUrl } from '@momentum/routes/brand/types'
import { getRetailerOptions } from '@momentum/utils/selectOptions'
import { Close } from '@mui/icons-material'
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { Retailer } from '@productwindtom/shared-momentum'
import { UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { getCurrencySymbol } from '@productwindtom/shared-ws-currency'
import { Form, NumberInput, SelectInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { useFormContext } from 'react-hook-form'
import * as uuid from 'uuid'
import * as yup from 'yup'
import { useBrandContext } from '../../context/BrandContext'

const schema = yup.object().shape({
  productUrl: yup.string().url('Not a valid URL').optional(),
  productId: yup.string().optional(),
  productTitle: yup
    .string()
    .optional()
    .when('productUrl', {
      is: (productUrl: string) => !productUrl,
      then: (schema: yup.Schema) => schema.required('Product title is required when product URL is not provided'),
      otherwise: (schema: yup.Schema) => schema.optional()
    }),
  productImage: yup.string().optional(),
  retailer: yup
    .string()
    .oneOf(Object.values(Retailer))
    .optional()
    .when('productUrl', {
      is: (productUrl: string) => !productUrl,
      then: (schema: yup.Schema) => schema.required('Retailer is required when product URL is not provided'),
      otherwise: (schema: yup.Schema) => schema.optional()
    }),
  listingLink: yup.string().url('Not a valid URL').optional(),
  price: yup
    .number()
    .optional()
    .when('productUrl', {
      is: (productUrl: string) => !productUrl,
      then: (schema: yup.Schema) => schema.required('Price is required when product URL is not provided'),
      otherwise: (schema: yup.Schema) => schema.optional()
    }),
    estimatedMonthlyUnitsSold: yup.number().optional()
})

const AddProductDialog = () => {
  const { isAddProductActiveOrCallback, setIsAddProductActiveOrCallback, handleAddProduct } = useBrandContext()

  const handleClose = () => setIsAddProductActiveOrCallback(false)

  return (
    <Dialog open={!!isAddProductActiveOrCallback} onClose={handleClose}>
      <Form
        onSubmit={(data: ProductUrl | ManualProduct) => handleAddProduct(data)}
        resolver={yupResolver(schema) as any}
        defaultValues={{ productId: uuid.v4() }}
      >
        <AddProductFormBody handleClose={handleClose} />
      </Form>
    </Dialog>
  )
}

const AddProductFormBody = ({ handleClose }: { handleClose: () => void }) => {
  const { brand } = useBrandContext()
  const availableRetailers = getRetailerOptions(brand.region)

  const { watch } = useFormContext()
  const productId = watch('productId')

  return (
    <Stack spacing={3} p={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h4'}>Add new product</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Typography variant={'label2'}>Import a product via its URL or enter the info manually below.</Typography>

      <TextInput primaryText={'Enter Product URL'} name={'productUrl'} />
      <Typography variant={'label2'}>OR</Typography>
      <TextInput primaryText={'Product Title'} name={'productTitle'} />
      <ImageSelect
        name={'productImage'}
        productId={productId}
        primaryText={'Product image'}
        buttonText={'Click to upload'}
        replacementText={'Click to replace'}
        buttonProps={{
          variant: 'outlined'
        }}
        uploadType={UploadRequestType.UPLOAD_PRODUCT_IMAGE}
      />
      <SelectInput primaryText={'Retailer'} name={'retailer'} options={availableRetailers} />
      <TextInput primaryText={'Product URL (optional)'} name={'listingLink'} />
      <Stack direction={'row'} justifyContent={'space-between'} spacing={3}>
        <NumberInput
          primaryText={'Product Price (ASP)'}
          name={'price'}
          prefix={getCurrencySymbol(brand.region)}
          decimalScale={2}
          fullWidth
          returnAsNumber
          returnAsCents
        />
        <NumberInput primaryText={'Est. Monthly Sales'} name={'estimatedMonthlyUnitsSold'} fullWidth />
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
        <Button variant={'outlined'} onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton variant={'contained'}>Add Product</SubmitButton>
      </Stack>
    </Stack>
  )
}

export default AddProductDialog
