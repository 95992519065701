import React, { useEffect } from 'react'
import { adjustSubscriptionCredits, getCredits, SubscriptionCreditAction } from '@momentum/routes/queries'
import { DateTime } from 'luxon'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { sum } from 'lodash'
import { CreditAction } from '@productwindtom/shared-momentum-zeus-types'

export type CompanySubscriptionContext = {
  startsAt?: DateTime
  endsAt?: DateTime
  getSubscriptionStatus: () => { isActive: boolean; hasStarted: boolean }
  subscriptionCreditActions: SubscriptionCreditAction[]
  creditsRemaining: number
  addCredits: (numCredits: number) => Promise<SubscriptionCreditAction>
  spendCredits: (numCredits: number) => Promise<SubscriptionCreditAction>
  refreshCredits: () => Promise<void>
}
const SubscriptionContext = React.createContext<CompanySubscriptionContext>({
  getSubscriptionStatus: () => ({ isActive: false, hasStarted: false }),
  subscriptionCreditActions: [],
  creditsRemaining: 0,
  addCredits: () => Promise.resolve({} as any),
  spendCredits: () => Promise.resolve({} as any),
  refreshCredits: () => Promise.resolve()
})

export const SubscriptionProvider = ({ children }: { children?: React.ReactNode }) => {
  const [subscriptionCreditActions, setSubscriptionCreditActions] = React.useState<SubscriptionCreditAction[]>([])

  const { selectedBrand } = useUserSessionContext()

  useEffect(() => {
    refreshCredits()
  }, [selectedBrand?.id])

  const refreshCredits = async () => {
    if (selectedBrand) {
      setSubscriptionCreditActions(await getCredits(selectedBrand.id))
    }
  }

  const getSubscriptionStatus = ({
    subscriptionStartsAt,
    subscriptionEndsAt
  }: {
    subscriptionStartsAt?: string
    subscriptionEndsAt?: string
  }) => {
    const now = DateTime.now()
    const startDateTimeObj = subscriptionStartsAt ? DateTime.fromISO(subscriptionStartsAt) : undefined
    const endDateTimeObj = subscriptionEndsAt ? DateTime.fromISO(subscriptionEndsAt) : undefined
    const isActive = !!startDateTimeObj && now > startDateTimeObj && (!endDateTimeObj || now < endDateTimeObj)
    const hasStarted = !!startDateTimeObj && now > startDateTimeObj
    return {
      isActive,
      hasStarted
    }
  }

  const handleAdjustCredits = async (action: CreditAction, numCredits: number) => {
    if (selectedBrand) {
      const newAction = await adjustSubscriptionCredits({
        id: selectedBrand.id,
        numCredits,
        action
      })

      setSubscriptionCreditActions(prevActions => [...prevActions, newAction])
      return newAction
    }
    throw new Error('No selected company to adjust credits for')
  }

  return (
    <SubscriptionContext.Provider
      value={{
        startsAt: selectedBrand?.subscriptionStartsAt
          ? DateTime.fromISO(selectedBrand.subscriptionStartsAt)
          : undefined,
        endsAt: selectedBrand?.subscriptionEndsAt ? DateTime.fromISO(selectedBrand.subscriptionEndsAt) : undefined,
        subscriptionCreditActions: subscriptionCreditActions,
        getSubscriptionStatus: () => getSubscriptionStatus(selectedBrand || {}),
        creditsRemaining: sum(
          subscriptionCreditActions.map(
            s => s.numCredits * (s.action === CreditAction.REMOVE ? -1 : s.action === CreditAction.ADD ? 1 : 0)
          )
        ),
        addCredits: numCredits => handleAdjustCredits(CreditAction.ADD, numCredits),
        spendCredits: numCredits => handleAdjustCredits(CreditAction.REMOVE, numCredits),
        refreshCredits
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

SubscriptionContext.displayName = 'SubscriptionContext'

export default SubscriptionContext

export const useSubscriptionContext = () => React.useContext(SubscriptionContext)
