import {
  useTheme,
  Stack,
  Typography,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  styled,
  TableCell
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { STORE_DETAILS } from '@momentum/utils/storeUtils'
import { CREATOR_TYPE_PRICING_CONFIG, SUPPORTED_CREATOR_TYPES, DEFAULT_PRICING } from '@momentum/utils/brandPricing'
import { NumberInput, SubmitButton } from '@productwindtom/ui-base'
import { PricingCreatorType, Store } from '@productwindtom/shared-momentum-zeus-types'
import * as yup from 'yup'
import { keyBy } from 'lodash'

type StorePricing = {
  store: Store
  creatorPricing: {
    price?: number | null
    type: PricingCreatorType
  }[]
}

export type PricingFormValues = {
  pricing: StorePricing[]
}

export const schema = yup.object({
  pricing: yup
    .array()
    .required()
    .of(
      yup.object({
        store: yup.string().oneOf(Object.values(Store)).required(),
        creatorPricing: yup
          .array()
          .required()
          .of(
            yup
              .object({
                price: yup.number().required().notRequired(),
                type: yup.string().oneOf(Object.values(PricingCreatorType)).required()
              })
              .required()
          )
      })
    )
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  padding: '8px 0'
}))

export const PricingForm = ({
  initialValues,
  defaultValues,
  title,
  submitText
}: {
  initialValues: PricingFormValues
  defaultValues?: StorePricing[]
  title: string
  submitText?: string
}) => {
  const theme = useTheme()
  const { reset } = useFormContext()

  const defaultPricingKeyedStore = keyBy(defaultValues, 'store')

  return (
    <Stack spacing={2}>
      <Stack direction={'row'}>
        <Typography variant={'h4'} mr={1}>
          {title}
        </Typography>
        <Chip label={'Admin'} size="small" color="secondary" />
      </Stack>

      <Stack border={1} borderRadius={'4px'} borderColor={theme.palette.grey.A200} p={3} spacing={1}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="label2">Creator type</Typography>
                </StyledTableCell>

                {initialValues.pricing.map(bp => (
                  <StyledTableCell key={bp.store}>
                    <Typography variant="label2">{STORE_DETAILS[bp.store].name}</Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 0 }}>
              {SUPPORTED_CREATOR_TYPES.map(type => (
                <TableRow key={type}>
                  <StyledTableCell>
                    <Typography variant="label4">
                      {CREATOR_TYPE_PRICING_CONFIG[type as PricingCreatorType].title}
                    </Typography>
                  </StyledTableCell>
                  {initialValues.pricing.map((bp, storeIndex) => (
                    <StyledTableCell key={bp.store}>
                      <NumberInput
                        width={'35px'}
                        name={`pricing.${storeIndex}.creatorPricing.${bp.creatorPricing.findIndex(cp => cp.type === type)}.price`}
                        placeholder={
                          defaultPricingKeyedStore?.[bp.store]?.creatorPricing
                            ?.find(cp => cp.type === type)
                            ?.price?.toLocaleString() || DEFAULT_PRICING[type]?.default?.toLocaleString()
                        }
                        decimalScale={2}
                        returnAsNumber
                        hideZero
                        allowNegative={false}
                      />
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <SubmitButton
            variant={'contained'}
            sx={{
              alignSelf: 'start',
              marginTop: 3
            }}
          >
            {submitText || 'Save pricing'}
          </SubmitButton>

          <Button variant={'text'} onClick={() => reset()}>
            Reset to default
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
