import { PricingForm, PricingFormValues, schema } from '@momentum/components/pricing-form'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { Form } from '@productwindtom/ui-base'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Typography } from '@mui/material'
import { Company } from '@momentum/routes/queries'
import { yupResolver } from '@hookform/resolvers/yup'
import { updatePricing, createPricing, deletePricing } from '@momentum/routes/company/queries'
import Loading from '@momentum/components/loading'
import { SUPPORTED_CREATOR_TYPES } from '@momentum/utils/brandPricing'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

const SUPPORTED_STORES = [Store.amazon, Store.walmart, Store.target, Store.other]

const REGION_STORES: Record<string, Store[]> = {
  [Store.amazon]: [Store.amazon, Store.amazon_ca, Store.amazon_uk, Store.amazon_de],
  [Store.walmart]: [Store.walmart],
  [Store.target]: [Store.target],
  [Store.other]: [Store.other]
}

export const CompanyPricing = ({ company }: { company: Company }) => {
  const { refreshCompany } = useUserSessionContext()
  const companyPricing = company.pricing

  const defaultValues = useMemo(
    () => ({
      pricing: SUPPORTED_STORES.map(store => ({
        store,
        creatorPricing: SUPPORTED_CREATOR_TYPES.map(type => ({
          price: (companyPricing || []).find(bp => bp.store === store)?.creatorPricing.find(cp => cp.type === type)
            ?.price,
          type
        }))
      }))
    }),
    [companyPricing]
  )

  const handleSubmit = async (submitValues: PricingFormValues) => {
    try {
      await Promise.all(
        submitValues.pricing
          .map(bp =>
            (REGION_STORES[bp.store] || []).map(store => {
              const input = {
                id: company.id,
                store: store,
                creatorPricing: bp.creatorPricing
                  .filter(cp => cp.price != null)
                  .map(cp => ({
                    type: cp.type,
                    price: cp.price!
                  }))
              }

              const anyNotBlank = !!bp.creatorPricing.find(cp => cp.price)

              if ((companyPricing || []).find(p => p.store === store)) {
                if (anyNotBlank) {
                  return updatePricing(input)
                } else {
                  return deletePricing({ id: company.id, store })
                }
              } else if (anyNotBlank) {
                return createPricing(input)
              }
            })
          )
          .flat()
      )
      await refreshCompany(company.id)
      toast(<Typography variant={'subtitle2'}>Company pricing saved!</Typography>, { type: 'success' })
    } catch (error) {
      console.error(error)
      toast(<Typography variant={'subtitle2'}>Failed to save company pricing!</Typography>, { type: 'error' })
    }
  }

  if (!companyPricing) {
    return <Loading />
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <PricingForm initialValues={defaultValues} title={'Company pricing'} submitText={'Save company pricing'} />
    </Form>
  )
}
