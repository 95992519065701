import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const TableLink = ({ to, label, className }: { to: string; label: string; className?: string }) => {
  return (
    <Link
      component={RouterLink}
      target="_blank"
      to={to}
      variant={'label4'}
      underline="hover"
      color="primary"
      className={className}
      alignSelf={'center'}
    >
      {label}
    </Link>
  )
}

export default TableLink
