// @ts-nocheck
import { ModelTypes } from '@productwindtom/shared-momentum-zeus-types'

export const SAMPLE_CAMPAIGN_PERFORMANCE_LAUNDRY: ModelTypes['CampaignPerformance'] = {
  campaignId: 'sample-campaign-laundry',
  skuId: 'sample-sku-laundry',
  promotedProductId: 'sample-sku-laundry',
  isVisible: false,
  comparisonMetric: 'REVIEWS',
  comparisonMetricValue: 200,
  summaryTitle: 'SIMILAR_PRODUCT',
  products: [
    {
      id: '95fd65f5-608f-4721-b442-32bc2ab649fc',
      productName: 'Laundry Detergent Pods',
      productUrl: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
      productImage: 'campaigns/sample-campaign-laundry/images/Screen Shot 2024-04-22 at 1.29.32 PM_1713817786289.png',
      productPerformanceRecords: [
        {
          date: '2023-08-01',
          reviewCount: 15,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-02',
          reviewCount: 15,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-03',
          reviewCount: 15,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-04',
          reviewCount: 15,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-05',
          reviewCount: 15,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-06',
          reviewCount: 15,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-07',
          reviewCount: 25,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-08',
          reviewCount: 25,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-09',
          reviewCount: 25,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-10',
          reviewCount: 25,
          rating: 3,
          salesRank: 48869
        },
        {
          date: '2023-08-11',
          reviewCount: 25,
          rating: 3,
          salesRank: 39764
        },
        {
          date: '2023-08-12',
          reviewCount: 37,
          rating: 3,
          salesRank: 41418
        },
        {
          date: '2023-08-13',
          reviewCount: 37,
          rating: 3,
          salesRank: 32680
        },
        {
          date: '2023-08-14',
          reviewCount: 37,
          rating: 3,
          salesRank: 28314
        },
        {
          date: '2023-08-15',
          reviewCount: 37,
          rating: 3,
          salesRank: 26721
        },
        {
          date: '2023-08-16',
          reviewCount: 37,
          rating: 3,
          salesRank: 23530
        },
        {
          date: '2023-08-17',
          reviewCount: 37,
          rating: 3,
          salesRank: 25686
        },
        {
          date: '2023-08-18',
          reviewCount: 51,
          rating: 3,
          salesRank: 26042
        },
        {
          date: '2023-08-19',
          reviewCount: 51,
          rating: 3,
          salesRank: 27945
        },
        {
          date: '2023-08-20',
          reviewCount: 51,
          rating: 7,
          salesRank: 30300
        },
        {
          date: '2023-08-21',
          reviewCount: 51,
          rating: 7,
          salesRank: 28563
        },
        {
          date: '2023-08-22',
          reviewCount: 58,
          rating: 7,
          salesRank: 30520
        },
        {
          date: '2023-08-23',
          reviewCount: 58,
          rating: 7,
          salesRank: 27944
        },
        {
          date: '2023-08-24',
          reviewCount: 58,
          rating: 7,
          salesRank: 30556
        },
        {
          date: '2023-08-25',
          reviewCount: 58,
          rating: 7,
          salesRank: 30699
        },
        {
          date: '2023-08-26',
          reviewCount: 67,
          rating: 7,
          salesRank: 32146
        },
        {
          date: '2023-08-27',
          reviewCount: 67,
          rating: 7,
          salesRank: 27678
        },
        {
          date: '2023-08-28',
          reviewCount: 67,
          rating: 7,
          salesRank: 22481
        },
        {
          date: '2023-08-29',
          reviewCount: 67,
          rating: 7,
          salesRank: 25256
        },
        {
          date: '2023-08-30',
          reviewCount: 85,
          rating: 11,
          salesRank: 30281
        },
        {
          date: '2023-08-31',
          reviewCount: 85,
          rating: 11,
          salesRank: 36825
        },
        {
          date: '2023-09-01',
          reviewCount: 85,
          rating: 11,
          salesRank: 34393
        },
        {
          date: '2023-09-02',
          reviewCount: 99,
          rating: 11,
          salesRank: 40611
        },
        {
          date: '2023-09-03',
          reviewCount: 99,
          rating: 11,
          salesRank: 45909
        },
        {
          date: '2023-09-04',
          reviewCount: 99,
          rating: 11,
          salesRank: 58602
        },
        {
          date: '2023-09-05',
          reviewCount: 99,
          rating: 11,
          salesRank: 70860
        },
        {
          date: '2023-09-06',
          reviewCount: 118,
          rating: 11,
          salesRank: 71236
        },
        {
          date: '2023-09-07',
          reviewCount: 118,
          rating: 11,
          salesRank: 58618
        },
        {
          date: '2023-09-08',
          reviewCount: 118,
          rating: 11,
          salesRank: 42381
        },
        {
          date: '2023-09-09',
          reviewCount: 118,
          rating: 11,
          salesRank: 50022
        },
        {
          date: '2023-09-10',
          reviewCount: 133,
          rating: 22,
          salesRank: 54344
        },
        {
          date: '2023-09-11',
          reviewCount: 133,
          rating: 22,
          salesRank: 58753
        },
        {
          date: '2023-09-12',
          reviewCount: 133,
          rating: 22,
          salesRank: 65654
        },
        {
          date: '2023-09-13',
          reviewCount: 133,
          rating: 22,
          salesRank: 68817
        },
        {
          date: '2023-09-14',
          reviewCount: 159,
          rating: 22,
          salesRank: 56212
        },
        {
          date: '2023-09-15',
          reviewCount: 159,
          rating: 22,
          salesRank: 57103
        },
        {
          date: '2023-09-16',
          reviewCount: 159,
          rating: 22,
          salesRank: 43813
        },
        {
          date: '2023-09-17',
          reviewCount: 159,
          rating: 22,
          salesRank: 31880
        },
        {
          date: '2023-09-18',
          reviewCount: 174,
          rating: 26,
          salesRank: 38338
        },
        {
          date: '2023-09-19',
          reviewCount: 174,
          rating: 26,
          salesRank: 34700
        },
        {
          date: '2023-09-20',
          reviewCount: 174,
          rating: 26,
          salesRank: 32504
        },
        {
          date: '2023-09-21',
          reviewCount: 185,
          rating: 26,
          salesRank: 19365
        },
        {
          date: '2023-09-22',
          reviewCount: 185,
          rating: 26,
          salesRank: 20625
        },
        {
          date: '2023-09-23',
          reviewCount: 185,
          rating: 32,
          salesRank: 24401
        },
        {
          date: '2023-09-24',
          reviewCount: 185,
          rating: 32,
          salesRank: 28384
        },
        {
          date: '2023-09-25',
          reviewCount: 190,
          rating: 32,
          salesRank: 31098
        },
        {
          date: '2023-09-26',
          reviewCount: 190,
          rating: 32,
          salesRank: 31658
        },
        {
          date: '2023-09-27',
          reviewCount: 190,
          rating: 32,
          salesRank: 40262
        },
        {
          date: '2023-09-28',
          reviewCount: 190,
          rating: 32,
          salesRank: 37732
        },
        {
          date: '2023-09-29',
          reviewCount: 202,
          rating: 39,
          salesRank: 43533
        },
        {
          date: '2023-09-30',
          reviewCount: 202,
          rating: 39,
          salesRank: 43200
        },
        {
          date: '2023-10-01',
          reviewCount: 202,
          rating: 39,
          salesRank: 52693
        },
        {
          date: '2023-10-02',
          reviewCount: 202,
          rating: 39,
          salesRank: 51252
        },
        {
          date: '2023-10-03',
          reviewCount: 215,
          rating: 39,
          salesRank: 46001
        },
        {
          date: '2023-10-04',
          reviewCount: 215,
          rating: 45,
          salesRank: 48025
        },
        {
          date: '2023-10-05',
          reviewCount: 215,
          rating: 45,
          salesRank: 40060
        },
        {
          date: '2023-10-06',
          reviewCount: 215,
          rating: 45,
          salesRank: 41277
        },
        {
          date: '2023-10-07',
          reviewCount: 228,
          rating: 45,
          salesRank: 43875
        },
        {
          date: '2023-10-08',
          reviewCount: 228,
          rating: 45,
          salesRank: 45181
        },
        {
          date: '2023-10-09',
          reviewCount: 228,
          rating: 49,
          salesRank: 38539
        },
        {
          date: '2023-10-10',
          reviewCount: 252,
          rating: 49,
          salesRank: 33838
        },
        {
          date: '2023-10-11',
          reviewCount: 252,
          rating: 49,
          salesRank: 38283
        },
        {
          date: '2023-10-12',
          reviewCount: 252,
          rating: 49,
          salesRank: 44448
        },
        {
          date: '2023-10-13',
          reviewCount: 252,
          rating: 49,
          salesRank: 42075
        },
        {
          date: '2023-10-14',
          reviewCount: 304,
          rating: 49,
          salesRank: 37798
        },
        {
          date: '2023-10-15',
          reviewCount: 304,
          rating: 64,
          salesRank: 37943
        },
        {
          date: '2023-10-16',
          reviewCount: 304,
          rating: 64,
          salesRank: 35039
        },
        {
          date: '2023-10-17',
          reviewCount: 304,
          rating: 64,
          salesRank: 29900
        },
        {
          date: '2023-10-18',
          reviewCount: 359,
          rating: 64,
          salesRank: 35056
        },
        {
          date: '2023-10-19',
          reviewCount: 359,
          rating: 64,
          salesRank: 31792
        },
        {
          date: '2023-10-20',
          reviewCount: 359,
          rating: 64,
          salesRank: 23758
        },
        {
          date: '2023-10-21',
          reviewCount: 409,
          rating: 73,
          salesRank: 27008
        },
        {
          date: '2023-10-22',
          reviewCount: 409,
          rating: 73,
          salesRank: 25874
        },
        {
          date: '2023-10-23',
          reviewCount: 409,
          rating: 73,
          salesRank: 29689
        },
        {
          date: '2023-10-24',
          reviewCount: 409,
          rating: 73,
          salesRank: 32691
        },
        {
          date: '2023-10-25',
          reviewCount: 477,
          rating: 73,
          salesRank: 27798
        },
        {
          date: '2023-10-26',
          reviewCount: 477,
          rating: 73,
          salesRank: 30666
        },
        {
          date: '2023-10-27',
          reviewCount: 477,
          rating: 83,
          salesRank: 30075
        },
        {
          date: '2023-10-28',
          reviewCount: 527,
          rating: 83,
          salesRank: 28858
        },
        {
          date: '2023-10-29',
          reviewCount: 527,
          rating: 83,
          salesRank: 25196
        },
        {
          date: '2023-10-30',
          reviewCount: 527,
          rating: 83,
          salesRank: 28509
        },
        {
          date: '2023-10-31',
          reviewCount: 565,
          rating: 83,
          salesRank: 29605
        },
        {
          date: '2023-11-01',
          reviewCount: 565,
          rating: 83,
          salesRank: 28786
        },
        {
          date: '2023-11-02',
          reviewCount: 565,
          rating: 97,
          salesRank: 32226
        },
        {
          date: '2023-11-03',
          reviewCount: 601,
          rating: 97,
          salesRank: 35355
        },
        {
          date: '2023-11-04',
          reviewCount: 601,
          rating: 97,
          salesRank: 33582
        },
        {
          date: '2023-11-05',
          reviewCount: 601,
          rating: 97,
          salesRank: 35604
        },
        {
          date: '2023-11-06',
          reviewCount: 601,
          rating: 97,
          salesRank: 37672
        },
        {
          date: '2023-11-07',
          reviewCount: 671,
          rating: 97,
          salesRank: 31994
        },
        {
          date: '2023-11-08',
          reviewCount: 671,
          rating: 102,
          salesRank: 34448
        },
        {
          date: '2023-11-09',
          reviewCount: 671,
          rating: 102,
          salesRank: 33106
        },
        {
          date: '2023-11-10',
          reviewCount: 671,
          rating: 102,
          salesRank: 24493
        },
        {
          date: '2023-11-11',
          reviewCount: 736,
          rating: 102,
          salesRank: 23296
        },
        {
          date: '2023-11-12',
          reviewCount: 736,
          rating: 102,
          salesRank: 27312
        },
        {
          date: '2023-11-13',
          reviewCount: 736,
          rating: 102,
          salesRank: 29392
        },
        {
          date: '2023-11-14',
          reviewCount: 787,
          rating: 106,
          salesRank: 17398
        },
        {
          date: '2023-11-15',
          reviewCount: 787,
          rating: 106,
          salesRank: 19229
        },
        {
          date: '2023-11-16',
          reviewCount: 787,
          rating: 106,
          salesRank: 19264
        },
        {
          date: '2023-11-17',
          reviewCount: 787,
          rating: 106,
          salesRank: 19831
        },
        {
          date: '2023-11-18',
          reviewCount: 867,
          rating: 106,
          salesRank: 20404
        },
        {
          date: '2023-11-19',
          reviewCount: 867,
          rating: 106,
          salesRank: 14750
        },
        {
          date: '2023-11-20',
          reviewCount: 867,
          rating: 107,
          salesRank: 13433
        },
        {
          date: '2023-11-21',
          reviewCount: 930,
          rating: 107,
          salesRank: 13825
        },
        {
          date: '2023-11-22',
          reviewCount: 930,
          rating: 107,
          salesRank: 13589
        },
        {
          date: '2023-11-23',
          reviewCount: 930,
          rating: 107,
          salesRank: 13180
        },
        {
          date: '2023-11-24',
          reviewCount: 930,
          rating: 107,
          salesRank: 12766
        },
        {
          date: '2023-11-25',
          reviewCount: 982,
          rating: 107,
          salesRank: 13645
        },
        {
          date: '2023-11-26',
          reviewCount: 982,
          rating: 125,
          salesRank: 13031
        },
        {
          date: '2023-11-27',
          reviewCount: 982,
          rating: 125,
          salesRank: 13500
        },
        {
          date: '2023-11-28',
          reviewCount: 1037,
          rating: 125,
          salesRank: 13555
        },
        {
          date: '2023-11-29',
          reviewCount: 1037,
          rating: 125,
          salesRank: 13587
        },
        {
          date: '2023-11-30',
          reviewCount: 1037,
          rating: 125,
          salesRank: 13850
        },
        {
          date: '2023-12-01',
          reviewCount: 1037,
          rating: 125,
          salesRank: 13592
        },
        {
          date: '2023-12-02',
          reviewCount: 1108,
          rating: 139,
          salesRank: 13940
        },
        {
          date: '2023-12-03',
          reviewCount: 1108,
          rating: 139,
          salesRank: 14413
        },
        {
          date: '2023-12-04',
          reviewCount: 1108,
          rating: 139,
          salesRank: 13555
        },
        {
          date: '2023-12-05',
          reviewCount: 1108,
          rating: 139,
          salesRank: 12576
        },
        {
          date: '2023-12-06',
          reviewCount: 1193,
          rating: 139,
          salesRank: 10966
        },
        {
          date: '2023-12-07',
          reviewCount: 1193,
          rating: 139,
          salesRank: 10289
        },
        {
          date: '2023-12-08',
          reviewCount: 1193,
          rating: 164,
          salesRank: 8820
        },
        {
          date: '2023-12-09',
          reviewCount: 1254,
          rating: 164,
          salesRank: 9233
        },
        {
          date: '2023-12-10',
          reviewCount: 1254,
          rating: 164,
          salesRank: 9434
        },
        {
          date: '2023-12-11',
          reviewCount: 1254,
          rating: 164,
          salesRank: 9388
        },
        {
          date: '2023-12-12',
          reviewCount: 1315,
          rating: 164,
          salesRank: 9152
        },
        {
          date: '2023-12-13',
          reviewCount: 1315,
          rating: 176,
          salesRank: 9210
        },
        {
          date: '2023-12-14',
          reviewCount: 1315,
          rating: 176,
          salesRank: 9329
        },
        {
          date: '2023-12-15',
          reviewCount: 1315,
          rating: 176,
          salesRank: 9620
        },
        {
          date: '2023-12-16',
          reviewCount: 1409,
          rating: 176,
          salesRank: 9534
        },
        {
          date: '2023-12-17',
          reviewCount: 1409,
          rating: 176,
          salesRank: 9345
        },
        {
          date: '2023-12-18',
          reviewCount: 1409,
          rating: 176,
          salesRank: 8990
        },
        {
          date: '2023-12-19',
          reviewCount: 1409,
          rating: 197,
          salesRank: 9082
        },
        {
          date: '2023-12-20',
          reviewCount: 1501,
          rating: 197,
          salesRank: 7628
        },
        {
          date: '2023-12-21',
          reviewCount: 1501,
          rating: 197,
          salesRank: 7306
        },
        {
          date: '2023-12-22',
          reviewCount: 1501,
          rating: 197,
          salesRank: 7390
        },
        {
          date: '2023-12-23',
          reviewCount: 1501,
          rating: 197,
          salesRank: 6680
        },
        {
          date: '2023-12-24',
          reviewCount: 1567,
          rating: 197,
          salesRank: 6006
        },
        {
          date: '2023-12-25',
          reviewCount: 1567,
          rating: 216,
          salesRank: 6520
        },
        {
          date: '2023-12-26',
          reviewCount: 1567,
          rating: 216,
          salesRank: 7076
        },
        {
          date: '2023-12-27',
          reviewCount: 1567,
          rating: 216,
          salesRank: 7566
        },
        {
          date: '2023-12-28',
          reviewCount: 1676,
          rating: 216,
          salesRank: 7177
        },
        {
          date: '2023-12-29',
          reviewCount: 1676,
          rating: 216,
          salesRank: 6875
        },
        {
          date: '2023-12-30',
          reviewCount: 1676,
          rating: 216,
          salesRank: 7050
        },
        {
          date: '2023-12-31',
          reviewCount: 1734,
          rating: 231,
          salesRank: 6258
        },
        {
          date: '2024-01-01',
          reviewCount: 1734,
          rating: 231,
          salesRank: 6733
        },
        {
          date: '2024-01-02',
          reviewCount: 1734,
          rating: 231,
          salesRank: 7079
        },
        {
          date: '2024-01-03',
          reviewCount: 1734,
          rating: 231,
          salesRank: 6094
        },
        {
          date: '2024-01-04',
          reviewCount: 1823,
          rating: 231,
          salesRank: 6360
        },
        {
          date: '2024-01-05',
          reviewCount: 1823,
          rating: 231,
          salesRank: 6672
        },
        {
          date: '2024-01-06',
          reviewCount: 1823,
          rating: 248,
          salesRank: 7388
        },
        {
          date: '2024-01-07',
          reviewCount: 1891,
          rating: 248,
          salesRank: 6800
        },
        {
          date: '2024-01-08',
          reviewCount: 1891,
          rating: 248,
          salesRank: 6426
        },
        {
          date: '2024-01-09',
          reviewCount: 1891,
          rating: 248,
          salesRank: 6754
        },
        {
          date: '2024-01-10',
          reviewCount: 1891,
          rating: 248,
          salesRank: 7133
        },
        {
          date: '2024-01-11',
          reviewCount: 2435,
          rating: 248,
          salesRank: 7965
        },
        {
          date: '2024-01-12',
          reviewCount: 2435,
          rating: 32726,
          salesRank: 7717
        },
        {
          date: '2024-01-13',
          reviewCount: 2435,
          rating: 32726,
          salesRank: 8477
        },
        {
          date: '2024-01-14',
          reviewCount: 2528,
          rating: 32726,
          salesRank: 8397
        },
        {
          date: '2024-01-15',
          reviewCount: 2528,
          rating: 32726,
          salesRank: 9056
        },
        {
          date: '2024-01-16',
          reviewCount: 2528,
          rating: 32726,
          salesRank: 8967
        },
        {
          date: '2024-01-17',
          reviewCount: 2611,
          rating: 32726,
          salesRank: 9183
        },
        {
          date: '2024-01-18',
          reviewCount: 2611,
          rating: 32726,
          salesRank: 8932
        },
        {
          date: '2024-01-19',
          reviewCount: 2611,
          rating: 32726,
          salesRank: 8884
        },
        {
          date: '2024-01-20',
          reviewCount: 2611,
          rating: 32726,
          salesRank: 8638
        },
        {
          date: '2024-01-21',
          reviewCount: 2722,
          rating: 32726,
          salesRank: 9374
        },
        {
          date: '2024-01-22',
          reviewCount: 2722,
          rating: 32726,
          salesRank: 8931
        },
        {
          date: '2024-01-23',
          reviewCount: 2722,
          rating: 32726,
          salesRank: 9163
        },
        {
          date: '2024-01-24',
          reviewCount: 2722,
          rating: 32726,
          salesRank: 9397
        },
        {
          date: '2024-01-25',
          reviewCount: 2295,
          rating: 32726,
          salesRank: 9008
        },
        {
          date: '2024-01-26',
          reviewCount: 2295,
          rating: 32726,
          salesRank: 8998
        },
        {
          date: '2024-01-27',
          reviewCount: 2295,
          rating: 32726,
          salesRank: 8145
        },
        {
          date: '2024-01-28',
          reviewCount: 2372,
          rating: 32726,
          salesRank: 8120
        },
        {
          date: '2024-01-29',
          reviewCount: 2372,
          rating: 32726,
          salesRank: 8027
        },
        {
          date: '2024-01-30',
          reviewCount: 2372,
          rating: 32726,
          salesRank: 8954
        },
        {
          date: '2024-01-31',
          reviewCount: 2455,
          rating: 32726,
          salesRank: 9488
        },
        {
          date: '2024-02-01',
          reviewCount: 2455,
          rating: 32726,
          salesRank: 8713
        },
        {
          date: '2024-02-02',
          reviewCount: 2455,
          rating: 32726,
          salesRank: 8524
        },
        {
          date: '2024-02-03',
          reviewCount: 2455,
          rating: 32726,
          salesRank: 8393
        },
        {
          date: '2024-02-04',
          reviewCount: 2507,
          rating: 32726,
          salesRank: 9227
        },
        {
          date: '2024-02-05',
          reviewCount: 2507,
          rating: 32726,
          salesRank: 8894
        },
        {
          date: '2024-02-06',
          reviewCount: 2507,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-07',
          reviewCount: 2595,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-08',
          reviewCount: 2595,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-09',
          reviewCount: 2595,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-10',
          reviewCount: 2595,
          rating: 32726,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      createdAt: '2024-04-20T00:17:51.887Z'
    },
    {
      id: 'efa47c1c-ea01-4b3c-bca6-b71325dbf24c',
      productName: 'Powder Laundry Detergent',
      productUrl: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
      productImage: 'campaigns/sample-campaign-laundry/images/Screen Shot 2024-04-22 at 3.31.38 PM_1713825108403.png',
      productPerformanceRecords: [
        {
          date: '2023-08-01',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-02',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-03',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-04',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-05',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-06',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-07',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-08',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-09',
          reviewCount: 1,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-10',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-11',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-12',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-13',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-14',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-15',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-16',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-17',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-18',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-19',
          reviewCount: 3,
          rating: 3,
          salesRank: null
        },
        {
          date: '2023-08-20',
          reviewCount: 7,
          rating: 7,
          salesRank: null
        },
        {
          date: '2023-08-21',
          reviewCount: 7,
          rating: 7,
          salesRank: null
        },
        {
          date: '2023-08-22',
          reviewCount: 7,
          rating: 7,
          salesRank: null
        },
        {
          date: '2023-08-23',
          reviewCount: 7,
          rating: 7,
          salesRank: null
        },
        {
          date: '2023-08-24',
          reviewCount: 7,
          rating: 7,
          salesRank: null
        },
        {
          date: '2023-08-25',
          reviewCount: 7,
          rating: 7,
          salesRank: 41850
        },
        {
          date: '2023-08-26',
          reviewCount: 7,
          rating: 7,
          salesRank: 33181
        },
        {
          date: '2023-08-27',
          reviewCount: 7,
          rating: 7,
          salesRank: 31819
        },
        {
          date: '2023-08-28',
          reviewCount: 7,
          rating: 7,
          salesRank: 30886
        },
        {
          date: '2023-08-29',
          reviewCount: 7,
          rating: 7,
          salesRank: 25350
        },
        {
          date: '2023-08-30',
          reviewCount: 11,
          rating: 11,
          salesRank: 29773
        },
        {
          date: '2023-08-31',
          reviewCount: 11,
          rating: 11,
          salesRank: 31637
        },
        {
          date: '2023-09-01',
          reviewCount: 11,
          rating: 11,
          salesRank: 34118
        },
        {
          date: '2023-09-02',
          reviewCount: 11,
          rating: 11,
          salesRank: 34260
        },
        {
          date: '2023-09-03',
          reviewCount: 11,
          rating: 11,
          salesRank: 39599
        },
        {
          date: '2023-09-04',
          reviewCount: 11,
          rating: 11,
          salesRank: 41017
        },
        {
          date: '2023-09-05',
          reviewCount: 11,
          rating: 11,
          salesRank: 46454
        },
        {
          date: '2023-09-06',
          reviewCount: 11,
          rating: 11,
          salesRank: 45558
        },
        {
          date: '2023-09-07',
          reviewCount: 11,
          rating: 11,
          salesRank: 48763
        },
        {
          date: '2023-09-08',
          reviewCount: 11,
          rating: 11,
          salesRank: 33911
        },
        {
          date: '2023-09-09',
          reviewCount: 11,
          rating: 11,
          salesRank: 35045
        },
        {
          date: '2023-09-10',
          reviewCount: 22,
          rating: 22,
          salesRank: 32805
        },
        {
          date: '2023-09-11',
          reviewCount: 22,
          rating: 22,
          salesRank: 33999
        },
        {
          date: '2023-09-12',
          reviewCount: 22,
          rating: 22,
          salesRank: 32831
        },
        {
          date: '2023-09-13',
          reviewCount: 22,
          rating: 22,
          salesRank: 37508
        },
        {
          date: '2023-09-14',
          reviewCount: 22,
          rating: 22,
          salesRank: 34670
        },
        {
          date: '2023-09-15',
          reviewCount: 22,
          rating: 22,
          salesRank: 29452
        },
        {
          date: '2023-09-16',
          reviewCount: 22,
          rating: 22,
          salesRank: 32633
        },
        {
          date: '2023-09-17',
          reviewCount: 22,
          rating: 22,
          salesRank: 33572
        },
        {
          date: '2023-09-18',
          reviewCount: 26,
          rating: 26,
          salesRank: 41343
        },
        {
          date: '2023-09-19',
          reviewCount: 26,
          rating: 26,
          salesRank: 28033
        },
        {
          date: '2023-09-20',
          reviewCount: 26,
          rating: 26,
          salesRank: 26057
        },
        {
          date: '2023-09-21',
          reviewCount: 26,
          rating: 26,
          salesRank: 27684
        },
        {
          date: '2023-09-22',
          reviewCount: 26,
          rating: 26,
          salesRank: 29797
        },
        {
          date: '2023-09-23',
          reviewCount: 32,
          rating: 32,
          salesRank: 28232
        },
        {
          date: '2023-09-24',
          reviewCount: 32,
          rating: 32,
          salesRank: 25440
        },
        {
          date: '2023-09-25',
          reviewCount: 32,
          rating: 32,
          salesRank: 27045
        },
        {
          date: '2023-09-26',
          reviewCount: 32,
          rating: 32,
          salesRank: 25496
        },
        {
          date: '2023-09-27',
          reviewCount: 32,
          rating: 32,
          salesRank: 19048
        },
        {
          date: '2023-09-28',
          reviewCount: 32,
          rating: 32,
          salesRank: 23216
        },
        {
          date: '2023-09-29',
          reviewCount: 39,
          rating: 39,
          salesRank: 21089
        },
        {
          date: '2023-09-30',
          reviewCount: 39,
          rating: 39,
          salesRank: 22825
        },
        {
          date: '2023-10-01',
          reviewCount: 39,
          rating: 39,
          salesRank: 24376
        },
        {
          date: '2023-10-02',
          reviewCount: 39,
          rating: 39,
          salesRank: 23712
        },
        {
          date: '2023-10-03',
          reviewCount: 39,
          rating: 39,
          salesRank: 23509
        },
        {
          date: '2023-10-04',
          reviewCount: 45,
          rating: 45,
          salesRank: 23686
        },
        {
          date: '2023-10-05',
          reviewCount: 45,
          rating: 45,
          salesRank: 25046
        },
        {
          date: '2023-10-06',
          reviewCount: 45,
          rating: 45,
          salesRank: 25716
        },
        {
          date: '2023-10-07',
          reviewCount: 45,
          rating: 45,
          salesRank: 24274
        },
        {
          date: '2023-10-08',
          reviewCount: 45,
          rating: 45,
          salesRank: 25561
        },
        {
          date: '2023-10-09',
          reviewCount: 49,
          rating: 49,
          salesRank: 23068
        },
        {
          date: '2023-10-10',
          reviewCount: 49,
          rating: 49,
          salesRank: 22768
        },
        {
          date: '2023-10-11',
          reviewCount: 49,
          rating: 49,
          salesRank: 22606
        },
        {
          date: '2023-10-12',
          reviewCount: 49,
          rating: 49,
          salesRank: 23931
        },
        {
          date: '2023-10-13',
          reviewCount: 49,
          rating: 49,
          salesRank: 21086
        },
        {
          date: '2023-10-14',
          reviewCount: 49,
          rating: 49,
          salesRank: 22766
        },
        {
          date: '2023-10-15',
          reviewCount: 64,
          rating: 64,
          salesRank: 19898
        },
        {
          date: '2023-10-16',
          reviewCount: 64,
          rating: 64,
          salesRank: 22088
        },
        {
          date: '2023-10-17',
          reviewCount: 64,
          rating: 64,
          salesRank: 21290
        },
        {
          date: '2023-10-18',
          reviewCount: 64,
          rating: 64,
          salesRank: 20845
        },
        {
          date: '2023-10-19',
          reviewCount: 64,
          rating: 64,
          salesRank: 18705
        },
        {
          date: '2023-10-20',
          reviewCount: 64,
          rating: 64,
          salesRank: 19287
        },
        {
          date: '2023-10-21',
          reviewCount: 73,
          rating: 73,
          salesRank: 18335
        },
        {
          date: '2023-10-22',
          reviewCount: 73,
          rating: 73,
          salesRank: 19356
        },
        {
          date: '2023-10-23',
          reviewCount: 73,
          rating: 73,
          salesRank: 18261
        },
        {
          date: '2023-10-24',
          reviewCount: 73,
          rating: 73,
          salesRank: 19406
        },
        {
          date: '2023-10-25',
          reviewCount: 73,
          rating: 73,
          salesRank: 19152
        },
        {
          date: '2023-10-26',
          reviewCount: 73,
          rating: 73,
          salesRank: 22203
        },
        {
          date: '2023-10-27',
          reviewCount: 83,
          rating: 83,
          salesRank: 22323
        },
        {
          date: '2023-10-28',
          reviewCount: 83,
          rating: 83,
          salesRank: 22340
        },
        {
          date: '2023-10-29',
          reviewCount: 83,
          rating: 83,
          salesRank: 21977
        },
        {
          date: '2023-10-30',
          reviewCount: 83,
          rating: 83,
          salesRank: 19793
        },
        {
          date: '2023-10-31',
          reviewCount: 83,
          rating: 83,
          salesRank: 22458
        },
        {
          date: '2023-11-01',
          reviewCount: 83,
          rating: 83,
          salesRank: 18474
        },
        {
          date: '2023-11-02',
          reviewCount: 97,
          rating: 97,
          salesRank: 19800
        },
        {
          date: '2023-11-03',
          reviewCount: 97,
          rating: 97,
          salesRank: 23592
        },
        {
          date: '2023-11-04',
          reviewCount: 97,
          rating: 97,
          salesRank: 23447
        },
        {
          date: '2023-11-05',
          reviewCount: 97,
          rating: 97,
          salesRank: 23362
        },
        {
          date: '2023-11-06',
          reviewCount: 97,
          rating: 97,
          salesRank: 21763
        },
        {
          date: '2023-11-07',
          reviewCount: 97,
          rating: 97,
          salesRank: 22544
        },
        {
          date: '2023-11-08',
          reviewCount: 102,
          rating: 102,
          salesRank: 20697
        },
        {
          date: '2023-11-09',
          reviewCount: 102,
          rating: 102,
          salesRank: 19264
        },
        {
          date: '2023-11-10',
          reviewCount: 102,
          rating: 102,
          salesRank: 19874
        },
        {
          date: '2023-11-11',
          reviewCount: 102,
          rating: 102,
          salesRank: 20729
        },
        {
          date: '2023-11-12',
          reviewCount: 102,
          rating: 102,
          salesRank: 20607
        },
        {
          date: '2023-11-13',
          reviewCount: 102,
          rating: 102,
          salesRank: 18821
        },
        {
          date: '2023-11-14',
          reviewCount: 106,
          rating: 106,
          salesRank: 17292
        },
        {
          date: '2023-11-15',
          reviewCount: 106,
          rating: 106,
          salesRank: 17939
        },
        {
          date: '2023-11-16',
          reviewCount: 106,
          rating: 106,
          salesRank: 17588
        },
        {
          date: '2023-11-17',
          reviewCount: 106,
          rating: 106,
          salesRank: 18383
        },
        {
          date: '2023-11-18',
          reviewCount: 106,
          rating: 106,
          salesRank: 17946
        },
        {
          date: '2023-11-19',
          reviewCount: 106,
          rating: 106,
          salesRank: 19353
        },
        {
          date: '2023-11-20',
          reviewCount: 107,
          rating: 107,
          salesRank: 18391
        },
        {
          date: '2023-11-21',
          reviewCount: 107,
          rating: 107,
          salesRank: 20691
        },
        {
          date: '2023-11-22',
          reviewCount: 107,
          rating: 107,
          salesRank: 25157
        },
        {
          date: '2023-11-23',
          reviewCount: 107,
          rating: 107,
          salesRank: 21625
        },
        {
          date: '2023-11-24',
          reviewCount: 107,
          rating: 107,
          salesRank: 19546
        },
        {
          date: '2023-11-25',
          reviewCount: 107,
          rating: 107,
          salesRank: 20240
        },
        {
          date: '2023-11-26',
          reviewCount: 125,
          rating: 125,
          salesRank: 22220
        },
        {
          date: '2023-11-27',
          reviewCount: 125,
          rating: 125,
          salesRank: 21170
        },
        {
          date: '2023-11-28',
          reviewCount: 125,
          rating: 125,
          salesRank: 22360
        },
        {
          date: '2023-11-29',
          reviewCount: 125,
          rating: 125,
          salesRank: 19981
        },
        {
          date: '2023-11-30',
          reviewCount: 125,
          rating: 125,
          salesRank: 20765
        },
        {
          date: '2023-12-01',
          reviewCount: 125,
          rating: 125,
          salesRank: 22771
        },
        {
          date: '2023-12-02',
          reviewCount: 139,
          rating: 139,
          salesRank: 22048
        },
        {
          date: '2023-12-03',
          reviewCount: 139,
          rating: 139,
          salesRank: 20596
        },
        {
          date: '2023-12-04',
          reviewCount: 139,
          rating: 139,
          salesRank: 19382
        },
        {
          date: '2023-12-05',
          reviewCount: 139,
          rating: 139,
          salesRank: 20041
        },
        {
          date: '2023-12-06',
          reviewCount: 139,
          rating: 139,
          salesRank: 20229
        },
        {
          date: '2023-12-07',
          reviewCount: 139,
          rating: 139,
          salesRank: 19903
        },
        {
          date: '2023-12-08',
          reviewCount: 164,
          rating: 164,
          salesRank: 21445
        },
        {
          date: '2023-12-09',
          reviewCount: 164,
          rating: 164,
          salesRank: 23593
        },
        {
          date: '2023-12-10',
          reviewCount: 164,
          rating: 164,
          salesRank: 24859
        },
        {
          date: '2023-12-11',
          reviewCount: 164,
          rating: 164,
          salesRank: 21694
        },
        {
          date: '2023-12-12',
          reviewCount: 164,
          rating: 164,
          salesRank: 21112
        },
        {
          date: '2023-12-13',
          reviewCount: 176,
          rating: 176,
          salesRank: 21698
        },
        {
          date: '2023-12-14',
          reviewCount: 176,
          rating: 176,
          salesRank: 21593
        },
        {
          date: '2023-12-15',
          reviewCount: 176,
          rating: 176,
          salesRank: 20992
        },
        {
          date: '2023-12-16',
          reviewCount: 176,
          rating: 176,
          salesRank: 19053
        },
        {
          date: '2023-12-17',
          reviewCount: 176,
          rating: 176,
          salesRank: 18777
        },
        {
          date: '2023-12-18',
          reviewCount: 176,
          rating: 176,
          salesRank: 19260
        },
        {
          date: '2023-12-19',
          reviewCount: 197,
          rating: 197,
          salesRank: 18505
        },
        {
          date: '2023-12-20',
          reviewCount: 197,
          rating: 197,
          salesRank: 20128
        },
        {
          date: '2023-12-21',
          reviewCount: 197,
          rating: 197,
          salesRank: 21157
        },
        {
          date: '2023-12-22',
          reviewCount: 197,
          rating: 197,
          salesRank: 21037
        },
        {
          date: '2023-12-23',
          reviewCount: 197,
          rating: 197,
          salesRank: 21530
        },
        {
          date: '2023-12-24',
          reviewCount: 197,
          rating: 197,
          salesRank: 22675
        },
        {
          date: '2023-12-25',
          reviewCount: 216,
          rating: 216,
          salesRank: 20895
        },
        {
          date: '2023-12-26',
          reviewCount: 216,
          rating: 216,
          salesRank: 21145
        },
        {
          date: '2023-12-27',
          reviewCount: 216,
          rating: 216,
          salesRank: 22502
        },
        {
          date: '2023-12-28',
          reviewCount: 216,
          rating: 216,
          salesRank: 24932
        },
        {
          date: '2023-12-29',
          reviewCount: 216,
          rating: 216,
          salesRank: 25120
        },
        {
          date: '2023-12-30',
          reviewCount: 216,
          rating: 216,
          salesRank: 24235
        },
        {
          date: '2023-12-31',
          reviewCount: 231,
          rating: 231,
          salesRank: 25554
        },
        {
          date: '2024-01-01',
          reviewCount: 231,
          rating: 231,
          salesRank: 22784
        },
        {
          date: '2024-01-02',
          reviewCount: 231,
          rating: 231,
          salesRank: 22021
        },
        {
          date: '2024-01-03',
          reviewCount: 231,
          rating: 231,
          salesRank: 22824
        },
        {
          date: '2024-01-04',
          reviewCount: 231,
          rating: 231,
          salesRank: 21872
        },
        {
          date: '2024-01-05',
          reviewCount: 231,
          rating: 231,
          salesRank: 21292
        },
        {
          date: '2024-01-06',
          reviewCount: 248,
          rating: 248,
          salesRank: 21160
        },
        {
          date: '2024-01-07',
          reviewCount: 248,
          rating: 248,
          salesRank: 25805
        },
        {
          date: '2024-01-08',
          reviewCount: 248,
          rating: 248,
          salesRank: 25792
        },
        {
          date: '2024-01-09',
          reviewCount: 248,
          rating: 248,
          salesRank: 26670
        },
        {
          date: '2024-01-10',
          reviewCount: 248,
          rating: 248,
          salesRank: 23584
        },
        {
          date: '2024-01-11',
          reviewCount: 248,
          rating: 248,
          salesRank: 22083
        },
        {
          date: '2024-01-12',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 23173
        },
        {
          date: '2024-01-13',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 24843
        },
        {
          date: '2024-01-14',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 27071
        },
        {
          date: '2024-01-15',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 28977
        },
        {
          date: '2024-01-16',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 28260
        },
        {
          date: '2024-01-17',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 25731
        },
        {
          date: '2024-01-18',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 24534
        },
        {
          date: '2024-01-19',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 24672
        },
        {
          date: '2024-01-20',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 22584
        },
        {
          date: '2024-01-21',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 23908
        },
        {
          date: '2024-01-22',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 19648
        },
        {
          date: '2024-01-23',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 19080
        },
        {
          date: '2024-01-24',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 19853
        },
        {
          date: '2024-01-25',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 20955
        },
        {
          date: '2024-01-26',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 21706
        },
        {
          date: '2024-01-27',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 21907
        },
        {
          date: '2024-01-28',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 23575
        },
        {
          date: '2024-01-29',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 23023
        },
        {
          date: '2024-01-30',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 21595
        },
        {
          date: '2024-01-31',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 24009
        },
        {
          date: '2024-02-01',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 28577
        },
        {
          date: '2024-02-02',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 27207
        },
        {
          date: '2024-02-03',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 25452
        },
        {
          date: '2024-02-04',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 25237
        },
        {
          date: '2024-02-05',
          reviewCount: 32726,
          rating: 32726,
          salesRank: 26194
        },
        {
          date: '2024-02-06',
          reviewCount: 32726,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-07',
          reviewCount: 32726,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-08',
          reviewCount: 32726,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-09',
          reviewCount: 32726,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-10',
          reviewCount: 32726,
          rating: 32726,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      createdAt: '2024-04-20T00:17:35.844Z'
    },
    {
      id: 'sample-sku-laundry',
      productName: 'Laundry Detergent',
      productUrl: 'https://www.amazon.com/Arm-Hammer-Laundry-Detergent-Packaging/dp/B0C8RD2XHD',
      productImage: 'https://media.productwind.com/sample/images/laundry.png',
      productPerformanceRecords: [
        {
          date: '2023-08-01',
          reviewCount: 2,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-02',
          reviewCount: 2,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-03',
          reviewCount: 2,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-04',
          reviewCount: 2,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-05',
          reviewCount: 2,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-06',
          reviewCount: 2,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-07',
          reviewCount: 16,
          rating: 1,
          salesRank: null
        },
        {
          date: '2023-08-08',
          reviewCount: 16,
          rating: 1,
          salesRank: 52901
        },
        {
          date: '2023-08-09',
          reviewCount: 16,
          rating: 1,
          salesRank: 41842
        },
        {
          date: '2023-08-10',
          reviewCount: 16,
          rating: 3,
          salesRank: 50006
        },
        {
          date: '2023-08-11',
          reviewCount: 16,
          rating: 3,
          salesRank: 34757
        },
        {
          date: '2023-08-12',
          reviewCount: 25,
          rating: 3,
          salesRank: 37031
        },
        {
          date: '2023-08-13',
          reviewCount: 25,
          rating: 3,
          salesRank: 37999
        },
        {
          date: '2023-08-14',
          reviewCount: 25,
          rating: 3,
          salesRank: 30873
        },
        {
          date: '2023-08-15',
          reviewCount: 25,
          rating: 3,
          salesRank: 21825
        },
        {
          date: '2023-08-16',
          reviewCount: 25,
          rating: 3,
          salesRank: 23208
        },
        {
          date: '2023-08-17',
          reviewCount: 25,
          rating: 3,
          salesRank: 25119
        },
        {
          date: '2023-08-18',
          reviewCount: 54,
          rating: 3,
          salesRank: 19978
        },
        {
          date: '2023-08-19',
          reviewCount: 54,
          rating: 3,
          salesRank: 15383
        },
        {
          date: '2023-08-20',
          reviewCount: 54,
          rating: 7,
          salesRank: 14981
        },
        {
          date: '2023-08-21',
          reviewCount: 54,
          rating: 7,
          salesRank: 10680
        },
        {
          date: '2023-08-22',
          reviewCount: 85,
          rating: 7,
          salesRank: 8129
        },
        {
          date: '2023-08-23',
          reviewCount: 85,
          rating: 7,
          salesRank: 5755
        },
        {
          date: '2023-08-24',
          reviewCount: 85,
          rating: 7,
          salesRank: 5509
        },
        {
          date: '2023-08-25',
          reviewCount: 85,
          rating: 7,
          salesRank: 5414
        },
        {
          date: '2023-08-26',
          reviewCount: 139,
          rating: 7,
          salesRank: 4472
        },
        {
          date: '2023-08-27',
          reviewCount: 139,
          rating: 7,
          salesRank: 4110
        },
        {
          date: '2023-08-28',
          reviewCount: 139,
          rating: 7,
          salesRank: 3157
        },
        {
          date: '2023-08-29',
          reviewCount: 182,
          rating: 7,
          salesRank: 3331
        },
        {
          date: '2023-08-30',
          reviewCount: 182,
          rating: 11,
          salesRank: 2979
        },
        {
          date: '2023-08-31',
          reviewCount: 182,
          rating: 11,
          salesRank: 2207
        },
        {
          date: '2023-09-01',
          reviewCount: 182,
          rating: 11,
          salesRank: 1839
        },
        {
          date: '2023-09-02',
          reviewCount: 247,
          rating: 11,
          salesRank: 1858
        },
        {
          date: '2023-09-03',
          reviewCount: 247,
          rating: 11,
          salesRank: 1538
        },
        {
          date: '2023-09-04',
          reviewCount: 247,
          rating: 11,
          salesRank: 1501
        },
        {
          date: '2023-09-05',
          reviewCount: 294,
          rating: 11,
          salesRank: 1167
        },
        {
          date: '2023-09-06',
          reviewCount: 294,
          rating: 11,
          salesRank: 1119
        },
        {
          date: '2023-09-07',
          reviewCount: 294,
          rating: 11,
          salesRank: 857
        },
        {
          date: '2023-09-08',
          reviewCount: 294,
          rating: 11,
          salesRank: 907
        },
        {
          date: '2023-09-09',
          reviewCount: 346,
          rating: 11,
          salesRank: 934
        },
        {
          date: '2023-09-10',
          reviewCount: 346,
          rating: 22,
          salesRank: 938
        },
        {
          date: '2023-09-11',
          reviewCount: 346,
          rating: 22,
          salesRank: 993
        },
        {
          date: '2023-09-12',
          reviewCount: 346,
          rating: 22,
          salesRank: 866
        },
        {
          date: '2023-09-13',
          reviewCount: 413,
          rating: 22,
          salesRank: 1015
        },
        {
          date: '2023-09-14',
          reviewCount: 413,
          rating: 22,
          salesRank: 860
        },
        {
          date: '2023-09-15',
          reviewCount: 413,
          rating: 22,
          salesRank: 967
        },
        {
          date: '2023-09-16',
          reviewCount: 413,
          rating: 22,
          salesRank: 834
        },
        {
          date: '2023-09-17',
          reviewCount: 479,
          rating: 22,
          salesRank: 836
        },
        {
          date: '2023-09-18',
          reviewCount: 479,
          rating: 26,
          salesRank: 541
        },
        {
          date: '2023-09-19',
          reviewCount: 479,
          rating: 26,
          salesRank: 763
        },
        {
          date: '2023-09-20',
          reviewCount: 533,
          rating: 26,
          salesRank: 532
        },
        {
          date: '2023-09-21',
          reviewCount: 533,
          rating: 26,
          salesRank: 638
        },
        {
          date: '2023-09-22',
          reviewCount: 533,
          rating: 26,
          salesRank: 608
        },
        {
          date: '2023-09-23',
          reviewCount: 533,
          rating: 32,
          salesRank: 735
        },
        {
          date: '2023-09-24',
          reviewCount: 612,
          rating: 32,
          salesRank: 656
        },
        {
          date: '2023-09-25',
          reviewCount: 612,
          rating: 32,
          salesRank: 812
        },
        {
          date: '2023-09-26',
          reviewCount: 612,
          rating: 32,
          salesRank: 606
        },
        {
          date: '2023-09-27',
          reviewCount: 612,
          rating: 32,
          salesRank: 757
        },
        {
          date: '2023-09-28',
          reviewCount: 698,
          rating: 32,
          salesRank: 637
        },
        {
          date: '2023-09-29',
          reviewCount: 698,
          rating: 39,
          salesRank: 675
        },
        {
          date: '2023-09-30',
          reviewCount: 698,
          rating: 39,
          salesRank: 644
        },
        {
          date: '2023-10-01',
          reviewCount: 782,
          rating: 39,
          salesRank: 713
        },
        {
          date: '2023-10-02',
          reviewCount: 782,
          rating: 39,
          salesRank: 414
        },
        {
          date: '2023-10-03',
          reviewCount: 782,
          rating: 39,
          salesRank: 497
        },
        {
          date: '2023-10-04',
          reviewCount: 854,
          rating: 45,
          salesRank: 287
        },
        {
          date: '2023-10-05',
          reviewCount: 854,
          rating: 45,
          salesRank: 347
        },
        {
          date: '2023-10-06',
          reviewCount: 854,
          rating: 45,
          salesRank: 344
        },
        {
          date: '2023-10-07',
          reviewCount: 854,
          rating: 45,
          salesRank: 361
        },
        {
          date: '2023-10-08',
          reviewCount: 959,
          rating: 45,
          salesRank: 279
        },
        {
          date: '2023-10-09',
          reviewCount: 959,
          rating: 49,
          salesRank: 315
        },
        {
          date: '2023-10-10',
          reviewCount: 959,
          rating: 49,
          salesRank: 264
        },
        {
          date: '2023-10-11',
          reviewCount: 959,
          rating: 49,
          salesRank: 358
        },
        {
          date: '2023-10-12',
          reviewCount: 1077,
          rating: 49,
          salesRank: 256
        },
        {
          date: '2023-10-13',
          reviewCount: 1077,
          rating: 49,
          salesRank: 338
        },
        {
          date: '2023-10-14',
          reviewCount: 1077,
          rating: 49,
          salesRank: 221
        },
        {
          date: '2023-10-15',
          reviewCount: 1164,
          rating: 64,
          salesRank: 286
        },
        {
          date: '2023-10-16',
          reviewCount: 1164,
          rating: 64,
          salesRank: 206
        },
        {
          date: '2023-10-17',
          reviewCount: 1164,
          rating: 64,
          salesRank: 288
        },
        {
          date: '2023-10-18',
          reviewCount: 1164,
          rating: 64,
          salesRank: 215
        },
        {
          date: '2023-10-19',
          reviewCount: 1319,
          rating: 64,
          salesRank: 284
        },
        {
          date: '2023-10-20',
          reviewCount: 1319,
          rating: 64,
          salesRank: 370
        },
        {
          date: '2023-10-21',
          reviewCount: 1319,
          rating: 73,
          salesRank: 337
        },
        {
          date: '2023-10-22',
          reviewCount: 1421,
          rating: 73,
          salesRank: 364
        },
        {
          date: '2023-10-23',
          reviewCount: 1421,
          rating: 73,
          salesRank: 339
        },
        {
          date: '2023-10-24',
          reviewCount: 1421,
          rating: 73,
          salesRank: 328
        },
        {
          date: '2023-10-25',
          reviewCount: 1421,
          rating: 73,
          salesRank: 245
        },
        {
          date: '2023-10-26',
          reviewCount: 1539,
          rating: 73,
          salesRank: 298
        },
        {
          date: '2023-10-27',
          reviewCount: 1539,
          rating: 83,
          salesRank: 249
        },
        {
          date: '2023-10-28',
          reviewCount: 1539,
          rating: 83,
          salesRank: 292
        },
        {
          date: '2023-10-29',
          reviewCount: 1671,
          rating: 83,
          salesRank: 180
        },
        {
          date: '2023-10-30',
          reviewCount: 1671,
          rating: 83,
          salesRank: 231
        },
        {
          date: '2023-10-31',
          reviewCount: 1671,
          rating: 83,
          salesRank: 196
        },
        {
          date: '2023-11-01',
          reviewCount: 1671,
          rating: 83,
          salesRank: 220
        },
        {
          date: '2023-11-02',
          reviewCount: 2018,
          rating: 97,
          salesRank: 212
        },
        {
          date: '2023-11-03',
          reviewCount: 2018,
          rating: 97,
          salesRank: 274
        },
        {
          date: '2023-11-04',
          reviewCount: 2018,
          rating: 97,
          salesRank: 276
        },
        {
          date: '2023-11-05',
          reviewCount: 2190,
          rating: 97,
          salesRank: 326
        },
        {
          date: '2023-11-06',
          reviewCount: 2190,
          rating: 97,
          salesRank: 295
        },
        {
          date: '2023-11-07',
          reviewCount: 2190,
          rating: 97,
          salesRank: 336
        },
        {
          date: '2023-11-08',
          reviewCount: 2190,
          rating: 102,
          salesRank: 304
        },
        {
          date: '2023-11-09',
          reviewCount: 2537,
          rating: 102,
          salesRank: 327
        },
        {
          date: '2023-11-10',
          reviewCount: 2537,
          rating: 102,
          salesRank: 276
        },
        {
          date: '2023-11-11',
          reviewCount: 2537,
          rating: 102,
          salesRank: 263
        },
        {
          date: '2023-11-12',
          reviewCount: 2537,
          rating: 102,
          salesRank: 260
        },
        {
          date: '2023-11-13',
          reviewCount: 3024,
          rating: 102,
          salesRank: 302
        },
        {
          date: '2023-11-14',
          reviewCount: 3024,
          rating: 106,
          salesRank: 274
        },
        {
          date: '2023-11-15',
          reviewCount: 3024,
          rating: 106,
          salesRank: 322
        },
        {
          date: '2023-11-16',
          reviewCount: 3294,
          rating: 106,
          salesRank: 331
        },
        {
          date: '2023-11-17',
          reviewCount: 3294,
          rating: 106,
          salesRank: 388
        },
        {
          date: '2023-11-18',
          reviewCount: 3294,
          rating: 106,
          salesRank: 342
        },
        {
          date: '2023-11-19',
          reviewCount: 3294,
          rating: 106,
          salesRank: 406
        },
        {
          date: '2023-11-20',
          reviewCount: 3494,
          rating: 107,
          salesRank: 379
        },
        {
          date: '2023-11-21',
          reviewCount: 3494,
          rating: 107,
          salesRank: 418
        },
        {
          date: '2023-11-22',
          reviewCount: 3494,
          rating: 107,
          salesRank: 394
        },
        {
          date: '2023-11-23',
          reviewCount: 3649,
          rating: 107,
          salesRank: 404
        },
        {
          date: '2023-11-24',
          reviewCount: 3649,
          rating: 107,
          salesRank: 317
        },
        {
          date: '2023-11-25',
          reviewCount: 3649,
          rating: 107,
          salesRank: 349
        },
        {
          date: '2023-11-26',
          reviewCount: 3649,
          rating: 125,
          salesRank: 270
        },
        {
          date: '2023-11-27',
          reviewCount: 3904,
          rating: 125,
          salesRank: 284
        },
        {
          date: '2023-11-28',
          reviewCount: 3904,
          rating: 125,
          salesRank: 261
        },
        {
          date: '2023-11-29',
          reviewCount: 3904,
          rating: 125,
          salesRank: 300
        },
        {
          date: '2023-11-30',
          reviewCount: 4067,
          rating: 125,
          salesRank: 344
        },
        {
          date: '2023-12-01',
          reviewCount: 4067,
          rating: 125,
          salesRank: 287
        },
        {
          date: '2023-12-02',
          reviewCount: 4067,
          rating: 139,
          salesRank: 354
        },
        {
          date: '2023-12-03',
          reviewCount: 4067,
          rating: 139,
          salesRank: 287
        },
        {
          date: '2023-12-04',
          reviewCount: 4248,
          rating: 139,
          salesRank: 231
        },
        {
          date: '2023-12-05',
          reviewCount: 4248,
          rating: 139,
          salesRank: 203
        },
        {
          date: '2023-12-06',
          reviewCount: 4248,
          rating: 139,
          salesRank: 228
        },
        {
          date: '2023-12-07',
          reviewCount: 4248,
          rating: 139,
          salesRank: 241
        },
        {
          date: '2023-12-08',
          reviewCount: 4412,
          rating: 164,
          salesRank: 228
        },
        {
          date: '2023-12-09',
          reviewCount: 4412,
          rating: 164,
          salesRank: 212
        },
        {
          date: '2023-12-10',
          reviewCount: 4412,
          rating: 164,
          salesRank: 231
        },
        {
          date: '2023-12-11',
          reviewCount: 4412,
          rating: 164,
          salesRank: 225
        },
        {
          date: '2023-12-12',
          reviewCount: 4582,
          rating: 164,
          salesRank: 218
        },
        {
          date: '2023-12-13',
          reviewCount: 4582,
          rating: 176,
          salesRank: 217
        },
        {
          date: '2023-12-14',
          reviewCount: 4582,
          rating: 176,
          salesRank: 236
        },
        {
          date: '2023-12-15',
          reviewCount: 4582,
          rating: 176,
          salesRank: 268
        },
        {
          date: '2023-12-16',
          reviewCount: 4713,
          rating: 176,
          salesRank: 261
        },
        {
          date: '2023-12-17',
          reviewCount: 4713,
          rating: 176,
          salesRank: 263
        },
        {
          date: '2023-12-18',
          reviewCount: 4713,
          rating: 176,
          salesRank: 263
        },
        {
          date: '2023-12-19',
          reviewCount: 4789,
          rating: 197,
          salesRank: 272
        },
        {
          date: '2023-12-20',
          reviewCount: 4789,
          rating: 197,
          salesRank: 246
        },
        {
          date: '2023-12-21',
          reviewCount: 4789,
          rating: 197,
          salesRank: 288
        },
        {
          date: '2023-12-22',
          reviewCount: 4789,
          rating: 197,
          salesRank: 273
        },
        {
          date: '2023-12-23',
          reviewCount: 4893,
          rating: 197,
          salesRank: 259
        },
        {
          date: '2023-12-24',
          reviewCount: 4893,
          rating: 197,
          salesRank: 215
        },
        {
          date: '2023-12-25',
          reviewCount: 4893,
          rating: 216,
          salesRank: 233
        },
        {
          date: '2023-12-26',
          reviewCount: 4893,
          rating: 216,
          salesRank: 286
        },
        {
          date: '2023-12-27',
          reviewCount: 4997,
          rating: 216,
          salesRank: 295
        },
        {
          date: '2023-12-28',
          reviewCount: 4997,
          rating: 216,
          salesRank: 369
        },
        {
          date: '2023-12-29',
          reviewCount: 4997,
          rating: 216,
          salesRank: 338
        },
        {
          date: '2023-12-30',
          reviewCount: 4997,
          rating: 216,
          salesRank: 413
        },
        {
          date: '2023-12-31',
          reviewCount: 5133,
          rating: 231,
          salesRank: 360
        },
        {
          date: '2024-01-01',
          reviewCount: 5133,
          rating: 231,
          salesRank: 425
        },
        {
          date: '2024-01-02',
          reviewCount: 5133,
          rating: 231,
          salesRank: 396
        },
        {
          date: '2024-01-03',
          reviewCount: 5255,
          rating: 231,
          salesRank: 435
        },
        {
          date: '2024-01-04',
          reviewCount: 5255,
          rating: 231,
          salesRank: 378
        },
        {
          date: '2024-01-05',
          reviewCount: 5255,
          rating: 231,
          salesRank: 404
        },
        {
          date: '2024-01-06',
          reviewCount: 5349,
          rating: 248,
          salesRank: 325
        },
        {
          date: '2024-01-07',
          reviewCount: 5349,
          rating: 248,
          salesRank: 402
        },
        {
          date: '2024-01-08',
          reviewCount: 5349,
          rating: 248,
          salesRank: 342
        },
        {
          date: '2024-01-09',
          reviewCount: 5349,
          rating: 248,
          salesRank: 402
        },
        {
          date: '2024-01-10',
          reviewCount: 5497,
          rating: 248,
          salesRank: 350
        },
        {
          date: '2024-01-11',
          reviewCount: 5497,
          rating: 248,
          salesRank: 350
        },
        {
          date: '2024-01-12',
          reviewCount: 5497,
          rating: 32726,
          salesRank: 383
        },
        {
          date: '2024-01-13',
          reviewCount: 5587,
          rating: 32726,
          salesRank: 31
        },
        {
          date: '2024-01-14',
          reviewCount: 5587,
          rating: 32726,
          salesRank: 27
        },
        {
          date: '2024-01-15',
          reviewCount: 5587,
          rating: 32726,
          salesRank: 23
        },
        {
          date: '2024-01-16',
          reviewCount: 5689,
          rating: 32726,
          salesRank: 28
        },
        {
          date: '2024-01-17',
          reviewCount: 5689,
          rating: 32726,
          salesRank: 40
        },
        {
          date: '2024-01-18',
          reviewCount: 5689,
          rating: 32726,
          salesRank: 60
        },
        {
          date: '2024-01-19',
          reviewCount: 5689,
          rating: 32726,
          salesRank: 78
        },
        {
          date: '2024-01-20',
          reviewCount: 5813,
          rating: 32726,
          salesRank: 84
        },
        {
          date: '2024-01-21',
          reviewCount: 5813,
          rating: 32726,
          salesRank: 121
        },
        {
          date: '2024-01-22',
          reviewCount: 5813,
          rating: 32726,
          salesRank: 139
        },
        {
          date: '2024-01-23',
          reviewCount: 5911,
          rating: 32726,
          salesRank: 206
        },
        {
          date: '2024-01-24',
          reviewCount: 5911,
          rating: 32726,
          salesRank: 198
        },
        {
          date: '2024-01-25',
          reviewCount: 5911,
          rating: 32726,
          salesRank: 195
        },
        {
          date: '2024-01-26',
          reviewCount: 5996,
          rating: 32726,
          salesRank: 214
        },
        {
          date: '2024-01-27',
          reviewCount: 5996,
          rating: 32726,
          salesRank: 243
        },
        {
          date: '2024-01-28',
          reviewCount: 5996,
          rating: 32726,
          salesRank: 272
        },
        {
          date: '2024-01-29',
          reviewCount: 5996,
          rating: 32726,
          salesRank: 283
        },
        {
          date: '2024-01-30',
          reviewCount: 6133,
          rating: 32726,
          salesRank: 268
        },
        {
          date: '2024-01-31',
          reviewCount: 6133,
          rating: 32726,
          salesRank: 258
        },
        {
          date: '2024-02-01',
          reviewCount: 6133,
          rating: 32726,
          salesRank: 271
        },
        {
          date: '2024-02-02',
          reviewCount: 6133,
          rating: 32726,
          salesRank: 291
        },
        {
          date: '2024-02-03',
          reviewCount: 6295,
          rating: 32726,
          salesRank: 305
        },
        {
          date: '2024-02-04',
          reviewCount: 6295,
          rating: 32726,
          salesRank: 312
        },
        {
          date: '2024-02-05',
          reviewCount: 6295,
          rating: 32726,
          salesRank: 309
        },
        {
          date: '2024-02-06',
          reviewCount: 6410,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-07',
          reviewCount: 6410,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-08',
          reviewCount: 6410,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-09',
          reviewCount: 6538,
          rating: 32726,
          salesRank: null
        },
        {
          date: '2024-02-10',
          reviewCount: 6538,
          rating: 32726,
          salesRank: null
        }
      ],
      reviewDataUploaded: true,
      bestSellerRankDataUploaded: true,
      isVisible: true,
      createdAt: '2024-04-15T20:49:15.661Z'
    }
  ]
}
