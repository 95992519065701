import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { creatorProductsFormValidationSchema } from '@momentum/routes/proposals-create/validations'
import { determineNumberReviewsForNextLevel, getNextLevelRating } from '@momentum/utils/proposalUtils'
import {
  calculateResults,
  getReviewPercentage,
  getReviewRate,
  getReviewsAllowedPerWeek,
  STORE_TO_RETAILER
} from '@productwindtom/shared-momentum'
import { ProposalStatus, STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { keyBy, maxBy, sum } from 'lodash'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTimelineSteps } from '@momentum/routes/proposals-create/common/useTimelineSteps'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import { notEmpty } from '@productwindtom/shared-node'

export default function useEstimatedDeliverables() {
  const { brand } = useBrandContext()
  const { selectedProduct, selectableProducts } = useCreateProposalContext()
  const { watch, setValue } = useFormContext<ProposalCreateForm>()
  const formData = watch()
  const isValid = creatorProductsFormValidationSchema.isValidSync(formData)
  const keyedProducts = keyBy(selectableProducts, 'id')

  const variations = selectedProduct?.childrenIds?.map(v => keyedProducts[v]).filter(notEmpty)
  const selectedVariations =
    formData.productVariationSkus &&
    variations?.filter(v => v.skuId && formData.productVariationSkus?.includes(v.skuId))
  const mostExpensiveVariation = maxBy(selectedVariations, 'priceCents')

  const productCost = formData.productPriceOverride || mostExpensiveVariation?.priceCents || selectedProduct?.priceCents

  const markAsPublished = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setValue('status', ProposalStatus.PUBLISHED)
    setValue('statusChangedAt', new Date().toISOString(), { shouldDirty: true, shouldValidate: true })
    e.currentTarget.click()
  }

  const markAsDraft = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setValue('status', ProposalStatus.DRAFT)
    setValue('statusChangedAt', new Date().toISOString(), { shouldDirty: true, shouldValidate: true })
    e.currentTarget.click()
  }
  const locale = selectedProduct?.store ? STORE_TO_LOCALE[selectedProduct?.store] : 'US'

  const results =
    productCost && isValid
      ? calculateResults(formData.goal, {
          creators: formData.creatorPricing || [],
          ratingGoal: formData.ratingGoal,
          productCost: productCost,
          exchangeRate: formData.exchangeRate,
          store: selectedProduct?.store,
          ratingsSummary: selectedProduct?.ratingSummary,
          estimatedAverageRating: formData.estimatedAverageRating
        })
      : undefined

  const unitsSoldPerWeek = (formData.estimatedUnitsSoldPerMonth || 0) / 4
  const isVendor = brand.brandApis.some(b => b.api.includes('vendor') && b.enabled)

  const { isMonthly, timelineSteps, durationWeeks } = useTimelineSteps({
    goal: formData.goal,
    creatorPricing: formData.creatorPricing,
    launchDate: formData.launchDate,
    reviewsGoal: results?.numReviews,
    estimatedUnitsSoldPerWeek: unitsSoldPerWeek,
    isVendor: isVendor
  })

  useEffect(() => {
    if (results) {
      setValue('totalCredits', results.totalCostCredits)
      setValue('totalProductCostCredits', results.totalProductCostCredits)
    }
  }, [results?.totalCostCredits, results?.totalProductCostCredits])

  useEffect(() => {
    setValue('productPrice', productCost)
  }, [productCost, setValue])

  const isDisplayResults = isValid && results

  const nextLevelRating = selectedProduct?.ratingSummary?.rating
    ? getNextLevelRating(selectedProduct.ratingSummary.rating)
    : undefined
  const reviewsRequired =
    selectedProduct?.ratingSummary?.rating != null && selectedProduct.ratingSummary?.numRatings != null
      ? determineNumberReviewsForNextLevel(
          selectedProduct.ratingSummary.rating,
          selectedProduct.ratingSummary.numRatings
        )
      : undefined

  const creatorsRequired = reviewsRequired
    ? Math.ceil(
        reviewsRequired / getReviewRate(selectedProduct?.store ? STORE_TO_RETAILER[selectedProduct?.store] : undefined)
      )
    : undefined

  const reviewsPerWeek = getReviewsAllowedPerWeek(unitsSoldPerWeek, selectedProduct?.store, isVendor)
  const reviewsPercentage = getReviewPercentage(selectedProduct?.store, isVendor)
  return {
    ...results,
    nextLevelRating,
    creatorsRequiredForNextLevel: creatorsRequired,
    totalCreators: sum((formData.creatorPricing || []).map(f => f.numCreators)),
    locale,
    markAsPublished,
    markAsDraft,
    productPrice: productCost,
    selectedProduct,
    isDisplayResults,
    isMonthly,
    timelineSteps,
    durationWeeks,
    reviewsPercentage,
    reviewsPerWeek: reviewsPerWeek || 0
  }
}
