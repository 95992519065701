import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { productSelector } from './selectors'

export const createProductFromUrl = async (input: ValueTypes['CreateProductFromUrlInput']) =>
  (
    await mApi('mutation')({
      createProductFromUrl: [
        {
          input
        },
        productSelector
      ]
    })
  )?.createProductFromUrl

export const createManualProduct = async (input: ValueTypes['ProductInput']) => {
  return (
    await mApi('mutation')({
      createManualProduct: [
        {
          input
        },
        productSelector
      ]
    })
  ).createManualProduct
}

export const adjustProductRecommendationVisibility = async (
  input: ValueTypes['AdjustProductRecommendationVisibilityInput']
) => {
  return (
    await mApi('mutation')({
      adjustProductRecommendationVisibility: [
        {
          input
        },
        productSelector
      ]
    })
  ).adjustProductRecommendationVisibility
}
