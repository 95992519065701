import { PricingCreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { chunk, round, sum, sumBy } from 'lodash'
import { stripEmpty } from '@productwindtom/shared-node'

type GetAutoBuysDistributionInput = {
  creatorPricing: { type: PricingCreatorType; numCreators: number }[]
}

/*
Get distribution as per https://docs.google.com/spreadsheets/d/1mstJsbiBIx1xldPpqMOTGBRqyKppBjM538XgXTsmHgk/edit
 */
export const getAutoBuysDistribution = ({ creatorPricing }: GetAutoBuysDistributionInput) => {
  const totalCreators = sumBy(creatorPricing, 'numCreators')
  const config = typeToDistribution.find(
    c => totalCreators >= c.minCreators && (!c.maxCreators || totalCreators <= c.maxCreators)
  )?.config
  if (!config) {
    throw new Error('Invalid number of creators')
  }

  const onlyOne = creatorPricing.filter(cp => !!cp.numCreators).length <= 1

  return creatorPricing.map(cp => {
    const typeConfig = config[cp.type]

    const creatorSchedule = typeConfig.distributionPercentage.map(p => round(p * cp.numCreators))
    const sumDistributed = sumBy(creatorSchedule)
    const diff = cp.numCreators - sumDistributed
    const dailySchedule = diff === 0 ? creatorSchedule : adjustOffDistributed(creatorSchedule, diff)
    return { type: cp.type, dailySchedule: onlyOne ? stripEmpty(dailySchedule) : dailySchedule }
  })
}

const adjustOffDistributed = (distributed: number[], extra: number) => {
  let index = distributed.length - 1
  while (extra !== 0 && index >= 0) {
    const diff = extra > 0 ? 1 : -1
    if (distributed[index] + diff >= 0) {
      distributed[index] += diff
      extra -= diff
    }
    index--
  }
  return distributed
}

const typeToDistribution: {
  minCreators: number
  maxCreators?: number
  config: {
    [key in PricingCreatorType]: {
      distributionPercentage: number[]
    }
  }
}[] = [
  {
    minCreators: 0,
    maxCreators: 50,
    config: {
      [PricingCreatorType.ADVOCATE]: {
        distributionPercentage: [0, 0, 0, 0, 0.1, 0.2, 0.3, 0.25, 0.15]
      },
      [PricingCreatorType.UGC]: {
        distributionPercentage: [0.1106, 0.1438, 0.187, 0.2431, 0.3155]
      },
      [PricingCreatorType.SOCIAL]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      },
      [PricingCreatorType.PREMIUM_UGC]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      }
    }
  },
  {
    minCreators: 51,
    maxCreators: 499,
    config: {
      [PricingCreatorType.ADVOCATE]: {
        distributionPercentage: [0, 0, 0, 0, 0, 0, 0, 0, 0.05, 0.08, 0.1, 0.12, 0.14, 0.15, 0.13, 0.11, 0.07, 0.05]
      },
      [PricingCreatorType.UGC]: {
        distributionPercentage: [0.0384, 0.0461, 0.0553, 0.0663, 0.0796, 0.0955, 0.1146, 0.1375, 0.1649, 0.1978]
      },
      [PricingCreatorType.SOCIAL]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      },
      [PricingCreatorType.PREMIUM_UGC]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      }
    }
  },
  {
    minCreators: 500,
    config: {
      [PricingCreatorType.ADVOCATE]: {
        distributionPercentage: [
          0, 0, 0, 0, 0, 0, 0, 0, 0.01, 0.03, 0.05, 0.07, 0.09, 0.011, 0.13, 0.15, 0.13, 0.11, 0.09, 0.07, 0.05, 0.03,
          0.01, 0.01
        ]
      },
      [PricingCreatorType.UGC]: {
        distributionPercentage: [0.0384, 0.0461, 0.0533, 0.0663, 0.0796, 0.0955, 0.1146, 0.1375, 0.1649, 0.1978]
      },
      [PricingCreatorType.SOCIAL]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      },
      [PricingCreatorType.PREMIUM_UGC]: {
        distributionPercentage: [0.2, 0.2, 0.2, 0.2, 0.2]
      }
    }
  }
]

// Convert daily distribution to weekly distribution
export const toWeeklyPartition = (dailyDistribution?: number[]) => {
  return chunk(dailyDistribution || [], 7).map(sum)
}
